import React, { Component } from 'react';
import Parse from 'parse';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import InputField from '../../components/InputField';
import Toast from '../../components/Toast';
import Button from '../../components/Button';
import ErrorBox from '../../components/ErrorBox';
import InfoBox from '../../components/InfoBox';
import Loader from '../../components/Loader';

import { withGA } from '../../utils/googleAnalytics';
import { checkPasswordFormat } from '../../utils/regex';
import { SETTINGS, ACCOUNT_SETTINGS_TITLE, ERR_PASSWORD_REQUIREMENTS, INFO_BOX_TYPE } from '../../constants';

class AccountSettings extends Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      infusionsoftId: '',
      errorMsg: '',
      passwordErrorMsg: '',
      mobileNumber: '',
      toast: null,
      infoMsg: null,
      isUserInfoLoading: true,
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    };
    this.toastTimeout = 3000;
    this.isAdmin = false;
  }

  async componentDidMount() {
    const { email } = Parse.User.current().attributes;
    this.isAdmin = await Parse.Cloud.run('isAdmin');
    this.checkValidatedEmail();
    this.validateForms();

    const { updateUserInformation } = this.props;
    let { firstName, lastName, phoneNumber, infusionSoftId } = Parse.User.current().attributes;

    if (!this.isAdmin && (!firstName || !lastName)) {
      // retrieve data from infusionsoft instead
      const userInfo = await updateUserInformation();
      firstName = _.get(userInfo, 'given_name');
      lastName = _.get(userInfo, 'family_name');
      phoneNumber = _.get(userInfo, 'phoneNumber');
      // also update to user data
      const user = Parse.User.current();
      if (firstName) user.set('firstName', firstName);
      if (lastName) user.set('lastName', lastName);
      if (phoneNumber) user.set('phoneNumber', phoneNumber);
      user.save();
    }

    this.setState({ email, firstName, lastName, mobileNumber: phoneNumber, infusionsoftId: infusionSoftId, isUserInfoLoading: false });

    document.title = ACCOUNT_SETTINGS_TITLE;
  }

  validateForms = () => {
    const forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if ((form.checkValidity() === false)
        || !(this.isValidPassword(this.newPassword))
        || !(this.isValidPassword(this.newPasswordConfirm))) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  checkValidatedEmail() {
    const currentUser = Parse.User.current();
    if (!currentUser.get("emailVerified")) {
      this.setState({ infoMsg: 'Please Validate Your Email' });
    } else {
      this.setState({ infoMsg: '' });
    }
  }

  isValidPassword = (password) => {
    return checkPasswordFormat.test(password);
  };

  async updateUserInfo(e) {
    try {
      this.setState({ errorMsg: '' });
      e.preventDefault();
      const { firstName, lastName, email, infusionsoftId, mobileNumber } = this.state;
      const currentUserEmail = Parse.User.current().get('email');

      if (currentUserEmail !== email ) {
        // user request changed email, reverify email
        if (!this.isAdmin) {
          await Parse.Cloud.run('updateEmail', { email });
        } else {
          await Parse.Cloud.run('updateAdminEmail', { email });
        }
        this.checkValidatedEmail();
      }

      if (!this.isAdmin) {
        const userInfo = {
          given_name: firstName,
          family_name: lastName,
          email_addresses: [{ email, field: 'EMAIL1' }], // 'EMAIL1' Field name is specific by infusionsoft
          phone_numbers: [{
            field: 'PHONE2',
            number: mobileNumber,
            extension: '',
            type: 'Other',
          }],
          id: infusionsoftId,
        };
        const result = await Parse.Cloud.run('updateUserInformation', { userInfo })
        if (result === 'success') {
          await Parse.User.current().fetch();
        } else {
          throw new Parse.Error(500, 'Fail to update email on infusionsoft system');
        }
      } else {
        await Parse.Cloud.run('updateAdminInformation', { firstName, lastName });
        await Parse.User.current().fetch();
      }
      this.setState({ toast: { type: 'success', message: 'Saved' } });
      setTimeout(() => this.hideNotification(), this.toastTimeout);

    } catch (error) {
      this.setState({ errorMsg: error.message });
    }
  }

  async updateUserPassword(e) {
    e.preventDefault();
    this.setState({ passwordErrorMsg: '' });
    const { password, newPassword, newPasswordConfirm } = this.state;
    if (newPasswordConfirm !== newPassword) {
      this.setState({ passwordErrorMsg: 'New passwords don\'t match' });
      return;
    }

    // admin will skip checking old password - todo - improve this one, currently we could not check current password with Parse Server
    if (!this.isAdmin && password === newPassword) {
      this.setState({ passwordErrorMsg: 'Your new password has to be different from your current password.' });
      return;
    }

    if (!this.isValidPassword(newPassword)) {
      this.setState({ passwordErrorMsg: ERR_PASSWORD_REQUIREMENTS });
      return;
    }

    try {
      //token is for admin only since we reset password to Parse.Object
      //normal users will reset password on infusionsoft, so no need to reset token for normal users
      const token = await Parse.Cloud.run('updateUserPassword', { newPassword, password });
      if (this.isAdmin) await Parse.User.become(token);

      // empty password fields, after successfully set password
      document.getElementById('password-reset-form').reset();
      await this.setState({ toast: { type: 'success', message: 'Saved'} , password: '' , newPassword: '', newPasswordConfirm: '' });
      setTimeout(() => this.hideNotification(), this.toastTimeout);

    } catch (error) {
      if (error.code === 401) {
        this.setState({ passwordErrorMsg: 'Your old password does not match the one in our system' });
      } else {
        console.log('Fail to reset password', error);
        this.setState({ passwordErrorMsg: 'Internal Server Error' });
      }
    }
  }

  hideNotification = () => {
    this.setState({ toast: null });
  }

  render() {
    const { errorMsg, firstName, lastName, email, passwordErrorMsg, mobileNumber, toast, infoMsg, 
      isUserInfoLoading, password, newPassword, newPasswordConfirm } = this.state;

    return (
      <div className="container mt-5 p-0">
        <div>
          <ErrorBox errorMsg={errorMsg} />
          <InfoBox infoMsg={infoMsg} type={INFO_BOX_TYPE.INFO} />
        </div>

        <form id="account-form" className="needs-validation" onSubmit={e => this.updateUserInfo(e)}>
          <div className="row">
            { isUserInfoLoading ? (
               <div className="col-8 d-flex justify-content-center align-items-center">
                  <Loader />
               </div>
            ) : (
              <div className="col-8">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <InputField
                      label="First Name"
                      required
                      type="text"
                      id="form-first-name"
                      controlled
                      value={firstName}
                      onInputChange={input => this.setState({ firstName: input })}
                      />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <InputField
                      label="Last Name"
                      required
                      type="text"
                      id="form-last-name"
                      controlled
                      value={lastName}
                      onInputChange={(input) => this.setState({ lastName: input })}
                      />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <InputField
                      label="Email Address"
                      required
                      type="email"
                      id="form-email"
                      controlled
                      value={email}
                      onInputChange={input => this.setState({ email: input })}
                      />
                  </div>
                </div>
              </div>
              { !this.isAdmin && (
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <InputField
                        label="Mobile Number (Optional)"
                        placeholder="(555)-555-5555"
                        type="tel"
                        id="form-mobile"
                        controlled
                        value={mobileNumber}
                        onInputChange={(input) => { this.setState({ mobileNumber: input }); }}
                        />
                    </div>
                  </div>
                </div>
              ) }
              <Button dashColor="outline-primary" dashClass="mt-2" type="submit" name="Update Information" />
            </div>
            ) }
            

            <div className="col">
              <div className="alert alert-white py-4 border" style={{marginTop: '2em'}}>
                <h3><i className="fe fe-info mr-3 d-inline-block" style={{transform: 'translateY(1px)'}}></i>Account Information</h3>
                <small>
                  <p className="text-muted">You&apos;ll use this information to log in and out. We&#39;ll also send you the occasional message based on your Notification Settings.</p>
                  <p className="text-muted mb-0"><a href={SETTINGS.PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">Read our Privacy Policy</a></p>
                </small>
              </div>
            </div>
          </div>
        </form>

        <hr className="mt-4 mb-5"/>

        <form id="password-reset-form" onSubmit={e => this.updateUserPassword(e)} >
          <h3 className="header-container header-title mb-4">Change Password</h3>
          <div className="row">
            <div className="col-8">
              <div>
                <ErrorBox errorMsg={passwordErrorMsg} />
              </div>
              { !this.isAdmin && (
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <InputField 
                        label="Current Password"
                        type="password"
                        id="form-password"
                        required 
                        controlled
                        value={password}
                        onInputChange={input => this.setState({ password: input })}
                      />
                    </div>
                  </div>
                </div>
              ) }
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <InputField
                      label="New Password"
                      type="password"
                      id="form-new-password"
                      required
                      controlled
                      value={newPassword}
                      onInputChange={input => this.setState({ newPassword: input })}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <InputField 
                      label="Confirm New Password" 
                      type="password" 
                      id="form-new-password-confirm" 
                      required
                      controlled
                      value={newPasswordConfirm}
                      onInputChange={input => this.setState({ newPasswordConfirm: input })}
                    />
                  </div>
                </div>
              </div>
              <Button dashColor="outline-primary" dashClass="mt-2" type="submit" name="Update Password" />
              <Toast notification={toast} onClose={() => this.hideNotification()} />
            </div>
            <div className="col">
              <div className="alert alert-white py-4 border" style={{marginTop: '2em'}}>
                <h3><i className="fe fe-shield mr-3 d-inline-block" style={{transform: 'translateY(1px)'}}></i>Password Requirements</h3>
                <p className="text-muted">To keep your account secure, your new password must meet the following rules:</p>
                <small>
                  <ul className="text-muted" style={{paddingLeft: '18px', lineHeight: '2em'}}>
                    <li className="card-subtitle">Minimum 8 characters</li>
                    <li className="card-subtitle">At least one special character (@!#$%)</li>
                    <li className="card-subtitle">At least one number (0-9)</li>
                  </ul>
                </small>
                <p className="text-muted mb-0">Please note that changing you current password will update your password on record for Investors Alley as well as Divcaster.</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withGA(withRouter(AccountSettings));
