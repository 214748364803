import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import { withGA } from '../../utils/googleAnalytics';
import { getTrackingCode } from '../../utils/trackingCode';

import Button from '../../components/Button';
import ErrorBox from '../../components/ErrorBox';
import ActionLink from '../../components/ActionLink';
import InputField from '../../components/InputField';
import { SETTINGS, SIGN_UP_TITLE, ERR_INTERNAL_ERROR } from '../../constants';
import { eventUserAccountCreationStarted, eventUserEntersEmailAddress } from '../../utils/googleAnalytics';

import './SignupPage.css';

class SignupPage extends Component {
  static propTypes = {
    setPage: PropTypes.func.isRequired,
    updateUserInfo: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
      errorMessage: null,
      email: '',
      trackingCode: getTrackingCode(),
    };
  }

  componentDidMount() {
    eventUserAccountCreationStarted();
    document.title = SIGN_UP_TITLE;
    
    const { email } = this.props;
    if (email) {
      this.setState({ email });
    }
  }

  /**
   * Get user information form infusionsoft
   * If user never have IA acc before create one for user
   */
  async getUserInformation(e, email, trackingCode) {
    e.preventDefault();
    const { setPage, updateUserInfo } = this.props;
    this.setState({ errorMessage: null, buttonDisabled: true }); // clear error message

    try {
      // check if the email is already registered with divcaster
      const query = new Parse.Query(Parse.User);
      query.equalTo('email', email.toLowerCase());
      let user = await query.first();
      if (user) throw new Parse.Error(400, 'This email is already used!');

      let userInfo = await Parse.Cloud.run('getUserInformation', { email });

      if (userInfo && !userInfo.isUnderProcessingSignup) {
        userInfo.existingCustomer = true;
      } else {
        userInfo = {
          email_addresses: [{
            email: email.toLowerCase()
          }]
        }
      }

      updateUserInfo(userInfo);
      setPage(SETTINGS.ONBOARDING.PLAN_PAGE);
      eventUserEntersEmailAddress();
    } catch (error) {
      let errorMessage;

      // 401 (code) = infusionsoft token is not correct
      // if it's not infusionsoft token error, display the actual error message
      if (error.code === 401 || error === 500 ) {
        errorMessage = ERR_INTERNAL_ERROR;
      } else {
        errorMessage = error.message;
      }

      console.log('Sign-up Error', error);
      this.setState({ errorMessage: errorMessage, buttonDisabled: false });
    }
  }

  render() {
    const { buttonDisabled, errorMessage, email, trackingCode } = this.state;
    const { history, mainErrorMessage } = this.props;

    return (
      <div className="auth-wrapper d-flex align-items-center bg-auth border-top border-top-2 border-primary">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <img src="/img/divcaster-logo.svg" alt="Divcaster by Investors Alley" style={{width: 144, margin: 0, maxWidth: 144}}/>
                </div>
                <div className="card-body">
                  <form id="validate-email-signup" className={email.length > 0 ? 'was-validated' : ''} onSubmit={(e) => { this.getUserInformation(e, email, trackingCode); }}>
                    <h2 className="muted-text mt--2 mb-3">
                      Welcome!
                    </h2>
                    <p className="lead muted-text">Enter your email below to begin maximizing the income potential of your investment portfolio.</p>
                    <ErrorBox errorMsg={mainErrorMessage ? mainErrorMessage : errorMessage} />
                    <InputField
                      required
                      id="email"
                      type="email"
                      label=""
                      placeholder="Enter your email"
                      controlled
                      value={email}
                      onInputChange={input => this.setState({ email: input })}
                    />
                    <div className="my-3">
                      <Button type="submit"
                        name="Yes, let's start maximizing my earnings!"
                        dashColor="primary"
                        dashClass="btn-block"
                        disabled={buttonDisabled}
                        loading={buttonDisabled} />  {/* Loading status is same as buttonDisabled*/}
                    </div>
                  </form>
                </div>
                <div className="card-footer bordered">
                  <small className="d-flex justify-content-center text-muted">
                    Already have an account?&nbsp;
                    <ActionLink text="Log In" clickAction={() => { history.push('login'); }} />
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGA(withRouter(SignupPage));
