import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Parse from 'parse';
import FileSaver from 'file-saver';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import MetaTags from 'react-meta-tags';

import Tab from '../../components/Tab';
import Tabs from '../../containers/Tabs';
import Toast from '../../components/Toast';
import Table from '../../containers/Table';
import Modal from '../../containers/Modal';
import NoTransaction from './NoTransaction';
import Loader from '../../components/Loader';
import Header from '../../components/Header';
import Button from '../../components/Button';
import History from '../../containers/History';
import Forecast from '../../containers/Forecast';
import AddTicker from '../../containers/AddTicker';
import VideoPlayer from '../../components/VideoPlayer';
import InfoSidebar from '../../containers/InfoSidebar';
import ShowHideColumns from '../../modals/ShowHideColumns';
import ScenariosContent from '../../modals/ScenariosContent';
import PortfolioSideModal from '../../modals/PortfolioSideModal';
import ImportTransactions from '../../modals/ImportTransactions';
import BillingModalContent from '../../modals/BillingModalContent';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import UnlinkPortfolioConfirmation from '../../modals/UnlinkPortfolioConfirmation';
import RefreshPlaidConnection from '../../modals/RefreshPlaidConnection';

import getTDH from '../../utils/getTDH';
import getConfig from '../../utils/getConfig';
import { serializer } from '../../utils/sessionStorage';
import { portfolioHeaderScroll } from '../../utils/jquery';

import { withGA, eventPortfolioDeleted, eventWhatIfScenarioDeleted, eventTDHDuplicated, eventTrainingVideoStarted } from '../../utils/googleAnalytics';

import {
  getDefaultPortfolio, deletePortfolio, getFirstPortfolio, setDefaultPortfolio, getScenarios, deleteScenario, renameScenario,
  queryByPlotIndex, duplicatePortfolio, updatePlotIndex, deleteTransactionsBySymbol, deleteTransactionsByIds, isTDH, getPortfolio,
  getInvestmentInstitutionById, unlinkPortfolio,
} from '../../parse';

import {
  ERR_IMPORT_TRANSACTIONS,
  ERR_DELETE_TX, SUBSCRIBE_PLAN_ERROR, ERR_INTERNAL_ERROR,
  PORTFOLIO_NAME_MAX_LENGTH, TX_LIMIT_PER_PORTFOLIO,
  ERR_MAX_TX_LIMIT_REACHED_FOR_CSV_IMPORT,
  PLAID_GENERAL_ERROR, TABLE_COLUMNS_TDH, TABLE_COLUMNS,
  TABLE_COLUMNS_CLOSED,
  VALUE
} from '../../constants';

import './Portfolio.css';

const NUM_OF_PLOTTABLE_SCENARIOS = 3, TOAST_DELAY = 5000;

const portfolioError = "There was an error loading data for your portfolio. Please try again later."

class Portfolio extends Component {
  state = {
    // Market Data
    tickers: [],
    transactions: [],
    portfolioSummary: {},

    // Portfolio
    tdh: {},
    activePortfolio: {},
    defaultPortfolio: {},
    portfolioToDelete: {},
    triggerGetAllPortfolios: false,

    // Ticker
    tickerSymbolToDelete: null,

    // Transaction
    txToDelete: [],
    txToEdit: null,

    // InvestmentInstitution
    investmentInstitution: {},
    isPlaidConnectionExpired: false,

    // Scenario
    scenarios: [],
    activeScenario: null,
    scenarioSlots: [],
    activeScenarioIndex: null,
    scenarioToRename: null,
    scenarioToRenameAlt: null,
    scenarioToDelete: null,
    scenarioSnapshotStatus: false,
    scenarioForecastData: [],

    // Chart
    historyChartData: null,
    forecastChartData: null,
    redrawGraph: false,

    // Table
    tableColumns: TABLE_COLUMNS,
    tableColumnsClosed: TABLE_COLUMNS_CLOSED,
    tableColumnsTDH: TABLE_COLUMNS_TDH,

    // Sorting Functions
    expandedRows: [],
    ascIsNext: true,
    currentSort: null,
    notification: null,
    showInfoSideBar: true, // show the option of info sidebar
    infoSideBarTabIndex: 0,

    // Misc
    loadingPortfolio: true,
    portfolioDoesNotExist: false,
    importTutorialVideoId: '',
    portfolioTutorialVideoId: '',

    // upgrade plan modal
    selectedPlanTitle: null,
    selectedPlan: null,
    activeIsTDH: false,
    creditCardInfo: null,

    showOpenPositions: true,

    // Error Handling
    hasLoadingError: false,
  }

  componentDidMount = async () => {
    try {
      this.setState({ loadingPortfolio: true });
      // jquery for table component
      portfolioHeaderScroll();
      // add event listener to save state to sessionStorage when user leaves/refreshes the page
      // state will reset when user exits browser/tab, hence the use of sessionStorage over localStorage
      window.addEventListener("beforeunload", this.saveStateToSessionStorage.bind(this));
      // state persistence
      await this.hydrateStateWithSessionStorage();
      // sets active portfolio based on URL path (ie. /portfolio vs /portfolio/TDH)
      const defaultPortfolio = await getDefaultPortfolio();
      await this.setDisplayPortfolio(null, defaultPortfolio);
      await this.getAllScenarios();
      // get default portfolio and set to state to use in renderTDH header
      this.setState({ defaultPortfolio });

      // show notification from parent component
      const { notification } = this.props;
      if (notification) {
        const { type, message } = notification;
        this.showNotification(type, message);
      }

      this.props.refreshNotifications();

      this.initializeVideo('import-transactions-tutorial-modal','importTutorialVideoId' ,"YE7VzlLtp-4");
      this.initializeVideo('portfolio-tutorial-modal', 'portfolioTutorialVideoId', "VF9-sEbqDvU");
    } catch (error) {
      console.trace('Error during componentDidMount', error);
    }
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { activePortfolio } = this.state;
    if ((prevProps.match.url !== this.props.match.url) ||
      (_.get(this.props.location, 'state.redirectWithPortfolioId', null) !== _.get(prevProps.location, 'state.redirectWithPortfolioId', null))) {
      this.setState({ loadingPortfolio: true })
      await this.setDisplayPortfolio(prevProps.match.url);
    } else if (activePortfolio.id !== prevState.activePortfolio.id) {
      await this.initializePortfolioData();
      this.setState({ loadingPortfolio: false })
    }
  }

  refreshRequired = () => {
    const lastUpdatedAt = _.get(this.state, 'activePortfolio.plaidLinkUpdatedAt');
    return this.isPortfolioLinkedToPlaid() && lastUpdatedAt && moment().startOf('day').diff(moment(lastUpdatedAt).startOf('day'), 'days') >= 1;
  }

  componentWillUnmount () {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToSessionStorage.bind(this)
    );

    // state persistence
    this.saveStateToSessionStorage();
  }

  saveStateToSessionStorage = () => {
    if(Parse.User.current()) {
      for (const key in this.state) {
        try {
          // set loadingPortfolio to true so that the loader shows up right away on /portfolio
          if (key === 'loadingPortfolio') {
            sessionStorage.setItem(key, true, serializer(null, 2));
          } else if (key === 'portfolioDoesNotExist') {
            sessionStorage.setItem(key, false, serializer(null, 2));
          }
          else {
            sessionStorage.setItem(key, JSON.stringify(this.state[key]), serializer(null, 2));
          }
        } catch (error) {
          // fail to save the key to sessionStorage
          // ignore error to prevent TypeError: Converting circular structure to JSON
        }
      }
    }
  }

  updatePortfolios = () => {
    this.portfolioSideComponent.getAllPortfolios();
  }

  hydrateStateWithSessionStorage = async () => {
    try {
      for (const key in this.state) {
        if (sessionStorage.hasOwnProperty(key)) {
          let value = sessionStorage.getItem(key);
          // parse the sessionStorage string and setState
          try {
            value = JSON.parse(value);
            await this.setState({ [key]: value });
          } catch (e) {
            // handle empty string
            await this.setState({ [key]: value });
          }
        }
      }
    } catch (error) {
      this.setState({ activePortfolio: {} });
    }
  }

  setActivePortfolio = async (portfolio, showModal, currentIsTDH) => {
    if (!currentIsTDH) {
      currentIsTDH = await isTDH(portfolio.id);
    }

    if (currentIsTDH) {
      this.setInfoSideBar(false);
    } else {
      this.setInfoSideBar(true);
    }

    const currentPortfolio = currentIsTDH ? portfolio : await getPortfolio(portfolio.id);
    this.setState({ activePortfolio: currentPortfolio, activeIsTDH: currentIsTDH });

    if (!showModal) {
      this.closeModal('#portfolioSideModal');
    }
  }

  resetPortfolio () {
    this.setState({
      historyChartData: null,
      forecastChartData: null,
      tickers: [],
      portfolioSummary: {},
    })
  }

  async initializePortfolioData () {
    const ignoreRedrawGraph = true;
    this.resetPortfolio();
    this.setState({ loadingPortfolio: true });

    await this.getPortfolioData();
    await this.getActiveScenarios(ignoreRedrawGraph);

    this.setState({ expandedRows: [], loadingPortfolio: false })

    // Refresh plaid-linked portfolio if it is 1+ day old since it's last refresh
    if (this.refreshRequired()) {
      await this.refreshPlaidPortfolio();
    }
  }

  fetchTDH = async () => {
    let { tdh } = this.state;
    if (_.isEmpty(tdh)) {
      tdh = await getTDH();
      this.setState({ tdh });
    }
    return tdh;
  }

  extractTransactionsFromTickers = (tickers=[]) => {
    const txsByIds = {};
    tickers.forEach(ticker => {
      ticker.transactions.forEach(tx => {
        txsByIds[tx.id] = tx;
      })
    });
    return txsByIds;
  }

  getPortfolioData = async () => {
    const { activePortfolio, redrawGraph } = this.state;
    this.hasLoadingError = false;

    const portfolioId = activePortfolio.id;
    let portfolioIdForTDHComparison = undefined;

    if (!portfolioId) {
      return;
    }

    const tdh = await this.fetchTDH();
    if (tdh.id === portfolioId) {
      const defaultPortfolio = await getDefaultPortfolio();
      portfolioIdForTDHComparison = defaultPortfolio.id;
    }

    try {
      const portfolioData = await Parse.Cloud.run("getPortfolioData", { portfolioId, portfolioIdForTDHComparison });
      if (!portfolioData || _.isEmpty(portfolioData.summary.portfolio)) {
        return;
      }

      const { forecast, history, summary } = portfolioData;
      const { portfolio, tickers, comparisonTickerDividendYield } = summary;

      this.setState({
        historyChartData: history,
        forecastChartData: forecast,
        tickers,
        portfolioSummary: { ...portfolio, comparisonTickerDividendYield },
        redrawGraph: !redrawGraph
      });

      return portfolioData;

    } catch (e) {
      this.hasLoadingError = true;
      console.error(e);
    }
  };

  getTransactions = async (id) => {
    const { activePortfolio } = this.state;
    const portfolioId = id || activePortfolio.id;
    try {
      const portfolioData = await Parse.Cloud.run('summarizePortfolioWithMarketData', { portfolioId });
      if (!portfolioData || _.isEmpty(portfolioData.portfolio)) {
        this.showNotification('error', portfolioError);
        return;
      }

      const { portfolio, tickers, comparisonTickerDividendYield } = portfolioData;
      this.setState({ tickers, portfolioSummary: { ...portfolio, comparisonTickerDividendYield } });
      return this.extractTransactionsFromTickers(tickers);
    } catch (err) {
      throw err;
    }
  }

  getAllScenarios = async () => {
    const { activePortfolio, activeIsTDH } = this.state;
    let scenarios;
    if (activeIsTDH) {
      scenarios = [];
    } else {
      scenarios = await getScenarios(activePortfolio.id);
    }
    this.setState({ scenarios });
  }

  updateActiveScenario = (id, keepActiveScenario=false) => {
    const { activeScenario } = this.state;
    const scenarioId = (activeScenario === id && !keepActiveScenario) ? null : id;
    this.setState({ activeScenario: scenarioId });
  }

  setScenarioToRename = (id) => {
    this.setState({ scenarioToRename: id });
  }

  setScenarioToRenameAlt = (id) => {
    this.setState({ scenarioToRenameAlt: id })
  }

  callRenameScenario = async (e, id, input) => {
    e.preventDefault();
    if (input){
      await renameScenario(id, input);
      await this.getAllScenarios();
    }
    this.setScenarioToRename();
    this.setScenarioToRenameAlt();
  }

  setActiveScenarioIndex = async (slotIndex) => {
    await this.setState({ activeScenarioIndex: slotIndex });
  }

  setScenarioSlots = async (slotIndex, scenarioId, scenarioIdToRemove) => {
    const { scenarioSlots } = this.state;
    const newIndex = scenarioIdToRemove ? null : slotIndex;

    try {
      await updatePlotIndex(newIndex, scenarioIdToRemove || scenarioId);
    } catch (err) {
      console.log('Error updating plot index: ', err);
    }

    scenarioSlots[slotIndex] = scenarioId;
    await this.getScenarioForecastData(scenarioSlots);
    await this.setState({ scenarioSlots, activeScenarioIndex: null });
  }

  getScenarioForecastData = async(newScenarioSlots, ignoreRedrawGraph) => {
    const { redrawGraph, scenarios } = this.state;
    if (_.isEmpty(newScenarioSlots.filter(s => s))) {
      this.setState({ scenarioForecastData: [], redrawGraph: !redrawGraph });
      return;
    }
    try {
      const scenarioForecastsById = await Parse.Cloud.run("getScenarioForecast", { ids: newScenarioSlots });
      Object.keys(scenarioForecastsById).forEach(scenarioForecastId => {
        const scenarioObj = scenarios.find(scenario => scenario && scenario.id === scenarioForecastId);
        if (scenarioObj) {
          scenarioForecastsById[scenarioForecastId].id = scenarioForecastId;
          scenarioForecastsById[scenarioForecastId].name = scenarioObj.name;
        }
      });
      const scenarioForecastData = Object.values(scenarioForecastsById);
      this.setState({ scenarioForecastData, redrawGraph: !ignoreRedrawGraph ? !redrawGraph : redrawGraph });
    } catch (e) {
      this.showNotification('error', 'There was an error loading chart data. Please try again later.');
      console.error(e);
    }
  }

  async setDisplayPortfolio(prevURL, foundDefaultPortfolio) {
    const { match: { url }, location } = this.props;
    const { activePortfolio } = this.state;
    try {
      const defaultPortfolio = foundDefaultPortfolio || await getDefaultPortfolio();
      const tdh = await this.fetchTDH();
      const activeIsTDH = tdh.id === activePortfolio.id;
      this.setState({ tdh });

      const redirectWithPortfolioId = _.get(location, 'state.redirectWithPortfolioId', null);
      if (redirectWithPortfolioId) {
        const newActivePortfolio = await getPortfolio(redirectWithPortfolioId);
        if (_.isEmpty(newActivePortfolio)) {
          this.setState({ portfolioDoesNotExist: true });
        } else {
          location.state.redirectWithPortfolioId = null;
          await this.setActivePortfolio(newActivePortfolio);
        }
      } else if (!activeIsTDH && prevURL === '/portfolio/TDH') {
        await this.setActivePortfolio(activePortfolio, null, activeIsTDH);
      } else if (activeIsTDH && url === '/portfolio') {
        await this.setActivePortfolio(defaultPortfolio, null, false);
      }
      else if (url === '/portfolio/TDH') {
        await this.setActivePortfolio(tdh, null, true);
        await this.setState({ investmentInstitution: {}, isPlaidConnectionExpired: false });
        this.resetColumnVisibility();
      }
      else if ((prevURL === '/portfolio/TDH' && url === '/portfolio') || _.isEmpty(activePortfolio)) {
        await this.setActivePortfolio(defaultPortfolio, null, false);
      }
      else {
        await this.setActivePortfolio(activePortfolio, null, activeIsTDH);
      }

      // Investment Institution
      const newPortfolioHasInstitutionId = !!this.state.activePortfolio.investmentInstitutionId;
      const newPortfolioIsTDH = tdh.id === this.state.activePortfolio.id;
      if (!newPortfolioIsTDH && newPortfolioHasInstitutionId) {
        try {
          const investmentInstitution = await getInvestmentInstitutionById(activePortfolio.investmentInstitutionId);
          this.setState({ investmentInstitution, isPlaidConnectionExpired: false });
        } catch (e) {
          // 401 is the catch-all for any scenarios that requires users to reset their Plaid connection.
          if (e.code === 401) {
            // Note about this error handling:
            // If institution object does not exist, it means it is automatically deleted from being stale (30+ days);
            // If institution object does exist, it means access token is no longer valid.
            await this.setState({ isPlaidConnectionExpired: true }); // the bool here flags the portfolio to not initiate default behavior: retrieve transactions from iex if last refresh date was 1+ day old.
            this.openModal('#reconnect-plaid');
          } else {
            this.showNotification('error', PLAID_GENERAL_ERROR);
            throw e;
          }
        }
      } else {
        this.setState({ investmentInstitution: {}})
      }
    } catch (err) {
      console.log(err);
    }
  }

  // this function is passed down to side modal as prop -- gets the TDH
  // object from modal's componentDidMount and brings back to Portfolio
  sendTDHtoPortfolio = (tdh) => {
    this.setState({ tdh });
  }

  openPortfolioDeleteModal = (portfolioToDelete) => {
    this.closeModal('#portfolioSideModal');
    $('#deletePortfolio').modal();
    this.setState({ portfolioToDelete });
  }

  callDeletePortfolio = async () => {
    const { activePortfolio, portfolioToDelete: { id, isDefault } } = this.state;
    await deletePortfolio(id);
    // if portfolio being deleted is set as default, set the next available portfolio as default
    eventPortfolioDeleted();
    if (isDefault) {
      let nextDefault = await getFirstPortfolio();
      await setDefaultPortfolio(nextDefault.id);
      this.setState({ defaultPortfolio: nextDefault });
    }
    // if portfolio being deleted is active, replace active portfolio with default portfolio
    if (id === activePortfolio.id) {
      const defaultPortfolio = await getDefaultPortfolio();
      await this.setState({ activePortfolio: defaultPortfolio });
    }

    this.setState({ portfolioToDelete: {}, activeScenarioIndex: null });
  }

  openScenarioDeleteModal = (scenarioId, slotIndex) => {
    this.closeModal('#whatifModal');
    this.closeModal('#portfolioSideModal');
    $('#deleteScenario').modal();
    this.setState({ scenarioToDelete: scenarioId, activeScenarioIndex: slotIndex });
  }

  callDeleteScenario = async () => {
    const { scenarioToDelete } = this.state;
    await deleteScenario(scenarioToDelete);
    await this.getActiveScenarios();
    this.getAllScenarios();
    this.setState({ activeScenarioIndex: null });
    eventWhatIfScenarioDeleted();
  }

  getActiveScenarios = async (ignoreRedrawGraph) => {
    const { activePortfolio, activeIsTDH } = this.state;
    const scenarioSlots = [];
    if (activeIsTDH) return;
    else {
      for (let i = 0; i < NUM_OF_PLOTTABLE_SCENARIOS; i++) {
        try {
          const result = await queryByPlotIndex(i, activePortfolio.id);
          scenarioSlots.push(result);
        } catch (err) {
          console.log('Error querying scenario by plot index: ', err);
        }
      }
      const newScenarioSlots = await Promise.all(scenarioSlots);
      await this.getScenarioForecastData(newScenarioSlots, ignoreRedrawGraph);
      await this.setState({ scenarioSlots });
    }
  }

  initializeVideo = (modalId, videoInState, videoId) => {
    const modal = $(`#${modalId}`);
    this.setState({ [videoInState]: videoId });
    modal.on('shown.bs.modal', () => this.setState({ [videoInState]: videoId }));
    modal.on('hidden.bs.modal', () => this.setState({ [videoInState]: '' }));
  }

  setInfoSideBar = (bool) => {
    this.setState({ showInfoSideBar: bool });
  }

  setInfoSideBarTabIndex = (index) => {
    this.setState({ infoSideBarTabIndex: index });
  }

  sort = (category) => {
    const { tickers, currentSort, ascIsNext } = this.state;
    const sortArray = tickers.map((data, idx) => ({idx, data}));

    if (currentSort === category) {
      const sort1 = ascIsNext ? -1 : 1;
      const sort2 = ascIsNext ? 1 : -1;

      sortArray.sort((a, b) => {
        if (a.data[category] > b.data[category]) return sort1;
        if (b.data[category] > a.data[category]) return sort2;
        return a.idx - b.idx;
      });
      const sortedArray = sortArray.map(value => value.data);
      this.setState({ tickers: sortedArray, ascIsNext: !ascIsNext });
    } else {
      sortArray.sort((a, b) => {
        if (a.data[category] > b.data[category]) return 1;
        if (b.data[category] > a.data[category]) return -1;
        return a.idx - b.idx;
      });
      const sortedArray = sortArray.map(value => {return value.data});
      this.setState({ tickers: sortedArray, currentSort: category, ascIsNext: true });
    }
  }

  expand = async (tickerSymbol) => {
    const { expandedRows } = this.state;
    const index = expandedRows.findIndex(symbol => symbol === tickerSymbol);
    if (index === -1) { expandedRows.push(tickerSymbol); }
    else { expandedRows.splice(index, 1); }
    this.setState({ expandedRows });
  }

  onFileChange = async (uploadedFile) => {
    const { url } = uploadedFile;
    const { activePortfolio, portfolioSummary } = this.state;
    const { history, updateCsvPreview } = this.props;
    try {
      const preview = await Parse.Cloud.run('previewCsv', { url });

      if((portfolioSummary.txCount + preview.rowCount) > TX_LIMIT_PER_PORTFOLIO) {
        this.closeModal('#import-transactions');
        this.showNotification('error', ERR_MAX_TX_LIMIT_REACHED_FOR_CSV_IMPORT)
      } else {
        await updateCsvPreview({ ...preview, url, portfolioId: activePortfolio.id });
        this.closeModal('#import-transactions');
        history.push('/import-transactions');
      }
    } catch (err) {
      this.closeModal('#import-transactions');
      this.showNotification('error', ERR_IMPORT_TRANSACTIONS);
    }
  }

  resetColumnVisibility() {
    const { tableColumns, tableColumnsClosed } = this.state;
    tableColumns.forEach(col => col.hidden = false);
    tableColumnsClosed.forEach(col => col.hidden = false);
    this.setState({ tableColumns, tableColumnsClosed });
  }

  setColumnVisibility = (inputKey) => {
    const { showOpenPositions } = this.state;
    const columnName = showOpenPositions ? 'tableColumns' : 'tableColumnsClosed';
    const columns = this.state[columnName];

    const objectIndex = columns.findIndex(({ key }) => key === inputKey);
    columns[objectIndex].hidden = !columns[objectIndex].hidden;

    this.setState({ [columnName]: columns });
  }

  toggleScenarioSnapshotStatus = () => {
    const { scenarioSnapshotStatus } = this.state;
    this.setState({ scenarioSnapshotStatus: !scenarioSnapshotStatus});
  }

  showNotification = (type, message) => {
    if (!_.isEmpty(this.state.notification)) {
      return;
    }
    this.setState({ notification: { type, message } });
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
    this.toastTimeout = setTimeout(() => this.hideNotification(), TOAST_DELAY);
  }

  hideNotification = () => {
    this.setState({ notification: null });
  }

  openModal(modalId) {
    $(modalId).modal();
  }

  closeModal(modalId) {
    $(modalId).modal('hide');
  }

  duplicateTDH = async(e) => {
    e.preventDefault();
    const { tdh, tickers, triggerGetAllPortfolios } = this.state;
    const tdhTxToClone = this.extractTransactionsFromTickers(tickers);
    const newCopy = await duplicatePortfolio(tdh.id, Object.values(tdhTxToClone));
    await this.setActivePortfolio(newCopy);
    this.props.history.push('/portfolio');
    eventTDHDuplicated();
    this.setState({ triggerGetAllPortfolios: !triggerGetAllPortfolios });
  }

  async openUpgradePlanModal(tdh) {
    const planInfo = await Parse.Cloud.run('getProductInformation');
    const productInfo = { plans: {}, tdh: {} };

    productInfo.plans.monthly = _.get(planInfo, `plan.monthly[0]`);
    productInfo.plans.annual = _.get(planInfo, `plan.yearly[0]`);
    productInfo.tdh.annual = planInfo.tdhSubscriptionData.find(item => item.cycle_type === 'YEAR');
    const infusionSoftId = Parse.User.current().attributes.infusionSoftId;
    const creditCardInfo = await Parse.Cloud.run('getCreditCardInfoForUser', { infusionSoftId });
    this.setState({ creditCardInfo, selectedPlan: tdh ? productInfo.tdh : productInfo.plans, selectedPlanTitle: tdh ? 'THE DIVIDEND HUNTER ADD-ON' : 'Upgrade to Premium Plan' });
    $('#upgradePlan').modal();
  }

  addNewCard = async() => {
    this.setState({ errMsg: '' }); // reset error message

    const { email } = Parse.User.current().attributes;
    const { userInfo } = this.props;

    let phoneNumber  = userInfo ? _.get(userInfo.phone_numbers, 1, { number: this.mobileNumber }).number : null;
    if (phoneNumber) phoneNumber = phoneNumber.replace(/\D/g, '');

    // redirect to infusionsoft order form
    const configurations = await getConfig();
    const infusionsoftUrl = await configurations.get("CHANGE_BILLING_INFORMATION_URL");

    if (infusionsoftUrl) {
      let billingUrl = `${infusionsoftUrl}?email=${encodeURIComponent(email)}&name=${userInfo.given_name}&lastname=${userInfo.family_name}`;
      if(phoneNumber) billingUrl += `&phonenumber=${phoneNumber}`;
      window.location.replace(billingUrl);
    } else {
      this.setState({ errMsg: ERR_INTERNAL_ERROR });
    }
  }

  saveBillingInfoFromModal = async (billingInfo) => {
    const { checkUserPlans, productIds } = this.props;
    const { infusionSoftId, email } = Parse.User.current().attributes;
    const userInfo = { infusionSoftId, email };
    let { selectedPlan, creditCardInfo } = this.state;

    try {
      this.setState({ loadingPortfolio: true })
      if (_.size(selectedPlan) <= 1) {
        this.closeModal('#upgradePlan');
        await Parse.Cloud.run('addTDH', { userInfo, creditCardInfo });
        this.setState({ loadingPortfolio: false })
      } else {
        // upgrade to premium plan
        selectedPlan = billingInfo.selectedPlan;
        selectedPlan.product = {
          id: productIds.PREMIUM,
        };
        await Parse.Cloud.run('upgradePlanForBasicUser', { userInfo, creditCardInfo, selectedPlan });
      }
      // successful upgrade
      await Parse.User.current().fetch();
      checkUserPlans(); // update app level state value
      await this.getPortfolioData();
      this.closeModal('#upgradePlan');
    } catch ( error ) {
      this.closeModal('#upgradePlan');
      if (error.code === 500) this.showNotification('error', ERR_INTERNAL_ERROR);
      else if ( error.code === 503) this.showNotification('error', SUBSCRIBE_PLAN_ERROR);
      else this.showNotification('error', error.message);
    } finally {
      this.setState({ loadingPortfolio: false })
    }
  }

  exportTransactionsAsCSV = () => {
    const { activePortfolio, tableColumns, tickers } = this.state;

    let rows = [[tableColumns.map(col => col.name).join(', ')]];
    rows = rows.concat(tickers.map(trans => [tableColumns.map(col => trans[col.key]).join(', ')]));

    const blob = new Blob([rows.join('\n')], {type: 'text/plain;charset=utf-8'});
    FileSaver.saveAs(blob, `${activePortfolio.name}.csv`);
  }

  openTickerToDeleteModal = (e, tickerSymbolToDelete) => {
    e.stopPropagation();
    $('#deleteTicker').modal();
    this.setState({ tickerSymbolToDelete });
  }

  callDeleteTicker = async () => {
    const { tickerSymbolToDelete, tdh, activePortfolio } = this.state;
    const currentIsTDH = tdh.id === activePortfolio.id;
    await deleteTransactionsBySymbol(tickerSymbolToDelete, activePortfolio.id, currentIsTDH);
    await this.getPortfolioData();
    this.setState({ tickerSymbolToDelete: null });
  }

  onSelectTxToDelete = (txId) => {
    const { txToDelete } = this.state;
    const fIndex = txToDelete.findIndex(tx => tx === txId);
    if (fIndex === -1) txToDelete.push(txId);
    else txToDelete.splice(fIndex, 1);
    this.setState({ txToDelete });
  }

  callDeleteTx = async () => {
    const { txToDelete } = this.state;

    try {
      await deleteTransactionsByIds(txToDelete);
      await this.getPortfolioData();
    } catch(err){
      console.log('Transaction deletion error: ', err);
      this.showNotification('error', ERR_DELETE_TX);
    }
    this.setState({ txToDelete: [] });
  }

  onSelectTxToEdit = async (txToEdit = null) => {
    await this.setState({ txToEdit });
    if(!_.isEmpty(txToEdit)) {
      $('#addTicker').modal();
    }
  }

  updateDefaultPortfolio = (defaultPortfolio) => {
    this.setState({ defaultPortfolio });
  }

  onToggleOpenClosePosition = (showOpenPositions) => {
    this.setState({ showOpenPositions });
  }

  renderTDHheader = (defaultPortfolio) => {
    let { subscribedToTDH, onPremiumPlan, userIsAdmin } = this.props;
    const premiumOptions = { text: "Want to experiment on your own? You can make a copy of this portfolio and use it as a starting point.", buttonText: 'Copy to New Portfolio', buttonAction: (e) => this.duplicateTDH(e) };
    const basicOptions = { text: 'Upgrade to premium and you can make a copy of this portfolio to use as a starting point.', buttonText: 'Upgrade to Premium', buttonAction: () => this.openUpgradePlanModal(false) }
    const headerOptions = onPremiumPlan ? premiumOptions : basicOptions;
    return (
      <React.Fragment>
        {
          subscribedToTDH || userIsAdmin ? (
            <div className="alert alert-primary p-4" role="alert">
              <p>You're viewing a scenario in which the value of your default portfolio <strong>({defaultPortfolio.name})</strong> is split across the holdings in <strong>The Dividend Hunter's </strong>portfolio. This allows you to forecast what your dividend earnings could be if you reallocated your funds.</p>
              <hr/>
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-0">{headerOptions.text}</p>
                <Button name={headerOptions.buttonText} dashColor="outline-white" clickAction={headerOptions.buttonAction}/>
              </div>
            </div>
          ) : (
            <div className="alert alert-light p-4" role="alert">
              <h2 className="alert-heading">Free Preview</h2>
              <p>Get immediate access to The Dividend Hunter portfolio and customize it with your own holdings plus, </p>
              <ul>
                <li>12 monthly issues with new stocks and updates</li>
                <li>FREE Monthly Dividend Paycheck Calendar</li>
                <li>Stock of the Week Emails with new dividend recommendationsLive customer service</li>
                <li>FREE bonus research like the 36 Month Accelerated Income Plan and U.S. Dividend Investing for Canadian Investors </li>
                <li>Regular training sessions</li>
                <li>Full 100% Money Back Guarantee</li>
              </ul>
              <hr/>
              <div className="d-flex align-items-center justify-content-between">
                <p className="lead mb-0">Ready to unlock the full power of The Dividend Hunter?</p>
                <Button name="Get Instant Access" dashColor="primary" clickAction={(e) => this.openUpgradePlanModal(true)}/>
              </div>
            </div>
          )
        }
      </React.Fragment>
    )
  }

  setToDefaultPortfolio = () => {
    try {
      const { defaultPortfolio } = this.state;
      this.setState({ activePortfolio: defaultPortfolio, portfolioDoesNotExist: false, loadingPortfolio: false });
    } catch (err) {
      console.log('Failed to set to default portfolio', err);
    }
  }

  handlePortfolioUnlink = async () => {
    const { activePortfolio } = this.state;
    await unlinkPortfolio(activePortfolio.id);
    const newActivePortfolio = { ...activePortfolio, investmentInstitutionId: null };
    this.setState({ activePortfolio: newActivePortfolio, investmentInstitution: {}, isPlaidConnectionExpired: false });
  };

  isPortfolioLinkedToPlaid = () => {
    return !_.isEmpty(this.state.investmentInstitution);
  }

  refreshPlaidPortfolio = async () => {
    const { activePortfolio, isPlaidConnectionExpired } = this.state;
    if (!isPlaidConnectionExpired) {
      try {
        this.setState({ loadingPortfolio: true });

        await Parse.Cloud.run('resyncPortfolioById', { id: activePortfolio.id });
        await this.getPortfolioData();
        this.setState({ activePortfolio: { ...this.state.activePortfolio, plaidLinkUpdatedAt: moment() }});
      } catch (e) {
        console.error('Error resynching portfolio from Plaid', e);
        if (e.code === 401) {
          await this.setState({ isPlaidConnectionExpired: true }); // the bool here flags the portfolio to not initiate default behavior: retrieve transactions from iex if last refresh date was 1+ day old.
          this.openModal('#reconnect-plaid');
        } else {
          this.showNotification('error', 'Something went wrong with retrieving your latest investments. Please try again or reconnect your brokerage account.');
        }
      } finally {
        this.setState({ loadingPortfolio: false });
      }
    }
  };

  renderEmptyPortfolio = () => {
    return (
      <div className="full-height d-flex flex-column">
        <div className="text-center my-auto">
          <Header content="This portfolio no longer exists" />
          <p className="text-muted mb-3 mt-2">The portfolio you are looking for may have been deleted.</p>
          <div className="mx-auto d-flex justify-content-center">
            <Button name="Back to Default Portfolio" clickAction={this.setToDefaultPortfolio}/>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      activePortfolio, scenarios, activeScenario, showInfoSideBar, tableColumns, tableColumnsTDH, tableColumnsClosed,
      infoSideBarTabIndex, scenarioToRename, scenarioToRenameAlt, portfolioToDelete, currentSort, ascIsNext,
      scenarioSnapshotStatus, defaultPortfolio, triggerGetAllPortfolios, scenarioSlots, activeScenarioIndex, notification,
      tdh, expandedRows, loadingPortfolio, tickers, portfolioSummary, txToDelete, txToEdit, portfolioTutorialVideoId, importTutorialVideoId,
      historyChartData, forecastChartData, scenarioForecastData , redrawGraph, creditCardInfo, selectedPlanTitle, selectedPlan,
      activeIsTDH, tickerSymbolToDelete, portfolioDoesNotExist, showOpenPositions, investmentInstitution, isPlaidConnectionExpired } = this.state;

    const columns = showOpenPositions ? tableColumns : tableColumnsClosed;

    if (portfolioDoesNotExist) return this.renderEmptyPortfolio();


    const { userIsAdmin, tagIds, onBasicPlan, subscribedToTDH, loadingPlanType } = this.props;
    const tdhActive = tdh.id === activePortfolio.id;

    const tabTitle = (activePortfolio.name && activePortfolio.name.length > PORTFOLIO_NAME_MAX_LENGTH) ? (
        <div className="m-0 truncate" style={{ cursor: 'default' }} title={activePortfolio.name}>
          {activePortfolio.name.length < PORTFOLIO_NAME_MAX_LENGTH ? activePortfolio.name : activePortfolio.name.substring(0, PORTFOLIO_NAME_MAX_LENGTH) + '...'}
        </div>
      ) : (
        activePortfolio.name
      );
    const centeredLoader = (
      <div className="d-flex justify-content-center align-items-center" style={{height: 'calc(100vh - 72px)'}}>
        <Loader />
      </div>
    );

    const currentYear = new Date().getFullYear();
    const currentYearData = !_.isEmpty(historyChartData) ? historyChartData[currentYear] : {};
    const lastYearData = !_.isEmpty(historyChartData) ? historyChartData[currentYear - 1] : {};
    const positionsHeld = activeIsTDH ? tickers.length : _.filter(tickers, (ticker) => !_.isEmpty(ticker.open)).length;
    const earliestTxDate = _.get(portfolioSummary, "firstOpenDate", new Date());
    const forecastedAnnualIncome = _.get(forecastChartData, 'summary.yearly', 0);
    const dynamicTitle = `${activePortfolio.name} | Divcaster`;
    const noTransactions = _.isEmpty(tickers) && !tdhActive && !loadingPortfolio;

    let transactionData;

    if (this.hasLoadingError) {
      transactionData = (
        <div className="full-height d-flex flex-column">
          <div className="text-center my-auto">
            <Header content="There was an error loading this portfolio." />
            <p className="text-muted mb-3 mt-2">Please try again later or contact support.</p>
            <div className="mx-auto d-flex justify-content-between" style={{ width: 320 }}>
              <Button name="Switch Portfolio" clickAction={() => this.openModal('#portfolioSideModal')}/>
              <Button name="Reload Page" clickAction={() => this.initializePortfolioData()}/>
            </div>
          </div>
        </div>
      )
    } else if (noTransactions) {
      transactionData = (
        <NoTransaction
          activePortfolio={activePortfolio}
          closeModal={this.closeModal}
          getPortfolioData={this.getPortfolioData}
          Link={Link}
          onBasicPlan={onBasicPlan}
          onUpdateActivePortfolio={(newActivePortfolio) => this.setState({ activePortfolio: newActivePortfolio })}
          onUpdateInvestmentInstitution={(newInvestmentInstitution) => this.setState({ investmentInstitution: newInvestmentInstitution })}
          openModal={this.openModal}
          showNotification={this.showNotification}
        />
      )
    } else {
      transactionData = (
        <div className="container-fluid bg-dark">
          {/* Top Section */}
          <div className="top-boxes row flex-row flex-nowrap">
            <div className="col-12 p-4">
              { tdhActive
                ? (this.renderTDHheader(defaultPortfolio))
                : (
                    <Button
                      name="Switch Portfolio"
                      dashColor="outline-light"
                      dashClass="btn-sm"
                      dataToggle="modal"
                      dataTarget="#portfolioSideModal"
                    />
                  )
              }
              <Tabs title={tabTitle} imageSrc={tdhActive ? '/img/logo-tdh-white.png' : ''} imageHeight={tdhActive ? '32' : ''} dashClass="border-secondary text-white pt-0" defaultActiveTabIndex={0}>
                <Tab linkClassName="" heading="12-Month Forecast" clickAction={!activeIsTDH ? () => this.setInfoSideBar(true) : () => {}} dashColor="text-white">
                  <Forecast
                    tickers={tickers}
                    tdhActive={tdhActive}
                    redrawGraph={redrawGraph}
                    lastYearData={lastYearData}
                    positionsHeld={positionsHeld}
                    activePortfolio={activePortfolio}
                    subscribedToTDH={subscribedToTDH}
                    currentYearData={currentYearData}
                    portfolioSummary={portfolioSummary}
                    portfolioForecast={forecastChartData}
                    scenarioForecast={scenarioForecastData}
                  />
                </Tab>
                { tdhActive
                  ? (<React.Fragment></React.Fragment>)
                  : (
                    <Tab linkClassName="" heading="Historical Income" clickAction={() => this.setInfoSideBar(false)} dashColor="text-white" disabled={onBasicPlan} >
                      <History
                        data={historyChartData}
                        redrawGraph={redrawGraph}
                        earliestTxDate={earliestTxDate}
                        forecastedAnnualIncome={forecastedAnnualIncome}
                      />
                    </Tab>
                  )
                }
              </Tabs>
            </div>
            {
              showInfoSideBar
                && (
                  <InfoSidebar
                    scenarios={scenarios}
                    activeScenario={activeScenario}
                    scenarioToRename={scenarioToRename}
                    getAllScenarios={this.getAllScenarios}
                    renameScenario={this.callRenameScenario}
                    setScenarioToRename={this.setScenarioToRename}
                    openDeleteModal={this.openScenarioDeleteModal}
                    updateActiveScenario={this.updateActiveScenario}
                    setInfoSideBarTabIndex={this.setInfoSideBarTabIndex}
                    toggleScenarioSnapshotStatus={this.toggleScenarioSnapshotStatus}
                    scenarioSlots={scenarioSlots}
                    activeScenarioIndex={activeScenarioIndex}
                    setActiveScenarioIndex={this.setActiveScenarioIndex}
                    setScenarioSlots={this.setScenarioSlots}
                    showNotification={this.showNotification}
                    onBasicPlan={onBasicPlan}
                    getScenarioForecastData={this.getScenarioForecastData}
                    loadingPlanType={loadingPlanType}
                    totalMarketValue={portfolioSummary[VALUE]}
                  />
                )
            }
          </div>
          {/* Bottom Section */}
          <div className="box-bottom row">
            <Table
              tickers={tickers}
              portfolioSummary={portfolioSummary}
              activePortfolio={activePortfolio}
              sort={this.sort}
              expand={this.expand}
              expandedRows={expandedRows}
              columns={tdhActive ? tableColumnsTDH : columns}
              currentSort={currentSort}
              ascIsNext={ascIsNext}
              userIsAdmin={userIsAdmin}
              showNotification={this.showNotification}
              onBasicPlan={onBasicPlan}
              tdh={tdh}
              subscribedToTDH={subscribedToTDH}
              onExportTransactions={this.exportTransactionsAsCSV}
              openTickerToDeleteModal={this.openTickerToDeleteModal}
              txToDelete={txToDelete}
              onSelectTxToDelete={this.onSelectTxToDelete}
              onSelectTxToEdit={this.onSelectTxToEdit}
              openDeleteModal={this.openPortfolioDeleteModal}
              setActivePortfolio={this.setActivePortfolio}
              updatePortfolios={this.updatePortfolios}
              showOpenPositions={showOpenPositions}
              onToggleOpenClosePosition={this.onToggleOpenClosePosition}
              onRefreshPlaidPortfolio={this.refreshPlaidPortfolio}
              isPlaidConnectionExpired={isPlaidConnectionExpired}
            />
          </div>
        </div>
      )
    }

    return (
      <div>
        <MetaTags>
          <title>{dynamicTitle}</title>
          <meta name="og:title" property="og:title" id="og-title" content={dynamicTitle} />
          <meta name="apple-mobile-web-app-title" id="apple-title" content={dynamicTitle} />
        </MetaTags>
        { loadingPortfolio ? centeredLoader : transactionData }
          {/* Toast */}
          <div className="position-absolute">
            <Toast notification={notification} onClose={() => this.hideNotification()} />
          </div>
          {/* Modals */}
          <Modal modalId="what-if-modal" header={`${(activeScenarioIndex || activeScenarioIndex === 0) ? 'Change' : 'Manage'} What-if Scenarios`}>
            <ScenariosContent
              scenarios={scenarios}
              tickers={tickers}
              scenarioToRename={scenarioToRenameAlt}
              getAllScenarios={this.getAllScenarios}
              renameScenario={this.callRenameScenario}
              tabIndex={infoSideBarTabIndex}
              setScenarioToRename={this.setScenarioToRenameAlt}
              openDeleteModal={this.openScenarioDeleteModal}
              updateActiveScenario={this.updateActiveScenario}
              activePortfolio={activePortfolio}
              scenarioSnapshotStatus={scenarioSnapshotStatus}
              scenarioSlots={scenarioSlots}
              setScenarioSlots={this.setScenarioSlots}
              activeScenarioIndex={activeScenarioIndex}
              setActiveScenarioIndex={this.setActiveScenarioIndex}
              onBasicPlan={onBasicPlan}
              activeIsTDH={activeIsTDH}
              />
          </Modal>
          <Modal modalId="import-transactions">
            <ImportTransactions onFileChange={this.onFileChange} />
          </Modal>
          <Modal modalId="show-hide-columns">
            <ShowHideColumns columns={columns} toggleAction={this.setColumnVisibility} />
          </Modal>
          <Modal modalId="portfolio-tutorial-modal" header="Portfolio Tutorial">
            <VideoPlayer
              videoId={portfolioTutorialVideoId}
              id="portfolio-tutorial"
              onVideoStart={() => eventTrainingVideoStarted('Portfolio Tutorial')}
            />
          </Modal>
          <Modal modalId="import-transactions-tutorial-modal" header="Import Transactions Tutorial">
            <VideoPlayer
              videoId={importTutorialVideoId}
              id="import-transactions-tutorial"
              onVideoStart={() => eventTrainingVideoStarted('Import Transactions Tutorial')}
            />
          </Modal>
          <Modal type="vertical" modalId="portfolioSideModal">
            <PortfolioSideModal
              onRef={ref => (this.portfolioSideComponent = ref)}
              activePortfolioId={activePortfolio.id}
              setActivePortfolio={this.setActivePortfolio}
              openDeleteModal={this.openPortfolioDeleteModal}
              portfolioToDelete={portfolioToDelete}
              triggerGetAllPortfolios={triggerGetAllPortfolios}
              onBasicPlan={onBasicPlan}
              tagIds={tagIds}
              updateDefaultPortfolio={this.updateDefaultPortfolio}
              tdh={tdh}
              getTransactions={this.getTransactions}
            />
          </Modal>
          <Modal modalId="addTicker">
            <AddTicker
              txToEdit={txToEdit || null}
              activePortfolio={activePortfolio}
              showOpenPositions={showOpenPositions}
              onSelectTxToEdit={this.onSelectTxToEdit}
              getPortfolioData={this.getPortfolioData}
              showNotification={this.showNotification}
            />
          </Modal>
          <Modal modalId="deletePortfolio">
            <DeleteConfirmationModal typeToDelete="Portfolio" callDeleteFunction={this.callDeletePortfolio} />
          </Modal>
          <Modal modalId="deleteTicker">
            <DeleteConfirmationModal
              callDeleteFunction={this.callDeleteTicker}
              copyInsertion={{ text: `This will delete ALL of your transactions for ${tickerSymbolToDelete} and cannot be undone`, button: `Yes, Delete ${tickerSymbolToDelete}` }}/>
          </Modal>
          <Modal modalId="deleteTransactions">
            <DeleteConfirmationModal typeToDelete="selected Transactions" callDeleteFunction={this.callDeleteTx} />
          </Modal>
          <Modal modalId="deleteScenario">
            <DeleteConfirmationModal typeToDelete="Scenario" callDeleteFunction={this.callDeleteScenario} />
          </Modal>
          <Modal modalId="unlinkPortfolio">
            <UnlinkPortfolioConfirmation
              institutionName={investmentInstitution ? investmentInstitution.name : 'your brokerage account'}
              callUnlinkFunction={this.handlePortfolioUnlink}
            />
          </Modal>
          <Modal modalId="upgradePlan">
            <BillingModalContent
              addNewCard={()=>{ this.addNewCard(); }}
              planTitle={selectedPlanTitle}
              selectedPlan={selectedPlan}
              buttonText='Upgrade'
              creditCardInfo={creditCardInfo}
              saveBillingInfoFromModal={this.saveBillingInfoFromModal}
              />
          </Modal>
          <Modal modalId="reconnect-plaid">
            <RefreshPlaidConnection
              activePortfolio={activePortfolio}
              getPortfolioData={this.getPortfolioData}
              showNotification={this.showNotification}
              unlinkPortfolio={this.handlePortfolioUnlink}
              updateIsPlaidConnectionExpired={(bool) => this.setState({ isPlaidConnectionExpired: bool })}
              closeModal={() => this.closeModal('#reconnect-plaid')}
            />
          </Modal>
      </div>
    );
  }
}

export default withGA(withRouter(Portfolio));
