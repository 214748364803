import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Parse from 'parse';
import moment from 'moment';
import { filter, debounce } from 'lodash';

import Dropdown from '../../components/Dropdown';
import ReportModal from '../../components/ReportModal';
import { generateReport} from './GenerateReport';

class ReportsList extends Component {

  static propTypes = {
    tagIds: PropTypes.object,
    filterByCurrentUser: PropTypes.bool,
  }

  static defaultProps = {
    tagIds: {},
    filterByCurrentUser: false,
  }

  static SEGMENT_NAMES = {
    '': 'ALL',
    'BASIC_PLAN': 'BASIC',
    'PREMIUM_PLAN': 'PREMIUM',
  }

  static REPORT_TYPES = {
    'All Report Types': '',
    'User List': 'USER_LIST',
    'User Portfolio': 'USER_PORTFOLIO',
    'Holdings': 'HOLDINGS',
    'TDH': 'TDH',
  }

  state = {
    loading: true,
    reports: [],
    search: '',
    reportType: '',
  }

  // NOTE: placeholder for refs
  modal = {}

  async componentDidMount() {
    await this.fetchReports();
    this.setState({ loading: false });
  }

  fetchReports = async () => {
    const { filterByCurrentUser } = this.props;
    const { reportType } = this.state;

    const query = new Parse.Query('Report');
    query.include('file');
    query.include('user');
    query.descending('createdAt');

    if (filterByCurrentUser) {
      query.equalTo('user', Parse.User.current());
    }

    if (reportType) {
      query.equalTo('type', reportType);
    }

    const results = await query.find();

    this.setState({ reports: results || [] });
  }

  setReportType(text) {
    const { REPORT_TYPES } = ReportsList;
    const reportType = REPORT_TYPES[text];
    this.setState({ reportType }, _ => this.fetchReports());
  }

  // NOTE: debounce this to minimize state changes
  //       when search field is updated
  setSearch = debounce(text => {
    this.setState({ search: text });
  }, 200);

  gotoGenerateReport = () => {
    // NOTE: for this to work, it requires Reports component
    //       to handle changes to location prop and update the
    //       active tab accordingly
    this.props.history.push('/reports/generate');
  }

  generateSimilarReport = async (report) => {
    const type = report.get('type');

    const fields = {
      name: report.get('name'),
      accountType: report.get('userSegment'),
      selectedUsers: report.get('users'),
      selectedCompany: report.get('symbols') || [],
    };

    const modal = this.modal[type];
    modal.setFields(fields);
    modal.openModal();
  }

  deleteReport = async (report) => {
    if (global.confirm(`Are you sure you want to delete '${report.get('name')}'?`)) {
      await report.destroy();
      await this.fetchReports();
    }
  }

  // NOTE: delayed fetching reports in to give time for job to save report
  callGenerateReport = async (data) => {
    await generateReport(data);
    setTimeout(this.fetchReports, 200)
  }

  render() {
    const { SEGMENT_NAMES, REPORT_TYPES } = ReportsList;
    const { tagIds } = this.props;
    const { loading, search, reports } = this.state;

    let filteredReports = reports;

    if (search) {
      filteredReports = filter(reports, report => {
        return (report.get('name') || '').toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    }

    // NOTE: reverse REPORT_TYPES dictionary so we map it the
    //       other way without having to hard code another set
    //       of values
    const reversedReportTypes = {};
    for (var key in REPORT_TYPES) {
      reversedReportTypes[REPORT_TYPES[key]] = key;
    }

    return (
      <div className="container-fluid my-4">
        <div className="card">
          <div className="card-header py-4">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-auto">
                    <input className="form-control " type="search" placeholder="Search..." onChange={e => this.setSearch(e.target.value)} />
                  </div>
                  <div className="col-auto">
                    <Dropdown id="search-" text="All Report Types"
                      menu={Object.keys(REPORT_TYPES)}
                      clickAction={(text) => this.setReportType(text)} />
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" onClick={this.gotoGenerateReport}>Generate Report</button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush list my--3">
              {filteredReports.length > 0 ? filteredReports.map(report => {
                const file = report.get('file');
                const user = report.get('user');
                const username = user.getUsername();
                const email = user.getEmail();
                const firstName = user.get('firstName');
                const date = moment(report.get('createdAt')).format('MMMM D, YYYY');
                const status = report.get('status');
                return (
                  <li key={report.id} className="list-group-item px-0">
                    <div className="row">
                      <div className="col">
                        <div className="badge badge-info mb-2 text-uppercase">{reversedReportTypes[report.get('type')]}</div>
                        <div className="">{report.get('name')}</div>
                        <div className="text-muted small">Generated by {firstName || username || email} on {date}</div>
                      </div>
                      <div className="col-3 d-flex align-items-center">
                        <div className="row">
                          <div className="col-auto">
                            <div>{SEGMENT_NAMES[report.get('userSegment')]}</div>
                            <div className="text-muted text-uppercase small">User Segment</div>
                          </div>
                          <div className="col-auto">
                            <div>{report.get('numRowsGenerated')}</div>
                            <div className="text-muted text-uppercase small">Rows</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 d-flex align-items-center justify-content-end">
                        {status === 'success' ? (
                          <a href={file && file.url()} className="btn btn-sm btn-outline-primary">Download</a>
                        ) : (
                            <button disabled={true} className="btn btn-sm btn-warning disabled">
                              {status === 'pending' ? 'Pending...' : 'Failed'}
                            </button>
                        )}
                      </div>
                      <div className="col-auto d-flex align-items-center">
                          <div className="dropdown">
                            <button className="dropdown-ellipses dropdown-toggle border-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fe fe-more-vertical" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                              <button className="dropdown-item" onClick={() => this.generateSimilarReport(report)}>Generate Similar</button>
                              <button className="dropdown-item" onClick={() => this.deleteReport(report)}>Delete</button>
                            </div>
                          </div>
                      </div>
                    </div>
                  </li>
                );
              }) : (
                <div className="d-flex justify-content-center">
                  <div className="row">
                    <div className="col-auto p-6">
                      <div className="text-muted">{loading ? 'Loading...' : 'No Reports Found'}</div>
                    </div>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
        <ReportModal
          ref={ref => this.modal.USER_LIST = ref}
          id="report-list-user-report-modal"
          header="New User List Report"
          type="USER_LIST"
          tagIds={tagIds} selectUser="multiple"
          onSubmit={this.callGenerateReport}
        />
        <ReportModal
          ref={ref => this.modal.USER_PORTFOLIO = ref}
          id="report-list-user-portfolio-report-modal"
          header="New User Portfolio Report"
          tagIds={tagIds}
          type="USER_PORTFOLIO"
          selectUser="single"
          onSubmit={this.callGenerateReport}
        />
        <ReportModal
          ref={ref => this.modal.HOLDINGS = ref}
          id="report-list-holdings-report-modal"
          header="New Holdings Report"
          type="HOLDINGS"
          tagIds={tagIds}
          selectCompany={true}
          onSubmit={this.callGenerateReport}
        />
        <ReportModal
          ref={ref => this.modal.TDH = ref}
          id="report-list-tdh-report-modal"
          header="New TDH Report"
          type="TDH"
          tagIds={tagIds}
          onSubmit={this.callGenerateReport}
        />
      </div>
    );
  }
}

export default withRouter(ReportsList);
