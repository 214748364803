import React, { useState } from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';

import { deleteTransactionsByPortfolioId } from '../parse';

const RefreshPlaidConnection = (props) => {
  const [loading, updateLoading] = useState(false);

  const handleUnlinkPortfolio = async() => {
    const { closeModal, showNotification, updateIsPlaidConnectionExpired } = props;
    try {
      updateLoading(true);
      await _unlinkPortfolio();
      await updateIsPlaidConnectionExpired(false);
      updateLoading(false);
      showNotification('success', 'Your portfolio has successfully been unlinked.');
      closeModal();
    } catch (e) {
      updateLoading(false);
      showNotification('error', 'There was an error with unlinking your portfolio. Please try again later.');
      closeModal();
    }
  };

  const _unlinkPortfolio = async() => {
    const { activePortfolio , unlinkPortfolio } = props;
    if (activePortfolio.id) {
      await Parse.Cloud.run('deleteInvestmentInstitutionByPortfolioId', { id: activePortfolio.id });
      await unlinkPortfolio();
    }
  };

  const clearTransactions = async() => {
    const { activePortfolio, closeModal, getPortfolioData, showNotification, updateIsPlaidConnectionExpired } = props;
    try {
      await _unlinkPortfolio();
      await deleteTransactionsByPortfolioId(activePortfolio.id);
      await updateIsPlaidConnectionExpired(false);
      await getPortfolioData();
    } catch (e) {
      console.error('Error clearing transactions: ', e);
      updateLoading(false);
      showNotification('error', 'There was an error with clearing your portfolio. Please try again later.');
      closeModal();
    }
  };

  return (
    <div className="m-2">
      <div className="text-center">
        <div className="modal-header p-1">
          <h2>This portfolio's connection to your brokerage account has expired.</h2>
        </div>
        <section className="modal-body">
          <p className="lead">Would you like to re-establish the connection?</p>
          <p className="text-left">The transactions will be cleared from the portfolio and imported again after re-connecting to the brokerage account.</p>
          <p className="text-left">Or you may unlink this portfolio from the brokerage account and keep the existing transactions.</p>
        </section>
      </div>
      <div className="modal-footer pb-0 pt-3">
        <button type="button" className="btn btn-outline-dark mr-auto" data-dismiss="modal" onClick={handleUnlinkPortfolio} disabled={loading}>Unlink and Keep Transactions</button>
        <button data-dismiss="modal" onClick={clearTransactions} id="unlink-portfolio-button" type="button" className="btn btn-primary" disabled={loading}>Clear Transactions and Re-Connect</button>
      </div>
    </div>
  )
}

RefreshPlaidConnection.propTypes = {
  activePortfolio: PropTypes.object.isRequired,
  getPortfolioData: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  unlinkPortfolio: PropTypes.func.isRequired,
  updateIsPlaidConnectionExpired: PropTypes.func.isRequired,
};

export default RefreshPlaidConnection;
