import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import './SummaryItem.css'

class SummaryItem extends Component {
  static propTypes = {
    heading: PropTypes.string,
    hidePriceData: PropTypes.bool,
    icon: PropTypes.object,
    price: PropTypes.number,
    percentage: PropTypes.number,
    redirectTo: PropTypes.string,
    suspended: PropTypes.bool,
    tickerSymbol: PropTypes.string,
  };

  static defaultProps = {
    heading: null,
    hidePriceData: false,
    price: null,
    percentage: null,
  }

  render() {
    const { heading, hidePriceData, icon, price, redirectTo, suspended, tickerSymbol } = this.props;
    const eps = 0.0001;

    let { percentage } = this.props;
    let percentageBade;

    if (percentage !== null && percentage !== undefined) {
      percentage = Math.round((percentage + Number.EPSILON) * 100) / 100;
      if (percentage >= eps) {
        percentageBade = <span className="badge badge-success mt--2 ml-2">{percentage}%</span>;
      } else if (percentage <= -eps) {
        percentageBade = <span className="badge badge-danger mt--2 ml-2">{percentage}%</span>;
      } else {
        percentageBade = <span className="badge badge-light mt--2 ml-2">0.00%</span>
      }
    }

    return (
      <div className={`flex-fill d-flex flex-row ${redirectTo ? "cursor-pointer" : ""}`} onClick={redirectTo ? () => this.props.history.push(redirectTo) : null}>
        <span className="ticker-symbol">
          <span>{icon ? (<span>{icon}</span>) : tickerSymbol}</span>
        </span>

        <div className="mt-2">
          <h6 className="text-uppercase mb-2">{heading}</h6>
          { <span className="h2 mb-0">{price ? `$${parseFloat(price).toFixed(2)}` : (hidePriceData ? "" : 'N/A')}</span>}
          { percentageBade }
          {suspended && <span className="badge badge-warning mt--2 ml-2">Suspended</span>}
        </div>
      </div>
    );
  }
}

export default withRouter(SummaryItem);
