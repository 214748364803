import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Parse from 'parse';
import StatModal from "../../components/StatModal";

class MarketingStats extends Component {
  state = {
    dividendsEarnedByTDHStocks: 0,
    loading: false,
    ownedStocks: [],
    searchTicker: null,
    sum: 0,
    sumTDHDivReceived: 0,
    sumAvgPortfolioSize: 0,
    tdhIncomeEarnedArray: [],
    holdingsAndDividends: null
  }

  mostOwnedStocks = async () => {
    //if data already fetched, do not load re-load.
    if(this.state.ownedStocks.length) return;
    this.setState({loading: true});

    try {
      const mostOwnedStocks = await Parse.Cloud.run('getMostOwnedStocks');
      const incomeEarned = await Parse.Cloud.run('getTotalIncomeEarned', {mostOwnedStocks});

      //SUM of all total income earned columns for the list of most-owned stocks
      const sum = _.sumBy(incomeEarned, ticker => ticker.dividendsReceived);
      this.setState({
        loading: false,
        ownedStocks: _.merge(incomeEarned, mostOwnedStocks).slice(0,20),
        sum,
      });
    } catch (error) {
      alert("There was an error retrieving the data in mostOwnedStocks.");
      console.error(error);

      this.setState({
        loading: false,
        ownedStocks: [],
        sum: null,
      });
    }
  }

  tdhDividendsEarned = async () => {
    //if data already fetched, do not load re-load.
    if(this.state.tdhIncomeEarnedArray.length) return;
    this.setState({loading: true})

    try {
      const tdhIncomeEarnedArray = await Parse.Cloud.run('getTDHSubscribersDividendsEarned');
      const sumTDHDivReceived = _.sumBy(tdhIncomeEarnedArray, user => user.dividendsReceived);

      this.setState({
        loading: false,
        tdhIncomeEarnedArray : tdhIncomeEarnedArray ? tdhIncomeEarnedArray.slice(0,10) : [],
        sumTDHDivReceived,
      });
    } catch (error) {
      alert("There was an error retrieving the data in tdhDividendsEarned.");
      console.error(error);

      this.setState({
        loading: false,
        tdhIncomeEarnedArray : [],
        sumTDHDivReceived : null,
      });
    }
  }

  dividendsByDH = async () => {
    //if data already fetched, do not load re-load.
    if(this.state.dividendsEarnedByTDHStocks) return;
    this.setState({loading: true});

    try {
      const dividendsEarnedByTDHStocks = await Parse.Cloud.run('getDividendsEarnedByTDHStocks');

      this.setState({
        loading: false,
        dividendsEarnedByTDHStocks
      });
    } catch (error) {
      alert("There was an error retrieving the data in dividendsByDH.");
      console.error(error);

      this.setState({
        loading: false,
        dividendsEarnedByTDHStocks: null
      });
    }
  }

  portfolioAvg = async () => {
    //if data already fetched, do not load re-load.
    if(this.state.avgPortfolioSize) return;
    this.setState({loading: true})

    try {
      const avgPortfolioSize = await Parse.Cloud.run('getAverageSizePortfolio');

      this.setState({
        loading: false,
        avgPortfolioSize
      });
    } catch (error) {
      alert("There was an error retrieving the data in portfolioAvg.");
      console.error(error);

      this.setState({
        loading: false,
        avgPortfolioSize: 0
      });
    }
  }

  userHoldingsAndDividends = async () => {
    if (!this.state.searchTicker) return;
    this.setState({loading: true});

    try {
      const holdingsAndDividends = await Parse.Cloud.run('getUserHoldingsDividendsEarned', {ticker: this.state.searchTicker});
      this.setState({
        loading: false,
        holdingsAndDividends
      })
    } catch (error) {
      alert("There was an error retrieving the data in userHoldingsAndDividends.");
      console.error(error);
      this.setState({
        loading: false,
        qty : null,
        ticker: null,
        dividendsReceived: null
      })
    }
  }

  onSearchTickerChange = _.debounce((email) => {
    this.setState({
      searchTicker: email ? email.toUpperCase() : ""
    })
  }, 200)

  render() {
    const {
      ownedStocks,
      sum,
      loading,
      tdhIncomeEarnedArray,
      sumTDHDivReceived,
      dividendsEarnedByTDHStocks,
      avgPortfolioSize,
      holdingsAndDividends
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" onClick={this.mostOwnedStocks} data-toggle="modal" data-target="#most-owned-stock-list">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <img src="/img/logo-tdh-target.png" alt="The Dividend Hunter" style={{height: '32px', width: '32px'}} />
                </div>
                <h2 className="card-title my-3">
                  Most-Owned Stocks List/Income
                </h2>
                <p className="card-text text-muted mb-2">Total Income Earned for the most-owned stocks.</p>
              </div>
            </button>
          </div>

          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" onClick={this.tdhDividendsEarned} data-toggle="modal" data-target="#tdh-sub-dividend-earned">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <img src="/img/logo-tdh-target.png" alt="The Dividend Hunter" style={{height: '32px', width: '32px'}} />
                </div>
                <h2 className="card-title my-3">
                  TDH Subscribers Dividends Earned
                </h2>
                <p className="card-text text-muted mb-2">Sum of all dividends earned by TDH subscribers.</p>
              </div>
            </button>
          </div>

          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" onClick={this.dividendsByDH} data-toggle="modal" data-target="#dividends-by-dh">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <img src="/img/logo-tdh-target.png" alt="The Dividend Hunter" style={{height: '32px', width: '32px'}} />
                </div>
                <h2 className="card-title my-3">
                  Dividends Earned by DH Stocks
                </h2>
                <p className="card-text text-muted mb-2">Sum of all dividends earned by DH stocks in user portfolios.</p>
              </div>
            </button>
          </div>

          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" onClick={this.portfolioAvg} data-toggle="modal" data-target="#avg-portfolio-size">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <img src="/img/logo-tdh-target.png" alt="The Dividend Hunter" style={{height: '32px', width: '32px'}} />
                </div>
                <h2 className="card-title my-3">
                  Average Portfolio Size
                </h2>
                <p className="card-text text-muted mb-2">SUM total of all portfolio values/# of portfolios in Divcaster.</p>
              </div>
            </button>
          </div>

          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" data-toggle="modal" data-target="#holdings-search">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <img src="/img/logo-tdh-target.png" alt="The Dividend Hunter" style={{height: '32px', width: '32px'}} />
                </div>
                <h2 className="card-title my-3">
                  User Holdings and Dividends
                </h2>
                <p className="card-text text-muted mb-2">Search users holdings and dividends earned by Stock.</p>
              </div>
            </button>
          </div>
        </div>

        <StatModal id="most-owned-stock-list" header="Most-Owned Stocks List/Income">
          { loading
              ? (
                  <div>LOADING...</div>
              ) : (

                  <table className="table">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Ticker</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Total Income Earned</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ownedStocks && ownedStocks.map((stock, i) => (
                        <tr key={stock.ticker}>
                          <th scope="row">{i+1}</th>
                          <td>{stock.ticker}</td>
                          <td>{stock.count}</td>
                          <td>${stock.dividendsReceived.toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>TOTAL:</td>
                      <td><strong>${sum ? sum.toFixed(2) : "N/A"}</strong></td>
                    </tr>
                    </tfoot>
                  </table>
              )
          }
        </StatModal>

        <StatModal id="tdh-sub-dividend-earned" header="TDH Subscribers Dividends Earned">
          { loading
            ? (
                <div>LOADING...</div>
            ) : (
                <table className="table">
                  <thead>
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Total Dividends Earned</th>
                  </tr>
                  </thead>
                  <tbody>
                  {tdhIncomeEarnedArray && tdhIncomeEarnedArray.map((user, i) => (
                      <tr key={user.user}>
                        <th scope="row">{user.user}</th>
                        <td>${user.dividendsReceived.toFixed(2)}</td>
                      </tr>
                  ))}
                  </tbody>
                  <tfoot>
                  <tr>
                    <td>TOTAL:</td>
                    <td><strong>${sumTDHDivReceived ? sumTDHDivReceived.toFixed(2) : "N/A"}</strong></td>
                  </tr>
                  </tfoot>
                </table>
            )
          }
        </StatModal>

        <StatModal id="dividends-by-dh" header="Total Dividends Earned by DH Stocks">
          { loading
            ? (
                <div>LOADING...</div>
            ) : (
                <table className="table">
                  <thead>
                  <tr>
                    <th scope="col">Total Dividends Earned</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>${dividendsEarnedByTDHStocks ? dividendsEarnedByTDHStocks.dividendsReceived.toFixed(2) : "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
            )
          }
        </StatModal>

        <StatModal id="avg-portfolio-size" header="Average portfolio size">
          { loading
            ? (
                <div>LOADING...</div>
            ) : (
                <table className="table">
                  <thead>
                  <tr>
                    <th scope="col">Average portfolio size</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>${avgPortfolioSize ? avgPortfolioSize.toFixed(2) : "N/A"}</td>
                  </tr>

                  </tbody>
                </table>
            )
          }
        </StatModal>

        <StatModal id="holdings-search" header="User Holdings and Dividends Search by Ticker">
          <div className="container-fluid my-4">
            <div className="row">
              <div className="col-auto">
                <input className="form-control" type="search" placeholder="Search by ticker..." onChange={e => this.onSearchTickerChange(e.target.value)} style={{textTransform: "Uppercase"}} />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" onClick={this.userHoldingsAndDividends}>Submit</button>
              </div>
            </div>
          </div>

          { loading &&
            <div>LOADING...</div>
          }

          { holdingsAndDividends &&
            <table className="table">
              <thead>
              <tr>
                <th scope="col">Ticker</th>
                <th scope="col">Holdings (QTY)</th>
                <th scope="col">Total Dividends Earned</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{holdingsAndDividends.ticker}</td>
                <td>{holdingsAndDividends.qty}</td>
                <td>${holdingsAndDividends.dividendsReceived ? holdingsAndDividends.dividendsReceived.toFixed(2) : "N/A"}</td>
              </tr>
              </tbody>
            </table>
          }
        </StatModal>
      </div>
    );
  }
}

export default withRouter(MarketingStats);
