import React, { Component } from 'react';

import './Footer.css';

class Footer extends Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

    return (
      <div className="footer container">
        <div className="d-flex align-items-center justify-content-between">
          <div className="footer-left">
            <span>&copy;&nbsp;{new Date().getFullYear()}&nbsp;Investors Alley Corp.</span>
            <ul className="footer-links">
              <li><a href="https://www.investorsalley.com/terms-of-use/#privacy" target="_blank" title="Privacy Policy" rel="noopener noreferrer">Privacy</a></li>
            </ul>
          </div>
          <img src="/img/divcaster-icon.svg" alt="Divcaster by Investors Alley Corp." style={{display: 'block', width: '32px'}}/>
            <div className="footer-right">
              <ul className="footer-links">
                <li><a href="https://support.divcaster.com" target="_blank" title="Help Center" rel="noopener noreferrer">Help</a></li>
                <li><a href="https://support.divcaster.com" target="_blank" title="Contact Customer Support" rel="noopener noreferrer">Contact Us</a></li>
              </ul>
            </div>
        </div>
      </div>
    );
  }
}

export default Footer;
