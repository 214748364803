import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import moment from 'moment';

import TableControl from './TableControl';
import Button from '../components/Button';
import SummaryItem from '../components/SummaryItem';
import ProgressCircle from '../components/ProgressCircle';

import { tableScroll } from '../utils/jquery';
import { commaSeparatorReg } from '../utils/regex';
import { COMPANY, SYMBOL, TOTAL_QUANTITY, YIELD_COST, GAIN_LOSS, RETURN, DIV_YIELD, DATE_KEY,
  AVERAGE, LAST_PRICE_PER_SHARE, TICKER_WEIGHT, PREVIOUS_DELTA, OPEN_QUANTITY, CLOSE_QUANTITY,
  OPEN_DATE, CLOSE_DATE, CLOSE_GAIN, OPEN, CLOSED, SUSPENDED,
} from '../constants';

  import './Table.css';

  class Table extends Component {
    static propTypes = {
      tickers: PropTypes.array,
      portfolioSummary: PropTypes.object,
      activePortfolio: PropTypes.object.isRequired,
      columns: PropTypes.array.isRequired,
      sort: PropTypes.func.isRequired,
      expand: PropTypes.func.isRequired,
      expandedRows: PropTypes.array.isRequired,
      submitTicker: PropTypes.func,
      currentSort: PropTypes.string,
      ascIsNext: PropTypes.bool,
      userIsAdmin: PropTypes.bool,
      showNotification: PropTypes.func.isRequired,
      onBasicPlan: PropTypes.bool.isRequired,
      tdh: PropTypes.object.isRequired,
      onExportTransactions: PropTypes.func.isRequired,
      openTickerToDeleteModal: PropTypes.func.isRequired,
      txToDelete: PropTypes.array.isRequired,
      onSelectTxToDelete: PropTypes.func.isRequired,
      onSelectTxToEdit: PropTypes.func.isRequired,
      onRefreshPlaidPortfolio: PropTypes.func.isRequired,
    }

    static defaultProps = {
      ticker: '',
      tickers: [],
      ascIsNext: true,
      currentSort: null,
      portfolioSummary: {},
      onTickerUpdate: () => {},
      submitTicker: () => {},
    }

    state = {}

    componentDidMount() {
      tableScroll();
    }

    preventEventBubbling = (e) => {
      e.preventDefault();
      e.stopPropagation();
    }

    redirectTo = (route) => {
      const { history } = this.props;
      history.push(route);
    }

    isChecked(txId) {
      const { txToDelete } = this.props;
      return txToDelete.some(tx => tx === txId);
    }

    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$%$%$%$%$%";
      for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    }

  render() {
    const {
      activePortfolio, sort, expand, columns, showNotification, currentSort, ascIsNext, userIsAdmin, onBasicPlan,
      tdh, expandedRows, onExportTransactions, openTickerToDeleteModal, portfolioSummary, tickers, txToDelete, onSelectTxToDelete,
      onSelectTxToEdit, openDeleteModal, setActivePortfolio, updatePortfolios, showOpenPositions, onToggleOpenClosePosition, onRefreshPlaidPortfolio,
      isPlaidConnectionExpired, } = this.props;

    const activePortfolioIsTDH = activePortfolio.id === tdh.id;
    const controlDisabled = !!activePortfolio.investmentInstitutionId;

    return (
      <div className="table-container">
        <div className="table-sticky" style={activePortfolioIsTDH && !userIsAdmin ? { height: 72 } : null}>
          {/* Table Control & Scroll Bar */}
          {
            (!(activePortfolioIsTDH) || userIsAdmin) &&
              <TableControl
                tdh={tdh}
                tickers={tickers}
                txToDelete={txToDelete}
                onBasicPlan={onBasicPlan}
                userIsAdmin={userIsAdmin}
                activePortfolio={activePortfolio}
                showNotification={showNotification}
                portfolioSummary={portfolioSummary}
                onExportTransactions={onExportTransactions}
                openDeleteModal={openDeleteModal}
                setActivePortfolio={setActivePortfolio}
                updatePortfolios={updatePortfolios}
                showOpenPositions={showOpenPositions}
                onToggleOpenClosePosition={onToggleOpenClosePosition}
                onRefreshPlaidPortfolio={onRefreshPlaidPortfolio}
                isPlaidConnectionExpired={isPlaidConnectionExpired}
              />
          }

              {/* <ScrollBar /> */}

              {/* Header */}
              <div className="table-header-wrapper" >
                <div className="table-header table--scroll" id="portfolio-table-header">
                  <table className="table mb-0">
                      <tbody>
                        <tr>
                          {columns && columns.map(({ name, key, hidden }) => {
                            if (hidden) return null;
                            if (key === AVERAGE) { name = (<span>Avg Cost<br/>Per Share</span>)};
                            return (
                              <th scope="col" key={key} className={`${key === COMPANY ? 'column-wide sticky sticky-left text-left' : ''} table-header-column`}>
                                <div onClick={() => sort(key)} className={`row-content ${key === COMPANY ? 'd-inline-block' : ''}`}>
                                  {name}
                                  {currentSort === key
                                    ? ascIsNext
                                    ? (<img className="sort-icon mr-2" src="/icons/icon-sort-desc.svg" alt="sort-descending" height="12px" width="12px" />)
                                    : (<img className="sort-icon mr-2" src="/icons/icon-sort-asc.svg" alt="sort-ascending" height="12px" width="12px" />)
                                    : (<img className="sort-icon mr-2" src="/icons/icon-sort-none.svg" alt="sort-none" height="12px" width="12px" />)}
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Body */}
              <div className="position-relative table--scroll" id="portfolio-table-body">
                { onBasicPlan && !activePortfolioIsTDH
                  && (
                    <div className="table-upgrade-msg-wrapper" style={{zIndex: '999'}}>
                      <div className="table-upgrade-msg alert alert-dark alert-dismissible fade show" style={{paddingRight: '1.25rem'}}>
                        <div className="card-body text-center">
                          <h2>Unlock your full earning potential!</h2>
                          <p>Get access to all this data by upgrading to a Premium account.</p>
                          <Button dashClass="btn-block" name="Upgrade to Premium" clickAction={() => this.redirectTo('/account/billing')} />
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span className="alert-dark" aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                }

                <table className="table mb-0 table-hover cursor-pointer">
                  <tbody>
                    { !_.isEmpty(tickers) && tickers.map((row, rIndex) => {
                      const rowExpanded = expandedRows.some(symbol => symbol === row[SYMBOL]);

                      // hide tickers with empty transactions for all portfolios except tdh portfolio
                      if (!activePortfolioIsTDH) {
                        if(showOpenPositions) {
                          if(_.isEmpty(row[OPEN])) return (<></>);
                        }
                        else {
                          if(_.isEmpty(row[CLOSED])) return(<></>);
                        }
                      }

                      return (
                        <React.Fragment key={row[COMPANY] + rIndex}>
                          {/* Rows */}
                          <tr>
                            {columns && columns.map(({ key, hidden }) => {
                              if (hidden) return null;
                              const hideData = (onBasicPlan && (!(key === TOTAL_QUANTITY || key === COMPANY))) && !activePortfolioIsTDH;
                              let processedData = null;
                              let suspended = null;
                              if (hideData) {
                                processedData = this.randomString();
                              } else {
                                processedData = row[key];
                                suspended = row.suspended;
                                switch (key) {
                                  case COMPANY:
                                    break;
                                  case TOTAL_QUANTITY:
                                  case OPEN_QUANTITY:
                                  case CLOSE_QUANTITY:
                                    processedData = parseFloat(processedData.toFixed(4));
                                    break;
                                  case GAIN_LOSS:
                                  case CLOSE_GAIN:
                                  case RETURN:
                                  case YIELD_COST:
                                  case DIV_YIELD: // Adds '%'
                                    if(processedData) processedData = `${_.round(parseFloat(processedData * 100), 2).toLocaleString()}%`;
                                    break;
                                  case OPEN_DATE:
                                  case CLOSE_DATE:
                                    if (processedData) processedData = moment.utc(processedData).format('L');
                                    break;
                                  default: // Adds $ and ',' on thousands' place
                                    if(processedData) processedData = `$${parseFloat(processedData).toFixed(2).replace(commaSeparatorReg, '$1,')}`;
                                }
                              }

                              return key === COMPANY
                              ? (
                                // Rows: Headers (Company Names)
                                <th
                                  key={row[COMPANY] + key}
                                  style={{ cursor: activePortfolioIsTDH ? 'default' : 'pointer' }}
                                  className="column-wide sticky sticky-left company"
                                  onClick={ activePortfolioIsTDH ? () => {} : () => { expand(row[SYMBOL]) } }>
                                  <div className="d-flex flex-row">
                                    { activePortfolioIsTDH ? '' : <span className={`fe fe-chevron-${rowExpanded ? 'up' : 'down'} my-auto mr-2`} id={`company-chevron-icon${rIndex}`} /> }
                                    <SummaryItem
                                      heading={processedData}
                                      tickerSymbol={row[SYMBOL]}
                                      price={row[LAST_PRICE_PER_SHARE]}
                                      percentage={row[PREVIOUS_DELTA]}
                                      suspended={!!row[SUSPENDED]}
                                      redirectTo={`/ticker/${row[SYMBOL]}`}
                                      />

                                    { showOpenPositions && <ProgressCircle width={35} percentage={(Math.round((row[TICKER_WEIGHT])*100))}/> }
                                    {
                                      ((!userIsAdmin && activePortfolioIsTDH) || controlDisabled)
                                        ? null
                                        : (
                                          <button className="border-0 p-0 cursor-pointer ml-3 bg-trans" onClick={(e)=> openTickerToDeleteModal(e, row[SYMBOL])}>
                                            <i className="fe fe-trash-2"></i>
                                          </button>
                                        )
                                    }
                                  </div>
                                </th>
                              )
                              : (
                                <td className={`align-middle ${hideData ? 'obscured' : '' }`} key={row[COMPANY] + key}>
                                  <div className="row-content">{ processedData === 0 ? '—' : processedData || (suspended ? 'N/A' : '—') }</div>
                                </td>
                              )
                            }
                          )}
                        </tr>

                        {/* perTransaction Data:  */}
                        { rowExpanded
                          && row[showOpenPositions ? OPEN : CLOSED].map((hData, hdIndex) => {
                            return (
                              <ReactCSSTransitionGroup
                                component="tr"
                                transitionName="historicalDataDropdown"
                                transitionAppear={true}
                                transitionAppearTimeout={500}
                                transitionEnter={false}
                                transitionLeave={true}
                                transitionLeaveTimeout={500}
                                key={row[COMPANY] + hdIndex}
                                className="row-historical"
                                >
                                {columns && columns.map(({ key, hidden }) => {
                                  if (hidden) return null;
                                  const { id } = hData;
                                  const referenceId = showOpenPositions ? id : hData.referenceId;

                                  let processedData = hData[key];
                                  const suspended = hData.suspended;
                                  const hideData = (onBasicPlan && (!(key === TOTAL_QUANTITY || key === COMPANY))) && !activePortfolioIsTDH;
                                  if (hideData) {
                                    processedData = this.randomString();
                                  } else {
                                    switch (key) {
                                      case COMPANY:
                                        processedData = hData[DATE_KEY];
                                        break;
                                      case TOTAL_QUANTITY:
                                      case OPEN_QUANTITY:
                                      case CLOSE_QUANTITY:
                                        processedData = parseFloat(hData[key].toFixed(4));
                                        break;
                                      case GAIN_LOSS:
                                      case CLOSE_GAIN:
                                      case RETURN:
                                      case YIELD_COST:
                                      case DIV_YIELD: // Adds '%'
                                        if(hData[key]) processedData = `${_.round(parseFloat(hData[key] * 100), 2).toLocaleString()}%`;
                                        break;
                                      case OPEN_DATE:
                                      case CLOSE_DATE:
                                        if (processedData) processedData = moment.utc(hData[key]).format('L');
                                        break;
                                      default: // Adds $ and ',' on thousands' place
                                        if(hData[key]) processedData = `$${parseFloat(hData[key]).toFixed(2).replace(commaSeparatorReg, '$1,')}`;
                                      }
                                    }

                                    return key === COMPANY && ((activePortfolioIsTDH && userIsAdmin) || !activePortfolioIsTDH)
                                    ? (
                                      <th key={row[COMPANY] + key} className="sticky sticky-left row-historical d-flex" style={{ alignItems: 'center' }}>
                                        {!activePortfolio.investmentInstitutionId && (
                                          <input id='delete' type="checkbox" onChange={() => onSelectTxToDelete(referenceId)} checked={this.isChecked(referenceId)}/>
                                        )}
                                        <div className="row-content d-flex justify-content-left">{ processedData }</div>

                                        {!controlDisabled && <span className="fe fe-edit d-flex align-items-center" onClick={() => onSelectTxToEdit({...hData, companyName: row[key]})} />}

                                      </th>
                                    )
                                    : (
                                      <td key={row[COMPANY] + key} className={hideData ? 'obscured' : ''}>
                                        <div className="row-content text-right">{ processedData === 0 ? 0 : processedData || (suspended ? 'N/A' : '—') }</div>
                                      </td>
                                    );
                                  })}
                                </ReactCSSTransitionGroup>);
                              })}
                            </React.Fragment>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* Footer */}
                  <div className="sticky sticky-bottom table-footer table--scroll" id="portfolio-table-footer">
                    <table className="table mb-0 bg-default table-secondary">
                      <tbody>
                        { !_.isEmpty(portfolioSummary)
                          ? (

                            <tr>
                              {columns && columns.map(({ key, hidden }) => {
                                if (hidden) return null;

                                const hideData = (onBasicPlan && (!(key === TOTAL_QUANTITY || key === COMPANY))) && !activePortfolioIsTDH;
                                let processedData;
                                if (hideData) {
                                  processedData = this.randomString();
                                } else {
                                  processedData = portfolioSummary[key];
                                }
                                switch (key) {
                                  case COMPANY:
                                    processedData = 'Totals';
                                    break;
                                  case TOTAL_QUANTITY:
                                  case OPEN_QUANTITY:
                                  case CLOSE_QUANTITY:
                                    processedData = '';
                                    break;
                                  case GAIN_LOSS:
                                  case CLOSE_GAIN:
                                  case RETURN:
                                  case DIV_YIELD:
                                    if(portfolioSummary[key]) processedData = `${_.round(parseFloat(portfolioSummary[key] * 100), 2).toLocaleString()}%`;
                                    break;
                                  default: // Adds ',' on thousands' place
                                    if(portfolioSummary[key]) processedData = `$${parseFloat(portfolioSummary[key]).toFixed(2).replace(commaSeparatorReg, '$1,')}`;
                                }
                                return key === COMPANY
                                ? (
                                  <th key={key} className="column-wide sticky sticky-left table-secondary text-left">
                                    <div className="row-content d-inline-block">{ processedData }</div>
                                  </th>
                                )
                                : (
                                  // Rows: Body (Financial Data) - historical
                                  <td className={hideData ? 'obscured' : '' } key={key}>
                                    <div className="row-content text-right">{ processedData === 0 ? 0 : processedData || '—' }</div>
                                  </td>
                                );
                              })}
                            </tr>)
                            : null }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  );
                }
              }

              export default withRouter(Table);
