import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Ellipses from '../components/Ellipses';
import { PORTFOLIO_NAME_MAX_LENGTH } from '../constants';
class EllipsesContainer extends Component {
  static propTypes = {
    dashColor: PropTypes.string,
    ellipsesColor: PropTypes.string,
    name: PropTypes.string,
    dropDownItems: PropTypes.array,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    clickAction: PropTypes.func,
    hideEllipses: PropTypes.bool,
    activated: PropTypes.bool,
  }

  static defaultProps = {
    dashColor: '',
    ellipsesColor: '',
    name: '',
    dropDownItems: [],
    icon: '',
    iconColor: null,
    clickAction: () => {},
    hideEllipses: false,
    activated: true,
  }

  render() {
    const { name, icon, iconColor, dashColor, ellipsesColor, dropDownItems, clickAction, hideEllipses, activated } = this.props;
    return (
      <div title={name} className={`btn btn-${dashColor || 'primary'} btn-block d-flex justify-content-between ${activated ? '' : 'disabled'}`} onClick={activated ? clickAction : null}>
        <div>
          {icon ? <i className={icon} style={iconColor ? {color: iconColor}: null}/> : null}
          { name.length < PORTFOLIO_NAME_MAX_LENGTH ? name : name.substring(0, PORTFOLIO_NAME_MAX_LENGTH) + '...' }
        </div>
        { !hideEllipses
          && (
            <Ellipses
              dashColor={`${ellipsesColor || 'white'} mt-3`}
              dropDownItems={dropDownItems}
            />
          ) }
      </div>
    );
  }
}

export default EllipsesContainer;
