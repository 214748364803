import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ButtonGroup extends Component {
  static propTypes = {
    buttons: PropTypes.array,
    clickAction: PropTypes.func,
    dashClass: PropTypes.string,
  }

  static defaultProps = {
    buttons: [],
    clickAction: () => {},
    dashClass: '',
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { buttons, clickAction, dashClass} = this.props;
    return (
      <div className="btn-group interval-toggle-container" role="group" aria-label="Basic example">
        {buttons.map((button, i) => {
          return (
            <button
              key={i}
              type="button"
              style={{ zIndex: 0 }}
              className={`btn ${dashClass} ${button.active ? 'active' : ''}`}
              onClick={() => clickAction(button)}
            >
              {button.name}
            </button>
          );
        })}
      </div>
    );
  }
}

export default ButtonGroup;
