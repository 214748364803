import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ProgressCircle.css';

const STROKE_WIDTH = 4;

class ProgressCircle extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { width, percentage } = this.props;
    const cxcy = width/2;
    const radius = (width / 2) - (STROKE_WIDTH / 2);
    const circumference = Math.PI * (radius * 2);
    const percentageOffset = ((100 - percentage) / 100) * circumference;

    return (
      <div id="progress-div" className="d-flex align-items-center" data-pct={percentage}>
        <svg id="progress-svg" width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
          <circle cx={cxcy} cy={cxcy} r={radius} fill="none" stroke="#e6e6e6" strokeWidth={STROKE_WIDTH} />
          <circle cx={cxcy} cy={cxcy} r={radius} fill="none" stroke="#39afd1" strokeWidth={STROKE_WIDTH} strokeDasharray={circumference} strokeDashoffset={percentageOffset}/>
        </svg>
      </div>
  )};
}
export default ProgressCircle;
