import React, { Component } from 'react';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import { withGA, eventTrainingVideoStarted } from '../../utils/googleAnalytics';
import MetaTags from 'react-meta-tags';

import Footer from '../../components/Footer';
import VideoPlayer from '../../components/VideoPlayer';
import ActionLink from '../../components/ActionLink';
import { HOME_TITLE } from '../../constants';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      welcomeTutorialViewed: false,
      portfolioSetupCompleted: false,
      firstName: '',
    };
  }

  async componentDidMount() {
    const { history, refreshNotifications } = this.props;

    refreshNotifications && refreshNotifications();

    const user = Parse.User.current();

    // NOTE: should fetch user attributes, otherwise it may be cached in local storage
    await user.fetch();

    // NOTE: Mark that we've seen the welcome screen any time we get here and
    //       redirect to portfolio if flag is set. This will mean the user will
    //       only ever see this screen once.
    const welcomeScreenViewed = user.get('welcomeScreenViewed');
    const verifiedEmail = user.get('emailVerified');

    if (welcomeScreenViewed && verifiedEmail) {
      history.push('/portfolio');
      return;
    } else {
      const firstName = user.get('firstName');
      this.setState({ firstName });
      user.save({ welcomeScreenViewed: true });
    }

    const welcomeTutorialViewed = user.get('welcomeTutorialViewed');
    let portfolioSetupCompleted = user.get('portfolioSetupCompleted');

    if (!portfolioSetupCompleted) {

      // NOTE: if portfolioSetupCompleted is false, run query to check if complete
      if (await this.hasTransaction()) {

        // NOTE: set portfolioSetupCompleted is true and save in user object
        this.markPortfolioSetupComplete();

        portfolioSetupCompleted = true;
      }
    }

    // NOTE: redirect to portfolio page if both steps complete
    if (welcomeTutorialViewed && portfolioSetupCompleted) {
      history.push('/portfolio');
    }

    this.setState({
      loading: false,
      welcomeTutorialViewed,
      portfolioSetupCompleted,
    });
  }

  async hasTransaction() {
    const user = Parse.User.current();

    const query = new Parse.Query('Transaction');
    query.equalTo('user', user);

    const transaction = await query.first();

    return transaction;
  }

  markTutorialWatched() {
    if (this.state.welcomeTutorialViewed) {
      return;
    }

    const user = Parse.User.current();
    user.set('welcomeTutorialViewed', true).save();
    this.setState({ welcomeTutorialViewed: true });
  }

  markPortfolioSetupComplete() {
    if (this.state.portfolioSetupCompleted) {
      return;
    }

    const user = Parse.User.current();
    user.set('portfolioSetupCompleted', true).save();
    this.setState({ portfolioSetupCompleted: true });
  }

  render() {
    const { loading, welcomeTutorialViewed, portfolioSetupCompleted, firstName } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div>
        <React.Fragment>
          <MetaTags>
            <title>{HOME_TITLE}</title>
            <meta name="og:title" property="og:title" id="og-title" content={HOME_TITLE} />
            <meta name="apple-mobile-web-app-title" id="apple-title" content={HOME_TITLE} />
          </MetaTags>
        </React.Fragment>
        <div className="pt-7 pb-8 bg-dark bg-ellipses">
          <div className="container-fluid">
            <img src="/img/divcaster-icon.svg" alt="Divcaster by Investors Alley" style={{margin: '0 auto 40px auto', display: 'block'}}/>
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-6">
                <h1 className="display-3 text-center text-white">
                  Welcome{firstName ? `, ${firstName}` : ''}!
                </h1>
                <p className="lead text-muted text-center">Let’s start maximizing your income potential!</p>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-4">
                    <div className="card">
                      <div className="card-header text-muted">
                        <div className="d-flex justify-content-between">
                          Step 1
                          <i className="fe fe-check-circle mr-3 text-success" style={{transform: 'translateY(2px)', display: 'inline-block'}}></i>
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="m-0"><strike>Create your account</strike></h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card">
                      <div className="card-header text-muted">
                        <div className="d-flex justify-content-between">
                          Step 2
                          { welcomeTutorialViewed && <i className="fe fe-check-circle mr-3 text-success" style={{transform: 'translateY(2px)', display: 'inline-block'}}></i> }
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="m-0" id="watchTutorial">
                          { welcomeTutorialViewed
                            ? <strike>View welcome tutorial</strike>
                            : <ActionLink text="View welcome tutorial" clickAction={() => this.player.play()} />
                          }
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card">
                      <div className="card-header text-muted">
                        <div className="d-flex justify-content-between">
                          Step 3
                          { portfolioSetupCompleted && <i className="fe fe-check-circle mr-3 text-success" style={{transform: 'translateY(2px)', display: 'inline-block'}}></i> }
                        </div>
                      </div>
                      <div className="card-body">
                        <h3 className="m-0">
                          { portfolioSetupCompleted
                            ? <strike>Set up your portfolio</strike>
                            : <ActionLink text="Set up your portfolio" clickAction={() => this.props.history.push('/portfolio')} />
                          }
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt--8">
          <div className="row justify-content-center">
            <div className="col-12">
              <VideoPlayer id="home-video-id" videoId="atQcBbeBMx0" ref={ref => this.player = ref}
                onVideoStart={(player) => eventTrainingVideoStarted(player.getVideoData().title)}
                onVideoEnd={() => this.markTutorialWatched()}
                videoEndPercentage={0.9} />
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withGA(withRouter(Home));
