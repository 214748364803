export const RESET_PASSWORD_TITLE = 'Password Reset | Divcaster';
export const LOG_IN_TITLE = 'Log In | Divcaster';
export const ADMIN_LOG_IN_TITLE = 'Admin Log In | Divcaster'
export const SIGN_UP_TITLE = 'Sign Up | Divcaster';
export const IMPORT_TRANSACTION_TITLE = 'Import Transaction | Divcaster';
export const PORTFOLIO_TITLE = ' | Divcaster';
export const HOME_TITLE = 'Home | Divcaster';
export const ACCOUNT_BILLING_TITLE = 'Billing | Account Settings | Divcaster';
export const ACCOUNT_NOTIFICATIONS_SETTINGS_TITLE = 'Notifications | Account Settings | Divcaster';
export const ACCOUNT_NOTIFICATIONS_TITLE = 'Account Notifications | Divcaster';
export const ACCOUNT_SETTINGS_TITLE = 'General | Account Settings| Divcaster';
export const ACCOUNT_PROFILE_TITLE = 'Profile | Account Settings | Divcaster';
export const SUPPORT_TITLE = 'Support | Divcaster';

export const TITLES_OBJECT = {
  '/login': LOG_IN_TITLE,
  '/signup': SIGN_UP_TITLE,
  '/resetpassword': RESET_PASSWORD_TITLE,
  '/phinaz': ADMIN_LOG_IN_TITLE,
  '/account/billing': ACCOUNT_BILLING_TITLE,
  '/account/settings': ACCOUNT_SETTINGS_TITLE,
  '/account/profile': ACCOUNT_PROFILE_TITLE,
  '/account/notifications': ACCOUNT_NOTIFICATIONS_SETTINGS_TITLE,
  '/support': SUPPORT_TITLE,
  '/notifications': ACCOUNT_NOTIFICATIONS_TITLE,
  '/import-transactions': IMPORT_TRANSACTION_TITLE,
}

export const TITLE_CHAR_COUNT_CUTOFF = 15;