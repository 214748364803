export const MSG_SIGNIN_SUCCESS = {
  'reset-password-sent': 'We\'ve sent you an email with password reset instructions.',
  'reset-password': 'Your password was updated successfully!',
  'verify-email': 'Thanks for verifying your email!',
};

export const MSG_SIGNIN_ERROR = {
  'invalid-verify-link': 'Verification link expired',
  'link-send-fail': 'Hmm, we couldn\'t find that user or that email has already been verified',
  'link-send-success': 'We\'ve sent you a link to verify your email address.',
};
