import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Youtube from 'react-youtube';

import './VideoPlayer.css';

class VideoPlayer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    onVideoStart: PropTypes.func,
    onVideoEnd: PropTypes.func,
    videoEndPercentage: PropTypes.number,
  }

  static defaultProps = {
    onVideoStart: () => {},
    onVideoEnd: () => {},
    videoEndPercentage: 1.0,
  }

  state = {
    started: false,
    ended: false,
  }

  play = () => this.player().playVideo()
  pause = () => this.player().pauseVideo()
  player = () => this.youtube.internalPlayer

  render() {
    const { id, videoId } = this.props;
    return (
      <div className="embed-responsive video-player-container embed-responsive-16by9">
        <Youtube id={id} videoId={videoId}
          ref={ref => this.youtube = ref}
          opts={{
            playerVars: {
              rel: 1,
              modestbranding: 1,
            }
          }}
          onPlay={({target}) => {
            const { onVideoStart } = this.props;
            const { started } = this.state;

            if (!started) {
              onVideoStart(target);
              this.setState({started: true});
            }
          }}
          onPause={({target}) => {
            const { onVideoEnd, videoEndPercentage } = this.props;
            const { ended } = this.state;

            if (ended) {
              return;
            }

            const time = target.getCurrentTime();
            const duration = target.getDuration();
            const percent = time / duration;
            const endPercent = Math.max(0, Math.min(videoEndPercentage, 1.0));

            if (percent >= endPercent) {
              onVideoEnd(target);
              this.setState({ended: true});
            }
          }}
          onEnd={({target}) => {
            const { onVideoEnd } = this.props;
            const { ended } = this.state;

            if (!ended) {
              onVideoEnd(target);
              this.setState({ended: true});
            }
          }}
          />
      </div>
    );
  }
}

export default VideoPlayer;
