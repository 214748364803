import React from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';
import Button from './Button';

const RedirectMessage = ({ title, subtitle, text, buttonText, link }) => (
  <div className="full-height d-flex flex-column">
    <div className="text-center my-auto">
      <p className="extra-large-font">{title}</p>
      <Header content={subtitle} />
      <p className="text-muted mb-3 mt-2">{text}</p>
      <div className="mx-auto d-flex justify-content-center">
        <Link to={link}>
          <Button name={buttonText} />
        </Link>
      </div>
    </div>
  </div>
);

export default RedirectMessage;
