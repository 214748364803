import Parse from 'parse';

// ScenarioItem Object //
// user: Pointer
// symbol: String
// quantity: Number
// scenario: Pointer

const Scenario = Parse.Object.extend('Scenario');
const ScenarioItem = Parse.Object.extend('ScenarioItem');

// helper function
const _formatScenarioItemData = (parseObject) => ({
  id: parseObject.id,
  updatedAt: parseObject.updatedAt,
  createdAt: parseObject.createdAt,
  symbol: parseObject.get('symbol'),
  quantity: parseObject.get('quantity'),
});

export async function createScenarioItemsFromArray(scenarioItemsArray, scenarioPointerObj) {
  const currentUser = Parse.User.current();
  const scenarioItemsObjects = [];  
  scenarioItemsArray.forEach(({ symbol, quantity }) => {
    const obj = new Parse.Object('ScenarioItem');
    const qty = parseFloat(quantity);

    obj.setACL(new Parse.ACL(currentUser)); // set read/write permission to current user only
    obj.set('user', currentUser);
    obj.set('symbol', symbol);
    obj.set('quantity', qty);
    obj.set('scenario', scenarioPointerObj); // set pointer to parent scenario

    scenarioItemsObjects.push(obj);
  });
  return await Parse.Object.saveAll(scenarioItemsObjects);
}

export async function getScenarioItems(scenarioId) {
  const scenarioQuery = new Parse.Query(Scenario);
  const scenarioItemQuery = new Parse.Query(ScenarioItem);
  try {
    const scenario = await scenarioQuery.get(scenarioId);
    scenarioItemQuery.equalTo('scenario', scenario);
    const results = await scenarioItemQuery.find();
    const scenarioItems = results.map(result => _formatScenarioItemData(result));
    return scenarioItems;
  } catch (err) {
    throw err;
  }
};

export async function findScenarioItemId(symbol, activeScenario) {
  const scenarioQuery = new Parse.Query('Scenario');
  const scenarioItemQuery = new Parse.Query('ScenarioItem');
  const currentUser = Parse.User.current();
  
  try {
    const scenario = await scenarioQuery.get(activeScenario);
    const scenarioItem = await scenarioItemQuery
    .equalTo('symbol', symbol)
    .equalTo('scenario', scenario)
    .equalTo('user', currentUser)
    .first();
  return scenarioItem.id;
  } catch (err) {
    throw err;
  }
}

export async function updateScenarioItemQuantity(scenarioItemId, quantity) {
  const query = new Parse.Query('ScenarioItem');
  try {
    const scenarioItem = await query.get(scenarioItemId);
    return scenarioItem.save({ quantity });
  } catch (err) {
    throw err;
  }
}

export async function deleteScenarioItem(scenarioItemId) {
  const scenarioItem = new ScenarioItem();
  scenarioItem.id = scenarioItemId;
  return await scenarioItem.destroy();
}