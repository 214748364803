import React from 'react';
import RedirectMessage from '../components/RedirectMessage';

const NotFound = () => (
  <RedirectMessage 
    title="404 Error"
    subtitle="Oops! That Page Doesn't Exist 😭"
    text="Looks like you eneded up here by accident?"
    buttonText="Return to Home"
    link="/"
  />
);

export default NotFound;
