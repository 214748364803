import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';

import Header from '../../components/Header';
import Button from '../../components/Button';
import Toast from '../../components/Toast';
import VideoPlayer from '../../components/VideoPlayer';
import Modal from '../../containers/Modal';
import ColumnPicker from '../../containers/ColumnPicker';
import ImportingError from '../../modals/ImportingError';
import ImportingWarning from '../../modals/ImportingWarning';
import { withGA, eventPortfolioInputStarted, eventPortfolioInputCompleted,
  eventPortfolioInputFailed, eventPortfolioUpdated, eventTrainingVideoStarted } from '../../utils/googleAnalytics';

import {
  DUPE_COLUMN_SELECTION, ERR_IMPORT_TRANSACTIONS, MISSING_DATE_FORMAT, IMPORT_TRANSASTIONS_SUCCESS,
  IMPORT_COLUMNS, ERR, WARNING, SYMBOL_NAME, DATE_NAME, QUANTITY_NAME, PRICE_NAME, IMPORT_TRANSACTION_TITLE } from '../../constants';
import './ImportPage.css';
import getTDH from '../../utils/getTDH';

const TOAST_DELAY = 3000;

class ImportPage extends Component {
  static propTypes = {
    csvPreview: PropTypes.object,
    clearCsvImport: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    userIsAdmin: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    csvPreview: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: {},
      typeCheck: {},
      doNotImport: {},
      duplicateColumns: [],
      errorList: [],
      warningItem: {},
      dateFormat: null,
      notification: null,
      importTutorialVideoId: '',
    };
  }

  componentDidMount = async () => {
    const { selectedColumns } = this.state;
    IMPORT_COLUMNS.forEach(({ key }) => {
      selectedColumns[key] = null;
    });
    this.initializeVideo('import-transactions-tutorial-modal','importTutorialVideoId' ,"YE7VzlLtp-4");
    eventPortfolioInputStarted();
  }

  initializeVideo = (modalId, videoInState, videoId) => {
    const modal = $(`#${modalId}`);
    this.setState({ [videoInState]: videoId });
    modal.on('shown.bs.modal', () => this.setState({ [videoInState]: videoId }));
    modal.on('hidden.bs.modal', () => this.setState({ [videoInState]: '' }));
  }

  updateDateFormat = async (dateFormat) => {
    await this.setState({ dateFormat });
  }

  toggleDoNotImport = async (column) => {
    const { doNotImport, selectedColumns, typeCheck } = this.state;
    const value = doNotImport[column];
    doNotImport[column] = value ? !value : true;
    selectedColumns[column] = null;
    delete typeCheck[column];
    await this.setState({ doNotImport, selectedColumns, typeCheck });
  }

  submitData = async (columnKey, selection, typeCheckValue) => {
    const { selectedColumns, duplicateColumns, typeCheck, doNotImport } = this.state;
    selectedColumns[columnKey] = selection;
    await this.setState({ selectedColumns, duplicateColumns: [] });

    // update typeCheck state:
    const shallowCopy = typeCheck;
    if(doNotImport[columnKey]) {
      delete shallowCopy[columnKey]
    } else {
      shallowCopy[columnKey] = typeCheckValue;
    };
    this.setState({ typeCheck: shallowCopy });

    // check for duplicates:
    const selections = Object.values(selectedColumns).filter(s => !!s);
    const count = selected => selected.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});
    const checkDuplicates = counts => Object.keys(counts).filter(a => counts[a] > 1);
    const hasDuplicates = checkDuplicates(count(selections));
    if (!_.isEmpty(hasDuplicates)) {
      if (!duplicateColumns.includes(selection) && hasDuplicates.includes(selection)) {
        this.showNotification('error', DUPE_COLUMN_SELECTION);
      }
      this.setState({ duplicateColumns: hasDuplicates });
    }
  }

  checkForError = () => {
    const { typeCheck } = this.state;
    const errorList = [];
    this.setState({ errorList });
    Object.keys(typeCheck).forEach((key) => {
      if (typeCheck[key].key === ERR) {
        errorList.push(typeCheck[key].name);
      }
    });
    return errorList;
  }

  checkForWarning = () => {
    const { typeCheck, selectedColumns } = this.state;
    const { csvPreview: { previewRows } } = this.props;
    const warningItem = {};
    this.setState({ warningItem });
    if (typeCheck.date && typeCheck.date.key === WARNING && !_.isEmpty(typeCheck.date.warningIndex)) {
      const warningIndex = typeCheck.date.warningIndex[0];
      const { symbol, date, quantity, price } = selectedColumns;
      const selectedColumnsPreview = [
        { name: SYMBOL_NAME, data: symbol },
        { name: DATE_NAME, data: date },
        { name: QUANTITY_NAME, data: quantity },
        { name: PRICE_NAME, data: price },
      ];

      selectedColumnsPreview.forEach(({ name, data }) => {
        const pIndex = previewRows.findIndex(row => row.column === data);
        const previewData = previewRows[pIndex].data[warningIndex];
        warningItem[name] = previewData;
      });
      return warningItem;
    }
  }

  checkForDuplicates = () => {
    const { selectedColumns } = this.state;
    return Object.values(selectedColumns).filter(value => !!value);
  }

  onImportClick = () => {
    const { dateFormat } = this.state;
    // check for duplicates:
    const hasDuplicates = this.checkForDuplicates();
    if (_.uniq(hasDuplicates).length !== hasDuplicates.length) {
      return this.showNotification('error', DUPE_COLUMN_SELECTION);
    }
    // check for unspecified date format:
    if(!dateFormat) {
      this.setState({ dateError: true });
      return this.showNotification('error', MISSING_DATE_FORMAT);
    }
    // check for errors:
    const errorList = this.checkForError();
    if (!_.isEmpty(errorList)) {
      this.setState({ errorList });
      return this.openModal('#import-transactions-error');
    }
    // check for warning:
    const warningItem = this.checkForWarning();
    if (!_.isEmpty(warningItem)) {
      this.setState({ warningItem });
      return this.openModal('#import-transactions-warning');
    }

    this.importTransaction();
  }

  clearDateError = () => {
    this.setState({ dateError: false });
  }

  importTransaction = async () => {
    const { selectedColumns, dateFormat } = this.state;
    const { history, clearCsvImport, showNotification, csvPreview: { url, portfolioId, }, userIsAdmin } = this.props;
    try {
      await Parse.Cloud.run('uploadCsv', { url, selectedColumns, portfolioId, dateFormat, userIsAdmin });
      const tdh = await getTDH();
      if (tdh.id === portfolioId) {
        history.push('/portfolio/TDH');
      } else {
        history.push('/portfolio');
      }
      showNotification('success', IMPORT_TRANSASTIONS_SUCCESS);
      clearCsvImport();
      eventPortfolioInputCompleted();
      eventPortfolioUpdated('Transaction Imported');
    } catch (err) {
      console.log('Error from csv Parsing: ', err);
      eventPortfolioInputFailed();
      return this.showNotification('error', ERR_IMPORT_TRANSACTIONS);
    }
  }

  openModal = (modalId) => {
    $(`${modalId}`).modal();
  }

  showNotification = (type, message) => {
    this.setState({ notification: { type, message } });
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
    this.toastTimeout = setTimeout(() => this.hideNotification(), TOAST_DELAY);
  }

  hideNotification = () => {
    this.setState({ notification: null });
  }

  render() {
    const { history, csvPreview } = this.props;
    const { rowCount, previewRows } = csvPreview;
    const { selectedColumns, notification, duplicateColumns, errorList, warningItem,
      typeCheck, dateFormat, doNotImport, dateError, importTutorialVideoId } = this.state;
    const buttonDisabled = (
      _.isEmpty(selectedColumns) || Object.keys(selectedColumns).some(key => selectedColumns[key] === null && !doNotImport[key])
    );

    // route to Portfolio page if csvPreview object is empty
    if (_.isEmpty(csvPreview)) {
      history.push('/portfolio');
      return null;
    }

    return (
      <div className="my-5 mx-5">
        <React.Fragment>
          <MetaTags>
            <title>{IMPORT_TRANSACTION_TITLE}</title>
            <meta name="og:title" property="og:title" id="og-title" content={IMPORT_TRANSACTION_TITLE} />
            <meta name="apple-mobile-web-app-title" id="apple-title" content={IMPORT_TRANSACTION_TITLE} />
          </MetaTags>
        </React.Fragment>
        <Header content="Import Transactions" icon={<i className="fe fe-file-text" />} />
        <p className="lead mt-3">
          Select the data in your uploaded file that matches the heading for each of the columns below.
          {/* Select the data in your uploaded file that matches the heading for each of the columns below. Need help? <a href="#!" onClick={() => this.openModal('#import-transactions-tutorial-modal')}>Watch the tutorial.</a> */}
        </p>
        <hr className="mt-4 mb-5"/>
        <div className="d-flex pb-4 column-picker-container">
          {IMPORT_COLUMNS.map((column) => {
            return (
              <ColumnPicker
                key={column.key}
                column={column}
                rowCount={rowCount}
                previewRows={previewRows}
                submitData={this.submitData}
                duplicateColumns={duplicateColumns}
                typeCheckValue={typeCheck[column.key]}
                doNotImport={doNotImport}
                toggleDoNotImport={this.toggleDoNotImport}
                dateFormat={dateFormat}
                dateError={dateError}
                clearDateError={this.clearDateError}
                updateDateFormat={this.updateDateFormat}
              />
            )}
          )}
        </div>
        <div className="fixed-bottom mx-4 mb-3 d-flex justify-content-between align-items-end" style={{ zIndex: 0 }}>
          <div className="tutorial-thumbnail" onClick={() => this.openModal('#import-transactions-tutorial-modal')}>
          {/* Hidden for PHASE 1 -- temporarilyt commented out until phase 2 */}
            {/* <img src="/img/tutorial-thumbnail.png" alt="Watch Transaction Import Tutorial" /> */}
          </div>
          <div className="import-button d-flex">
            <Button className="center" name={`Import ${rowCount} Transactions`} disabled={buttonDisabled} clickAction={() => this.onImportClick()} />
          </div>
        </div>

        {/* Toast */}
        <Toast notification={notification} onClose={() => this.hideNotification()} />
        {/* Modals */}
        <Modal modalId="import-transactions-tutorial-modal" header="Import Transactions Tutorial">
          <VideoPlayer
            videoId={importTutorialVideoId}
            id="import-transactions-tutorial"
            onVideoStart={() => eventTrainingVideoStarted('Import Tutorial Tutorial')}
          />
        </Modal>
        <Modal modalId="import-transactions-error" header="Oops! Something looks a bit off...">
          <ImportingError errorList={errorList} />
        </Modal>
        <Modal modalId="import-transactions-warning" header="Double check the date!">
          <ImportingWarning warningItem={warningItem} dateFormat={dateFormat} updateDateFormat={this.updateDateFormat} importTransaction={this.importTransaction} />
        </Modal>
      </div>
    );
  }
}

export default withGA(withRouter(ImportPage));
