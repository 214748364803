import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Parse from 'parse';
import $ from 'jquery';
import _ from 'lodash';

import Loader from '../components/Loader';
import Button from '../components/Button';
import ToolTip from '../components/ToolTip';
import InputField from '../components/InputField';
import SmallTable from '../components/SmallTable';
import EllipsesContainer from './EllipsesContainer';

import { findScenarioItemId, updateScenarioItemQuantity, getScenario, createScenarioItemsFromArray } from '../parse';
import { TICKER_OBJECT, ERR_UPDATING_QTY, SCENARIO_COLOR_BANK, ERR_INSUFFICIENT_OFFSITE_DATA } from '../constants';

import './InfoSideBar.css';
import { eventWhatIfScenarioUpdated } from '../utils/googleAnalytics';

const NEW_SCENARIO_TAB = 1;
const ALL_SCENARIOS_TAB = 0;
class InfoSidebar extends Component {
  static propTypes = {
    activeScenario: PropTypes.string,
    scenarioToRename: PropTypes.string,
    scenarios: PropTypes.array.isRequired,
    getAllScenarios: PropTypes.func.isRequired,
    openDeleteModal: PropTypes.func.isRequired,
    setScenarioToRename: PropTypes.func.isRequired,
    updateActiveScenario: PropTypes.func.isRequired,
    setInfoSideBarTabIndex: PropTypes.func.isRequired,
    toggleScenarioSnapshotStatus: PropTypes.func.isRequired,
    activeScenarioIndex: PropTypes.number,
    scenarioSlots: PropTypes.array.isRequired,
    setScenarioSlots: PropTypes.func.isRequired,
    setActiveScenarioIndex: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    onBasicPlan: PropTypes.bool.isRequired,
    getScenarioForecastData: PropTypes.func.isRequired,
    loadingPlanType: PropTypes.bool.isRequired,
    totalMarketValue: PropTypes.number,
  };

  static defaultProps = {
    activeScenario: null,
    scenarioToRename: null,
    activeScenarioIndex: null,
    totalMarketValue: 0,
  }

  constructor(props) {
    super(props);
    this.scenarioToRename = '';
    this.state = {
      tickers: [],
      scenarioSummary: {},
      loading: false,
      //asyncTypeAhead

      // allowNew: false,
      // errorMsg: null,
      options: [],
      allowNew: false,
      selectedTicker: null,
      asyncTypeaheadLoading: false,
      setFocusOnInputIndex: null, // saving the index position of the newly added ticker so child component can auto focus on input upon creation
    };
  }

  componentDidMount() {
    const { activeScenario } = this.props;
    if(activeScenario) this.getAllScenarioItems(activeScenario);
  }

  componentDidUpdate(prevProps) {
    const { activeScenario } = this.props;
    if (prevProps.activeScenario !== activeScenario) {
      this.getAllScenarioItems(activeScenario);
    }
  }

  getAllScenarioItems = async (id) => {
    if (!id) {
      return this.setState({ tickers: [] });
    }

    const scenarioItemsWithMarketData = await Parse.Cloud.run('getScenarioItemsWithMarketData', { scenarioId: id });
    const { perTicker, scenarioSummary } = scenarioItemsWithMarketData;
    // note: due to characteristic of SmallTable component, the ordering of the object will affect its output:
    this.setState({ loading: true });
    const tickers = Object.keys(perTicker).map(ticker => ({
      [TICKER_OBJECT]: {
        symbol: ticker,
        id: perTicker[ticker].id,
        company: perTicker[ticker].companyName,
        price: perTicker[ticker].lastPricePerShare,
      },
      quantity: parseFloat(perTicker[ticker].totalQuantity.toFixed(4)),
      id: ticker,
    }));
    await this.setState({ scenarioSummary, tickers, loading: false });
  }

  removeScenarioItem = async (id) => {
    const { tickers } = this.state;
    const { getScenarioForecastData, scenarioSlots } = this.props;

    const tIndex = tickers.findIndex(ticker => _.get(ticker,  `${[TICKER_OBJECT]}.id`) === id);
    tickers.splice(tIndex, 1);
    this.setState({ tickers });

    // rerenders chart
    await getScenarioForecastData(scenarioSlots);
  }

  updateQty = async (input, symbol) => {
    const { activeScenario, showNotification, getScenarioForecastData, scenarioSlots } = this.props;
    try {
      const id = await findScenarioItemId(symbol, activeScenario);
      await updateScenarioItemQuantity(id, input);
      eventWhatIfScenarioUpdated('Qty Updated');
    } catch (err) {
      try {
        const scenarioObject = await getScenario(activeScenario);
        await createScenarioItemsFromArray([{
          symbol,
          quantity: input,
        }], scenarioObject);

        // rerenders chart
        await getScenarioForecastData(scenarioSlots);

      } catch (err) {
        showNotification('error', ERR_UPDATING_QTY);
      }
    }
    this.getAllScenarioItems(activeScenario);
    getScenarioForecastData(scenarioSlots);
  }

  openModal = (modalId, tabIndex) => {
    const { setInfoSideBarTabIndex, toggleScenarioSnapshotStatus } = this.props;
    setInfoSideBarTabIndex(tabIndex);
    toggleScenarioSnapshotStatus();
    $(`${modalId}`).modal();
  }

  callRenameScenario = async (e, id) => {
    const { renameScenario } = this.props;
    renameScenario(e, id, this.scenarioToRename);
    this.scenarioToRename = '';
    eventWhatIfScenarioUpdated('Renamed');
  }

  onChangeScenario = (slotIndex) => {
    const { setActiveScenarioIndex } = this.props;
    setActiveScenarioIndex(slotIndex);
    this.openModal('#what-if-modal', ALL_SCENARIOS_TAB);
  }

  addNewScenario = (slotIndex) => {
    const { setActiveScenarioIndex } = this.props;
    setActiveScenarioIndex(slotIndex);
    this.openModal('#what-if-modal', NEW_SCENARIO_TAB);
  }

  redirectTo = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  _handleSearch = async (query) => {
    this.setState({ asyncTypeaheadLoading: true });
    const result = await Parse.Cloud.run('getSymbolLookup', { query });
    this.setState({ asyncTypeaheadLoading: false, options: result });
  }

  addTicker = async () => {
    const { showNotification } = this.props;
    const { tickers, selectedTicker : { symbol, name: company } } = this.state;

    const fIndex = tickers.findIndex(tickerItem => tickerItem.id === symbol);

    if (fIndex === -1) {
      try {
        const price = await Parse.Cloud.run('queryClosingPriceByTicker', { ticker: symbol });
        tickers.push({
          [TICKER_OBJECT]: {
            symbol: symbol,
            company,
            price,
          },
          quantity: 0,
          id: symbol,
        });
        this.setState({ tickers, selectedTicker: null, setFocusOnInputIndex: tickers.length - 1 });
      } catch (error) {
        showNotification('error', ERR_INSUFFICIENT_OFFSITE_DATA);
      }
    } else {
      this.setState({ selectedTicker: null })
    }
    this._typeahead.getInstance().clear();
  }

  _handleChange = async (ticker) => {
    await this.setState({ selectedTicker: ticker[0] });
  }

  _isButtonDisabled = () => {
    const { selectedTicker } = this.state;
    if ( !selectedTicker ) return true;
  }

  toggleSidebar = () => {
    $('#info-sidebar').modal('toggle');
  }

  render() {
    const { scenarios, updateActiveScenario, scenarioToRename, setScenarioToRename, openDeleteModal,
      activeScenario, scenarioSlots, setScenarioSlots, onBasicPlan,
      loadingPlanType, showNotification, totalMarketValue } = this.props;
    const { tickers, scenarioSummary, loading, options, selectedTicker, allowNew, asyncTypeaheadLoading, setFocusOnInputIndex } = this.state;

    const newTicker =
      <div className="d-flex align-items-center table-padding" style={_.isEmpty(tickers) ? { paddingBottom: '16px'} : null}>
        <AsyncTypeahead
          id="scenarioTicker"
          minLength={1}
          options={options}
          allowNew = {allowNew}
          onSearch={this._handleSearch}
          onChange={this._handleChange}
          filterBy={["name", "symbol"]}
          selectedTicker={selectedTicker}
          isLoading={asyncTypeaheadLoading}
          ref={(ref) => this._typeahead = ref}
          placeholder="Add Ticker By Company or Symbol..."
          useCache={false} // https://github.com/ericgio/react-bootstrap-typeahead/issues/417
          labelKey={(option) => `${option.name} (${option.symbol})`} // displays result as "companyName (ticker)"
          renderMenuItemChildren={option => (
            <span key={option.id}>{option.name} {`(${option.symbol})`}</span>
          )}
        />
        <button
          className="btn btn-outline-primary ml-3"
          type="button"
          onClick={this.addTicker}
          disabled={this._isButtonDisabled()}
          >
            + ADD
        </button>
    </div>;

    return (
      <>
        <div className="scenarios-maximize-button">
          <div className="card mb-0">
            <div className="card-body py-3">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="card-header-title text-nowrap">
                    What-if Scenarios
                    <ToolTip tooltipId="what-if-scenario" popupText="Forecast estimated future dividend earnings for up to 3 what-if scenarios at once." className="ml-2"><i className="fe fe-help-circle" /></ToolTip>
                  </h4>
                </div>
                <div className="col-auto">
                  <button type="button" className="btn btn-white btn-sm ml-3" onClick={this.toggleSidebar}>
                    <i className="fe fe-maximize"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="info-sidebar" className="modal fade fixed-right" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-vertical" role="document">
            <div className="modal-content">

              <div className="modal-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="card-header-title text-nowrap">
                      What-if Scenarios
                      <ToolTip tooltipId="what-if-scenario" popupText="Forecast estimated future dividend earnings for up to 3 what-if scenarios at once." className="ml-2"><i className="fe fe-help-circle" /></ToolTip>
                    </h4>
                  </div>
                  <div className="col-auto">
                    { (!!scenarios.length && !onBasicPlan) && <button type="button" className="btn btn-white btn-sm" onClick={() => this.openModal('#what-if-modal', ALL_SCENARIOS_TAB)}>Manage</button> }
                    <button type="button" className="btn btn-white btn-sm ml-3" onClick={this.toggleSidebar}>
                      <i className="fe fe-minimize"/>
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="scenario-items-container">
                  {
                    (loading || loadingPlanType)
                    ? (
                      <div className="absolute-center">
                        <Loader />
                      </div>
                    ) : null
                  }

                  { scenarioSlots.map((slot, slotIndex) => {
                    const dropdownItems = onBasicPlan
                    ? [ { name: 'Delete', clickAction: () => openDeleteModal(slot, slotIndex) } ]
                    : [ { name: 'Change Scenario', clickAction: () => this.onChangeScenario(slotIndex) },
                      { name: 'Remove from Slot', clickAction: () => setScenarioSlots(slotIndex, null, slot) },
                      { name: 'Rename', clickAction: () => setScenarioToRename(slot) },
                      { name: 'Delete', clickAction: () => openDeleteModal(slot, slotIndex) },
                    ];

                    let scenarioObject;

                    if (slot) {
                      scenarioObject = scenarios.find(scenario => scenario.id === slot);
                    }
                    const name = scenarioObject ? scenarioObject.name : '';
                    const disableScenario = onBasicPlan && slotIndex > 0;
                    const activeUpgradeModal = ((activeScenario && activeScenario === slot) && !_.isEmpty(scenarioSlots[0]));

                    return (
                      <React.Fragment key={slotIndex}>
                        {/* Upgrade modal will be shown for basic user and applied to first slot of portfolio only */}
                        {
                          (!loadingPlanType && (onBasicPlan && slotIndex === 0)) &&
                          (
                            <div className={`upgrade-message ${activeUpgradeModal ? 'active-scenario' : ''}`}>
                              <Button name="Upgrade to Unlock" clickAction={() => this.redirectTo('/account/billing')} />
                            </div>
                          )
                        }
                        <div className="scenario-item" key={ slot || slotIndex }>
                            { !slot
                              ? (
                                // scenario: empty slot
                                <div key={slotIndex} className={`scenario-item__slot mt-3 ${onBasicPlan ? 'position-relative' : ''}`}>
                                  <div className={`scenario-item__empty px-4 ${disableScenario ? 'disabled-background' : '' }`}>
                                    <Button
                                      name="Plot Scenario"
                                      clickAction={() => this.addNewScenario(slotIndex)}
                                      icon="fe fe-plus-circle mr-2"
                                      type="button"
                                      dashColor="white"
                                      dashClass="dz-message btn-block p-3"
                                      disabled={disableScenario}
                                      />
                                  </div>

                                </div>)
                                : slot === scenarioToRename
                                ? (
                                  // scenario: input field for renaming
                                  <form onSubmit={(e) => this.callRenameScenario(e, slot)} className="mt-3 mb-0 pb-0 mx-4">
                                    <InputField
                                      type="text"
                                      inputValue={(input) => { this.scenarioToRename = input; }}
                                      placeholder="Rename this scenario"
                                      id={slot}
                                      onBlur={() => setScenarioToRename()}
                                      autoFocus />
                                  </form> )
                                  : (
                                    // scenario: inactive
                                    <div className="scenario-item__row" >
                                      <EllipsesContainer
                                        name={name}
                                        dashColor="secondary"
                                        ellipsesColor="white"
                                        icon={`fe fe-target mr-2 `}
                                        dropDownItems={dropdownItems}
                                        iconColor={SCENARIO_COLOR_BANK[slotIndex]}
                                        clickAction={() => updateActiveScenario(slot)}
                                        />
                                    </div>
                                  )
                                }

                                {/* scenario: active */}
                                { activeScenario && activeScenario === slot
                                  ? (
                                    <div className="scenario-item__table-container">
                                      <ReactCSSTransitionGroup
                                        transitionName="what-if-table"
                                        transitionAppear={true}
                                        transitionAppearTimeout={500}
                                        transitionEnter={false}
                                        transitionLeave={true}
                                        transitionLeaveTimeout={500}
                                        >
                                        <div>
                                          { newTicker }
                                          <SmallTable className="mb-0"
                                            entries={tickers}
                                            updateQty={this.updateQty}
                                            showNotification={showNotification}
                                            setFocusOnInputIndex={setFocusOnInputIndex}
                                            removeScenarioItem={this.removeScenarioItem}
                                            editable
                                            deletable
                                          />
                                          <div className="scenario-item__cost-bar d-flex flex-row justify-content-between p-3" style={{alignItems: 'center'}}>
                                            <div>Total Cost to Implement:</div>
                                            <div style={{fontWeight: '700', fontSize: '18px'}}>${!_.isEmpty(scenarioSummary) ? parseFloat((scenarioSummary.totalMarketValue - totalMarketValue).toFixed(2)).toLocaleString() : '-'}</div>
                                          </div>
                                        </div>
                                      </ReactCSSTransitionGroup>
                                    </div> )
                                    : null
                                  }
                                </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(InfoSidebar);