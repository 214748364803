import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Parse from 'parse';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Tabs from '../../containers/Tabs';
import Tab from '../../components/Tab';
import ReportsList from './ReportsList';
import GenerateReport from './GenerateReport';
import OwnedStockList from "./MarketingStats";

const Report = Parse.Object.extend('Report');

class Reports extends Component {

  static propTypes = {
    tagIds: PropTypes.object,
  }

  static defaultProps = {
    tagIds: {},
  }

  static TAB_TYPES = {
    MINE: { heading: 'My Reports', path: '/reports/mine' },
    ALL: { heading: 'All Reports', path: '/reports/all' },
    GENERATE: { heading: '(+) Generate Report', path: '/reports/generate' },
    STATS: { heading: 'Marketing Stats', path: '/reports/stats' },
  }

  state = {
    loading: true,
    tabIndex: 0,
    hasReports: false,
    hasReportsByCurrentUser: false,
    showNewReportAlert: false,
    stats: false
  }

  async componentDidMount() {
    const { TAB_TYPES } = Reports;
    const { history } = this.props;

    await this.fetchReportCounts();

    this.setState({ loading: false }, _ => {

      // NOTE: we use history.replace here so that we can go
      //       from /reports/ to /reports/(mine|all|generate)
      //       after querying reports from server
      if (this.state.hasReportsByCurrentUser) {
        history.replace(TAB_TYPES.MINE.path);
      } else if (this.state.hasReports) {
        history.replace(TAB_TYPES.ALL.path);
      } else if (this.state.stats) {
        history.replace(TAB_TYPES.STATS.path);
      }else {
        history.replace(TAB_TYPES.GENERATE.path);
      }
    });
  }

  componentDidUpdate(prevProps) {
    // NOTE: hook into location changes so we can change tabs with
    //       history.push or back/forward buttons
    if (this.props.location !== prevProps.location) {
      const { TAB_TYPES } = Reports;
      const { hasReports, hasReportsByCurrentUser } = this.state;
      const path = this.props.location.pathname;

      const tabs = [];
      if (hasReportsByCurrentUser) {
        tabs.push(TAB_TYPES.MINE.path);
      }
      if (hasReports) {
        tabs.push(TAB_TYPES.ALL.path);
      }
      tabs.push(TAB_TYPES.GENERATE.path);
      tabs.push(TAB_TYPES.STATS.path);

      let tabIndex = tabs.indexOf(path);
      if (tabIndex === -1) {
        tabIndex = 0;
      }

      this.setState({ tabIndex });
    }
  }

  fetchReportCounts = async () => {
    const reportsByUserQuery = new Parse.Query(Report);
    reportsByUserQuery.equalTo('user', Parse.User.current());

    const reportsByUserCount = await reportsByUserQuery.count();
    const hasReportsByCurrentUser = reportsByUserCount > 0;

    // NOTE: save a query since reports by all users will always
    //       be true if reports by current user is true
    let hasReports = hasReportsByCurrentUser;
    if (!hasReports) {
      const reportsQuery = new Parse.Query(Report);
      const reportsCount = await reportsQuery.count();
      hasReports = reportsCount > 0;
    }

    this.setState({
      hasReports,
      hasReportsByCurrentUser,
    });
  }

  displayNewReportAlert = () => {
    this.setState({ showNewReportAlert: true });
  }

  hideNewReportAlert = () => {
    this.setState({ showNewReportAlert: false });
  }

  render() {
    const { TAB_TYPES } = Reports;
    const { tagIds, } = this.props;
    const { loading, tabIndex, hasReports, hasReportsByCurrentUser, showNewReportAlert } = this.state;
    const tabs = [];

    if (loading) {
      tabs.push(
        <Tab heading="" queryString="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto p-7 mb--5">
                <div className="text-muted">Loading...</div>
              </div>
            </div>
          </div>
        </Tab>
      );
    } else {

      // NOTE: create alert once and display on all tabs
      let alerts;
      if (showNewReportAlert) {
        alerts = (
          <div className="container-fluid my-4">
            <div className="alert alert-info">
              <button className="close" onClick={this.hideNewReportAlert}>&times;</button>
              <p className="p-0 m-0">Your report is being generated! You'll get an email when the report is ready to download.</p>
            </div>
          </div>
        );
      }

      if (hasReportsByCurrentUser) {
        tabs.push(
          <Tab linkClassName="" heading={TAB_TYPES.MINE.heading} queryString={TAB_TYPES.MINE.path}>
            {alerts}
            <ReportsList key="current-user-reports" tagIds={tagIds} filterByCurrentUser={true} />
          </Tab>
        );
      }

      if (hasReports) {
        tabs.push(
          <Tab linkClassName="" heading={TAB_TYPES.ALL.heading} queryString={TAB_TYPES.ALL.path}>
            {alerts}
            <ReportsList key="all-reports" tagIds={tagIds} />
          </Tab>
        );
      }

      tabs.push(
        <Tab linkClassName="" heading={TAB_TYPES.GENERATE.heading} queryString={TAB_TYPES.GENERATE.path}>
          {alerts}
          <GenerateReport tagIds={tagIds} refreshReports={this.fetchReportCounts} displayNewReportAlert={this.displayNewReportAlert} />
        </Tab>
      );

      tabs.push(
        <Tab linkClassName="" heading={TAB_TYPES.STATS.heading} queryString={TAB_TYPES.STATS.path}>
          {alerts}
          <OwnedStockList key="all-reports" tagIds={tagIds} />
        </Tab>
      );
    }

    return (
      <div className="container">
        <div className="mx-5 my-5">
          <Header content="Reports" />
          <Tabs defaultActiveTabIndex={0} passedDownTab={tabIndex} children={tabs} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Reports);
