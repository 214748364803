import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Tab.css';

class Tab extends Component {
  static propTypes = {
    heading: PropTypes.string,
    dashColor: PropTypes.string,
    linkClassName: PropTypes.string,
    isActive: PropTypes.bool,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    tabIndex: 0,
    heading: '',
    dashColor: '',
    linkClassName: '',
    isActive: false,
    disabled: false,
    onClick: () => {},
  };

  handleClick = (e, func, index) => {
    e.preventDefault();
    func(index);
  }

  render() {
    const { heading, dashColor, linkClassName, isActive, tabIndex, onClick, clickAction, disabled } = this.props;

    if (disabled) {
      return (
        <li className="nav-item">
          <h4 className={`nav-link disabled-tab m-0 ${linkClassName} ${isActive ? `active ${dashColor}` : ''}`}>
            {heading}
          </h4>
        </li>
      )
    }

    return (
      <li className="tab nav-item">
        <h4
          className={`nav-link no-highlight m-0 ${linkClassName} ${isActive ? `active ${dashColor}` : ''} `}
          onClick={(event) => {
            event.preventDefault();
            onClick(tabIndex);
            clickAction && clickAction();
          }}
          onKeyUp={() => {}}
          tabIndex="0"
          role="option"
          aria-selected
        >
          {heading}
        </h4>
      </li>
    );
  }
}

export default Tab;
