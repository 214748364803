import Parse from 'parse';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';


import Card from '../../components/Card';
import Notification from '../../containers/Notification';
import { ACCOUNT_NOTIFICATIONS_TITLE } from '../../constants';
import { withGA } from '../../utils/googleAnalytics';

class AccountNotifications extends Component {
  static propTypes = {
    notifications: PropTypes.array,
    notificationsSeen: PropTypes.bool,
    setNotificationStatus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    notifications: [],
    notificationsSeen: false,
  };

  componentDidMount() {
    const { setNotificationStatus } = this.props;
    setNotificationStatus(true);

    const notifications = this.props.notifications;
    if (notifications) {
      notifications.forEach(notification => notification.set('viewed', true));
      Parse.Object.saveAll(notifications);
    }
    document.title = ACCOUNT_NOTIFICATIONS_TITLE;
    this.props.refreshNotifications();
  }

  render() {
    const { notifications } = this.props;
    return (
      <div className="container">
        <div className="mt-3 row">
          <div className="col-8">
            { _.isEmpty(notifications) ? <h1 className="d-flex justify-content-center align-items-center" style={{height: 'calc(100vh - 72px)'}}>No New Notifications</h1> : notifications.map((notification) =>
              <Notification key={notification.id} notification={notification} />
            )}
          </div>
          <div className="col">
            <Card>
              <h3><i className="fe fe-info mr-3 d-inline-block" style={{transform: 'translateY(1px)'}}></i>Notifications</h3>
              <small>
                <p className="text-muted mb-0">You'll always be able to view your past notifications here. To change what you receive via email:</p>
              </small>
              <p className="text-muted mb-0 small"><a href="/account/notifications" rel="noopener noreferrer">Change your notification settings</a></p>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default withGA(withRouter(AccountNotifications));
