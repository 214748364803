import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UnlinkPortfolioConfirmation extends Component {
  static propTypes = {
    institutionName: PropTypes.string,
    callUnlinkFunction: PropTypes.func.isRequired,
  }

  render() {
    const { institutionName, callUnlinkFunction } = this.props;

    return (
      <div className="m-2">
        <div className="text-center">
          <div className="modal-header p-1">
            <h2>Are you sure?</h2>
          </div>
          <section className="modal-body d-flex justify-content-start ">
            <p className="pt-2 display-4">This will unlink your portfolio from {institutionName}.</p>
          </section>
        </div>
        <div className="modal-footer pb-0 pt-3">
          <button type="button" className="btn btn-outline-dark mr-auto" data-dismiss="modal">Cancel</button>
          <button data-dismiss="modal" onClick={callUnlinkFunction} id="unlink-portfolio-button" type="button" className="btn btn-danger">Yes, Unlink Portfolio</button>
        </div>
      </div>
    );
  }
}

export default UnlinkPortfolioConfirmation;
