import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import ActionLink from '../components/ActionLink';
import Button from '../components/Button';

class ImportingError extends Component {
  static propTypes = {
    errorList: PropTypes.array.isRequired,
  }

  static defaultProps = {
  }

  switchModal = (from, to) => {
    this.closeModal(from);
    $(`${to}`).modal();
  }

  closeModal = (modalId) => {
    $(`${modalId}`).modal('hide');
  }

  render() {
    const { errorList } = this.props;
    return (
      <div className="m-4">
        <div>Pleaes double check these columns:</div>
          <ul className="m-4">
          {errorList.map(item => <li key={item}>{item}</li>)}
          </ul>
          <span>
            Need Help?&nbsp;
            <ActionLink text="Watch tutorial" clickAction={() => this.switchModal('#import-transactions-error', '#import-transactions-tutorial-modal')} />
          </span>
        <div className="d-flex mt-4">
          <Button name="Go Back" clickAction={() => this.closeModal('#import-transactions-error')} />
        </div>
      </div>
    );
  }
}

export default ImportingError;
