import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import $ from 'jquery';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { AsyncTypeahead, MenuItem, Menu } from 'react-bootstrap-typeahead';

import Tab from '../components/Tab';
import Button from '../components/Button';
import ErrorBox from '../components/ErrorBox';
import ActionLink from '../components/ActionLink';
import InputField from '../components/InputField';
import Tabs from '../containers/Tabs';
import ScenarioSnapShot from '../containers/ScenarioSnapShot';
import EllipsesContainer from '../containers/EllipsesContainer';
import { createScenario, createScenarioItemsFromArray, updatePlotIndex } from '../parse';
import { NEW_QTY, TICKER_OBJECT, OPEN_QUANTITY, SYMBOL } from '../constants';
import { eventWhatIfScenarioCreated, eventWhatIfScenarioUpdated } from '../utils/googleAnalytics';
import './ScenariosContent.css';

class ScenariosContent extends Component {
  static propTypes = {
    tickers: PropTypes.array,
    scenarios: PropTypes.array.isRequired,
    scenarioToRename: PropTypes.string,
    tabIndex: PropTypes.number.isRequired,
    openDeleteModal: PropTypes.func.isRequired,
    getAllScenarios: PropTypes.func.isRequired,
    setScenarioToRename: PropTypes.func.isRequired,
    updateActiveScenario: PropTypes.func.isRequired,
    activePortfolio: PropTypes.object.isRequired,
    scenarioSnapshotStatus: PropTypes.bool.isRequired,
    scenarioSlots: PropTypes.array.isRequired,
    activeScenarioIndex: PropTypes.number,
    setActiveScenarioIndex: PropTypes.func.isRequired,
    setScenarioSlots: PropTypes.func.isRequired,
    onBasicPlan: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    tickers: [],
    activePortfolio: {},
    activeScenarioIndex: null,
  }

  constructor(props) {
    super(props);
    this.newScenarioName = '';
    this.scenarioToRename = '';
    this._typeahead = '';
    this.state = {
      tickersSnapShot: [],
      scenarioToRename: null,
      errorMsg: null,
      // adding ticker feature:
      allowNew: false,
      isLoading: false,
      options: [],
      selectedTicker: null,
    };
  }

  componentDidMount () {
    const { setActiveScenarioIndex } = this.props;
    const modal = $('#what-if-modal');
    modal.on('hidden.bs.modal', () => {
      this.setState({ errorMsg: null });
      this.newScenarioName = '';
      setActiveScenarioIndex(null);
    });
  }

  componentDidUpdate = async prevProps => {
    const { scenarioSnapshotStatus } = this.props;

    if(prevProps.scenarioSnapshotStatus !== scenarioSnapshotStatus) {
      await this.createTickersSnapShot();
    }
  }

  createTickersSnapShot = async () => {
    const { tickers } = this.props;
    const tickersSnapShot = tickers.map(ticker => {
      const symbol = ticker.symbol;
      const company = ticker.companyName;
      // note: due to characteristic of ScenarioSnapShot component, the ordering of this return object will affect its output:
      return {
        // TODO: Don't really need constants for key names once we switch over to using Typescript. -EY
        [TICKER_OBJECT]: {
          symbol: symbol,
          company,
        },
        qty: ticker[OPEN_QUANTITY], // existing quantity (uneditable in modal)
        [NEW_QTY]: ticker[OPEN_QUANTITY], // new quantity (editable; default at existing quantity as placeholder)
        id: ticker[SYMBOL],
      }
    });
    this.setState({ tickersSnapShot });
  }

  updateNewQty = (input, id, index) => {
    const { tickersSnapShot } = this.state;
    tickersSnapShot[index][NEW_QTY] = input;
    this.setState({ tickersSnapShot });
  }

  createNewScenario = async (e) => {
    const { tickersSnapShot } = this.state;
    const { getAllScenarios, updateActiveScenario, activePortfolio, setScenarioSlots, activeScenarioIndex } = this.props;
    e.preventDefault();

    this.setState({errorMsg: null});

    if (!this.newScenarioName) {
      return this.setState({errorMsg: "Scenario name may not be left blank."})
    }

    try {
      const scenario = await createScenario(this.newScenarioName, activePortfolio.id);
      const scenarioItemsArray = [];
      tickersSnapShot.forEach(obj => {
        if (obj[NEW_QTY] === null ) obj[NEW_QTY] = 0; // set value as default
        if (obj[NEW_QTY] === 0 || obj[NEW_QTY]) {
          scenarioItemsArray.push({
            symbol: obj[TICKER_OBJECT].symbol,
            quantity: obj[NEW_QTY],
          })
        }
      });

      await createScenarioItemsFromArray(scenarioItemsArray, scenario);
      await getAllScenarios();
      await setScenarioSlots(activeScenarioIndex, scenario.id);
      updateActiveScenario(scenario.id)
      this.newScenarioName = '';
      $('#what-if-modal').modal('hide');
      eventWhatIfScenarioCreated();
    } catch (error) {
      console.log('error', error);
    }
  }

  callRenameScenario = async (e, id) => {
    const { renameScenario } = this.props;
    renameScenario(e, id, this.scenarioToRename);
    this.scenarioToRename = '';
    eventWhatIfScenarioUpdated('Renamed');
  }

  handleScenarioClick = async (id) => {
    const { activeScenarioIndex, setScenarioSlots, scenarioSlots } = this.props;
    if (activeScenarioIndex || activeScenarioIndex === 0) {
      // remove plotIndex from old scenario from respective index
      const scenarioId = scenarioSlots[activeScenarioIndex];
      if (scenarioId) await updatePlotIndex(null, scenarioId);

      setScenarioSlots(activeScenarioIndex, id);
      $('#what-if-modal').modal('hide');
    }
  }

  _handleSearch = async (query) => {
    this.setState({ isLoading: true });
    const result = await Parse.Cloud.run('getSymbolLookup', { query });
    this.setState({isLoading: false, options: result});
  }

  _handleChange = async (ticker) => {
    await this.setState({ selectedTicker: ticker[0] });
  }

  _isButtonDisabled = () => {
    const { selectedTicker } = this.state;
    if ( !selectedTicker ) return true;
  }

  addTicker = async () => {
    const { tickersSnapShot, selectedTicker : { id, symbol, name: company } } = this.state;
    const fIndex = tickersSnapShot.findIndex(tickerItem => tickerItem[TICKER_OBJECT].symbol === symbol);
    if (fIndex === -1) {
      // note: due to characteristic of ScenarioSnapShot component, the ordering of this object will affect its output:
      tickersSnapShot.unshift({
        [TICKER_OBJECT]: {
          symbol,
          company,
        },
        id,
        qty: 0,
        newQty: null,
      });
      this.setState({ tickersSnapShot, selectedTicker: null });
    } else {
      this.setState({ selectedTicker: null })
    }
    this._typeahead.getInstance().clear();
  }

  redirectTo = (route) => {
    const { history } = this.props;
    $('#what-if-modal').modal('hide');
    history.push(route);
  }

  render() {
    const { scenarios, tabIndex, scenarioToRename, setScenarioToRename, openDeleteModal,
            scenarioSlots, activeScenarioIndex, onBasicPlan } = this.props;
    const { tickersSnapShot, errorMsg, allowNew, isLoading, selectedTicker } = this.state;

    return (
      <div>
      { onBasicPlan
        ? (
          <div className="alert alert-light p-4 mb-0">
            <p className="mb-0"><ActionLink text="Upgrade your account" clickAction={() => this.redirectTo('/account/billing')}/> to save unlimited what-if scenarios and compare up to 3 scenarios at a time!</p>
          </div>
        )
        : null
      }
      <Tabs defaultActiveTabIndex={0} passedDownTab={tabIndex} dashClass="pt-0">
        { !_.isEmpty(scenarios) ? (
          <Tab linkClassName="" heading="All Scenarios">

            { scenarios.map(({ id, name }) => {

              // if there is an active scenario index, it means a scenario was selected to be changed in InfoSideBar;
              // this is to prevent displaying duplicates of already selected scenarios from all scenarios list.
              let hideScenario = false;
              if (activeScenarioIndex || activeScenarioIndex === 0) {
                hideScenario = scenarioSlots.some(scenarioId => scenarioId === id);
              }

              return (
              <div key={id}>
              {id === scenarioToRename
                ? (
                  <form onSubmit={(e) => this.callRenameScenario(e, id, this.scenarioToRename)}>
                    <InputField
                      type="text"
                      inputValue={(input) => { this.scenarioToRename = input; }}
                      placeholder="Rename this scenario"
                      id={id}
                      onBlur={() => setScenarioToRename()}
                      autoFocus />
                    </form>)
                : !hideScenario
                  && (
                    <div className="scenario-item__row w-100 px-0 border-bottom-0 pb-0">
                      <EllipsesContainer
                        name={name}
                        dashColor="outline-secondary"
                        ellipsesColor="dark"
                        dropDownItems={[
                          { name: 'Rename', clickAction: () => setScenarioToRename(id) },
                          { name: 'Delete', clickAction: () => openDeleteModal(id) },
                        ]}
                        clickAction={() => this.handleScenarioClick(id)}
                      />
                    </div>)
              }
              </div>
            )})}
            <hr/>
            <div className="d-flex flex-row justify-content-end my-3">
              <button type="button" className="mr-3 my-auto btn btn-link p-0 font-13" data-dismiss="modal"><u>Cancel</u></button>
              <div>
                <button type="button" className="mr-3 my-auto btn btn-primary button-radius" data-dismiss="modal">Done</button>
              </div>
            </div>
          </Tab>
        ) : <React.Fragment />}

        <Tab linkClassName="" heading="(+) New Scenario">
          <div className="form-group mt-3">
            <InputField
              id="new-scenario-input"
              type="text"
              label="Scenario Name"
              placeholder="Type a unique name"
              inputValue={(input) => { this.newScenarioName = input; }}
              clearRefOnModalClose="what-if-modal"
            />
          </div>

          <ErrorBox errorMsg={errorMsg} />

          <div style={{ maxHeight: 300, overflowY: 'scroll', margin: '0 -24px', borderBottom: '1px solid #EDF2F9'}}>
            <ScenarioSnapShot
              tHeader={['Company', 'Your Qty', 'New Qty']}
              entries={tickersSnapShot}
              updateSmallTableQty={this.updateNewQty}
            />
          </div>
          <div className="d-flex align-items-center p-3 sticky-async-typehead">
              <AsyncTypeahead
                {...this.state}
                id="scenarioTicker"
                minLength={1}
                allowNew = {allowNew}
                isLoading = {isLoading}
                filterBy={["name", "symbol"]}
                onSearch={this._handleSearch}
                onChange={this._handleChange}
                selectedTicker = {selectedTicker}
                ref={(ref) => this._typeahead = ref}
                placeholder="Add Ticker By Company or Symbol..."
                labelKey={(option) => `${option.name} (${option.symbol})`} // displays result as "companyName (ticker)"
                useCache={false} // https://github.com/ericgio/react-bootstrap-typeahead/issues/417
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps} maxHeight="200px">
                    {results.map((result, index) => (
                      <MenuItem option={result} position={index}>
                        {result.name} {`(${result.symbol})`}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              />
              <button
                className="btn btn-outline-primary ml-3 py-0"
                type="button"
                onClick={this.addTicker}
                disabled={this._isButtonDisabled()}
                >
                  + ADD
              </button>
          </div>
          <div className="d-flex flex-row justify-content-end my-3">
            <button type="button" className="mr-3 my-auto btn btn-link p-0 font-13" data-dismiss="modal"><u>Cancel</u></button>
            <div>
              <Button type="submit" name={`${(activeScenarioIndex || activeScenarioIndex === 0) ? 'Plot' : 'Create'} Scenario`} clickAction={this.createNewScenario} />
            </div>
          </div>
        </Tab>
      </Tabs>
      </div>
    );
  }
}

export default withRouter(ScenariosContent);
