import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { client } from 'filestack-react';
import { get } from 'lodash';

import Loader from '../components/Loader';
import { ERR_INCORRECT_FILE_TYPE, ERR_IMPORT_TRANSACTIONS } from '../constants';
import './UploadBox.css';

// TODO: transfer API key to secure file.
const FILESTACK_API_KEY = 'Apmu4UdBNQbusQsVLcJ30z';
const fsClient = client.init(FILESTACK_API_KEY);
const DATA_TYPE = {
  TEXT_HTML: 'text/html',
};

class UploadBox extends Component {
  static propTypes = {
    acceptableFiles: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      loading: false,
    };
    this.options = {
      accept: this.props.acceptableFiles,
    };
  }

  componentDidUpdate() {
    const { acceptableFiles } = this.props;
    this.options = {
      ...this.options,
      accept: acceptableFiles,
    };
  }

  onDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const text = e.dataTransfer.getData(DATA_TYPE.TEXT_HTML);
    if (!text) {
      this.setState({ dragging: true });
    }
  }

  onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  }

  openDialogue = async () => {
    const { loading } = this.state;
    if (loading) return null;
    this.upload.click();
  }

  onUpload = async (e, filePath) => {
    e.preventDefault();
    e.stopPropagation();
    const { loading } = this.state;
    const { acceptableFiles, onChange, setErrorMessage } = this.props;

    if (loading) return null;

    const file = get(e, filePath);
    if (file) {
      setErrorMessage();
    } else { return setErrorMessage(ERR_IMPORT_TRANSACTIONS); }
    
    this.setState({ dragging: false, loading: true });

    // Get file extension.
    const lastDot = file.name.lastIndexOf('.');
    const ext = lastDot !== -1 ? file.name.slice(lastDot) : '';

    // Check if file type is accepted.
    if (acceptableFiles.includes(ext.toLowerCase()) || acceptableFiles.includes(file.type)) {
      // Upload file
      try {
        const uploadedFile = await fsClient.upload(file);
        if (uploadedFile) {
          if (uploadedFile.status === 'Stored') {
            await onChange(uploadedFile);
          } else {
            throw new Error('Upload Failed');
          }
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(ERR_IMPORT_TRANSACTIONS);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      setErrorMessage(ERR_INCORRECT_FILE_TYPE);
      this.setState({ loading: false });
    }
  }

  render() {
    const { children } = this.props;
    const { loading, dragging } = this.state;

    return (
      <div className={`${dragging ? 'dragging' : ''} upload-box-container my-4 p-4`} onClick={() => this.openDialogue() }>
      <input
        id="upload-input"
        type="file"
        ref={(ref) => this.upload = ref }
        onChange={(e) => this.onUpload(e, 'target.files[0]')}
        style={{ display: 'none' }} />
        <div className={`${loading ? 'invisible' : ''} children-wrapper`}>
          {children}
        </div>
        <div
          className="upload-box"
          onDragEnter={(e) => { this.onDrag(e); }}
          onDragOver={(e) => { this.onDragOver(e); }}
          onDragLeave={(e) => { this.onDragLeave(e); }}
          onDrop={(e) => this.onUpload(e, 'dataTransfer.files[0]')}
        />
        {loading
          ? (
            <div className="loading-container">
              <Loader />
              <p>Uploading...</p>
            </div>)
          : null}
      </div>
    );
  }
}

export default UploadBox;
