import React, { Component } from 'react';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import { withGA } from '../../utils/googleAnalytics';
import Header from '../../components/Header';
import InputField from '../../components/InputField';
import Button from '../../components/Button';
import ErrorBox from '../../components/ErrorBox';
import ActionLink from '../../components/ActionLink';

class ChoosePassword extends Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);

    const appId = params.get('id'),
      error = params.get('error'),
      email = params.get('username'),
      token = params.get('token');

    this.state = {
      password1: '',
      password2: '',
      appId,
      error,
      email,
      token,
    };

    if (!appId || !email || !token) {
      props.history.replace('/invalid_link');
    }
  }

  disableSubmit = () => {
    const { password1, password2 } = this.state;
    return (!password1 || password1 !== password2);
  }

  render() {
    const { appId, error, email, token } = this.state;

    return (
      <div className="container-fluid center-signup">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-md-4 col-lg-auto order-1 order-md-2">
            <div className="card bg-light border">
              <div className="alert alert-white py-4 border">
                <h3><i className="fe fe-shield mr-3 d-inline-block" style={{transform: 'translateY(1px)'}}></i>Password Requirements</h3>
                <p className="text-muted">To keep your account secure, your new password must meet the following rules:</p>
                <small>
                  <ul className="text-muted" style={{paddingLeft: '18px', lineHeight: '2em'}}>
                    <li className="card-subtitle">Minimum 8 characters</li>
                    <li className="card-subtitle">At least one special character (@!#$%)</li>
                    <li className="card-subtitle">At least one number (0-9)</li>
                  </ul>
                </small>
                <p className="text-muted mb-0">Please note that changing you current password will update your password on record for Investors Alley as well as Divcaster.</p>
              </div>
            </div>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 order-2 order-md-1">
            <div className="card">
              <div className="card-body d-flex flex-column flex-fill">
                <form method="POST" action={`${Parse.serverURL}/apps/${appId}/request_password_reset`}>
                  <input type="hidden" name="username" value={email} />
                  <input type="hidden" name="token" value={token} />
                  <div className="text-center">
                    <Header content="Reset your password for Divcaster" />
                  </div>
                  <p className="text-muted text-center my-2">
                    New password for {email}
                  </p>

                  <ErrorBox errorMsg={error} />
                  <div className="form-group">
                    <InputField
                      required
                      id="password1"
                      type="password"
                      label="New Password"
                      name="new_password"
                      placeholder="Type your password..."
                      inputValue={(password1) => { this.setState({ password1 }); }}
                    />
                  </div>
                  <div className="form-group">
                    <InputField
                      required
                      id="password2"
                      type="password"
                      label="Confirm New Password"
                      name="confirm_new_password"
                      placeholder="Type your password..."
                      inputValue={(password2) => { this.setState({ password2 }); }}
                    />
                  </div>
                  <Button type="submit" name="Request Reset Password" dashColor="primary btn-block" disabled={this.disableSubmit()} />
                  <small className="text-muted d-flex flex-row justify-content-center mt-2">
                    <ActionLink text="Return to Login" clickAction={() => { this.props.history.push('login'); }} />
                  </small>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGA(withRouter(ChoosePassword));
