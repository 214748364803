import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sum, get, range, flatMap, isString } from 'lodash';
import moment from 'moment';

import Chart from '../components/Chart';
import { commaSeparatorReg } from '../utils/regex';
import './History.css';

const YEARS_RANGE = 10;

function toDecimalWithCommas(value, places = 2) {
  return (value || 0).toFixed(places).replace(commaSeparatorReg, '$1,');
}

function HistoryCard({ title, amount, postfix }) {
  let rate, badgeStyle;
  if (amount >= 0.10) {
    rate = 'Very Fast';
    badgeStyle = 'badge-success';
  } else if (amount >= 0.05) {
    rate = 'Fast';
    badgeStyle = 'badge-success';
  } else if (amount >= 0.03) {
    rate = 'Average';
    badgeStyle = 'badge-secondary';
  } else if (amount >= 0.01) {
    rate = 'Slow';
    badgeStyle = 'badge-warning';
  } else {
    rate = 'Very Slow';
    badgeStyle = 'badge-warning';
  }

  let arrowClassname = '';
  if (amount > 0) {
    arrowClassname = 'fe-arrow-up text-success';
  } else if(amount < 0) {
    arrowClassname = 'fe-arrow-down text-danger';
  }

  return (
    <div className="card">
      <div className="card-body">
        <h6 className="card-title text-uppercase text-muted mb-2">{title}</h6>
        <div className="row">
          <div className="col">
            <span className="h2 mb-2">{toDecimalWithCommas(amount * 100, 1)}%</span>
            {isString(postfix) && <span className="text-muted mx-2">{postfix}</span>}
          </div>
          <div className="col-auto">
            <span className={`h2 fe ${arrowClassname}`}></span>
          </div>
        </div>
        <div>
          <span className={`badge mt-n1 ${badgeStyle}`}>{rate}</span>
        </div>
      </div>
    </div>
  );
}

// Data Flow //
// Portfolio (parent): Feeds Data =>
// Forecast/History (middle component): Converts Data =>
// Chart (child): Displays Data

class History extends Component {
  static propTypes = {
    data: PropTypes.object,
    forecastedAnnualIncome: PropTypes.number,
  }

  static defaultProps = {
    data: {},
    forecastedAnnualIncome: 0,
  }

  state = {
    type: 'line',
    labels: [],
    datasets: [],
    options: {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          { ticks: { callback: (value) => `$${value}` } }
        ]
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, data) => {
            return `$${toDecimalWithCommas(tooltipItems.yLabel, 2)}`;
          },
          title: function(tooltipItems, data) {
            return tooltipItems.map(tooltip => {
              const quarterIndex = tooltip.index % 4;
              const yearIndex = tooltip.index - quarterIndex;
              return `Q${quarterIndex + 1} ${data.labels[yearIndex]}`;
            });
          },
        },
        custom: (tooltipModel) => {
          let tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.classList.add('popover', 'bs-popover-top', 'p-3')
            tooltipEl.style.maxWidth = 'unset';
            tooltipEl.innerHTML = '';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const arrow = `<div class="arrow" style="left: 50%;transform: translateX(-50%);margin: 0"></div>`;
            const title = `<div class="h6 mb-3 text-muted text-center">${titleLines[0]}</div>`;
            const body = `<div class="h2 m-0 text-black text-center">$${toDecimalWithCommas(tooltipModel.dataPoints[0].yLabel)}</div>`;
            tooltipEl.innerHTML = arrow + title + body;
          }

          const position = this._chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.transform = 'translate(-50%, -100%) translateY(-16px)';
        },
      },
    },
  }

  componentDidMount() {
    this.initializeGraph();
  }

  componentDidUpdate(prevProp) {
    const { redrawGraph } = this.props;
    if(prevProp.redrawGraph !== redrawGraph) {
      this.initializeGraph();
    }
  }

  initializeGraph = () => {
    const { data } = this.props;

    if (!data) {
      return;
    }

    const to = new Date().getFullYear();
    const from = to - YEARS_RANGE;
    const years = range(from + 1, to + 1);
    const labels = flatMap(years, year => [year,'','','',]);
    const datasets = [{
      label: 'Historical Data',
      fill: false,
      data: flatMap(years, year => get(data, `[${year}]quarterly`, Array(4))),
      pointBorderWidth: 0,
      pointRadius: 0,
    }];

    this.setState({ datasets, labels });
  }

  _concatDataByYearsBack(yearsBack) {
    const { data } = this.props;
    const currentYear = new Date().getFullYear();
    
    return (data[currentYear - yearsBack].monthly).concat(data[currentYear - (yearsBack - 1)].monthly);
  }

  _checkForFinite(n) {
    return isFinite(n) ? n : 0;
  }

  getTotalIncome(yearData) {
    const currentMonth = new Date().getMonth();
    const twelveMonths = yearData.slice(currentMonth, currentMonth + 12);
    return sum(twelveMonths);
  }

  _clampValue(value, lower, upper) {
    return Math.min(Math.max(value, lower), upper);
  }

  render() {
    const { earliestTxDate, forecastedAnnualIncome } = this.props;
    const { datasets, labels, type, options } = this.state;

    const lastYearData = this._concatDataByYearsBack(1);
    const last2YearData = this._concatDataByYearsBack(2);
    const last5YearData = this._concatDataByYearsBack(4);
    const last10YearData = this._concatDataByYearsBack(10);

    const lastYearIncome = this.getTotalIncome(lastYearData); 
    const last2YearIncome = this.getTotalIncome(last2YearData);
    const last5YearIncome = this.getTotalIncome(last5YearData);
    const last10YearIncome = this.getTotalIncome(last10YearData);

    const lastYearGrowthRate = this._checkForFinite((lastYearIncome - last2YearIncome)/last2YearIncome);
    const last5GrowthRate = this._checkForFinite((Math.pow(lastYearIncome/last5YearIncome, (1/5)) - 1));
    const last10GrowthRate = this._checkForFinite((Math.pow(lastYearIncome/last10YearIncome, (1/10)) - 1));
    const clampedLastYearGrowthRate = this._clampValue(lastYearGrowthRate, 0.05, 0.15); // clamp growth rate to be between 5% - 15%

    return (
      <div className="py-4">
        <h3 className="text-white">Historical Dividend Earnings</h3>
        <p className="text-secondary mb-4">Here's how your dividend income has grown since <span className="text-white">{moment(earliestTxDate).format('L')}</span>, assuming dividends were withdrawn and not reinvested.</p>
        <div className="row">
          <div className="col">
            <HistoryCard title="Last Year" amount={lastYearGrowthRate} />
          </div>
          <div className="col">
            <HistoryCard title="Last 5 Years" amount={last5GrowthRate} postfix="Per Year" />
          </div>
          <div className="col">
            <HistoryCard title="Last 10 Years" amount={last10GrowthRate} postfix="Per Year" />
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <Chart
              ref={ref => this._chart = ref && ref.chart}
              type={type}
              labels={labels}
              datasets={datasets}
              options={options}
            />
          </div>
          <div className="col-3">
            <h4 className="text-white">Dividend Growth Potential</h4>
            <p className="text-muted">Here's how much you can earn in the future at your current year over year growth rate.</p>
            <div className="history-annual-income">
              <div className="income-row">
                <div className="income-col">Forecasted annual income</div>
                <div className="income-col">${toDecimalWithCommas(forecastedAnnualIncome)}</div>
              </div>
              <div className="income-row">
                <div className="income-col">In 5 Years</div>
                <div className="income-col">${toDecimalWithCommas(forecastedAnnualIncome * Math.pow(1 + clampedLastYearGrowthRate, 5))}</div>
              </div>
              <div className="income-row">
                <div className="income-col">In 10 Years</div>
                <div className="income-col">${toDecimalWithCommas(forecastedAnnualIncome * Math.pow(1 + clampedLastYearGrowthRate, 10))}</div>
              </div>
              <div className="income-row">
                <div className="income-col">In 15 Years</div>
                <div className="income-col">${toDecimalWithCommas(forecastedAnnualIncome * Math.pow(1 + clampedLastYearGrowthRate, 15))}</div>
              </div>
              <div className="income-row">
                <div className="income-col">In 20 Years</div>
                <div className="income-col">${toDecimalWithCommas(forecastedAnnualIncome * Math.pow(1 + clampedLastYearGrowthRate, 20))}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default History;
