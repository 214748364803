import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import _ from 'lodash';

import './Dropdown.css';

export default class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string, // Note: unique id is required if using label props.
    label: PropTypes.string,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    clickAction: PropTypes.func,
    menu: PropTypes.array.isRequired,
    dashColor: PropTypes.string,
    dashClass: PropTypes.string,
    required: PropTypes.bool,
    onChangeAction: PropTypes.func,
    maxHeight: PropTypes.number,
    width: PropTypes.number,
  }

  static defaultProps = {
    label: '',
    text: '',
    dashColor: 'white',
    dashClass: '',
    required: false,
    clickAction: () => {},
    onChangeAction: () => {},
    maxHeight: null,
    width: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      id: shortid.generate(),
      label: null,
      text: 'Make Selection',
      clickAction: () => {},
    };
  }

  componentDidMount = () => {
    const { text } = this.props;
    if (text) this.setState({ text });
  }

  componentDidUpdate = (prevProps) => {
    const { text } = this.props;
    if (prevProps.text !== text) {
      this.setState({ text });
    }
  }

  handleClick = (e) => {
    const text = e.target.textContent, { clickAction } = this.props;
    clickAction(text);
    this.setState({ text });
  }

  render() {
    const { id, menu, label, dashColor, dashClass, required, onChangeAction, maxHeight, width } = this.props;
    const { text } = this.state;
    const menuItems = [];
    if (!_.isEmpty(menu)) {
      menu.forEach((option, i) => {
        menuItems.push(
          <button type="button" className={`dropdown-item btn-${dashColor || 'primary'}`} key={i} href="#" onClick={this.handleClick}>{option}</button>,
        );
      });
    }
    return (
      <div className="dropdown d-flex flex-column" id={id}>
        {
          label ?
          <label htmlFor={id}>
            {label}
          </label> :
          ''
        }
        <button
          className={`btn ${dashClass} btn-${dashColor || 'primary'} dropdown-toggle text-left`}
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          required={required}
        >
          {text}
        </button>
        <div
          className={`dropdown-menu ${menuItems.length > 10 ? 'dropdown-overflow' : ''} form-group overflow-y`}
          aria-labelledby="dropdownMenuLink"
          style={{maxHeight, width}}
          required={required}
          onClick={onChangeAction}
        >
          {menuItems}
        </div>
      </div>
    );
  }
}
