import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ActionLink.css';

class ActionLink extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    clickAction: PropTypes.func,
    keyDownAction: PropTypes.func,
    dataToggle: PropTypes.string,
    dropdownItem: PropTypes.bool,
    //dataTarget refers to the ID of the modal in bootstrap -- the string
    //MUST begin with a # i.e.,) dataTarget="#portfolioSideModal"
    dataTarget: PropTypes.string,
    textTheme: PropTypes.string,
    tabIndex: PropTypes.string,
  }

  static defaultProps = {
    clickAction: null,
    keyDownAction: null,
    dataToggle: '',
    dataTarget: '',
    dropdownItem: false,
    textTheme: null,
    tabIndex: '',
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { text, clickAction, keyDownAction, dataToggle, dataTarget, dropdownItem, textTheme, tabIndex } = this.props;

    return (
      <span
        className={`cursor-pointer ${dropdownItem ? 'dropdown-item' : 'action-link text-primary no-highlight'} ${textTheme ? `text-${textTheme}` : ''}`}
        role="button"
        onClick={clickAction}
        onKeyDown={keyDownAction}
        data-target={dataTarget}
        data-toggle={dataToggle}
        tabIndex={tabIndex || 0}
      >
        {text}
      </span>
    );
  }
}

export default ActionLink;
