import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header';
import { withGA } from '../../utils/googleAnalytics';
import MetaTags from 'react-meta-tags';

import { SUPPORT_TITLE } from '../../constants';


class Support extends Component {

  componentDidMount() {
    this.props.refreshNotifications();
  }

  render() {
    return (
      <div className="heavy-margin">
        <React.Fragment>
          <MetaTags>
            <title>{SUPPORT_TITLE}</title>
            <meta name="og:title" property="og:title" id="og-title" content={SUPPORT_TITLE} />
            <meta name="apple-mobile-web-app-title" id="apple-title" content={SUPPORT_TITLE} />
          </MetaTags>
        </React.Fragment>
        <div className="border-bottom pb-3">
          <h6 className="header-pretitle">Divcaster</h6>
          <Header content="Support" icon={<i className="fe fe-info" />} />
        </div>

        <p className="m-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur sed sapien ex.
          Integer vitae egestas ipsum, nec blandit augue. Vestibulum porttitor porttitor risus nec posuere.
          Ut vel ante eget dolor mollis pretium. Aenean interdum mi a arcu luctus, non ultrices ligula ornare.
          Maecenas sed condimentum dui, sed suscipit odio. Etiam nulla diam, sodales in tincidunt vitae, luctus et eros.
          Aliquam quis orci a lorem accumsan rhoncus nec vitae augue. Proin faucibus pulvinar turpis, in lobortis diam tempor sed.
          Praesent cursus, nibh sagittis pretium sodales, justo leo elementum velit, eu ultrices nulla tellus nec lectus.
          Nullam id tempus nibh, sit amet porta lorem. Curabitur ullamcorper diam est, sed euismod mauris gravida rutrum.
          In egestas, erat non euismod consectetur, odio nulla tincidunt augue, vitae rutrum nisi dui sed quam.
        </p>

        <div className="border-bottom pb-2">
          <h2>More Support</h2>
        </div>
        <ol className="m-5">
          <li className="my-2">Nam ligula nisl, fermentum ut egestas eu, tincidunt vitae lorem.</li>
          <li className="my-2">Donec et mauris eget metus mollis ullamcorper vel sit amet lorem. Ut consectetur tellus ut maximus consectetur. Suspendisse in lectus interdum, imperdiet ex at, varius urna. </li>
          <li className="my-2">Nulla lobortis mauris ex, <u>et gravida massa volutpat sed.</u>Sed id justo massa.</li>
          <li className="my-2">Nunc et nisl vitae orci rutrum vehicula. Aenean mauris ante, congue non leo ut, efficitur tincidunt magna. Praesent commodo ipsum vitae lectus sagittis convallis. In eu eleifend ante</li>
        </ol>
      </div>
    );
  }
}

export default withGA(withRouter(Support));
