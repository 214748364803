import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Chart from '../components/Chart';
import ToolTip from '../components/ToolTip';
import { commaSeparatorReg } from '../utils/regex';
import { CHART_FORMAT, CHART_MONTHS, CHART_MONTHS_BY_QUARTER, SCENARIO_COLOR_BANK,
  FORCAST_DEFAULT_COLOR, BREAKDOWN_DEFAULT_COLORS, TITLE_CHAR_COUNT_CUTOFF } from '../constants';
import './Forecast.css';

function formatDollarOrPercent(value, isPercent) {
  return isPercent ? toDecimalWithCommas(value * 100, 1) + '%' : '$' + toDecimalWithCommas(value, 2);
}

function toDecimalWithCommas(value, places = 2) {
  return (value || 0).toFixed(places).replace(commaSeparatorReg, '$1,');
}

function ForecastCard({ title, amount, change, tooltip, isPercent, isEstimate, scenarios = [] }) {
  return (
    <div className={`card ${isEstimate && 'card-estimate'}`}>
      <ToolTip tooltipId="forecast-card-tooltip" popupText={tooltip}>
        <div className="card-body">
          <h6 className="card-title text-uppercase text-muted mb-2">{title}</h6>
          <span className="h2 mb-0">{formatDollarOrPercent(amount, isPercent)}</span>
          { !!change && (
            <span className={`badge mt-n1 ${change < 0 ? 'badge-danger' : 'badge-success'} mt--2 mx-2`} title={tooltip}>
              {`${change > 0.001 ? '+' : ''}${formatDollarOrPercent(change, true)}`}
            </span>
          )}
        </div>
        {!_.isEmpty(scenarios) && (
          <div className="card-footer">
            <ul className="list-inline d-flex flex-wrap justify-content-center m-0">
              {scenarios.map(({ id, color, amount }) => (
                <li key={id} className="list-inline-item small text-nowrap mr-3">
                  <div className="popover-body-indicator" style={{backgroundColor: color}}></div>&nbsp;{formatDollarOrPercent(amount, isPercent)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </ToolTip>
    </div>
  );
}

// Data Flow //
// Portfolio (parent): Feeds Data =>
// Forecast/History (middle component): Converts Data =>
// Chart (child): Displays Data

class Forecast extends Component {
  static propTypes = {
    scenarioForecast: PropTypes.array.isRequired,
    portfolioForecast: PropTypes.object,
    redrawGraph: PropTypes.bool.isRequired,
    activePortfolio: PropTypes.object.isRequired,
    tdhActive: PropTypes.bool,
    subscribedToTDH: PropTypes.bool,
    portfolioSummary: PropTypes.object.isRequired,
    lastYearData: PropTypes.object,
    currentYearData: PropTypes.object,
    positionsHeld: PropTypes.number,
    tickers: PropTypes.array.isRequired, // this is only being used for ticker symbol & company names
  }

  static defaultProps = {
    portfolioForecast: {},
    tdhActive: false,
    subscribedToTDH: false,
    lastYearData: {},
    currentYearData: {},
    positionsHeld: 0,
  }

  // blanket option for charts for tooltip display:
  chartJsOptions = {
    legend: {
      display: true,
    },
    tooltips: {
      mode: 'x',
      enabled: false,
      callbacks: {
        title: function(tooltipItems, data) {
          return tooltipItems.map(({ datasetIndex }) => data.datasets[datasetIndex].label);
        },
        beforeBody: function(tooltipItems, data) {
          return tooltipItems.map(({ datasetIndex }) => data.datasets[datasetIndex].nonPlottedData); // payingDividends = Array(12);
        }
      },
      custom: (tooltipModel) => {

        let tooltipEl = document.getElementById('chartjs-tooltip');

        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.classList.add('popover', 'bs-popover-top', 'p-3')
          tooltipEl.style.maxWidth = 'unset';
          tooltipEl.innerHTML = '';
          document.body.appendChild(tooltipEl);
        }

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const labelColors = tooltipModel.labelColors || [];

          const dataPoint = tooltipModel.dataPoints[0];
          const dataIndex = dataPoint.index;

          const arrow = `<div class="arrow" style="left: 50%;transform: translateX(-50%);margin: 0"></div>`;
          const title = `<div class="mb-3">
            <span class="popover-body-indicator" style="background-color: ${labelColors[0].backgroundColor}"></span>&nbsp;${titleLines[0]}
          </div>`;

          const body = `<div class="row flex-nowrap">
            <div class="col">
              <div class="h6 text-uppercase text-muted">Paying<br>Dividends</div>
              <div class="h2 m-0">${tooltipModel.beforeBody[0].payingDividends[dataIndex]}</div>
            </div>
            <div style="width: 1px; max-width: 1px; position: relative; padding: 0; background: var(--light)"></div>
            <div class="col">
              <div class="h6 text-uppercase text-muted">Estimated<br>Income</div>
              <div class="h2 m-0">${formatDollarOrPercent(dataPoint.yLabel)}</div>
            </div>
          </div>`;

          tooltipEl.innerHTML = arrow + title + body;
        }

        const position = this._chart.canvas.getBoundingClientRect();

        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.transform = 'translate(-50%, -100%) translateY(-16px)';
      },
    }
  }

  state = {
    graphFormat: CHART_FORMAT.BREAKDOWN,
    months: [],
    quarters: [],
    labels: [],
    datasets: [],
    options: {},
    pulledSummaryGraph: false,
    showLegend: false,
    // detailed forecast view
    activeMonthIndex: null,
    activeDatasetIndex: null,
    updateGraph: false,
  }

  async componentDidMount() {
    await this.getNext12MonthsAnd4Quarters();
    this.getSummary(); // show summary & monthly by default

    const { portfolioForecast } = this.props;
    // set this flag to prevent redrawing same thing again
    // but if the data is still empty, get the summary again during compoentDidUpdate method
    if (_.get(portfolioForecast, 'breakdown')) {
      this.setState({ pulledSummaryGraph: true });
    }
  }

  componentDidUpdate(prevProp, prevState){
    if (prevProp.redrawGraph !== this.props.redrawGraph) {
      this.getSummary();
    }
  }

  async getSummary() {
    const { portfolioForecast, activePortfolio, tdhActive } = this.props;
    const options = {
      ...this.chartJsOptions,
      scales: {
        yAxes: [
          { ticks: { callback: (value) => `$${value}`} }
        ],
        xAxes: [{
          barPercentage: 0.95,
          categoryPercentage: 0.78,
          maxBarThickness: 256,
        }],
      },
    };

    let data = [];

    if (_.get(portfolioForecast, 'summary')) {
      data = portfolioForecast.summary.monthly;
    }

    // calculates total # of paying dividends per month
    let payingDividends = _.fill(Array(12), 0);

    if (_.get(portfolioForecast, 'breakdown')) {
      _.forIn(portfolioForecast.breakdown, (ticker) => {
        ticker.monthly.forEach((dividend, dIndex) => {
          if (dividend > 0) {
            payingDividends[dIndex]++;
          }
        });
      })
    }

    const nonPlottedData = {
      payingDividends,
    };

    const numberOfMonths = 12;
    const backgroundColor = new Array(numberOfMonths).fill(FORCAST_DEFAULT_COLOR);

    const portfolioSummary = {
      data,
      nonPlottedData,
      fill: false,
      backgroundColor,
      label: activePortfolio.name && activePortfolio.name.length > TITLE_CHAR_COUNT_CUTOFF ? activePortfolio.name.substring(0, TITLE_CHAR_COUNT_CUTOFF) + "..." : activePortfolio.name,
    }
    const scenarioSummary = this._formatScenarioArray();

    let datasets = [portfolioSummary];
    if (!tdhActive) {
      datasets = datasets.concat(scenarioSummary);
    };

    this.updateLabels();
    this.setState({ datasets, options });
  }

  // helper functions:
  updateLabels() {
    const { months } = this.state;
      this.setState({ labels: months });
  }

  async getNext12MonthsAnd4Quarters() {
    const monthsInOneYear = 12;
    const quartersInOneYear = 4;

    const now = new Date();
    let month = now.getMonth();
    let year = now.getFullYear();

    let currentMonth;

    const months = [];
    for (let i = 0; i < monthsInOneYear; ++i) {
        if(i===0){currentMonth = CHART_MONTHS[month]}
        months.push(`${CHART_MONTHS[month]} '${year.toString().substr(-2)}`);
        if (++month === monthsInOneYear) {
            month = 0;
            ++year;
        };
    }

    const quartersArray = [];

    const currentQuarter = CHART_MONTHS_BY_QUARTER.findIndex(quarter => quarter.some(month => month === currentMonth)) + 1;
    quartersArray.push(currentQuarter);
    while(quartersArray.length < quartersInOneYear) {
      let nextQuarter = quartersArray[quartersArray.length-1];
      if (nextQuarter === quartersInOneYear) nextQuarter = 1;
      else nextQuarter += 1;
      quartersArray.push(nextQuarter);
    }

    const quarters = quartersArray.map(q => `Q${q}`);
    await this.setState({ months, quarters });
  }

  _formatScenarioArray() {
    const { scenarioForecast } = this.props;
    let formattedForecastData = scenarioForecast.map((obj, sIndex) => {

      if(!_.isEmpty(obj)){
        let data = [];
        if (obj && obj.summary) {
          data = obj.summary.monthly;
        }

        const name = obj.name;
        let payingDividends = _.fill(Array(12), 0);

        if (!_.isEmpty(obj.breakdown)) {
          _.forIn(obj.breakdown, (ticker) => {
            ticker.monthly.forEach((dividend, dIndex) => {
              if (dividend > 0) {
                payingDividends[dIndex]++;
              }
            });
          })
        };

        return {
          data,
          nonPlottedData: { payingDividends },
          label: (name && name.length > TITLE_CHAR_COUNT_CUTOFF) ? name.substring(0, TITLE_CHAR_COUNT_CUTOFF) + '...' : name,
          type: 'bar',
          borderWidth: 0,
          borderColor: SCENARIO_COLOR_BANK[sIndex],
          backgroundColor: SCENARIO_COLOR_BANK[sIndex],
        }
      }
      return null;
    });

    // filtering empty indexes at the last step so integrity of scenario indexes are preserved
    formattedForecastData = formattedForecastData.filter(data => !!data);
    return formattedForecastData;
  }

  handleBarClick = (monthIndex, datasetIndex) => {
    if(!monthIndex && monthIndex !== 0) {
      this.setState({ activeMonthIndex: null, activeDatasetIndex: null });
    } else {
      this.setState({ activeMonthIndex: monthIndex, activeDatasetIndex: datasetIndex });
    };
  };

  handleCloseBreakdown = () => {
    const { activeMonthIndex, activeDatasetIndex, updateGraph, datasets } =  this.state;
    datasets[activeDatasetIndex].backgroundColor[activeMonthIndex] = BREAKDOWN_DEFAULT_COLORS[activeDatasetIndex];
    this.setState({ activeMonthIndex: null, activeDatasetIndex: null, updateGraph: !updateGraph });
  };

  _checkForFinite(n) {
    return isFinite(n) ? n : 0;
  }

  renderDate = (dateArray) => {
    if (!dateArray) {
      return <div>N/A</div>;
    }

    const formattedDateArray = [];

    for (let i = 0; i < dateArray.length; i++) {
      const date = dateArray[i];
      const isLast = i === (dateArray.length - 1);

      formattedDateArray.push(<div>{`${moment.utc(date).format("MMM Do YYYY")} ${isLast ? "*" : " &"}`}</div>)
    };

    return formattedDateArray;
  }

  render() {
    const { portfolioSummary, portfolioForecast, lastYearData, currentYearData, positionsHeld, tdhActive } = this.props;
    const { datasets, labels, options, graphFormat, activeMonthIndex, activeDatasetIndex, months, updateGraph } = this.state;
    const scenarioForecast = _.filter(this.props.scenarioForecast);

    // FORECAST CARDS
    // ==============

    // annual income
    const lastYearAnnualIncome = _.get(lastYearData, 'yearly', 0);
    const annualIncome = _.get(portfolioForecast, 'summary.yearly', 0);
    const annualIncomeScenarios = tdhActive ? [] : _.map(scenarioForecast, (scenario, index) => (scenario && {
      id: scenario.id,
      color: SCENARIO_COLOR_BANK[index],
      amount: _.get(scenario, 'summary.yearly', 0),
    }));

    // average monthly income
    const averageMonthlyIncome = _.get(portfolioForecast, 'summary.yearly', 0) / 12;
    const currentMonthIncome = _.get(portfolioForecast, `summary.monthly[0]`) // current month always starts at index 0 for forecasts
    const incomeChange = (currentMonthIncome - averageMonthlyIncome) / averageMonthlyIncome;
    const incomeChangeTooltip = `${moment().format('MMMM')}'s income is ${formatDollarOrPercent(Math.abs(incomeChange), true)} ${incomeChange >= 0 ? 'higher' : 'lower'} than your forecasted monthly average. Consider using what-if scenarios to see how new positions change your average income.`;
    const averageMonthlyIncomeScenarios = tdhActive ? [] : _.map(scenarioForecast, (scenario, index) => (scenario && {
      id: scenario.id,
      color: SCENARIO_COLOR_BANK[index],
      amount: (_.get(scenario, 'summary.yearly', 0)) / 12,
    }));

    // average dividend yield AKA portfolio yield
    const averageDividendYieldDecimal = _.get(portfolioSummary, 'dividendYield', 0);
    const averageDividendYieldScenarios = tdhActive ? [] : _.map(scenarioForecast, (scenario, index) => (scenario && {
      id: scenario.id,
      color: SCENARIO_COLOR_BANK[index],
      amount: _.get(scenario, "summary.dividendYield", 0),
    }));

    const averageDividendYieldWholeNumber = averageDividendYieldDecimal * 100;
    const comparisonTickerDividendYield = _.get(portfolioSummary, 'comparisonTickerDividendYield', 0) * 100;
    const averageDividendYieldChange = (averageDividendYieldWholeNumber - comparisonTickerDividendYield) / comparisonTickerDividendYield;
    const averageDividendYieldTooltip = `Your portfolio yield is ${formatDollarOrPercent(averageDividendYieldChange, true)} ${averageDividendYieldChange >= 0 ? "higher" : "lower"} than the S&P 500's`

    // year to date income earned
    const currentMonth = new Date().getMonth();
    const yearToDateIncomeEarned = _.sum(_.get(currentYearData, 'monthly', []).slice(0, currentMonth + 1));
    const lastYearToDateIncomeEarned = _.sum(_.get(lastYearData, 'monthly', []).slice(0, currentMonth + 1));
    const yearToDateIncomeEarnedChange = ((yearToDateIncomeEarned / lastYearToDateIncomeEarned) - 1);
    const yearToDateIncomeEarnedScenarios = tdhActive ? [] : _.map(scenarioForecast, (scenario, index) => {
      if (scenario) {
        return {
          id: scenario.id,
          color: SCENARIO_COLOR_BANK[index],
          amount: _.sum(_.get(scenario, `summary.monthly`, []).slice(0, currentMonth + 1)),
        }
      }
    });


    // FORECAST DETAILS (SINGLE MONTH)
    // ==============
    const hasActiveBreakdown = activeMonthIndex || activeMonthIndex === 0;
    const isScenario = activeDatasetIndex > 0; // non-scenario's dataset index is always 0.
    const selectedPortfolio = !isScenario ? portfolioForecast : scenarioForecast[activeDatasetIndex-1];

    const barMonthTitle = !isScenario ? months[activeMonthIndex] : `${months[activeMonthIndex]} ${scenarioForecast[activeDatasetIndex-1].name} Scenario`;
    const barMonthTotal = formatDollarOrPercent(_.get(selectedPortfolio, `summary.monthly[${activeMonthIndex}]`));
    const barMonthTickers = [];

    if(hasActiveBreakdown) {
      Object.keys(selectedPortfolio.breakdown).sort().forEach(ticker => {
        const { companyName } = selectedPortfolio.breakdown[ticker];
        const income = _.get(selectedPortfolio, `breakdown[${ticker}].monthly[${activeMonthIndex}]`);
        const projectedDates = _.get(selectedPortfolio, `breakdown[${ticker}].projectedDatesMonthly[${activeMonthIndex}]`);

        if(income > 0) {
          barMonthTickers.push({
            ticker: `${companyName} (${ticker})`,
            income: formatDollarOrPercent(income),
            projectedDates,
          });
        };
      });
    };

    return (
      <div className="pt-4">
        <h3 className="text-white">Forecasted Dividend Earnings</h3>
        <p className="text-secondary mb-4">These are your forecasted earnings from <span className="text-white">{positionsHeld}</span> {positionsHeld > 1 ? 'positions' : 'position'} held, over the next 12 months.</p>
        <div className="row">
          <div className="col-3">
            <ForecastCard title="Forecasted Income Next 12 Months" amount={annualIncome} change={this._checkForFinite((annualIncome / lastYearAnnualIncome) - 1)} tooltip="Here's how much income you're forecasted to earn over the next 12 months. The red or green box shows your year over year change in income if we have enough data from your portfolio." isEstimate scenarios={annualIncomeScenarios} />
          </div>
          <div className="col-3">
            <ForecastCard title="Avg. Monthly Income" amount={averageMonthlyIncome} change={this._checkForFinite(incomeChange)} tooltip={incomeChangeTooltip} isEstimate scenarios={averageMonthlyIncomeScenarios} />
          </div>
          <div className="col-3">
            <ForecastCard title="Your portfolio's yield" amount={averageDividendYieldDecimal} change={averageDividendYieldChange} isPercent scenarios={averageDividendYieldScenarios} tooltip={averageDividendYieldTooltip}/>
          </div>
          <div className="col-3">
            <ForecastCard title="Year to Date Income Earned" amount={yearToDateIncomeEarned} change={this._checkForFinite(yearToDateIncomeEarnedChange)} tooltip="As compared to the same period last year" scenarios={yearToDateIncomeEarnedScenarios} />
          </div>
        </div>
        <div className="row">
          <div className={`col-${hasActiveBreakdown ? '8' : '12'}`}>
            <Chart
              ref={ref => this._chart = ref && ref.chart}
              type="bar"
              labels={labels}
              options={options}
              datasets={datasets}
              graphFormat={graphFormat}
              updateGraph={updateGraph}
              activeMonthIndex={activeMonthIndex}
              activeDatasetIndex={activeDatasetIndex}
              onBarClick={this.handleBarClick}
              hasHoverEvent={true}
            />
          </div>


          { hasActiveBreakdown && (
              <div className="col-4">
                <ReactCSSTransitionGroup
                  transitionName="barchart-breakdown"
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                  transitionLeave={true}
                  transitionLeaveTimeout={500}
                >
                <div className="d-flex">
                  <div className="close-button" onClick={this.handleCloseBreakdown}>
                    <span className="close-icon text-white" aria-hidden="true">&times;</span>
                  </div>
                  <h4 className="text-white d-flex justify-content-between w-100">
                    <span>{barMonthTitle} Breakdown</span>
                    <span>{barMonthTotal}</span>
                  </h4>
                </div>

                <div className="forecast-breakdown-wrapper">
                  <div className="forecast-breakdown">
                    <div className="ticker-row">
                      <div className="ticker-col">Ticker</div>
                      <div className="ticker-col">Ex-Dividend Date</div>
                      <div className="ticker-col">Payment Date</div>
                      <div className="ticker-col">Income</div>
                    </div>
                    {barMonthTickers.map(({ ticker, income, projectedDates }) => {
                      const projectedExDate = projectedDates.map(projection => projection.exDate);
                      const projectedPaymentDate = projectedDates.map(projection => projection.paymentDate);
                      return (
                      <div key={ticker} className="ticker-row">
                        <div className="ticker-col">{ticker}</div>
                        <div className="ticker-col text-left">{this.renderDate(projectedExDate)}</div>
                        <div className="ticker-col text-left">{this.renderDate(projectedPaymentDate)}</div>
                        <div className="ticker-col text-right">{income}</div>
                      </div>
                      )
                    })}
                    <div className="ticker-row">
                      <div className="ticker-col forecast-disclaimer"><span>*</span>Estimated Ex-Dividend/Payment dates.</div>
                    </div>
                  </div>
                </div>
              </ReactCSSTransitionGroup>
            </div>
          )}

        </div>
      </div>
    );
  }
}

export default Forecast;
