export const SETTINGS = {
  BASIC_PLAN_ID: 0, // this info is default value, not connecting w/ infusionsoft
  ONBOARDING: {
    SIGNUP_PAGE: 0,
    PLAN_PAGE: 1,
    ACCOUNT_PAGE: 2,
    WELCOME_PAGE: 3,
    COMPLETE: 4,
  },
  PLAN_OPTIONS: {
    MONTHLY: 'Monthly',
    ANNUAL: 'Annual',
  },
  NAVBAR: {
    PORTFOLIO: 'My Portfolio',
    THE_DIVIDEND_HUNTER: 'The Dividend Hunter',
    SHOW_PROGRESS: 'showProgress',
    IMPORT_TRANSACTIONS: 'importTransactions',
    HIDE_NAVBAR: 'hideNavBar',
  },
  PRIVACY_POLICY_URL: 'https://www.investorsalley.com/terms-of-use/#privacy',
  SUPPORT_EMAIL: 'help@investorsalley.com',
  PROFILE_DROPDOWN_OPTIONS: [
    { text: 'Make Selection', label: 'How much do you have invested?', key: 'OPT1', menu: { OPT1_01: 'Less than $50,000', OPT1_02: '$50,000-$100,000', OPT1_03: '$100,000 or more' } },
    { label: 'Experience Level', key: 'OPT2', menu: { OPT2_01: 'Beginner', OPT2_02: 'Intermediate', OPT2_03: 'Advanced' } },
    { label: 'Primary reason for investing', key: 'OPT3', menu: { OPT3_01: 'Income', OPT3_02: 'Wealth Creation', OPT3_03: 'Other' }, inputValue: '' },
  ],
  NOTIFICATION_OPTIONS: [
    { key: 'NOTIF01', title: 'Ex-dividend notification', body: 'We\'ll notify you when a company in one of your portfolios announces their next ex-dividend date', premium: false },
    { key: 'NOTIF02', title: 'Dividend Payment Notification (Premium Only)', body: 'We\'ll notify you when a company in one of your portfolios has paid a dividend', premium: true },
    { key: 'NOTIF03', title: 'Weekly Portfolio Valuation (Premium Only)', body: 'We\'ll send you your updated portfolio valuation each week', premium: true },
  ],
  NOTIFICATION_COMBINE: 
    { key: 'NOTIF-COMBINE', title: 'Combine notifications into weekly digest (Premium Only)', body: 'Weekly notification digest to be sent out on Wednesday Mornings at 8am EST', premium: true }
};

export const INFO_BOX_TYPE = {
  ERROR: 1,
  INFO: 2,
  SUCCESS: 3,
};

export const PARSE_CONFIGURATION = {
  PRODUCT_IDS: 'INFUSIONSOFT_PRODUCTS_IDS',
  SUBSCRIPTION_IDS: 'INFUSIONSOFT_SUBSCRIPTION_IDS',
  TAGS_IDS: 'INFUSIONSOFT_TAG_IDS',
  FULL_STORY_ID: 'FS_ORG_ID',
};

export const VALID_GREEN = '#00D97E';
export const INVALID_RED = '#E63757';
export const INPUT_TYPE = {
  EMAIL: 'email',
  PASSWORD: 'password',
  SEARCH: 'search',
  TELEPHONE: 'tel',
  TEXT: 'text',
  URL: 'url',
  TIME: 'time',
};
export const INPUT_VALIDATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  NONE: '',
}