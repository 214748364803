import React, { Component } from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import _ from 'lodash';

class InvestmentInstitution extends Component {
  static propTypes = {
    activeInstitution: PropTypes.object,
    callConnectInstitution: PropTypes.func.isRequired,
    callDeleteFunction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  state = {
    modalLoading: false,
  }

  onCallConnectInstitution = async () => {
    try {
      const { callConnectInstitution } = this.props;
      this.setState({ modalLoading: true });
      await callConnectInstitution();
      this.setState({ modalLoading: false });
    } catch (e) {
      throw e;
    }
  };

  // Dev use only: forefully expires the token of the active institution in order to access the re-update authentication flow. - EY
  onResetAccessCode = async () => {
    const { activeInstitution } = this.props;
    try {
      await Parse.Cloud.run("resetInstitutionAccessCode", { id: activeInstitution.id });
      console.log('Successfully reset access code for institution id: ', activeInstitution.id);
    } catch (e) {
      console.error(`Error resetting access code for institution id: ${activeInstitution.id}. Error: `, e);
    }

  }

  render() {
    const { modalLoading } = this.state;
    const { activeInstitution, callDeleteFunction, loading } = this.props;

    if (_.isEmpty(activeInstitution)) {
      return null;
    }

    return (
      <div className="m-2">
        <div>
          <section className="modal-body">
          <p className="lead">This will import your current portfolio from your connected {activeInstitution.name} account. This will only import a snapshot of your current portfolio and no transaction history. Proceed?</p>
            <button type="button" className="btn btn-primary mr-auto" onClick={this.onCallConnectInstitution} disabled={loading || modalLoading}>Import transactions</button>
          </section>
        </div>
        <div className="modal-footer pb-0 pt-3">
          <button type="button" className="btn btn-outline-dark mr-auto" data-dismiss="modal">Cancel</button>
          <button data-dismiss="modal" onClick={callDeleteFunction} id="delete-email-button" type="button" className="btn btn-danger" disabled={loading || modalLoading}>Disconnect Account</button>
        </div>

        {/* Dev Use Only: resetting institution access token -- https://plaid.com/docs/api/sandbox/#sandboxitemreset_login */}
        {/* <button onClick={this.onResetAccessCode}>Reset Access Token</button> */}
      </div>
    );
  }
}

export default InvestmentInstitution;
