
// for Numbers with decimal and validates up to Xth digits after decimal:
export const fourDecimalPlaces = /^\d+(\.\d{0,4})?$/;
export const twoDecimalPlaces = /^\d+(\.\d{0,2})?$/;

// for (Number).toFixed(num) [type: String] that requires commans at every thousandths place:
export const commaSeparatorReg = /(\d)(?=(\d{3})+(?!\d))/g;

// export const checkPasswordFormat = /(?=^.{8,}$)(?=.*\d)(?=.*\W+)(?![.\n]).*$/
// above checks minimum 8 chars, one special char (@!#$%), and at least one number
// Do Not Delete -- in case they want to change the requirements back from min 8 chars to this

export const checkPasswordFormat = /^.{8,}$/
export const checkEmailFormat = /^\w+[\w-\.\+]*\@\w+((-\w+)|(\w*))\.[a-z]{1,3}$/ // eslint-disable-line