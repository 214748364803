import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { PlaidLink } from "react-plaid-link";

import { plaidLinkStyle } from "../pages/portfolio/NoTransaction";

class UpdateInstitution extends Component {
  static propTypes = {
    linkToken: PropTypes.string,
    activeInstitution: PropTypes.object,
    onDeleteInstitution: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  }

  render() {
    const { activeInstitution, linkToken, loading, onCloseModal, onDeleteInstitution, onUpdateSuccess } = this.props;

    if (isEmpty(activeInstitution)) {
      // TODO: Implement display for empty state.
      return null;
    }

    return (
      <div className="m-2">
        <section className="modal-body">
          <p className="lead">Your {activeInstitution.name} authentication has expired. Please reconnect your account to continue importing your transactions.</p>
        </section>

        <div className="modal-footer pb-0 pt-3">
          <button type="button" className="btn btn-danger mr-auto" disabled={loading} onClick={onDeleteInstitution}>Remove Account</button>
          { linkToken && (
            <>
              <PlaidLink
                token={linkToken}
                onSuccess={onUpdateSuccess}
                onExit={() => onCloseModal("#update-institution")}
                style={plaidLinkStyle}
              >
              Reconnect Account
              </PlaidLink>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default UpdateInstitution;
