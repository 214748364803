import Parse from 'parse';

// Transaction Object //
// user: Pointer
// symbol: String
// quantity: Number
// price: Number
// date: Date
// transactionType: String
// portfolio: Pointer

const Transaction = Parse.Object.extend('Transaction');
const Portfolio = Parse.Object.extend('Portfolio');

export async function updateQuantity(transactionId, quantity) {
  const query = new Parse.Query(Transaction);
  try {
    const transaction = await query.get(transactionId);
    return transaction.save({ quantity });
  } catch (err) {
    throw err;
  }
}

export async function deleteTransactionsBySymbol(symbol, portfolioId, isTdh) {
  let currentUser = Parse.User.current();
  const email = currentUser.get('email');
  const isAdmin = await Parse.Cloud.run('isAdmin', { email });
  try {
    if (isAdmin && isTdh) {
      currentUser = await Parse.Cloud.run('getTDHAdminUser');
    }
    const portfolio = new Portfolio();
    portfolio.id = portfolioId;

    const transactions = await new Parse.Query(Transaction).equalTo('portfolio', portfolio).equalTo('symbol', symbol).equalTo('user', currentUser).find();
    return await Parse.Object.destroyAll(transactions);
  } catch (err) {
    throw err;
  }
}

export async function deleteTransactionsByIds(idArray) {
  const txToDelete = [];
  try {
    idArray.forEach(id => {
      const transaction = new Transaction();
      transaction.id = id;
      txToDelete.push(transaction);
    });
    return await Parse.Object.destroyAll(txToDelete);
  } catch (err) {
    throw err;
  }
}

export async function deleteTransactionsByPortfolioId(portfolioId) {
  const currentUser = Parse.User.current();
  const portfolio = new Portfolio();
  portfolio.id = portfolioId;
  const transactions = await new Parse.Query(Transaction).equalTo('portfolio', portfolio).equalTo('user', currentUser).limit(10000).find();
  return await Parse.Object.destroyAll(transactions);
}