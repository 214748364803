import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DeleteConfirmationModal extends Component {
  static propTypes = {
    typeToDelete: PropTypes.string,
    callDeleteFunction: PropTypes.func.isRequired,
    copyInsertion: PropTypes.shape({
      text: PropTypes.string,
      button: PropTypes.string,
    }),
  }

  static defaultProps = {
  }

  render() {
    const { typeToDelete, callDeleteFunction, copyInsertion } = this.props;

    return (
      <div className="m-2">
        <div className="text-center">
          <div className="modal-header p-1">
            <h2>Are you sure?</h2>
          </div>
          <section className="modal-body d-flex justify-content-start ">
            <p className="pt-2 display-4">{ copyInsertion ? copyInsertion.text : `This will delete the ${typeToDelete} and can't be undone.` }</p>
          </section>
        </div>
        <div className="modal-footer pb-0 pt-3">
          <button type="button" className="btn btn-outline-dark mr-auto" data-dismiss="modal">Cancel</button>
          <button data-dismiss="modal" onClick={() => callDeleteFunction()} id="delete-email-button" type="button" className="btn btn-danger">{ copyInsertion ? copyInsertion.button : `Yes, Delete ${typeToDelete}` }</button>
        </div>
      </div>
    );
  }
}

export default DeleteConfirmationModal;
