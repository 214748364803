export const CYCLE = {
  MONTHLY: 'MONTH',
  YEARLY: 'YEAR',
}

export const PATHS = {
  SIGNUP: 'signup',
  PLANS: 'plans',
  ACCOUNT: 'account',
  COMPLETE: 'complete',
}