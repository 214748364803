import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { INFO_BOX_TYPE } from '../constants';

// todo - migrate ErrorBox to InfoBox - nan
class InfoBox extends Component {
    static propTypes = {
      infoMsg: PropTypes.string,
      type: PropTypes.number, // note: the setting is in constants
    }

    static defaultProps = {
      infoMsg: null,
      type: INFO_BOX_TYPE.ERROR,
    }

    getDisplayType() {
      const { type } = this.props;
      switch (type) {
        case INFO_BOX_TYPE.INFO:
          return 'primary';
        case INFO_BOX_TYPE.SUCCESS:
          return 'success';
        default: 
          return 'danger';
      }
    }

    render() {
      const { infoMsg } = this.props;
      return infoMsg ? (
        <div className={`alert alert-${this.getDisplayType()}`} role="alert">
          { infoMsg }
        </div>
      ) : null;
    }
}

export default InfoBox;
