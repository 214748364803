import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ErrorBox from '../../components/ErrorBox';
import ButtonGroup from '../../components/ButtonGroup';
import Loader from '../../components/Loader';

import { SETTINGS } from '../../constants';
import { getParseConfiguration } from '../../utils/configuration';
import { withGA, eventUserSelectsPlan } from '../../utils/googleAnalytics';
import './PlansPage.css';

class PlansPage extends Component {
    static propTypes = {
      userInfo: PropTypes.object,
      setPage: PropTypes.func.isRequired,
      setSelectedPlan: PropTypes.func.isRequired,
    }

    static defaultProps = {
      userInfo: {},
    }

    constructor(props) {
      super(props);

      this.state = {
        errorMessage: null,
        acceptOffer: false,
        planType: {
          name: SETTINGS.PLAN_OPTIONS.ANNUAL,
        },
        buttons: [
          { name: SETTINGS.PLAN_OPTIONS.MONTHLY, active: false },
          { name: SETTINGS.PLAN_OPTIONS.ANNUAL, active: true },
        ],
      };
    }

    handleButtonGroupClick = (input) => {
      let { buttons } = this.state;
      buttons = [
        { name: SETTINGS.PLAN_OPTIONS.MONTHLY, active: (input.name === SETTINGS.PLAN_OPTIONS.MONTHLY) },
        { name: SETTINGS.PLAN_OPTIONS.ANNUAL, active: (input.name === SETTINGS.PLAN_OPTIONS.ANNUAL) },
      ];
      this.setState({ planType: input, buttons });
    }

    selectPlanClick = async(plan) => {
      const { planType } = this.state;
      eventUserSelectsPlan(plan.name, planType.name);
      const planUrl = await this.getOrderForm();
      window.location.replace(planUrl);
    }

    // TODO: remove this method and account page
    // keep this for now for referencing
    // the upsale model will need to be enabled later in the future
    // submitPlan(acceptOffer) {
    //   const { setPage, setSelectedPlan } = this.props;
    //   let { selectedPlan } = this.props;
    //   selectedPlan.freeTrial = acceptOffer;
    //   $('#upsellModal').modal('hide');
    //   setSelectedPlan(selectedPlan);
    //   setPage(SETTINGS.ONBOARDING.ACCOUNT_PAGE);
    // }

    async getOrderForm() {
      const { planCode, userEmail } = this.props;
      const { planType } = this.state;
      const planUrl = await getParseConfiguration('PLAN_URL');
      
      if (planCode) {
        return `${planUrl}-${planType.name.toLowerCase()}-${planCode}?email=${encodeURIComponent(userEmail)}`;
      } else {
        return `${planUrl}-${planType.name.toLowerCase()}?email=${encodeURIComponent(userEmail)}`;
      }
    }

    render() {
      const { planType, buttons, errorMessage } = this.state;
      const { userInfo, plans, loadingPlan } = this.props;
      const headerMsg = (userInfo && userInfo.given_name) ? `Welcome ${userInfo.given_name}! Get started today to begin maximizing the income potential of your investment portfolio.` : 'Get started today to begin maximizing the income potential of your investment portfolio.';
      const tdhUser = userInfo && userInfo.isTdhUser;
      const monthlyOption = planType.name === SETTINGS.PLAN_OPTIONS.MONTHLY;
      const cyclePlanDescription = monthlyOption ? 'per MONTH' : 'per YEAR';
      return (
        <div>
          <ErrorBox errorMsg={errorMessage} />
          <div className="pt-5 bg-dark bg-ellipses" style={{ paddingBottom: "8.5rem" }}>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-8 col-xl-9">
                  <img src="/img/divcaster-logo-white.svg" alt="Divcaster by Investors Alley" style={{margin: '0 auto 40px auto', display: 'block'}}/>
                  { tdhUser && (
                    <div className="alert alert-primary mb-5" role="alert">
                      <h4 className="alert-heading">Great News!</h4>
                      Your account will include special features for being a subscriber of <strong>The Dividend Hunter</strong>.
                    </div>
                  ) }
                  { userInfo && userInfo.existingCustomer && (
                    <div className="alert alert-primary mb-5" role="alert">
                      <h4 className="alert-heading m-0 text-center">Thanks for being an Investors Alley customer!</h4>
                    </div>
                  ) }
                  <h1 className="display-1 text-center text-white">
                    {headerMsg}
                  </h1>
                  {/* <p className="text-center text-white" style={{ fontSize: '1.25rem' }}>
                    We have both monthly and annual plan options, so you can choose whichever fits you best. Not ready to purchase? Try out a Basic membership for free!
                  </p> */}
                  <p className="text-center text-white" style={{ fontSize: '1.25rem' }}>
                    We offer monthly and annual billing so you can choose whichever fits you best.
                  </p>
                  <div className="d-flex flex-column align-items-center mt-3">
                    <ButtonGroup buttons={buttons} dashClass="btn-white" clickAction={this.handleButtonGroupClick} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row justify-content-center mt--7">

              {/* ODT-398 - Hide Basic Plan for production first phase */}
              {/* <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-uppercase text-center text-muted mb-4 plan-title">Basic Plan</h6>
                    <div className="row no-gutters align-items-center justify-content-center">
                      <div className="col-auto">
                        <div className="h2 mb-0">$</div>
                      </div>
                      <div className="col-auto">
                        <div className="display-2 mb-0">0</div>
                      </div>
                    </div>
                    <div className="h6 text-uppercase text-center text-muted mb-5">
                      Forever
                    </div>
                    <button className="btn btn-block btn-primary mb-2 plan-button" onClick={ () => this.selectPlanClick(plans.BASIC_PLAN) }>Start with Basic</button>
                    <div className="mb-3">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Dividend Forecaster</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Ex. Dividend Notifications</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>What-if Scenarios</small> <small>1 scenario</small>
                        </li>
                      </ul>
                      { tdhUser && (
                      <p className="lead mt-4">+ Access to <strong>The Dividend Hunter</strong></p>) }
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    {/* <h6 className="text-uppercase text-center text-muted mb-4 plan-title">Premium Plan <span className="badge badge-soft-primary">Most Popular!</span></h6> */}
                    
                    <div className="row no-gutters align-items-center justify-content-center">
                      { loadingPlan ?  (<Loader />) :
                        <Fragment>
                          <div className="col-auto">
                            <div className="h2 mb-0">$</div>
                          </div>
                          <div className="col-auto">
                            <div className="display-2 mb-0">{ monthlyOption ? plans.PREMIUM_PLAN.subscriptions.monthly.plan_price : plans.PREMIUM_PLAN.subscriptions.annual.plan_price }</div>
                          </div>
                        </Fragment>
                      }
                    </div>
                    
                    <div className="h6 text-uppercase text-center text-muted mb-5">
                      { cyclePlanDescription }
                    </div>
                    <button className="btn btn-block btn-primary mb-2 plan-button" onClick={ this.selectPlanClick }>Click to Buy Now</button>
                    <div className="mb-3">
                      <ul className="list-group list-group-flush">
                        <h3 className="border-0 d-flex justify-content-center mt-3">
                          Divcaster Features:
                        </h3>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Dividend Forecaster</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Ex. Dividend Notifications</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>What-if Scenarios</small> <small>Unlimited</small>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Automatic Transaction Import</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Historical Income Data</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Portfolio Value Notifications</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Dividend Payout Notifications</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Weekly Email Digest</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                      </ul>
                      { tdhUser && (
                      <p className="lead mt-4">+ Access to <strong>The Dividend Hunter</strong></p>) }
                    </div>
                  </div>
                </div>
              </div>

              {/* ODT-398 - Hide Bundle Plan for production first phase */}
              {/* { !tdhUser && (<div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="text-uppercase text-center text-muted mb-4 plan-title">Premium + The Dividend Hunter</h6>
                    <div className="row no-gutters align-items-center justify-content-center">
                      <div className="col-auto">
                        <div className="h2 mb-0">$</div>
                      </div>
                      <div className="col-auto">
                        <div className="display-2 mb-0">{ monthlyOption ? plans.BUNDLE_PLAN.subscriptions.monthly.plan_price : plans.BUNDLE_PLAN.subscriptions.annual.plan_price }</div>
                      </div>
                    </div>
                    <div className="h6 text-uppercase text-center text-muted mb-5">
                      { cyclePlanDescription }
                    </div>
                    <button className="btn btn-block btn-primary mb-2 plan-button" onClick={ () => this.selectPlanClick(plans.BUNDLE_PLAN) }>Premium + Div. Hunter</button>
                    <div className="mb-3">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                          <small>Everything included in Premium Plan</small> <i className="fe fe-check-circle text-success"></i>
                        </li>
                      </ul>
                      <p className="lead mt-4">+ Access to <strong>The Dividend Hunter</strong></p>
                      <p>A complete income investing system featuring over 20 top high-yield stocks to buy today, monthly issues packed with market research and education, and regular live training sessions. (Normally ${tdhSubscriptionPrice}/year)</p>
                    </div>
                  </div>
                </div>
              </div>) } */}

            </div>
          </div>

          {/* UPSALE MODAL COMPONENT */}
          {/* <Modal modalId="upsellModal">

            <div className="upsell-modal-container">
              <h1>Try The Dividend Hunter for Free!</h1>
              <p className="lead">Get access to <strong>The Dividend Hunter</strong>, our premier high-yield income investing portfolio free for 3-months and then just ${tdhPlanPrice} for the first year.</p>
              <div className="mt-5">
                <Button dashClass="btn-lg btn-block" name="Yes, take advantage of this special offer!" clickAction={() => { this.submitPlan(true); }} />
                <div className="mt-3 text-center">
                  <p><ActionLink text="No thanks" clickAction={() => { this.submitPlan(false); }} /></p>
                </div>
              </div>
            </div>
          </Modal> */}

        </div>
      );
    }
  }

  export default withGA(withRouter(PlansPage));
