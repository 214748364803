import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class Tabs extends Component {
  static propTypes = {
    defaultActiveTabIndex: PropTypes.number.isRequired,
    passedDownTab: PropTypes.number,
    history: PropTypes.object.isRequired,
    children: PropTypes.array,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    dashClass: PropTypes.string,
    imageSrc: PropTypes.string,
    imageHeight: PropTypes.string,
  }

  static defaultProps = {
    children: [],
    passedDownTab: null,
    title: '',
    dashClass: '',
    imageSrc: '',
    imageHeight: '',
  }

  constructor(props) {
    super(props);
    const { defaultActiveTabIndex } = this.props;
    this.state = {
      activeTabIndex: defaultActiveTabIndex,
    };
  }

  componentDidUpdate(prevProp) {
    const { passedDownTab } = this.props;
    if (prevProp.passedDownTab !== passedDownTab) {
      this.setState({ activeTabIndex: passedDownTab });
    }
  }

  handleTabClick = (tabIndex, queryString) => {
    const { history } = this.props;
    this.setState({ activeTabIndex: tabIndex });
    localStorage.setItem('activeTab', tabIndex);
    history.push(queryString);
  }

  renderChildrenWithTabsApiAsProps() {
    const { activeTabIndex } = this.state;
    const { children } = this.props;
    return React.Children.map(children, (child, index) => React.cloneElement(child, {
      onClick: () => this.handleTabClick(index, child.props.queryString),
      tabIndex: index,
      isActive: index === activeTabIndex,
    }));
  }

  renderActiveTabContent() {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
    return '';
  }

  render() {
    const { title, dashClass, imageSrc, imageHeight } = this.props;
    return (
      <div>
        <div className="header m-0">
          <div className={`header-body ${dashClass}`}>
            <div className="row align-items-end">
              {
                title && !imageSrc ?
                <div className="col">
                  <h1 className="header-title">{title}</h1>
                </div> : ''
              }
              {
                imageSrc ?
                <div className="col">
                  <h1 className="header-title">
                    <img src={imageSrc} height={imageHeight} alt={title ? title : ''}/>
                  </h1>
                </div> : ''
              }
              <div className="col-auto">
                <ul className="nav nav-tabs header-tabs">
                  {this.renderChildrenWithTabsApiAsProps()}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.renderActiveTabContent()}
        </div>
      </div>
    );
  }
}

export default withRouter(Tabs);
