import Parse from 'parse';

let tdh = null;

export default async function getTDH (subclassFormat) {
    if (subclassFormat) {
        return await Parse.Cloud.run('getTDH', { subclassFormat: true });
    }

    if (!tdh) {
        tdh = Parse.Cloud.run('getTDH');
    }

    return await tdh;
}