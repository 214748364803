import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import _ from 'lodash';
import { NavLink, withRouter, Link } from 'react-router-dom';

import IconDropdown from './IconDropdown';
import Notification from './Notification';

import './NavBar.css';
import { SETTINGS } from '../constants';

const SHOW_PROGRESS_BAR = {
  '/signup' : true,
  '/signup/plans': true,
  '/signup/account': true,
  '/signup/complete': true,
}

const SHOW_NAV_BAR = {
  '/' : true,
  '/notifications': true,
  '/portfolio/TDH': true,
  '/portfolio': true,
  '/account': true,
  '/account/settings': true,
  '/account/profile': true,
  '/account/billing': true,
  '/account/notifications': true,
  '/support': true,
  '/reports': true,
  '/reports/': true,
  '/reports/mine': true,
  '/reports/all': true,
  '/reports/generate': true,
  '/reports/stats': true,
  '/plans': true,
}

class NavBar extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    percentage: PropTypes.number.isRequired,
    notifications: PropTypes.array,
    setNotificationStatus: PropTypes.func,
    notificationsSeen: PropTypes.bool,
    userIsAdmin: PropTypes.bool,
    toggleImportReset: PropTypes.func,
    tagIds: PropTypes.object,
  };

  static defaultProps = {
    notifications: [],
    setNotificationStatus: () => {},
    toggleImportReset: () => {},
    notificationsSeen: false,
    tagIds: {},
  };

  constructor(props) {
    super(props);
    const { history } = this.props;
    this.state = {
      navStatus: null,
      dropDownOption: {
        'My Account': () => { history.push('/account/settings'); },
        'Change Plan': () => { history.push('/account/billing'); },
        Support: () => { window.location.replace('https://support.divcaster.com/'); },
        'Log Out': () => this.logOut(),
      }
    };
  }

  componentWillMount() {
    const { history } = this.props;
    // initial checking
    this.setNavStatus(history.location.pathname);
    this.unlisten = history.listen(() => {
      this.setNavStatus(history.location.pathname);
    });
  }

  componentWillUnmount() {
    // unlistens to event to prevent memory leak on component unmount
    // https://www.npmjs.com/package/history#usage
    this.unlisten();
  }

  componentWillReceiveProps(nextProps) {
    const { userIsAdmin } = nextProps;
    const { dropDownOption } = this.state;

    if (userIsAdmin) {
      delete dropDownOption['Change Plan'];
      this.setState({ dropDownOption });
    }
  }

  setNavStatus(pathName) {
    if (SHOW_PROGRESS_BAR[pathName]) {
      this.setState({ navStatus: SETTINGS.NAVBAR.SHOW_PROGRESS });
    } else if (SHOW_NAV_BAR[pathName]) {
      this.setState({ navStatus: SETTINGS.NAVBAR.SHOW_NAVBAR });
    } else if (pathName === '/import-transactions') {
      this.setState({ navStatus: SETTINGS.NAVBAR.IMPORT_TRANSACTIONS });
    } else if (pathName.split("/ticker").length > 1) {
      this.setState({ navStatus: SETTINGS.NAVBAR.SHOW_NAVBAR });
    } else {
      this.setState({ navStatus: null });
    }
  }

  mainLogo(className) {
    let classes = className;
    return (
      <NavLink className={`navbar-brand ${classes ? classes : ''}`} to="/">
        <img src="/img/divcaster-logo.svg" alt="logo" width="144" />
      </NavLink>
    );
  }

  renderUserControl = (notifications, setNotificationStatus, notificationsSeen) => {
    const { userIsAdmin, isPremium } = this.props;
    const { dropDownOption } = this.state;

    return (
      <div className="d-flex right-icons dropdown-container order-lg-4 float-right align-items-center">

        {/* Admin Reports */}
        {userIsAdmin && (
          <Fragment>
             <ul className="navbar-nav">
                <li className="nav-item mr-4 my-auto">
                  <NavLink className="nav-link p-0" to="/reports/">Reports</NavLink>
                </li>
                <li className="nav-item mr-4 my-auto">
                  <NavLink className="nav-link p-0" to="/plans">Plans</NavLink>
                </li>
              </ul>
          </Fragment>
        )}

        {/* Notification */}
        <div className="dropdown mr-4">
          <button className="text-muted border-0 p-0 bg-trans" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className={`icon ${!notificationsSeen ? 'active' : ''}`}>
              <i className="fe fe-bell bg-trans" style={{ cursor: 'pointer' }}></i>
            </span>
          </button>
          <div id="notification-menu" className="dropdown-menu dropdown-menu-card dropdown-menu-right dropdown-wide bg-light">
            <div className="card-header bg-white">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="m-0">Notifications</h4>
                </div>
                <div className="col-auto text-right small">
                  <Link to="/notifications" onClick={() => setNotificationStatus(true)}>View All</Link>
                </div>
              </div>
            </div>
            <div className="notification-container">
              <ul className="notification-list">
                { _.isEmpty(notifications) ? <li>No New Notifications</li> :
                  notifications.map(notification =>
                    <Notification key={notification.id} notification={notification} />
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* My Account */}
          <div>
            { isPremium && !userIsAdmin ? (<span className="badge badge-primary mr-3">Premium</span>) : <React.Fragment /> }
            { userIsAdmin && (<span className="badge badge-success mr-3">Admin</span>) }
            <IconDropdown
              options={dropDownOption}
              iconClass="fe fe-user avatar-title"
              />
          </div>
        </div>
      )
    }

    standardNavBar(userControlBar, navState) {
      const { isTDH } = this.props;
      if (navState === SETTINGS.NAVBAR.IMPORT_TRANSACTIONS) {
        return (
          <nav className="navbar navbar-light">
            {this.mainLogo()}
            <NavLink className="btn btn-sm btn-white" onClick={() => this._clearCsvImport()} to="/portfolio">
              Cancel Import
            </NavLink>
          </nav>
        );
      }
      return (
        <nav className="navbar navbar-expand-lg navbar-light px-3">
          <div className="navbar-toggler">
            <button className="btn btn-secondary mr-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fe fe-menu"/>
            </button>
          </div>
          {this.mainLogo('order-lg-first mr-3')}
          {userControlBar}
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink exact className="nav-link" to="/portfolio">
                  My Portfolio
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio/TDH">
                  The Dividend Hunter
                  { !isTDH ? <span className="fe fe-lock ml-2" style={{transform: 'translateY(1px)'}}/> : null }
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      );
    }

    logOut = async () => {
      const { history, userIsAdmin } = this.props;
      await Parse.User.logOut();
      if (userIsAdmin) {
        history.push('/phinaz');
      } else {
        history.push('/login');
      }
      sessionStorage.clear();
    }

    _clearCsvImport = () => {
      const { toggleImportReset } = this.props;
      toggleImportReset();
    }

    render() {
      const { navStatus } = this.state;
      const { SHOW_PROGRESS, SHOW_NAVBAR, IMPORT_TRANSACTIONS } = SETTINGS.NAVBAR;
      const { percentage, notifications, setNotificationStatus, notificationsSeen, history } = this.props;
      const userControlBar = (navStatus === SHOW_NAVBAR) && this.renderUserControl(notifications, () => setNotificationStatus(true), notificationsSeen, history);
      // The route for the dividend hunter will change -- just a placeholder for now
      // Bootstrap handles the active class based off of those routes
      const navBar = (navStatus === SHOW_PROGRESS) ? (
        <div style={{padding: '24px', backgroundColor: 'white'}}>
          <div className="progress-container d-flex flex-row mx-auto align-items-center">
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${percentage}%` }}
                aria-valuenow={percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                />
            </div>
            <h4 className="my-0 ml-3" style={{whiteSpace: 'nowrap'}}>{percentage}% Complete</h4>
          </div>
        </div>
      ) : this.standardNavBar(userControlBar, navStatus);
      return ((navStatus === SHOW_PROGRESS && !!percentage) || (navStatus === SHOW_NAVBAR) || (navStatus === IMPORT_TRANSACTIONS)) && (
        <div className="navbar-wrapper">
          {navBar}
        </div>
      );
    }
  }

  export default withRouter(NavBar);
