import React, { Component } from 'react';
import Parse from 'parse';

export class RedirectFromResetPassword extends Component {
  async componentDidMount() {
    const { history } = this.props;
    const url = new URL(window.location.href);
    const username = url.searchParams.get('username') || url.searchParams.get('useremail');
    const userIsAdmin = await Parse.Cloud.run('isAdmin', { email: username });
    if (userIsAdmin) {
      history.push('/phinaz?success=reset-password');
    } else {
      history.push('/login?success=reset-password');
    }
  }

  render () {
    return (
      <React.Fragment />
    )
  }
}
export class RedirectFromVerifyEmail extends Component {
  async componentDidMount() {
    const { history } = this.props;
    const url = new URL(window.location.href);
    const username = url.searchParams.get('username') || url.searchParams.get('useremail');
    const userIsAdmin = await Parse.Cloud.run('isAdmin', { email: username });
    if (userIsAdmin) {
      history.push("/phinaz?success=verify-email");
    } else {
      history.push("/login?success=verify-email");
    }
  }

  render () {
    return (
      <React.Fragment />
    )
  }
}

