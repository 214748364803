import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { find } from 'lodash';

import 'react-select2-wrapper/css/select2.css';

import Modal from '../containers/Modal';
import InputField from './InputField';
import Dropdown from './Dropdown';

import './ReportModal.css';

export default class ReportModal extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.string,
    tagIds: PropTypes.object,
    type: PropTypes.oneOf(['USER_LIST', 'USER_PORTFOLIO', 'HOLDINGS', 'TDH']).isRequired,

    // NOTE: single - select 1 user and is required
    // NOTE: multiple - select 0 or more users
    selectUser: PropTypes.oneOf(['single', 'multiple',]),
    selectCompany: PropTypes.bool,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    tagIds: {},
    selectUser: null,
    selectCompany: false,
    onSubmit: () => {}
  }

  static ACCOUNT_TYPES = {
    'All Account Types': '',
    'Basic Users': 'BASIC_PLAN',
    'Premium Users': 'PREMIUM_PLAN',
  }

  state = {
    accountType: '',
    name: '',
    users: [],
    selectedUsers: [],
    selectedCompany: [],
    companyTypeaheadLoading: false,
    companyTypeaheadOptions: [],
    manualFilter: false,
  }

  componentDidMount() {
    this.updateUsers();
  }

  async updateUsers(tagId) {
    const query = new Parse.Query(Parse.User);

    if (tagId) {
      query.containsAll('plans', [tagId]);
    }

    const users = await query.find();
    this.setState({
      users,
    });
  }

  setAccountType(type) {
    const { ACCOUNT_TYPES } = ReportModal;
    const accountType = ACCOUNT_TYPES[type];
    const tagId = this.props.tagIds[accountType] || 0;

    this.setState({
      accountType: accountType,
    });

    this.updateUsers(tagId);
  }

  setFields = async (data) => {

    this.setState({
      accountType: data.accountType || '',
      name: data.name || '',
      selectedUsers: data.selectedUsers || [],
      selectedCompany: [],
    });

    const selectedCompany = data.selectedCompany || [];
    const query = (selectedCompany[0] || '').trim().toUpperCase();
    if (query) {
      this.setState({
        companyTypeaheadLoading: true,
      });

      const result = await Parse.Cloud.run('getSymbolLookup', { query });
      const selectedCompany = find(result, ({ticker}) => ticker === query);

      this.setState({
        companyTypeaheadLoading: false,
        companyTypeaheadOptions: result,
        selectedCompany: selectedCompany ? [selectedCompany] : [],
      });
    }
  }

  resetFields = () => {
    this.setState({
      accountType: '',
      name: '',
      selectedUsers: [],
      selectedCompany: [],
    });
  }

  onSubmit = (e) => {

    const { type, selectUser, selectCompany } = this.props;
    const { name, accountType, selectedUsers, selectedCompany } = this.state;

    e.preventDefault();
    e.stopPropagation();

    const data = {
      type,
      name,
      userSegment: accountType,
    };

    if (selectUser) {
      data.users = selectedUsers;
    }

    if (selectCompany) {
      data.symbols = selectedCompany.map(({ ticker }) => ticker);
    }

    this.resetFields();
    this.closeModal();

    this.props.onSubmit(data);
  }

  // handling search with one key and w/ extra space
  _filterBy = (option, props) => {
    return (option.name.toLowerCase().indexOf(props.text.trim().toLowerCase()) !== -1 ||
    option.symbol.toLowerCase().indexOf(props.text.trim().toLowerCase()) !== -1)
  }

  onCompanySearch = async (query) => {
    this.setState({companyTypeaheadLoading: true, manualFilter: (query.trim().length === 1) }); // manualFilter is using for search w/ one character + space
    const result = await Parse.Cloud.run('getSymbolLookup', { query });
    this.setState({companyTypeaheadLoading: false, companyTypeaheadOptions: result});
  }

  onCompanyChange = async (company) => {
    await this.setState({ selectedCompany: company });
  }

  addSelectedUser = (user) => {
    const { selectedUsers } = this.state;

    if (this.props.selectUser === 'multiple') {
      const newSelectedUsers = [...selectedUsers];
      const index = newSelectedUsers.indexOf(user);

      if (index === -1) {
        newSelectedUsers.push(user);

        this.setState({
          selectedUsers: newSelectedUsers,
        });
      }
    } else {
      this.setState({
        selectedUsers: [user],
      });
    }
  }

  removeSelectedUser = (user) => {
    const { selectedUsers } = this.state;

    const newSelectedUsers = [...selectedUsers];
    const index = newSelectedUsers.indexOf(user);

    if (index !== -1) {
      newSelectedUsers.splice(newSelectedUsers.indexOf(user), 1);

      this.setState({
        selectedUsers: newSelectedUsers,
      })
    }
  }

  openModal = () => {
    $(`#${this.props.id}`).modal('show');
  }

  closeModal = () => {
    $(`#${this.props.id}`).modal('hide');
  }

  render() {
    const { ACCOUNT_TYPES } = ReportModal;
    const { id, header, selectCompany, selectUser } = this.props;
    const { name, users, accountType, selectedUsers, selectedCompany,
      companyTypeaheadLoading, companyTypeaheadOptions, manualFilter } = this.state;

    const reversedAccountTypes = {};
    for (var key in ACCOUNT_TYPES) {
      reversedAccountTypes[ACCOUNT_TYPES[key]] = key;
    }

    return (
      <Modal modalId={id} header={header}>
        <form className="form" onSubmit={this.onSubmit}>
          <div className="form-group">
            <InputField id="form-control-id" label="Name" required={true} type="text"
              controlled={true} value={name} onInputChange={name => this.setState({ name })}  />
          </div>
          <div className="form-group">
            <Dropdown
              ref={ref => this._segment}
              id="user-list-user-segment"
              label="User Segment"
              text={reversedAccountTypes[accountType]}
              menu={Object.keys(ACCOUNT_TYPES)}
              clickAction={(text) => this.setAccountType(text)} />
          </div>
          {selectUser && (
            <div className="form-group">
              <label>User</label>
              <Select2
                name="users"
                multiple={selectUser === 'multiple'}
                data={users.map(user => ({ id: user.id, text: user.get('username') }))}
                value={selectedUsers}
                required={selectUser === 'single'}
                options={{
                  placeholder: "Make selection",
                  allowClear: true,
                  tags: false,
                  dropdownParent: `#${id}`,
                }}
                onUnselect={e => this.removeSelectedUser(e.target.value)}
                onSelect={e => this.addSelectedUser(e.target.value)}
              />
              {selectUser === 'multiple' && (
                <div className="text-muted small mx-3 my-2">If no user is selected, all users will be exported</div>
              )}
            </div>
          )}
          {selectCompany && (
            <div className="form-group">
              <label htmlFor="ticker-symbol">Company</label>
              <AsyncTypeahead
                isLoading={companyTypeaheadLoading}
                options={companyTypeaheadOptions}
                minLength={1}
                id="ticker-symbol"
                ref={ref => this._typeahead = ref}
                labelKey={option => `${option.name} (${option.symbol})`}
                filterBy={ manualFilter ? this._filterBy : ["name", "symbol"]}
                placeholder="Search by company name or symbol..."
                selected={selectedCompany}
                onSearch={this.onCompanySearch}
                onChange={this.onCompanyChange}
                renderMenuItemChildren={option => (
                  <span key={option.symbol}>{option.name} ({option.symbol})</span>
                )}
              />
              <div className="text-muted small mx-3 my-2">If no company is selected, all holdings will be exported</div>
            </div>
          )}
          <div className="row justify-content-end mt-5">
            <div className="col-auto">
              <button className="btn btn-link" data-dismiss="modal" onClick={this.resetFields}>Cancel</button>
              <button className="btn btn-primary" type="submit">Generate</button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}