import React, { Component } from 'react';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import { filter } from 'lodash';

import Toast from '../../components/Toast';
import CheckboxItem from '../../components/CheckboxItem';
import { SETTINGS, ACCOUNT_NOTIFICATIONS_SETTINGS_TITLE } from '../../constants';
import { withGA } from '../../utils/googleAnalytics';

const UserSettings = Parse.Object.extend('UserSettings');
class AccountNotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationSettings: {},
      userSettings: {},
      toast: null,
    };
  }

  componentDidMount() {
    this.getUserSettings().then(() => {
      if (this.state.userSettings && Object.keys(this.state.userSettings).length > 0) {
        this.setState({ notificationSettings: this.state.userSettings.attributes.notificationSettings || {} });
      }
    });
    document.title = ACCOUNT_NOTIFICATIONS_SETTINGS_TITLE;
  }

  handleChange = async (e, key) => {
    const { notificationSettings } = this.state;
    if (e.target.checked && key !== SETTINGS.NOTIFICATION_COMBINE.key) {
      notificationSettings[key] = true;
    } else if (key === SETTINGS.NOTIFICATION_COMBINE.key && e.target.checked) {
      notificationSettings[key] = true;
    } else {
      notificationSettings[key] = false;
    }
    this.updateNotificationSettings(notificationSettings);
    this.setState({ notificationSettings, toast: { type: 'success', message: 'Saved' } });
    setTimeout(() => this.hideNotification(), 2500);
  }

  hideNotification = () => {
    this.setState({ toast: null });
  }

  createUserSettings = async (notifications) => {
    const userSettings = new UserSettings();
    const currentUser = Parse.User.current();
    userSettings.setACL(new Parse.ACL(currentUser));
    try {
      await userSettings.save({ user: currentUser, notificationSettings: notifications });
      this.getUserSettings();
    } catch (err) {
      //TODO come back to complete error handling
      console.log('Error: ', err);
    }
  }

  updateNotificationSettings = async (notifications) => {
    const currentUser = Parse.User.current();
    const query = new Parse.Query('UserSettings');
    query.equalTo('user', currentUser);
    try {
      const result = await query.first();
      if (!result) {
        this.createUserSettings(notifications);
      }
      result.save({ notificationSettings: notifications });
      this.getUserSettings();
    } catch (err) {
      // TODO come back to complete error handling
      console.log('Error: ', err);
    }
  }

  getUserSettings = async () => {
    const currentUser = Parse.User.current();
    const query = new Parse.Query('UserSettings');
    query.equalTo('user', currentUser);
    try {
      const result = await query.first();
      this.setState({ userSettings: result });
    } catch (err) {
      console.log('Error: ', err);
    }
  }

  render() {
    const { onPremiumPlan } = this.props;
    const { notificationSettings, toast } = this.state;
    return (
      <div className="container mt-5 p-0">
        <div className="row">
          <div className="col-8">
            {
              SETTINGS.NOTIFICATION_OPTIONS.map((obj) => {
                const { title, key, body, premium } = obj;
                return (
                  <div key={key} className="mb-5 ml-4">
                    <CheckboxItem
                      title={title}
                      body={body}
                      id={key}
                      key={key}
                      onChangeAction={(e) => { this.handleChange(e, key); }}
                      checked={notificationSettings ? notificationSettings[key] : false}
                      disabled={!onPremiumPlan && premium}
                    />
                </div>
                );
              })
            }
          </div>
          <div className="col">
            <div className="alert alert-white py-4 border" style={{marginTop: '2em'}}>
              <h3><i className="fe fe-info mr-3 d-inline-block" style={{transform: 'translateY(1px)'}}></i>Notifications</h3>
              <small>
                <p className="text-muted mb-0">You can change what notifications you'd like us to send to your email here. We&apos;ll still send you important updates regarding your account and security though.</p>
              </small>
            </div>
          </div>
        </div>

        <hr className="mt--1 mb-5"/>

        <div className="mb-5 ml-4">
          <CheckboxItem
            id={SETTINGS.NOTIFICATION_COMBINE.key}
            title={SETTINGS.NOTIFICATION_COMBINE.title}
            body={SETTINGS.NOTIFICATION_COMBINE.body}
            disabled={!onPremiumPlan || !filter(notificationSettings, (v, k) => k === SETTINGS.NOTIFICATION_COMBINE.key ? false : v).length}
            checked={notificationSettings && filter(notificationSettings, (v, k) => k === SETTINGS.NOTIFICATION_COMBINE.key ? false : v).length ? notificationSettings[SETTINGS.NOTIFICATION_COMBINE.key] : false}
            onChangeAction={onPremiumPlan ? (e) => { this.handleChange(e, SETTINGS.NOTIFICATION_COMBINE.key); } : null }
            />
        </div>
        <Toast notification={toast} onClose={() => this.hideNotification()} />
      </div>
    );
  }
}

export default withGA(withRouter(AccountNotificationSettings));
