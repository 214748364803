import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import './Toast.css';

class ToastInner extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: () => {},
  };

  render() {
    const { type, message, onClose } = this.props;
    const alertType = type === 'error' ? 'danger' : type;
    return (
      <div className="toast-container fixed-bottom d-flex flex-row justify-content-end mr-5">
        <div className={`alert alert-${alertType} toast alert-dismissible fade show`} role="alert">
          <span>{message}</span>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => onClose()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

class Toast extends React.Component {
  static propTypes = {
    notification: PropTypes.object,
  }

  static defaultProps = {
    notification: null,
  }

  render() {
    const { notification, onClose } = this.props;

    return (
      <ReactCSSTransitionGroup
        transitionName="toast"
        transitionEnterTimeout={250}
        transitionLeaveTimeout={300}
      >
        { notification
          ? (
            <ToastInner {...notification} onClose={onClose} />
          )
          : null }
      </ReactCSSTransitionGroup>
    );
  }
}

export default Toast;
