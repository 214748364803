// portfolio page: transaction & ticker object keys
export const SYMBOL = 'symbol';
export const COMPANY = 'companyName';
export const LAST_PRICE_PER_SHARE ='lastPricePerShare';
export const PREVIOUS_DELTA = 'percentageDeltaPrevious';
export const TICKER_WEIGHT = 'weightOfTickerInPortfolio';
export const DATE = 'date';
export const TOTAL_QUANTITY = 'totalQuantity';
export const OPEN_QUANTITY = 'openQuantity';
export const CLOSE_QUANTITY = 'closedQuantity';
export const AVERAGE = 'averageCostPerShare';
export const OPEN_COST = 'openCost';
export const CLOSE_COST = 'closeCost';
export const VALUE = 'totalMarketValue';
export const CLOSE_VALUE = 'closeValue';
export const GAIN_LOSS = 'unrealizedGain';
export const DIVIDENDS = 'dividendsReceived';
export const RETURN = 'totalReturn';
export const YIELD_COST = 'yieldOnCost';
export const DIV_YIELD = 'dividendYield';
export const ANNUAL_DIV = 'indicatedAnnualDividend';
export const OPEN_DATE = 'openDate';
export const CLOSE_DATE = 'closeDate';
export const CLOSE_GAIN = 'closeGain';
export const CLOSE_GAIN_DOLLARS = 'closeGainDollars';
export const OPEN_DIVIDENDS = 'openDividendsReceived';
export const CLOSE_DIVIDENDS = 'closeDividendsReceived';
export const OPEN = 'open';
export const CLOSED = 'closed';
export const SUSPENDED = 'suspended';

// portfolio page: historical chart
export const HISTORY_RANGE = {
  R0: { text: 'Current Year', value: 0 },
  R1: { text: 'Last Year', value: 1 },
  R2: { text: 'Last 2 Years', value: 2 },
  R3: { text: 'Last 3 Years', value: 3 },
  R4: { text: 'Last 4 Years', value: 4 },
  R5: { text: 'Last 5 Years', value: 5 },
};
export const CHART_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const CHART_MONTHS_BY_QUARTER = [
  [CHART_MONTHS[0], CHART_MONTHS[1], CHART_MONTHS[2]],
  [CHART_MONTHS[3], CHART_MONTHS[4], CHART_MONTHS[5]],
  [CHART_MONTHS[6], CHART_MONTHS[7], CHART_MONTHS[8]],
  [CHART_MONTHS[9], CHART_MONTHS[10], CHART_MONTHS[11]],
]
export const HISTORY_QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];
export const FORCAST_DEFAULT_COLOR = '#0DD9D9';
export const FORCAST_ACTIVE_COLOR = '#417BDC';
export const HISTORY_COLOR_BANK = [ // max of 6 years display total
  '#7F9CFF',
  '#69E9D8',
  '#76E2EF',
  '#FFD158',
  '#F1465A',
  '#D4FA74'
];
export const SCENARIO_COLOR_BANK = [ // max of 3 scenarios total
  '#7F9CFF',
  '#FFD158',
  '#F1465A',
]
export const BREAKDOWN_DEFAULT_COLORS = [
  FORCAST_DEFAULT_COLOR,
  ...SCENARIO_COLOR_BANK,
];
export const CHART_INTERVAL = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
}
export const CHART_FORMAT = {
  SUMMARY: 'Summary',
  BREAKDOWN: 'Breakdown',
  COMBINED: 'Combined',
}
export const TX_LIMIT_PER_PORTFOLIO = 3000;

// import csv page: mandatory columns name display
export const SYMBOL_NAME = 'Company Symbol';
export const DATE_NAME = 'Purchase Date';
export const QUANTITY_NAME = 'Purchase Qty';
export const PRICE_NAME = 'Price';
export const TRANSACTION_TYPE_NAME = 'Transaction Type';
export const TRANSACTION_COST_NAME = 'Commissions and Fees';

export const SYMBOL_KEY = SYMBOL;
export const DATE_KEY = DATE;
export const QUANTITY_KEY = 'quantity';
export const PRICE_KEY = 'price';
export const TRANSACTION_TYPE_KEY = 'transactionType';
export const TRANSACTION_COST_KEY = 'transactionCost';

export const IMPORT_COLUMNS = [
  { name: SYMBOL_NAME, key: SYMBOL_KEY },
  { name: DATE_NAME, key: DATE_KEY },
  { name: QUANTITY_NAME, key: QUANTITY_KEY },
  { name: PRICE_NAME, key: PRICE_KEY },
  { name: TRANSACTION_TYPE_NAME, key: TRANSACTION_TYPE_KEY },
  { name: TRANSACTION_COST_NAME, key: TRANSACTION_COST_KEY },
];

// import csv page: data type check
export const GOOD = 'Good';
export const ERR = 'Error';
export const WARNING = 'Warning';

// import csv page: date locale options
export const EN = 'en';
export const EN_GB = 'en_GB';
export const EN_FORMAT = 'MM/DD/YYYY';
export const EN_GB_FORMAT = 'DD/MM/YYYY';

// import csv page: import result
export const IMPORT_TRANSASTIONS_SUCCESS = 'Successfully imported CSV file';

// side modal
export const PORTFOLIO_NAME_MAX_LENGTH = 25;
export const PORTFOLIO_NAME_TOOLTIP_MAX_LENGTH = 15;

// table
export const TABLE_COLUMNS = [
  { name: 'Companies', key: COMPANY, hidden: false },
  { name: 'Qty', key: OPEN_QUANTITY, hidden: false },
  { name: 'Avg Cost Per Share', key: AVERAGE, hidden: false },
  { name: 'Dividend Yield', key: DIV_YIELD, hidden: false },
  { name: 'Return', key: RETURN, hidden: false },
  { name: 'Total Income Earned', key: OPEN_DIVIDENDS, hidden: false },
  { name: 'Yield on Cost', key: YIELD_COST, hidden: false },
  { name: 'Annual Dividend', key: ANNUAL_DIV, hidden: false },
  { name: 'Cost', key: OPEN_COST, hidden: false },
  { name: 'Value', key: VALUE, hidden: false },
  { name: 'Gain/Loss', key: GAIN_LOSS, hidden: false },
];
export const TABLE_COLUMNS_CLOSED = [
  { name: 'Companies', key: COMPANY, hidden: false },
  { name: 'Qty', key: CLOSE_QUANTITY, hidden: false },
  { name: 'Open Date', key: OPEN_DATE, hidden: false },
  { name: 'Cost', key: CLOSE_COST, hidden: false },
  { name: 'Close Date', key: CLOSE_DATE, hidden: false },
  { name: 'Value', key: CLOSE_VALUE, hidden: false },
  { name: 'Total Income Earned', key: CLOSE_DIVIDENDS, hidden: false },
  { name: 'Gain($)', key: CLOSE_GAIN_DOLLARS, hidden: false },
  { name: 'Gain(%)', key: CLOSE_GAIN, hidden: false },
];
export const TABLE_COLUMNS_TDH = [
  { name: 'Companies', key: COMPANY },
  { name: 'Dividend Yield', key: DIV_YIELD },
  { name: 'Annual Dividend', key: ANNUAL_DIV },
  { name: 'Avg Cost Per Share', key: AVERAGE },
  { name: 'Qty', key: TOTAL_QUANTITY },
  { name: 'Cost', key: OPEN_COST },
]