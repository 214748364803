import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Card.css';
// Card's props.children html elements can take the following className:
// card-title, card-subtitle, card-text.

class Card extends Component {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
    dashColor: PropTypes.string, // primary, secondary, success, danger, warning, info, light, dark
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]),
  }

  static defaultProps = {
    title: '',
    subtitle: '',
    className: '',
    children: null,
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { title, subtitle, children, className, dashColor } = this.props;
    return (
      <div className={`card mb-0 align-self-start ${className} ${dashColor ? `bg-${dashColor}` : '' }`}>
        <div className="card-body">
          { title ? <h5 className="card-title mb-3">{title}</h5> : null }
          { subtitle ? <h6 className="card-subtitle mb-2 text-muted mb-2">{subtitle}</h6> : null }
          <div className="card-text">
            { children }
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
