import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import InputField from '../components/InputField';
import SummaryItem from '../components/SummaryItem';
import { fourDecimalPlaces } from '../utils/regex';
import { NEW_QTY, QTY, QTY_ABBREVIATED, TICKER_OBJECT } from '../constants';
import './ScenarioSnapShot.css';

class ScenarioSnapShot extends Component {
  static propTypes = {
    className: PropTypes.string,
    tableClass: PropTypes.string,
    tHeader: PropTypes.array.isRequired,
    entries: PropTypes.array.isRequired,
    updateSmallTableQty: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    tableClass: '',
    quantity: '',
    tHeader: [],
    updateSmallTableQty: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderHeader = arr => (
    <thead>
      <tr>
        {arr.map((name, i) => (
            <th scope="col" key={i}>{name}</th>
        ))}
      </tr>
    </thead>
  )

  updateQty (value, id, index) {
    const { updateSmallTableQty } = this.props;
    if (!value || fourDecimalPlaces.test(value)) {
      updateSmallTableQty(value, id, index);
    }
  }

  displayEditableContent = (obj, key, i) => {
    let value = obj[key];

    if (key === QTY_ABBREVIATED || key === NEW_QTY) {
      // convert from input string to number and parse float again to get rid of extra 0
      if (value && !isNaN(value)) {
        value = parseFloat(parseFloat(value).toFixed(4)); 
        value = value.toString(); // convert to string for controlled type input field as 'text'
      }
    }
    
    const { id } = obj;
    if (key === TICKER_OBJECT) {
      return (
        <td key={key+id} className="border-light align-items-center" >
          <span className="d-flex text-left align-items-center mr-3">
            <SummaryItem
              heading={value.company}
              tickerSymbol={value.symbol}
              hidePriceData={true}
            />
          </span>
        </td>
      );
    } else if ((key === NEW_QTY || key === QTY)) {
      return (
        <td key={key+id} className="border-light align-middle py-0" style={{maxWidth: '80px'}}>
          <InputField
            id={id}
            type="text"
            controlled
            placeholder="qty"
            value={value || '0'}
            stylingClasses="text-right"
            onInputChange={(value) => this.updateQty(value, obj.id, i)}
            autoFocus={i===0}
            />
        </td>
      );
    }
    else {
      return (
        <td key={key+id} className="border-light align-middle">
          <div className={key === NEW_QTY ? 'input-display' : ''}>{value}</div>
        </td>
      );
    }
  }

  render() {
    const { tableClass, entries, tHeader, className } = this.props;
    const objectKeys = !_.isEmpty(entries) && Object.keys(entries[0]).filter(name => name !== 'id');

    return (
      <table className={`table ${tableClass || 'table-sm'} ${className}`} style={{marginBottom: 0}}>
        { this.renderHeader(tHeader) }
        <tbody>
          {
            entries.map((entry, i) => (
              <tr key={i} className="text-center">
                { objectKeys.map(objectKey => (this.displayEditableContent(entry, objectKey, i))) }
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}

export default ScenarioSnapShot;
