import React, { Component } from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withGA } from '../../utils/googleAnalytics';
import MetaTags from 'react-meta-tags';

import InputField from '../../components/InputField';
import Button from '../../components/Button';
import ErrorBox from '../../components/ErrorBox';
import InfoBox from '../../components/InfoBox';
import ActionLink from '../../components/ActionLink';
import { RESET_PASSWORD_TITLE } from '../../constants';

import { INFO_BOX_TYPE } from '../../constants';

class ResetPassword extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
      errorMsg: '',
      infoMsg: '',
    };
  }

  resetPasswordForUser = async (e, email) => {
    e.preventDefault();
    const { updateUserRole } = this.props;
    try {
      await Parse.User.requestPasswordReset(email.toLowerCase());
      const userIsAdmin = await Parse.Cloud.run('isAdmin', { email });
      if (userIsAdmin) {
        updateUserRole(true);
        this.props.history.push('/phinaz?success=reset-password-sent');
      } else {
        updateUserRole(false);
        this.props.history.push('/login?success=reset-password-sent');
      }
    } catch (error) {
      this.setState({ errorMsg: error.message });
      console.log(error);
    }
  }

  render() {
    const { buttonDisabled, errorMsg, infoMsg } = this.state;
    const { history } = this.props;
    return (
      <div className="auth-wrapper d-flex align-items-center bg-auth border-top border-top-2 border-primary">
        <div>
          <MetaTags>
            <title>{RESET_PASSWORD_TITLE}</title>
            <meta name="og:title" property="og:title" id="og-title" content={RESET_PASSWORD_TITLE} />
            <meta name="apple-mobile-web-app-title" id="apple-title" content={RESET_PASSWORD_TITLE} />
          </MetaTags>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 col-xl-4 my-5">
              <div className="card">
                <div className="card-header">
                  <img src="/img/divcaster-logo.svg" alt="Divcaster by Investors Alley" style={{width: 144, margin: 0, maxWidth: 144}}/>
                </div>
                <div className="card-body">
                  <h2 className="muted-text mt--2 mb-3">
                    Forgot your password?
                  </h2>
                  <p className="">That's okay, it happens. Enter your Divcaster account email below to reset it.</p>
                  <form onSubmit={(e) => { this.resetPasswordForUser(e, this.email); }}>
                    <ErrorBox errorMsg={errorMsg} />
                    <InfoBox infoMsg={infoMsg} type={INFO_BOX_TYPE.SUCCESS} />
                    <div className="form-group mb-3">
                      <InputField
                        required
                        id="email"
                        type="email"
                        label=""
                        placeholder="Enter your email..."
                        inputValue={(input) => { this.email = input; }}
                        />
                    </div>
                    <Button type="submit" name="Reset Password" dashColor="primary btn-block" disabled={buttonDisabled} />
                  </form>
                </div>
                <div className="card-footer bordered">
                  <small className="d-flex flex-row justify-content-center">
                    Return to&nbsp;<ActionLink text="Login" clickAction={() => { history.push('login'); }} />
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withGA(withRouter(ResetPassword));
