import React, { Component } from 'react';
import _ from 'lodash';
import Parse from 'parse';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { commaSeparatorReg } from '../../utils/regex';
import { currencyFormatter, currencyWithWords } from '../../utils/numberFormat';
import Chart from '../../components/Chart';
import RedirectMessage from '../../components/RedirectMessage';
import {
  FREQUENCY
} from '../../constants';
import Loader from '../../components/Loader';

//todo move to utils to
function toDecimalWithCommas(value, places = 2) {
  return (value || 0).toFixed(places).replace(commaSeparatorReg, '$1,');
}

class TickerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickerContent: {},
      loading: false,
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            { ticks: { callback: (value) => `$${value}` } }
          ],
          xAxes: [{
            offset: true,
            ticks: {
              autoSkip: true
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItems, data) => {
              return `$${toDecimalWithCommas(tooltipItems.yLabel, 2)}`;
            },
            title: function(tooltipItems, data) {
              return tooltipItems.map(tooltip => {
                return `${data.labels[tooltip.index]}`;
              });
            },
          },
        },
      },
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const ticker = _.get(this.props, 'computedMatch.params.symbol');
    if (ticker) {
      const tickerContent = await Parse.Cloud.run('getTickerContent', { ticker });
      this.setState({ tickerContent });
    }
    this.setState({ loading: false })
  }

  _getAverageDividendGrowthRateBySpan = (span) => {
    const { tickerContent } = this.state;
    const targetYearGrowth = _.get(tickerContent, 'averageDividendGrowthRates', {})[span];
    if (!targetYearGrowth) return "N/A";
    const thisYearDivAverage = _.get(tickerContent, 'averageDividendGrowthRates', {})[0];
    const dividendGrowth = (thisYearDivAverage / targetYearGrowth) ** (1 / span) - 1;
    if (dividendGrowth) {
      return `${(dividendGrowth * 100).toFixed(2)}%`;
    }
    return "N/A";
  }

  renderDataSummaryItem = ({ title, value }) => {
    return (
      <div className="d-flex flex-column align-items-center p-4" key={title}>
        <span>{title}</span>
        <span>{value}</span>
      </div>
    )
  }

  renderDataChartBlock = ({ title, subtitle, dataCards, chartComponent }) => {
    const chunkedDataCards = _.chunk(dataCards, 3);
    return (
      <div className="container">
        <div className="card mt-3 d-flex flex-direction-column justify-content-center align-items-center">
          <h1 className="pt-5">{title}</h1>
          <h4 className="align-self-center text-center text-secondary">{subtitle}</h4>
          {
            chunkedDataCards.map((chunk, i) => (
              <div className="d-flex flex-row justify-content-around container-fluid" key={i}>
                {chunk.map(this.renderDataSummaryItem)}
              </div>
            ))
          }
          <div className="pb-4">
            {chartComponent}
          </div>
        </div>
      </div>
    )
  }

  renderFormattedNumberOrDefault = (value, defaultValue) => {
    return !_.isNil(value) ? value.toFixed(2) : defaultValue;
  }

  renderDefaultForNull = (value, defaultValue) => {
    return _.isNil(value) ? defaultValue : value;
  }

  render() {
    const tickerSymbol = _.get(this.props, 'match.params.symbol');
    const pageTitle = `Ticker Content | ${tickerSymbol}`;
    const { tickerContent, options, loading } = this.state;
    const dividendGrowthChartData = _.get(tickerContent, 'dividendGrowthChartData', {});
    const paymentScheduleChartData = _.get(tickerContent, 'paymentScheduleChartData', {});
    if (_.isEmpty(tickerContent) && !loading) {
      return (
        <RedirectMessage
          title="No Ticker Content"
          subtitle="Oops! There is currently no data for this ticker"
          text="Please return to your portfolio"
          buttonText="Return to Portfolio"
          link="/portfolio"
        />
      )
    }

    if (loading) {
      return (
        <div className="d-flex absolute-center">
          <Loader />
        </div>
      )
    }

    return (
      <React.Fragment>
        <MetaTags>
          <title>{pageTitle}</title>
          <meta name="og:title" property="og:title" id="og-title" content={pageTitle} />
          <meta name="apple-mobile-web-app-title" id="apple-title" content={pageTitle} />
        </MetaTags>
        <a href="/#" onClick={this.props.history.goBack} className="cursor-pointer breadcrumb ml-4"> ← Go Back</a>
        {this.renderDataChartBlock({
          title: `${_.get(tickerContent, 'name')}: ${tickerSymbol}`,
          subtitle: "This page is still under development. Please note, if you come across any features or data that is incorrect please email Jared at Jared.nations@investorsalley.com.",
          dataCards: [
            { title: "Dividend Yield", value: `${(_.get(tickerContent, 'dividendYield', 0) * 100).toFixed(2)}%` },
            { title: "Dividend Safety", value: "TBD" }, // TODO Add once dividend safety feature is complete
            { title: "Dividend Growth", value: `${this._getAverageDividendGrowthRateBySpan(1)} Last Year` },
            { title: "Price", value: `${currencyFormatter.format(_.get(tickerContent, 'closingPrice'))}`},
            { title: "Market Cap", value: `${_.get(tickerContent, 'marketcap') ? currencyWithWords(_.get(tickerContent, 'marketcap')) : "N/A"}`},
            { title: "Beta", value: this.renderFormattedNumberOrDefault(_.get(tickerContent, 'beta'), "N/A")},
          ],
          chartComponent: null
        })}

        {this.renderDataChartBlock({
          title: "Dividend Growth",
          dataCards: [
            { title: "Last Year", value: `${this._getAverageDividendGrowthRateBySpan(1)}` },
            { title: "Last 5 Years", value: `${this._getAverageDividendGrowthRateBySpan(5)}` },
            { title: "Last 10 Years", value: `${this._getAverageDividendGrowthRateBySpan(10)}` },
          ],
          chartComponent: _.isEmpty(dividendGrowthChartData) ? (
            <h5>No Dividend Growth Data to Display</h5>
          ) : (
            <Chart
              ref={ref => this._dividendChart = ref && ref.chart}
              type="line"
              labels={Object.keys(dividendGrowthChartData)}
              datasets={[{
                label: 'Dividend Growth',
                fill: false,
                data: Object.values(dividendGrowthChartData),
                pointBorderWidth: 0,
                pointRadius: 0,
              }]}
              options={options}
            />
          )
        })}

        {this.renderDataChartBlock({
          title: "Payment Schedule",
          dataCards: [
            { title: "Frequency:", value: FREQUENCY[`${_.get(tickerContent, 'frequency', 0)}`] },
            { title: "Rate:", value: `$${this.renderFormattedNumberOrDefault(_.get(tickerContent, 'rate'), 'N/A')}` },
            { title: "Ex Dividend Date:", value: `${this.renderDefaultForNull(_.get(tickerContent, 'exDividendDate'), "N/A")}` },
            { title: "Payment Date:", value: `${this.renderDefaultForNull(_.get(tickerContent, 'paymentDate'), "N/A") }` },
          ],
          chartComponent: _.isEmpty(paymentScheduleChartData) ? (
            <h5>No Payment Data to Display</h5>
          ) : (
            <Chart
              ref={ref => this._paymentChart = ref && ref.chart}
              type="bar"
              labels={paymentScheduleChartData.map(data => data.paymentDate)}
              datasets={[{
                label: 'Payment Schedule',
                fill: false,
                data: paymentScheduleChartData.map(data => data.price),
                pointBorderWidth: 0,
                pointRadius: 0,
              }]}
              options={options}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

export default withRouter(TickerContent);