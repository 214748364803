import Parse from 'parse';
import _ from 'lodash';
import { eventTDHCustomizedFirstTime } from '../utils/googleAnalytics';
import getTDH from '../utils/getTDH';
// Portfolio Object //
// name: String
// user: Pointer
// isDefault: Boolean

const Portfolio = Parse.Object.extend('Portfolio');
const Transaction = Parse.Object.extend('Transaction');
const Scenario = Parse.Object.extend('Scenario');
const ScenarioItem = Parse.Object.extend('ScenarioItem');

// helper function
const _formatPortfolioData = (parseObject) => {
  return {
  id: parseObject.id,
  updatedAt: parseObject.updatedAt,
  createdAt: parseObject.createdAt,
  user: parseObject.get('user'),
  name: parseObject.get('name'),
  isDefault: parseObject.get('isDefault'),
  trackedOnGA: parseObject.get('trackedOnGA'),
  investmentInstitutionId: _.get(parseObject.get('investmentInstitution'), 'id'),
  plaidLinkUpdatedAt: parseObject.get('plaidLinkUpdatedAt'),
}};

export async function createPortfolio(name, isDefault=false) {
  try {
    const portfolio = new Portfolio();
    const currentUser = Parse.User.current();
    const userId = currentUser.id;
    const nameExists = await Parse.Cloud.run('nameExists', { name, userId });
    if (nameExists) {
      throw new Parse.Error(422, 'Portfolio name must be unique')
    }
    portfolio.setACL(new Parse.ACL(currentUser)); // Set read/write permission to current user only:
    const result = await portfolio.save({ user: currentUser, name, isDefault });
    const response = _formatPortfolioData(result);
    return response;
  } catch (err) {
    throw err;
  }
}

export async function getPortfolios() {
  const query = new Parse.Query(Portfolio);
  const currentUser = Parse.User.current();
  query.equalTo('user', currentUser);
  query.ascending('name');
  try {
    const results = await query.find();
    const portfolios = results.map(result => _formatPortfolioData(result));
    return portfolios;
  } catch (err) {
    throw err;
  }
}

export async function renamePortfolio(portfolioId, name) {
  const query = new Parse.Query(Portfolio);
  try {
    const portfolio = await query.get(portfolioId);
    await portfolio.save({ name });
    const renamedPortfolio = _formatPortfolioData(portfolio);
    return renamedPortfolio;
  } catch (err) {
    throw err;
  }
}

export async function setDefaultPortfolio(portfolioId) {
  const query = new Parse.Query(Portfolio);
  const currentUser = Parse.User.current();
  try {
    // Set default for selected portfolio
    const defaultPortfolio = await query.get(portfolioId);
    await defaultPortfolio.save({ isDefault: true });
    // Unset default for other portfolios
    query.equalTo('user', currentUser);
    query.equalTo('isDefault', true);
    query.notEqualTo('objectId', portfolioId);
    const notDefault = await query.find();
    notDefault.forEach(result => result && result.save({ isDefault: false }));
    return;
  } catch (err) {
    throw err;
  }
}

const _processTransactionTDH = (parseObjects, currentUser, clonedPortfolio) => {
  const clonedTransactions = [];
  parseObjects.forEach(object => {
    const symbol = object.symbol;
    const quantity = object.quantity;
    const price = object.price;
    const date = new Date();
    const transactionType = 'BUY';
    const transactionCost = object.transactionCost;
    const transactionObj = new Transaction();
    transactionObj.setACL(new Parse.ACL(currentUser));
    transactionObj.set('user', currentUser);
    transactionObj.set('symbol', symbol);
    transactionObj.set('quantity', quantity);
    transactionObj.set('price', price);
    transactionObj.set('date', date);
    transactionObj.set('transactionType', transactionType);
    transactionObj.set('transactionCost', transactionCost);
    transactionObj.set('portfolio', clonedPortfolio);

    clonedTransactions.push(transactionObj);
  })
  return clonedTransactions;
}

const _processTransaction = (parseObjects, currentUser, clonedPortfolio) => {
  const clonedTransactions = [];
  parseObjects.forEach(object => {
    const symbol = object.get('symbol');
    const quantity = object.get('quantity');
    const price = object.get('price');
    const date = object.get('date');
    const transactionType = object.get('transactionType');
    const transactionCost = object.get('transactionCost');
    const transactionObj = new Transaction();
    transactionObj.setACL(new Parse.ACL(currentUser));
    transactionObj.set('user', currentUser);
    transactionObj.set('symbol', symbol);
    transactionObj.set('quantity', quantity);
    transactionObj.set('price', price);
    transactionObj.set('date', date);
    transactionObj.set('transactionType', transactionType);
    transactionObj.set('transactionCost', transactionCost);
    transactionObj.set('portfolio', clonedPortfolio);

    clonedTransactions.push(transactionObj);
  })
  return clonedTransactions;
}


export async function duplicatePortfolio(portfolioId, tdhTxToClone) {
  const currentUser = Parse.User.current();
  const userId = currentUser.id;
  const portfolioQuery = new Parse.Query(Portfolio);
  const transactionQuery = new Parse.Query(Transaction);
  const scenarioQuery = new Parse.Query(Scenario);
  const scenarioItemQuery = new Parse.Query(ScenarioItem);

  let clonedPortfolio, name, originalPortfolio;

  try {
    // clone portfolio
    const portfolio = new Portfolio();
    const tdh = await getTDH(true);
    const isTDH = tdh.id === portfolioId;
    if (isTDH) {
      originalPortfolio = tdh;
    } else {
      originalPortfolio = await portfolioQuery.get(portfolioId);
    }
    portfolio.setACL(new Parse.ACL(currentUser));

    // Increment copy number if user wants multiple copies
    let copyNumber = 1;
    let nameExists = false;
    name = `Copy ${copyNumber++} of ${originalPortfolio.get('name')}`;

    while (!nameExists) {
      nameExists = await Parse.Cloud.run('nameExists', { name, userId });
      if (nameExists){
        nameExists = false;
        name = `Copy ${copyNumber++} of ${originalPortfolio.get('name')}`;
      } else {
        nameExists = true;
      }
    }
    clonedPortfolio = await portfolio.save({ name, user: currentUser, isDefault: false, trackedOnGA: !isTDH });

    // clone transactions within that portfolio
    let clonedTransactions;
    if (isTDH) {
      clonedTransactions = _processTransactionTDH(tdhTxToClone, currentUser, clonedPortfolio);
    } else {
      transactionQuery.equalTo('portfolio', originalPortfolio);
      const transactions = await transactionQuery.limit(10000).find();
      clonedTransactions = _processTransaction(transactions, currentUser, clonedPortfolio);
    }

    await Parse.Object.saveAll(clonedTransactions);
    // clone scenarios
    scenarioQuery.equalTo('portfolio', originalPortfolio);
    const scenarios = await scenarioQuery.find();
    scenarios.forEach(async scenario => {
      const scenarioName = scenario.get('name');
      const plotIndex = scenario.get('plotIndex');
      const scenarioItemsArray = [];

      const scenarioObj = new Scenario();
      scenarioObj.setACL(new Parse.ACL(currentUser));
      const clonedScenario = await scenarioObj.save({
        user: currentUser,
        name: scenarioName,
        portfolio: clonedPortfolio,
        plotIndex: plotIndex }
      );
      // clone scenariosItems within each scenario
      scenarioItemQuery.equalTo('scenario', scenario);
      const scenarioItems = await scenarioItemQuery.find();
      scenarioItems.forEach(scenarioItem => {
        const symbol = scenarioItem.get('symbol');
        const quantity = scenarioItem.get('quantity');
        const scenarioItemObj = new ScenarioItem();
        scenarioItemObj.setACL(new Parse.ACL(currentUser));
        scenarioItemObj.set('user', currentUser);
        scenarioItemObj.set('symbol', symbol);
        scenarioItemObj.set('quantity', quantity);
        scenarioItemObj.set('scenario', clonedScenario);

        scenarioItemsArray.push(scenarioItemObj);
      });
      await Parse.Object.saveAll(scenarioItemsArray);
    });
    return _formatPortfolioData(clonedPortfolio);
  } catch (err) {
    throw err;
  }
}

export async function deletePortfolio(portfolioId) {
  const portfolio = new Portfolio();
  portfolio.id = portfolioId;
  return await portfolio.destroy();
}

export async function getDefaultPortfolio() {
  const firstQuery = new Parse.Query(Portfolio);
  const currentUser = Parse.User.current();
  try {
    firstQuery.equalTo('isDefault', true);
    firstQuery.equalTo('user', currentUser);
    let result = await firstQuery.first();

    if (_.isEmpty(result)) {
      // fail safe: if there is no default portfolio found,
      // query for the next portfolio and set that portfolio as default
      const secondQuery = new Parse.Query(Portfolio);
      secondQuery.equalTo('user', currentUser);
      result = await secondQuery.first();
      // make it's to be default portfolio
      await result.save({ isDefault: true });
    };
    const portfolio = _formatPortfolioData(result);
    return portfolio;
  } catch (err) {
    throw err;
  }
}

export async function getPortfolio(id) {
  const portfolioQuery = new Parse.Query(Portfolio);
  const currentUser = Parse.User.current();
  try {
    portfolioQuery.equalTo('objectId', id);
    portfolioQuery.equalTo('user', currentUser);
    const portfolioRes = await portfolioQuery.first();
    if (_.isEmpty(portfolioRes)) return {}; // This line can be removed once we create a portfolio for new users on signup.

    const portfolio = _formatPortfolioData(portfolioRes);
    return portfolio;
  } catch (err) {
    throw err;
  }
}

export async function getFirstPortfolio() {
  const query = new Parse.Query(Portfolio);
  const currentUser = Parse.User.current();
  try {
    query.equalTo('user', currentUser);
    return await query.first();
  } catch (err) {
    throw err;
  }
}

export async function markAsTrackedOnGA(portfolioId) {
  const portfolio = await new Parse.Query(Portfolio).get(portfolioId);
  eventTDHCustomizedFirstTime();
  return await portfolio.save({ trackedOnGA: true });
}

export async function isTDH(portfolioId) {
  const tdh = await getTDH();
  return portfolioId === tdh.id;
}
