import Parse from 'parse';

// Scenario Object //
// name: String
// plotIndex: Number
// user: Pointer
// portfolio: Pointer


// re: plotIndex
// A user may assign up to (3) scenarios at any given time to what-if scenarios onto graph
// plotIndex helps persist the selected scenario as well as the order in which it is selected

const Scenario = Parse.Object.extend('Scenario');
const Portfolio = Parse.Object.extend('Portfolio');

// helper function
const _formatScenarioData = (parseObject) => ({
  id: parseObject.id,
  updatedAt: parseObject.updatedAt,
  createdAt: parseObject.createdAt,
  name: parseObject.get('name'),
  user: parseObject.get('user'),
  plotIndex: parseObject.get('plotIndex'),
});

export async function createScenario(name, portfolioId) {
  const portfolioQuery = await new Parse.Query(Portfolio)
  try {
    const scenario = new Scenario();
    const portfolio = await portfolioQuery.get(portfolioId);
    const currentUser = Parse.User.current();
    scenario.setACL(new Parse.ACL(currentUser)); // Set read/write permission to current user only:
    return await scenario.save({ user: currentUser, name, portfolio });
  } catch (err) {
    throw err;
  }
}

export async function getScenario(scenarioId) {
  const scenarioQuery = new Parse.Query(Scenario);
  try {
    const scenario = await scenarioQuery.get(scenarioId)
    return scenario;
  } catch (err) {
    throw err;
  }
}

export async function getScenarios(portfolioId) {
  const scenarioQuery = new Parse.Query(Scenario);
  const portfolioQuery = new Parse.Query(Portfolio);
  try {
    const portfolio = await portfolioQuery.get(portfolioId);
    scenarioQuery.equalTo('portfolio', portfolio);
    const results = await scenarioQuery.find();
    const scenarios = results.map(result => _formatScenarioData(result));
    return scenarios;
  } catch (err) {
    throw err;
  }
}

export async function renameScenario(scenarioId, name) {
  const query = new Parse.Query(Scenario);
  try {
    const scenario = await query.get(scenarioId);
    await scenario.save({ name });
    const renamedScenario = {
      id: scenario.id,
      updatedAt: scenario.updatedAt,
      createdAt: scenario.createdAt,
      name: scenario.get('name'),
      user: scenario.get('user'),
    }
    return renamedScenario;
  } catch (err) {
    throw err;
  }
}

export async function deleteScenario(scenarioId) {
  const scenarioQuery = new Parse.Query(Scenario);
  try {
    const scenario = await scenarioQuery.get(scenarioId);    
    return await scenario.destroy();
  } catch (err) {
    throw err;
  }
}

export async function queryByPlotIndex(plotIndex, portfolioId) {
  try {
    const portfolioQuery = new Parse.Query(Portfolio);
    const scenarioQuery = new Parse.Query(Scenario);
    const portfolio = await portfolioQuery.get(portfolioId);
    let scenarioId;
    scenarioQuery.equalTo('portfolio', portfolio);
    scenarioQuery.equalTo('plotIndex', plotIndex);
    const scenario = await scenarioQuery.first();
    if (scenario) scenarioId = scenario.id
    return scenarioId;
  } catch (err) {
    throw err;
  }
}

export async function updatePlotIndex(plotIndex, scenarioId) {
  const scenarioQuery = new Parse.Query(Scenario);
  try {
    const scenario = await scenarioQuery.get(scenarioId);
    scenario.save({ plotIndex });
  } catch (err) {
    throw new Parse.Error(404, 'No scenario found with provided plotIndex');
  }
}