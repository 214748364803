import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Header.css';

class Header extends Component {
  static propTypes = {
    icon: PropTypes.object,
    content: PropTypes.string,
    pretitle: PropTypes.string,
  }

  static defaultProps = {
    content: '',
    pretitle: '',
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { icon, content, pretitle } = this.props;

    return (
      <div>
        {
          pretitle ? <h6 className="header-pretitle">{pretitle}</h6> : null
        }
        <h1 className="header-container header-title" style={{marginLeft: '-0.3em'}}>
          {icon} {/* TODO: INSERT AS SGV */}
          &nbsp;
          {content}
        </h1>
      </div>
    );
  }
}

export default Header;
