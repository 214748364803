import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../components/Loader';

import './Button.css';
class Button extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    clickAction: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    // i.e.) d-flex flex-start to change the placement of the icon
    iconStyling: PropTypes.string,
    buttonBlock: PropTypes.bool,
    dashColor: PropTypes.string,
    dashClass : PropTypes.string,
    dataToggle: PropTypes.string,
    dataTarget: PropTypes.string,
  }

  static defaultProps = {
    type: 'button',
    clickAction: null,
    dashColor: '',
    dashClass: '',
    iconStyling: '',
    disabled: false,
    buttonBlock: false,
    dataToggle: '',
    dataTarget: '',
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { name, clickAction, type, icon, dashColor, iconStyling, disabled, dashClass, dataToggle, dataTarget, loading } = this.props;
    return (
      <button
        type={type || 'button'}
        className={`btn btn-${dashColor || 'primary'} ${dashClass}`}
        onClick={clickAction}
        disabled={disabled}
        data-toggle={dataToggle}
        data-target={dataTarget}
      >
        <div className={`mx-auto ${iconStyling}`}>
          { loading ? (
            <div style={{ display: 'inline-block' }}>
              {dashClass.indexOf('btn-block') <= -1 ? (
                <div className="buttonTitleWithLoader">
                    <span className="mr-2">{name}</span>
                    <Loader />
                </div>
              ) : (
                <Loader />
              )} 
            </div>
          ) : (
            <span>
              {icon && <i className={icon}/>}
              <span>{name}</span>
            </span>
          ) }
        </div>
      </button>
    );
  }
}

export default Button;
