import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Card from '../components/Card';
const CC_SUCCESS_STATUS = 'Good';

class CreditCardInfoBox extends Component {
  static propTypes = {
    creditCardInfo: PropTypes.object.isRequired,
    changeCardAction: PropTypes.func.isRequired,
  }
  render() {
    const { creditCardInfo, changeCardAction } = this.props;
    const creditCardValidation = (creditCardInfo && creditCardInfo.validation_status === CC_SUCCESS_STATUS);
    return (
      <Card>
        <div className="d-flex justify-content-between">
          <div>
            <span>{!_.isEmpty(creditCardInfo) ? creditCardInfo.card_type + ' ending in ' + creditCardInfo.card_number.substring(creditCardInfo.card_number.length - 4) : ''}</span>
            <span className={`ml-2 badge ${creditCardValidation ? "badge-success" : "badge-danger"}`}>{ creditCardValidation ? 'Active' : 'Inactive'}</span>
            
            <div className="d-flex mt-3">
              <img className="mr-3" src="/img/secure-checkout.svg" alt="Secure checkout" style={{ width: '120px'}}/>
              <div className="d-flex flex-column align-items-center icon-margins">
                <img src="/img/lets_encrypt.svg" alt="Secure checkout" style={{ maxWidth: '90px', objectFit: 'contain', marginTop: '-10px' }}/>
                <span className="subtitle" style={{ fontSize:"10px" }}>SHA-256 with RSA Encryption</span>
              </div>
            </div>

          </div>
          <button className="btn btn-sm btn-white" onClick={() => changeCardAction(true)}>Change</button>
        </div>
      </Card>
    )
  }
}

export default CreditCardInfoBox;
