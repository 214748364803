import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { serializer } from '../../utils/sessionStorage';
import moment from 'moment';
import Parse from 'parse';
import _ from 'lodash';
import $ from 'jquery';

import Card from '../../components/Card';
import Modal from '../../containers/Modal';
import Toast from '../../components/Toast';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import ActionLink from '../../components/ActionLink';
import SmallTable from '../../components/SmallTable';
import BillingModalContent from '../../modals/BillingModalContent';
import CreditCardInfoBox from '../../containers/CreditCardInfoBox';

import './AccountBilling.css';

import { SETTINGS, ACCOUNT_BILLING_TITLE, ERR_INTERNAL_ERROR, SUBSCRIBE_PLAN_ERROR } from '../../constants';
import { withGA, eventUserAccountUpgrade, eventTDHUpsell } from '../../utils/googleAnalytics';
import getConfig from '../../utils/getConfig';
let TDH_SUBSCRIPTION_ID = 78; // this will be replaced w/ configuration from infusionsoft, assigned 78 with default value

const PREMIUM = 'Premium', PREMIUM_TDH = 'Premium + TDH', BASIC = 'Basic', BASIC_TDH = 'Basic + TDH';
// setting param from infusionsoft
const CYCLE = {
  1: 'annual',
  2: 'monthly',
  YEAR: 1,
  MONTH: 2,
};
const BASE = 10; // numeric base
const SESSION_REQUESTED_CHANGE_CC = 'DIVCASTER_CC_CHANGE_REQUEST';

class AccountBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlan: '',
      subscribeTDH: false,
      creditCardInfo: null,
      transactions: null,
      productInfo: null,
      currentPlanInformation: {},
      selectedPlan: null,
      selectedPlanId: '',
      selectedPlanTitle: null,
      billingSubmitTitle: 'Submit',
      changePlanButtonTitle: 'Upgrade to Premium',
      changeCreditCard: false,
      subscriptionNotFound: false,
      changePlanInformation: false,
      cancelTitle: 'Cancel',
      nextBillingDate: '',
      // Loaders
      isCurrentPlanLoading: true,
      isCreditCardInfoLoading: true,
      isTransactionLoading: true,
      // Message display
      toast: null,
      errMsg: '',
      // TDH Plan information
      tdhPlanPrice: 49, //default value
      tdhSubscriptionPrice: 99, //default value
    };
  }

  async componentWillMount() {
    const isAdmin = await Parse.Cloud.run('isAdmin');
    if (!isAdmin) {
      const planInfo = await Parse.Cloud.run('getProductInformation');
      const tdhSubscriptionPrice = _.get(planInfo, 'tdhSubscriptionData[0].plan_price', 99);
      const tdhPlanPrice = _.get(planInfo, 'tdhTrailPrice', 49);
      this.setState({ tdhPlanPrice, tdhSubscriptionPrice });
    }
  }

  async componentDidMount() {
    document.title = ACCOUNT_BILLING_TITLE;

    if (sessionStorage.hasOwnProperty(SESSION_REQUESTED_CHANGE_CC)) {
      // update credit card for subscroiption
      if(sessionStorage.getItem(SESSION_REQUESTED_CHANGE_CC) === 'true') {
        // notes: need to compare w/ 'true' as string
        sessionStorage.setItem(SESSION_REQUESTED_CHANGE_CC, false, serializer(null, 2));
        const { infusionSoftId } = Parse.User.current().attributes;
        try {
          await Parse.Cloud.run('changeCreditCardInfoForSubscription', { infusionSoftId });
        } catch (error) {
          this.setState({ errMsg: 'Fail to update new credit card to current subscription' });
          console.log('Error', error);
        }
      }
    }

    const isAdmin = await Parse.Cloud.run('isAdmin');

    if (!isAdmin) {
      // notes: no await on these function since the try/catch are in each individual functions
      this.getCreditCardInformation();
      this.getOrderTransaction();
      this.getUserSubscriptionInformation();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tagIds !== this.props.tagIds) {
      this.getUserSubscriptionInformation();
    }
  }

  getCreditCardInformation = async () => {
    const { infusionSoftId } = Parse.User.current().attributes;
    // once user update credit card, we need to reload this data (for this page it will redirect back from infusionsoft)
    const url = new URL(window.location.href);
    try {
      const invalidateCache = url.searchParams.get("updatedCC");
      const creditCardInfo = await Parse.Cloud.run('getCreditCardInfoForUser', { infusionSoftId, invalidateCache }); 
      this.setState({ creditCardInfo });
    } catch (error) {
      // failed to get credit card info
      console.log('Failed to get user credit card information', error);
    } finally {
      this.setState({ isCreditCardInfoLoading: false  });
    }
  }

  getOrderTransaction = async () => {
    const { infusionSoftId } = Parse.User.current().attributes;
    try {
      let transactions = await Parse.Cloud.run('getOrderForUser', { infusionSoftId });
      const configurations = await getConfig();
      const productID = await configurations.get("INFUSIONSOFT_PRODUCTS_IDS");
      transactions = transactions.filter(item => {
        // ignore update billing information invoices
        if(item.order_items[0].product.id === productID.BILLING_UPDATE_PRODUCT_ID) return false;
        else return true;
      }).map(item => ({
        transactionId: item.id,
        order_date: new Date(item.order_date).toISOString().slice(0, 10),
        // note : if the invoice has refund the refund will be negative value
        status: (item.refund_total < 0) ? 'REFUND' : item.status,
        total: (item.refund_total < 0) ? item.refund_total : item.total,
      }));
      this.setState({ transactions });
    } catch (error) {
      console.log('Failed to get transaction information');
    } finally {
      this.setState({  isTransactionLoading: false });
    }
    
  }

  async getUserSubscriptionInformation() {

    try {
      const { plans, infusionSoftId } = Parse.User.current().attributes;
      const { tagIds, productIds } = this.props;
      const planInfo = await Parse.Cloud.run('getProductInformation');
      const productInfo = { plans: {}, bundlePlan: {}, tdh: {} };
      productInfo.plans.monthly = _.get(planInfo, `plan.monthly[0]`);
      productInfo.plans.annual = _.get(planInfo, `plan.yearly[0]`);
      productInfo.bundlePlan.monthly = _.get(planInfo, `bundle.monthly[0]`);
      productInfo.bundlePlan.annual = _.get(planInfo, `bundle.yearly[0]`);
      productInfo.tdh.annual = planInfo.tdhSubscriptionData.find(item => item.cycle_type === 'YEAR');
      TDH_SUBSCRIPTION_ID = productInfo.tdh.annual.id;

      this.setState({ productInfo, isCurrentPlanLoading: true });
      // update user information subscription
      if (tagIds && plans.includes(tagIds.BUNDLE_PLAN)) this.setState({ currentPlan: PREMIUM_TDH, changePlanButtonTitle: 'CHANGE PLAN' });
      else if (tagIds && plans.includes(tagIds.PREMIUM_PLAN)) this.setState({ currentPlan: PREMIUM });
      else this.setState({ currentPlan: BASIC });

      if (tagIds && plans.includes(tagIds.TDH)) this.setState({ subscribeTDH: true });

      const { currentPlan, subscribeTDH } = this.state;
      if (currentPlan !== BASIC || subscribeTDH) {
        let subscriptionInfo = await Parse.Cloud.run('getSubscriptionInformation', { infusionSoftId });
        if(!_.isEmpty(subscriptionInfo)) {
          // subscription information for tdh
          let tdhInformation = subscriptionInfo[TDH_SUBSCRIPTION_ID];
          delete subscriptionInfo[TDH_SUBSCRIPTION_ID];
          if(tdhInformation) {
            const tdhSubscriptionId = parseInt(tdhInformation.Id.i4[0], BASE);
            const nextTdhBillingDate = this.getDate(tdhInformation.NextBillDate['dateTime.iso8601'][0].slice(0, 8));
            const tdhInfo= {tdhSubscriptionId, nextTdhBillingDate}
            if(tdhInformation.EndDate) tdhInfo.endDate = await this.getDate(tdhInformation.EndDate['dateTime.iso8601'][0].slice(0, 8));
            tdhInformation = tdhInfo;
          }
          if (!_.isEmpty(subscriptionInfo)) {
            const ssId = Object.keys(subscriptionInfo)[0]; // subscription id from infusionsoft
            subscriptionInfo = subscriptionInfo[ssId];
            const billingCycle = parseInt(subscriptionInfo.BillingCycle, BASE);
            const subscriptionId = parseInt(subscriptionInfo.Id.i4[0], BASE);
            const nextBillingDate = this.getDate(subscriptionInfo.NextBillDate['dateTime.iso8601'][0].slice(0, 8));
            const currentPlanInformation = { billingCycle, nextBillingDate, subscriptionId, tdhInformation, ssId };
            if(subscriptionInfo.EndDate) currentPlanInformation.endDate = this.getDate(subscriptionInfo.EndDate['dateTime.iso8601'][0].slice(0, 8));
            switch (currentPlan) {
              case PREMIUM:
              currentPlanInformation.planPrice = await Parse.Cloud.run('retrieveSubscriptionInformation', { productId: productIds.PREMIUM, subscriptionId: ssId });
              currentPlanInformation.planPrice = currentPlanInformation.planPrice.plan_price;
              break;
              case PREMIUM_TDH:
              currentPlanInformation.planPrice = await Parse.Cloud.run('retrieveSubscriptionInformation', { productId: productIds.BUNDLE_PLAN, subscriptionId: ssId });
              currentPlanInformation.planPrice = currentPlanInformation.planPrice.plan_price;
              break;
              case BASIC_TDH:
              currentPlanInformation.planPrice = await Parse.Cloud.run('retrieveSubscriptionInformation', { productId: productIds.TDH, subscriptionId: ssId });
              currentPlanInformation.planPrice = currentPlanInformation.planPrice.plan_price;
              break;
              default:
            }
            this.setState({ currentPlanInformation, subscriptionNotFound: false, isCurrentPlanLoading: false });
          } else if(subscribeTDH) {
            const currentPlanInformation = { tdhInformation };
            this.setState({ currentPlanInformation, subscriptionNotFound: false, isCurrentPlanLoading: false });
          }
        } else {
          // no subscription under this account, it may be from credit card is invalid
          this.setState({ subscriptionNotFound: true, isCurrentPlanLoading: false });
        }
      } else {
        this.setState({ isCurrentPlanLoading: false })
      }
    } catch (error) {
      console.log('Failed to get subscription Information', error);
      this.setState({ isCurrentPlanLoading: false })
    }
    
  }

  getDate = (date) => {
    return moment(date).format('LL');
  }

  mailTo = () => {
    window.location.href = `mailto:${SETTINGS.SUPPORT_EMAIL}`;
  }

  openModal = (modalId) => {
    $(`${modalId}`).modal();
  }

  saveBillingInfoFromModal = async (billingInfo) => {
    const { infusionSoftId, email } = Parse.User.current().attributes;
    const userInfo = { infusionSoftId, email };
    const {
      currentPlanInformation: {
        nextBillingDate,
        tdhInformation,
        billingCycle,
      },
      selectedPlan,
      selectedPlanId,
      changePlanInformation
    } = this.state;
    let {currentPlanInformation: { subscriptionId, endDate, ssId }} = this.state;
    let message = '';
    try {
      if (changePlanInformation) {
        if (billingInfo.renew) {
          const modalSelectedPlan = billingInfo.selectedPlan;
          // renew the plan after the end date with selected plan
          if(_.size(selectedPlan) <= 1 || CYCLE[modalSelectedPlan.cycle_type] === billingCycle) {
            //if tdh update data;
            if(_.size(selectedPlan) <= 1) {
              endDate = tdhInformation.endDate;
              subscriptionId = tdhInformation.tdhSubscriptionId;
              ssId = TDH_SUBSCRIPTION_ID;
            }
            try {
              await Parse.Cloud.run('renewPlan', { subscriptionId, endDate, userInfo, ssId });
            } catch (error) {
              if (error.code === 500) throw new Parse.Error(500, 'Please contact customer support for assistance');
              else throw error;
            }
          } else {
            // change billing cycle -- same method with change plan
            const currentPlan = { subscriptionId, endDate };
            const subscriptionInfo = { id: modalSelectedPlan.id, price: modalSelectedPlan.plan_price };
            const { creditCardInfo } = this.state;
            await Parse.Cloud.run('changePlan', { userInfo, creditCardInfo, subscriptionInfo, currentPlan });
          }
          message = 'Renew Successfully!';
        } else if(billingInfo.cancel) {

          if (_.size(selectedPlan) <= 1) {
            // tdh plan subscription
            const { tdhSubscriptionId, nextTdhBillingDate} = tdhInformation;
            const subscriptionId = tdhSubscriptionId;
            const nextBillingDate = nextTdhBillingDate;
            ssId = TDH_SUBSCRIPTION_ID;
            await Parse.Cloud.run('cancelSubscription', { subscriptionId, nextBillingDate, infusionSoftId, ssId });
          } else {
            await Parse.Cloud.run('cancelSubscription', { subscriptionId, nextBillingDate, infusionSoftId, ssId });
          }
          message = 'Cancel Successfully!';
        }

      } else if (_.size(selectedPlan) <= 1) {
        // add-on tdh plan
        const { creditCardInfo } = this.state;
        await Parse.Cloud.run('addTDH', { userInfo, creditCardInfo });
        eventTDHUpsell();
        message = 'Add TDH Complete!';
      } else if (!subscriptionId) {
        // add new credit card with adding plan for basic user
        let selectedPlan = billingInfo.selectedPlan;
        selectedPlan.product = {
          id: selectedPlanId
        };
        const { creditCardInfo } = this.state;
        await Parse.Cloud.run('upgradePlanForBasicUser', { userInfo, creditCardInfo, selectedPlan });
        eventUserAccountUpgrade();
        message = 'Upgrade Plan Complete!';
      } else {
        // change plan - this is edge case
        const selectedPlan = billingInfo.selectedPlan;
        const currentPlan = { subscriptionId, endDate };
        const subscriptionInfo = { id: selectedPlan.id, price: selectedPlan.plan_price };

        const { creditCardInfo } = this.state;
        await Parse.Cloud.run('changePlan', { userInfo, creditCardInfo, subscriptionInfo, currentPlan });
        message = 'Change Plan Succesfully!';
      }

      $('#billingModal').modal('hide');

      const { checkUserPlans } = this.props;
      await Parse.User.current().fetch();
      checkUserPlans(); // update app level state value
      this.getUserSubscriptionInformation();
      this.setState({ toast: { type: 'success', message }});
      setTimeout(() => this.hideNotification(), 2500);

    } catch (error) {
      if (error.code === 500) this.setState({ errMsg: ERR_INTERNAL_ERROR });
      else if ( error.code === 503) this.setState({ errMsg: SUBSCRIBE_PLAN_ERROR });
      else this.setState({ errMsg: error.message });
    }
  }

  addNewCard = async(updateCard) => {
    this.setState({ errMsg: '' }); // reset error message

    let { firstName, lastName, phoneNumber, email } = Parse.User.current().attributes;
 
    if (!!!firstName || !!!lastName) {
      const { updateUserInformation } = this.props;
      // retrieve data from infusionsoft instead
      const userInfo = await updateUserInformation();
      firstName = _.get(userInfo, 'given_name');
      lastName = _.get(userInfo, 'family_name');
      phoneNumber = _.get(userInfo, 'phoneNumber');
      // also update to user data
      const user = Parse.User.current();
      if (firstName) user.set('firstName', firstName);
      if (lastName) user.set('lastName', lastName);
      if (phoneNumber) user.set('phoneNumber', phoneNumber);
      user.save();
    }

    if (phoneNumber) phoneNumber = phoneNumber.replace(/\D/g, '');

    // redirect to infusionsoft order form
    const configurations = await getConfig();
    const infusionsoftUrl = await configurations.get("CHANGE_BILLING_INFORMATION_URL");

    if (infusionsoftUrl) {
      if (updateCard) {
        // if user request to change card
        // save state, when infusionsoft redirect back to us we update new credit card to the current subscription
        sessionStorage.setItem(SESSION_REQUESTED_CHANGE_CC, true, serializer(null, 2));
      }

      let billingUrl = `${infusionsoftUrl}?email=${encodeURIComponent(email)}&name=${firstName}&lastname=${lastName}`;
      if(phoneNumber) billingUrl += `&phonenumber=${phoneNumber}`;
      window.location.replace(billingUrl);
    } else {
      this.setState({ errMsg: 'Internal Server Error'});
    }
  }

  hideNotification = () => {
    this.setState({ toast: null });
  }

  upgradePlan = (plan) => {
    const { productIds } = this.props;
    const { productInfo } = this.state;
    this.setState({ errMsg: '', selectedPlanTitle:  `'${plan.toUpperCase()}' ACCOUNT PLAN`, billingSubmitTitle: 'UPGRADE MY ACCOUNT!', changeCreditCard: false, changePlanInformation: false });
    switch(plan){
      case PREMIUM:
      this.setState({ selectedPlan: productInfo.plans, selectedPlanId: productIds.PREMIUM });
      break;
      case PREMIUM_TDH:
      this.setState({ selectedPlan: productInfo.bundlePlan, selectedPlanId: productIds.PREMIUM_TDH });
      break;
      default:
      break;
    }
    this.openModal('#billingModal');
  }

  addTDH = () => {
    const { productIds } = this.props;
    const { productInfo } = this.state;
    this.setState({
      errMsg: '',
      selectedPlan: productInfo.tdh,
      selectedPlanId: productIds.TDH,
      billingSubmitTitle: 'GET THE DIVIDEND HUNTER',
      selectedPlanTitle:  'THE DIVIDEND HUNTER ADD-ON',
      changeCreditCard: false,
      changePlanInformation: false,
    });
    this.openModal('#billingModal');
  }

  changeTdhPlanInformation = () => {
    const { productIds } = this.props;
    const { productInfo, currentPlanInformation: { tdhInformation } } = this.state;
    this.setState({
      errMsg: '',
      changePlanInformation: true,
      changeCreditCard: false,
      selectedPlanTitle:  'The Dividend Hunter',
      selectedPlanId: productIds.TDH,
      selectedPlan: productInfo.tdh,
      billingSubmitTitle: 'Save Changes',
      cancelTitle: (!tdhInformation.endDate) ? 'Cancel The Dividend Hunter Subscription' : null,
    });
    this.openModal('#billingModal');
  }

  changePlanInformation = () => {
    const { productIds } = this.props;
    const { currentPlan, productInfo, currentPlanInformation } = this.state;
    this.setState({
      errMsg: '',
      changePlanInformation: true,
      changeCreditCard: false,
      selectedPlanTitle: (!currentPlanInformation.endDate) ? `Current Plan: ${currentPlan}` : `Renew Plan: ${currentPlan}`,
      billingSubmitTitle: 'Save Changes',
      cancelTitle: (!currentPlanInformation.endDate) ? `Cancel '${currentPlan}' Subscription` : null,
    });
    switch(currentPlan){
      case PREMIUM:
      this.setState({ selectedPlan: productInfo.plans, selectedPlanId: productIds.PREMIUM });
      break;
      case PREMIUM_TDH:
      this.setState({ selectedPlan: productInfo.bundlePlan, selectedPlanId: productIds.PREMIUM_TDH });
      break;
      default:
      break;
    }
    this.openModal('#billingModal');
  }

  setErrorMessage = (message) => {
    this.setState({ errMsg: message });
  }

  render() {
    const { currentPlan, creditCardInfo, transactions, errMsg, currentPlanInformation, selectedPlan, selectedPlanTitle,
      billingSubmitTitle, toast, changeCreditCard, subscribeTDH, changePlanButtonTitle, changePlanInformation, cancelTitle,
      isCurrentPlanLoading, isCreditCardInfoLoading, isTransactionLoading } = this.state;

      const LoaderCard = (
        <Card>
          <Loader />
        </Card>
      );

      let creditCardBox = LoaderCard;
      if (!isCreditCardInfoLoading) {
        creditCardBox = creditCardInfo
        ? (
          <CreditCardInfoBox creditCardInfo={creditCardInfo} changeCardAction={this.addNewCard} />
        )
        : (
          <div className="card card-inactive bg-white bordered">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center py-2">
                <h3>You don't have a credit card saved.</h3>
                <div className="d-flex add-card">
                  <Button dashColor="outline-primary" name="Add New Card" clickAction={() => { this.addNewCard(false) }} />
                  <img src="/img/secure-checkout.svg" alt="Secure checkout" className="icon-margins" style={{ width: '160px' }}/>
                  <div className="d-flex flex-column align-items-center icon-margins">
                    <img src="/img/lets_encrypt.svg" alt="Secure checkout" style={{ maxWidth: '160px', objectFit: 'contain', marginTop: '-10px' }}/>
                    <span className="subtitle">SHA-256 with RSA Encryption</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      let transactionBox = LoaderCard;
      if (!isTransactionLoading) {
        transactionBox = !_.isEmpty(transactions)
        ? (
          <div>
            <hr className="mt-4 mb-5"/>
            <h3 className="header-container header-title mb-4">Past Invoices</h3>
            <div className="card" style={{overflow: 'hidden'}}>
              <div className="card-body p-0">
                <SmallTable tHeader={['ID', 'DATE', 'STATUS', 'TOTAL']} entries={transactions} />
              </div>
            </div>
          </div>
        )
        : ''
      }
      let currentPlanBox = <Loader />;
      if (!isCurrentPlanLoading) {
        currentPlanBox = _.has(currentPlanInformation, 'planPrice')
        && (
          <div>
            <span style={{verticalAlign: 'middle'}}>
              $
              { currentPlanInformation.planPrice}/{(currentPlanInformation.billingCycle === CYCLE.MONTH) ? 'Mo' : 'Yr'}
            </span>
          </div>
        );
      }

      return (
        <div className="container mt-5 p-0">
          <div className="row">
            <div className="col-8 inner-container">
              <h3 className="header-container header-title mb-4">Saved Payment Method</h3>

              {creditCardBox}
              {transactionBox}

              <hr className="mt-4 mb-5"/>

              <div className="alert alert-light py-4 border">
                <h4 className="alert-heading">Need help?</h4>
                <p className="mb-0">To see if you’re eligible for a refund for the unused time in your billing cycle, please email us at <ActionLink text={SETTINGS.SUPPORT_EMAIL} clickAction={() => this.mailTo()} /></p>
              </div>

            </div>
            <div className="col">
              <h3 className="header-container header-title mb-4">Your Current Plan</h3>
              <div className="card">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="card-title m-0">{currentPlan}</h3>
                    </div>
                    <div className="col-auto">
                      { (currentPlan) === BASIC ? ( <span>Free!</span> ) : currentPlanBox }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  { (currentPlan !== BASIC ? (
                    <div className="">
                      <em>
                        {currentPlanInformation.endDate ? (
                          <small>Subscription ends: {currentPlanInformation.endDate}</small>
                        ) : (
                          <small>Next billing: {currentPlanInformation.nextBillingDate}</small>
                        )}
                      </em>
                      {/* ODTO - 398 : disable this feature for phase 1 production rollout */}
                      {/* <button className="btn btn-white btn-sm ml-3" onClick={() => this.changePlanInformation()}>Change</button> */}
                    </div>
                  ) : (
                    <div><Button dashClass="btn-block" name={changePlanButtonTitle} clickAction={() => {this.upgradePlan(PREMIUM)}} /></div>
                  )
                )}
              </div>
            </div>

            { subscribeTDH && (
              <div>
                <Card>
                  <span className="badge badge-soft-primary mb-2">Add-on</span>
                  <img className="mb-2 mt-2" src="/img/logo-tdh.png" alt="The Dividend Hunter" style={{width: '100%', maxHeight: '32px'}}/>
                  <hr/>
                  <div className="d-flex justify-content-between">
                    { currentPlanInformation.tdhInformation && !currentPlanInformation.tdhInformation.endDate ? (
                      <div>
                        <em><small>Next billing: {currentPlanInformation.tdhInformation ? currentPlanInformation.tdhInformation.nextTdhBillingDate : 'N/A'}</small></em>
                        <Button dashColor="white" dashClass="btn-sm ml-3" name="Change" clickAction={() => { this.changeTdhPlanInformation() }}/>
                      </div>
                    ) : (
                      <h5 className="header-container header-title">
                        { /* Note:  User Subscribe TDH from other system, we could not modify the order information w/ divcaster system
                          This part is place holder, Kevin will modify it again */ }
                          Email support at <ActionLink text={SETTINGS.SUPPORT_EMAIL} clickAction={() => this.mailTo()} />
                      </h5>
                    )}
                  </div>
                </Card>
              </div>
            )}

            <hr className="my-4"/>

            {/* ODT-398 - Hide this feature for V1 launch */}
            {/* { (!subscribeTDH && currentPlan !== PREMIUM_TDH) && (
              <Card>
                <span className="badge badge-soft-primary mb-2">Add-on</span>
                <img className="mb-2 mt-2" src="/img/logo-tdh.png" alt="The Dividend Hunter" style={{width: '100%', maxHeight: '32px', objectFit: 'contain'}}/>
                <hr/>
                <p>${tdhPlanPrice} Intro Rate for New Members</p>
                  <Button dashColor="primary" dashClass="btn-block" name="Get Access!" clickAction={() => {this.addTDH()}} />
                </Card>
              )} */}

              {/* NOTE: Temporarily commenting this out for V1 launch:
                (!subscribeTDH && currentPlan === BASIC) && (
                <div className="mt-4">
                  <Card>
                    <span className="badge badge-soft-primary mb-2">Bundle</span>
                    <span className="font-weight-bold text-middle font-l d-block">Premium + TDH</span>
                    <p>${productInfo ? ((productInfo.bundlePlan.annual.plan_price)/12).toFixed(2) : 0 }/Mo (Save 20%!)</p>
                    <Button dashColor="primary" dashClass="btn-block" name="Get the Bundle!" clickAction={() => {this.upgradePlan(PREMIUM_TDH)}} />
                  </Card>
                </div>
              )*/}

            </div>
          </div>

          <Modal modalId="billingModal" header="Make changes to your plan">
            <BillingModalContent
              planTitle={selectedPlanTitle}
              selectedPlan={selectedPlan}
              saveBillingInfoFromModal={this.saveBillingInfoFromModal}
              errorMessage={errMsg}
              setErrorMessage={this.setErrorMessage}
              buttonText={billingSubmitTitle}
              creditCardInfo={creditCardInfo}
              changeCreditCard={changeCreditCard}
              changePlanInformation={changePlanInformation}
              cancelTitle={cancelTitle}
              addNewCard={this.addNewCard}
              />
          </Modal>
          <Toast notification={toast} onClose={() => this.hideNotification()} />
        </div>
      );
    }
  }

  export default withGA(withRouter(AccountBilling));
