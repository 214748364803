import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import ErrorBox from '../../components/ErrorBox';
import Parse from 'parse';

const ProductInformation = Parse.Object.extend('InfusionsoftProductInformation');

class Plans extends Component {

  constructor(props) {
    super(props);
    this.state = {
      premiumPlans: {
        monthly: [],
        yearly: []
      },
      planCodes: [],
      planCodesOnChange: [],
      savingPlan: false,
      refreshing: false,
      errorMessage: null,
    }
  }
  async componentWillMount() {
    this.updatePlanInformation();
  }

  async updatePlanInformation() {
    // TODO: Adding loading while retriving plan information

    try {
      const configurationQuery = new Parse.Query(ProductInformation);
      configurationQuery.limit(1);
      const results = await configurationQuery.find();
      const productConfiguration = results[0];

      const planCodes = await productConfiguration.get('planCodes') || [];
      const premiumPlans = await productConfiguration.get('premiumPlan');
      this.setState({ premiumPlans, planCodes, planCodesOnChange: planCodes });
    } catch (error) {
      //TODO: Report error to users' ui
      console.log('Error updating plan information', error);
    }

    
  }

  savePlanCodes= async() =>{
    try {
      this.setState({ savingPlan: true });
      const { planCodes } = this.state;
      const configurationQuery = new Parse.Query(ProductInformation);
      configurationQuery.limit(1);
      const results = await configurationQuery.find();
      const productConfiguration = results[0];
      productConfiguration.set('planCodes', planCodes);
      productConfiguration.save();
    } catch (error) {
      console.log('Error Saving Plan Codes', error);
      this.setState({ errorMessage: 'Internal System Error' });
    } finally {
      this.setState({ savingPlan: false });
    }
  }

  refreshInfusionsoftPlanInformation = async() => {
    try {
      this.setState({ refreshing: true });
      await Parse.Cloud.run('refreshInfusionsoftProductInformation');
      this.updatePlanInformation();
    } catch (error) {
      console.log('Failed to refresh plans information form infusionsoft', error);
      this.setState({ errorMessage: 'Internal System Error' });
    } finally {
      this.setState({ refreshing: false });
    }
  }

  insertPlanCodes = (e, i) => {
    const { planCodes } = this.state;
    const code = e.target.value;
    if ((planCodes.indexOf(code) >= 0) && (planCodes.indexOf(code) !== (i-1))) {
      alert('Duplicated Code!');
    } else {
      planCodes[i-1] = code;
      this.setState({ planCodes });
    }
  }

  convertToDollar = (num) => {
    // format the numbers with zero decimal places if the number is a whole number, or exactly two decimal places if the number is not a whole number, i.e. $12 or $99.99
    const isInteger = Number.isInteger(num);
    const convertedNum = num.toLocaleString(undefined, { minimumFractionDigits: isInteger ? 0 : 2 });
    return convertedNum;
  }

  render() {
    const { premiumPlans, planCodesOnChange, refreshing, savingPlan, errorMessage } = this.state;

    let primaryPlans = [], secondaryPlan = [];
    if (premiumPlans.monthly.length > premiumPlans.yearly.length) {
      primaryPlans = premiumPlans.monthly;
      secondaryPlan = premiumPlans.yearly;
    } else {
      primaryPlans = premiumPlans.yearly;
      secondaryPlan = premiumPlans.monthly;
    }

    return (
      <div className="container">
          <div className="my-5">
            <ErrorBox errorMsg={errorMessage} />
            <Header content="Plans" />
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header py-4">
                <div className="row">
                  <div className="col-auto">
                    <Button 
                      clickAction={this.refreshInfusionsoftPlanInformation}
                      name={"Refresh"}
                      loading={refreshing}
                    />
                  </div>
                  <div className="col-auto">
                    <Button 
                      clickAction={this.savePlanCodes}
                      name={"Save Plan"}
                      loading={savingPlan}
                    />
                  </div>
                </div>  
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="4" scope="col">
                        premium plan
                      </th>
                      <th rowSpan="2" scope="col">
                        code
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">
                        freq.
                      </th>
                      <th scope="col">
                        Price
                      </th>
                      <th scope="col">
                        freq.
                      </th>
                      <th scope="col">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                      {
                        primaryPlans.map((item, i)=> {
                          const secondPlan = secondaryPlan[i];
                          return (
                            <Fragment>
                              <tr>
                                <td className="border-light align-items-center" key={`cycle-${item.id}`}>{item.cycle_type}</td>
                                <td className="border-light align-items-center" key={`price-${item.id}`}>${this.convertToDollar(item.plan_price)}</td>
                                { secondPlan ? (
                                  <Fragment>
                                    <td className="border-light align-items-center" key={`cycle-${secondaryPlan[i].id}`}>{secondaryPlan[i].cycle_type}</td>
                                    <td className="border-light align-items-center"  key={`price-${secondaryPlan[i].id}`}>${this.convertToDollar(secondaryPlan[i].plan_price)}</td>
                                  </Fragment>
                                  
                                ) : (
                                  // TODO: Display as red highlight
                                  <Fragment>
                                    <td className="border-light align-items-center">-</td>
                                    <td className="border-light align-items-center">-</td>
                                  </Fragment>
                                )}
                                
                                <td className="border-light align-items-center" key={i}>
                                  {(i === 0) 
                                    ? 'Default'
                                    : <input 
                                        type="text" 
                                        value={planCodesOnChange[i-1]}
                                        onChange={(e)=> {
                                          const newPlanCodes = planCodesOnChange;
                                          newPlanCodes[i-1] = e.target.value;
                                          this.setState({ planCodesOnChange: newPlanCodes })
                                        }}
                                        onKeyUp={(e) => this.insertPlanCodes(e, i)} /> 
                                  }
                                </td>
                              </tr>
                            </Fragment>
                          )
                        })
                      }
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withRouter(Plans);