import React from 'react';
import ReactGA from 'react-ga';
import getConfig from './getConfig';

export const CATEGORY = {
  USER: 'User',
  PORTFOLIO: 'Portfolio',
  SCENARIO: 'What If Scenario',
  TDH: 'TDH',
  TRAINING: 'Training',
}

export const ACTION = {
  SIGN_IN: 'Sign In',
  USER_STARTED: 'User Started Sign Up',
  EMAIL_ENTERED: 'User Email Entered',
  USER_ABANDON_SIGNUP: 'User Abandon Sign Up',
  USER_SELECTS_PLAN: 'User Selected Plan',
  USER_CREATED: 'User Created',
  USER_UPGRADE: 'User Upgraded Plan',
  FREE_USER_CREATED: 'Free User Account Created',
  PAID_USER_CREATED: 'Paid User Account Created',
  USER_SELF_MANAGE: 'User Self-Managed Account',
  UPLOAD: 'Upload',
  SAVE: 'Save',
  FAILURE: 'Failure',
  CORRECTION: 'Correction',
  DELETE: 'Delete',
  CREATE: 'Create',
  DUPLICATE: 'Duplicate',
  UPDATE: 'Update',
  ERROR: 'Error',
  UPSELL: 'Upsell',
  WATCH: 'Watch',
  CUSTOMIZE: 'Customize',
}

export async function initGA() {
  const configurations = await getConfig();
  const ga_tag = await configurations.get("GA_TAG");
  if (!ga_tag) {
    return;
  }
  if (global.window && !global.ga) {
    ReactGA.initialize(ga_tag, {
      debug: false,
      titleCase: false,
    })
  }
}

export function gaFunc(func){
  return async(...args) => {
    await initGA();
    func(...args);
  };
};

export const pageview = gaFunc(() => {
  ReactGA.pageview(window.location.pathname);
});

// USER
export const eventUserSignIn = gaFunc((username) => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_STARTED,
    label: 'User Sign in'
  });
});

export const eventUserAccountCreationStarted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_STARTED,
    label: 'Free Acct. Set Up Started',
  });
});

export const eventUserEntersEmailAddress = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.EMAIL_ENTERED,
    label: 'Free Acct. Email Added',
  });
});

export const eventUserAbandonedSignUp = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_ABANDON_SIGNUP,
    label: 'User Abandoned Signup',
  });
});

export const eventUserSelectsPlan = gaFunc((plan, paymentPeriod) => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_SELECTS_PLAN,
    label: `Plan: ${plan}, Payment Period: ${paymentPeriod}`,
  });
});

export const eventUserAccountCreationCompleted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_CREATED,
    label: 'User paid acct. creation completed',
  });
});

export const eventFreeUserAccountCreationCompleted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.FREE_USER_CREATED,
    label: 'Free Acct. Set-up Completed',
  });
});

export const eventPaidUserAccountCreationCompleted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.PAID_USER_CREATED,
    label: 'Paid Acct. Set-up Completed',
  });
});

export const eventUserAccountUpgrade = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_UPGRADE,
    label: 'User upgraded plan',
  });
});

export const eventUserAccountManaged = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.USER,
    action: ACTION.USER_SELF_MANAGE,
    label: 'Acct. managed by user',
  });
});

// PORTFOLIO
export const eventPortfolioInputStarted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.UPLOAD,
    label: 'Portfolio Import Attempt Started',
  });
});

export const eventPortfolioInputCompleted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.SAVE,
    label: 'Portfolio Import Completed',
  });
});

export const eventPortfolioInputFailed = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.FAILURE,
    label: 'Portfolio Import Failed',
  });
});

// export const eventPortfolioImportCorrection = gaFunc(() => {
//   ReactGA.event({
//     category: CATEGORY.PORTFOLIO,
//     action: ACTION.CORRECTION,
//     label: 'Portfolio Import Corrected',
//   });
// })

export const eventPortfolioDeleted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.DELETE,
    label: 'Portfolio Deleted',
  });
});

export const eventPortfolioCreated = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.CREATE,
    label: 'Portfolio Created',
  });
});

export const eventPortfolioDuplicated = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.DUPLICATE,
    label: 'Portfolio Copied',
  });
});

export const eventPortfolioUpdated = gaFunc((updateType) => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.UPDATE,
    label: `Portfolio Updated: ${updateType}`,
  });
});

export const eventPortfolioTickerNotFound = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.PORTFOLIO,
    action: ACTION.ERROR,
    label: 'Ticker Value Error',
  });
})

// WHAT IF SCENARIOS
export const eventWhatIfScenarioCreated = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.SCENARIO,
    action: ACTION.CREATE,
    label: 'What if Scenario Created',
  });
});

export const eventWhatIfScenarioUpdated = gaFunc((updateType) => {
  ReactGA.event({
    category: CATEGORY.SCENARIO,
    action: ACTION.UPDATE,
    label: `What if Scenario Updated: ${updateType}`,
  });
});

export const eventWhatIfScenarioDeleted = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.SCENARIO,
    action: ACTION.DELETE,
    label: 'What if Scenario Deleted',
  });
});

//TDH
export const eventTDHUpsell = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.TDH,
    action: ACTION.UPSELL,
    label: 'TDH Upsell Complete',
  });
});

export const eventTDHDuplicated = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.TDH,
    action: ACTION.DUPLICATE,
    label: 'TDH Copied',
  });
});

export const eventTDHCustomizedFirstTime = gaFunc(() => {
  ReactGA.event({
    category: CATEGORY.TDH,
    action: ACTION.CUSTOMIZE,
    label: 'TDH Portfolio Copy Customized for the 1st Time',
  });
});

//TRAINING
export const eventTrainingVideoStarted = gaFunc((videoName) => {
  ReactGA.event({
    category: CATEGORY.TRAINING,
    action: ACTION.WATCH,
    label: `Training Video Started: ${videoName}`,
  });
});

export const withGA = (Component) => {
  class WithGA extends React.Component {
    componentDidMount() {
      pageview();
    }

    render() {
      return React.createElement(Component, this.props);
    }
  }

  if (Component.getInitialProps) {
    WithGA.getInitialProps = Component.getInitialProps;
  }

  return WithGA;
}