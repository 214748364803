import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ActionLink from '../components/ActionLink';

class Ellipses extends Component {
  static propTypes = {
    dropDownItems: PropTypes.array,
    dashColor: PropTypes.string,
    dashClass: PropTypes.string,
    clickAction: PropTypes.func,
  }

  static defaultProps = {
    dropDownItems: [],
    dashColor: '',
    dashClass: '',
    clickAction: () => {},
  }

  render() {
    const { dropDownItems, dashColor, dashClass } = this.props;
    const anchors = dropDownItems.map(obj => (
      <ActionLink key={obj.name} text={obj.name} clickAction={(e) => { e.stopPropagation(); obj.clickAction(obj.name); }} dropdownItem />
    ));
    return !_.isEmpty(anchors) && (
      <div className={`dropdown ${dashClass ? dashClass : ''}`} style={{verticalAlign: 'middle'}} onClick={(e) => { e.stopPropagation() } }>
        <a href="#!" className={`dropdown-ellipses dropdown-toggle text-${dashColor || 'dark'} px-3`} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="fe fe-more-vertical" />
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          { anchors }
        </div>
      </div>
    );
  }
}

export default Ellipses;
