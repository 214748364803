import Parse from 'parse';
import _ from 'lodash';

const Portfolio = Parse.Object.extend('Portfolio');

// helper function
function formatInvestmentInstitution(parseObject) {
  return {
  id: parseObject.id,
  updatedAt: parseObject.updatedAt,
  createdAt: parseObject.createdAt,
  name: parseObject.get('name'),
  institutionId: parseObject.get('institutionId'),
}};

async function _getPortfolio(id) {
  const portfolioQuery = new Parse.Query(Portfolio);
  const currentUser = Parse.User.current();
  try {
    portfolioQuery.equalTo('objectId', id);
    portfolioQuery.equalTo('user', currentUser);
    return await portfolioQuery.first();
  } catch (err) {
    throw err;
  }
}

export async function getInvestmentInstitutionById(id) {
  if (!id) {
    return;
  }
  try {
    const result = await Parse.Cloud.run('getInvestmentInstitutionById', { id });
    if (_.isEmpty(result)) {
      return {};
    }
    
    return formatInvestmentInstitution(result);
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentInstitutions() {
  try {
    const results = await Parse.Cloud.run('getInvestmentInstitutions');

    if (_.isEmpty(results)) {
      return [];
    }

    return results.map(data => formatInvestmentInstitution(data));
  } catch (e) {
    throw e;
  }
}

export async function unlinkPortfolio(portfolioId) {
  try {
    const portfolioRes = await _getPortfolio(portfolioId)
    portfolioRes.set('investmentInstitution', null);
    portfolioRes.set('plaidLinkUpdatedAt', null);
    portfolioRes.set('subAccountIds', null);
    await portfolioRes.save();
  } catch (e) {
    throw e;
  }
}

export async function saveSubAccountsToPortoflio (portfolioId, subAccountIds) {
  try {
    const portfolioRes = await _getPortfolio(portfolioId);
    portfolioRes.set('subAccountIds', subAccountIds);
    await portfolioRes.save();
  } catch (e) {
    console.error('Error saving subaccounts to portfolio', e);
    throw e;
  }
};