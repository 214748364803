import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import _ from 'lodash';

import Loader from '../components/Loader';
import { BREAKDOWN_DEFAULT_COLORS, FORCAST_ACTIVE_COLOR } from '../constants';
import './Chart.css';

class ChartJs extends Component {
  static propTypes = {
    options: PropTypes.object,
    onBarClick: PropTypes.func,
    updateGraph: PropTypes.bool,
    graphFormat: PropTypes.string,
    activeMonthIndex:  PropTypes.number,
    activeDatasetIndex: PropTypes.number,
    type: PropTypes.string.isRequired,
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.array.isRequired,
    hasHoverEvent: PropTypes.bool,
  }

  static defaultProps = {
    options: {},
    graphFormat: 'Summary',
  }

  constructor(props) {
    super(props);
    this.chart = null; // Chart instance. Use this.chart to reference chart.js API.
    this.chartRef = null;
    this.throttledHover = _.throttle(this.handleHover, 200);
  }

  componentDidMount() {
    if (this.props.options && this.props.datasets) {
      this.drawGraph();
    }
  }

  componentDidUpdate(prevProps) {
    const { type, labels, datasets, options } = this.props;

    if (prevProps.type !== type
      || prevProps.labels !== labels
      || prevProps.datasets !== datasets
      || prevProps.options !== options) {
      this.drawGraph();
    }

    if ((prevProps.redrawGraph !== this.props.redrawGraph) && this.chart) {
      this.chart.update();
    }
  }

  handleClick = (evt)  => {
    const { onBarClick, datasets, activeMonthIndex, activeDatasetIndex } = this.props;
    if(onBarClick) {
      const activeElement = (this.chart.getElementAtEvent(evt))[0];
      const selectedIndex = _.get(activeElement, '_index');
      const selectedDatasetIndex = _.get(activeElement, '_datasetIndex');
      const alreadyActive = (selectedIndex === activeMonthIndex && selectedDatasetIndex === activeDatasetIndex);
      
      // Update breakdown chart
      if(alreadyActive) {
        // handles clicks on already-active bars; reset   
        onBarClick();
      } else {
        onBarClick(selectedIndex, selectedDatasetIndex);
      };

      // Update bar colors
      if(_.get(datasets, `${[selectedDatasetIndex]}.backgroundColor`)) {
        const numberOfMonths = 12;
        const backgroundColor = new Array(numberOfMonths).fill(BREAKDOWN_DEFAULT_COLORS[selectedDatasetIndex]);

        datasets.forEach((d, dIndex) => {
          if(dIndex === selectedDatasetIndex) {
            if(!alreadyActive) {
              backgroundColor[selectedIndex] = FORCAST_ACTIVE_COLOR;
            };
            datasets[dIndex].backgroundColor = backgroundColor;
          } else {
            datasets[dIndex].backgroundColor = BREAKDOWN_DEFAULT_COLORS[dIndex];
          }
        });
      } else {
        // handles clicks on non-graphed part of chart
        datasets.forEach((d, dIndex) => {
          datasets[dIndex].backgroundColor = BREAKDOWN_DEFAULT_COLORS[dIndex];
        });
      };
      this.chart.update();
    };
  };

  handleHover = (evt) => {
    const { activeMonthIndex, activeDatasetIndex, hasHoverEvent } = this.props;
    if (hasHoverEvent) {
      const activeElement = this.chart.getElementAtEvent(evt);
      
      if(!activeElement.length) return;
      const bar = activeElement[0];

      // return if hovered bar is a scenario or already active
      if (bar._index === activeMonthIndex && bar._datasetIndex === activeDatasetIndex) return;
  
      const barHeight = bar.height();
      const barWidth = _.get(bar, '_model.width', 0);
      
      // Eye icon
      let tooltipEl = document.getElementById('chartjs-tooltip');
      
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.classList.add('popover', 'bs-popover-top', 'p-3')
        tooltipEl.style.maxWidth = 'unset';
        tooltipEl.innerHTML = '';
        document.body.appendChild(tooltipEl);
      }
  
      let eyeIcon = document.getElementById('chartjs-tooltip-eye');
  
      if (!eyeIcon)  {
        eyeIcon = document.createElement('i');
        eyeIcon.id = 'chartjs-tooltip-eye';
        eyeIcon.classList.add('fe', 'fe-eye');
        tooltipEl.appendChild(eyeIcon);
      }

      // resize icon relative to bar width, but with clamp restriction.
      const eyeSize = Math.min(Math.max(barWidth - 8, 16), 25);

      eyeIcon.style.opacity = 1;
      eyeIcon.style.left = '50%';
      eyeIcon.style.color =  'white';
      eyeIcon.style.position =  'absolute';
      eyeIcon.style.transform = 'translateX(-50%)';
      eyeIcon.style.top = 115 + (barHeight/2) + 'px';
      eyeIcon.style.fontSize = eyeSize + 'px';
    }
  };

  drawGraph() {
    const { chartRef } = this;
    const { type, labels, datasets, options } = this.props;
    // Chart instance needs to be destroyed to prevent chart overlap.
    // https://www.chartjs.org/docs/latest/developers/api.html#destroy
    if (this.chart && this.chart !== null) {
      this.chart.destroy();
    }
    
    this.chart = new Chart(chartRef, {
      type,
      data: {
        labels,
        datasets,
      },
      options: { ...options, onClick: this.handleClick, onHover: this.throttledHover },
    });
  }

  render() {
    const { datasets } = this.props;
    return (
      <div style={{ position: "relative" }}>
        {
          _.isEmpty(datasets) 
          ? (
            <div className="absolute-center">
              <Loader />
            </div> ) 
          : null
        }
        <canvas
          style={{ height: 300 }}
          ref={(chartRef) => { this.chartRef = chartRef; }}
        />
      </div>
    );
  }
}

export default ChartJs;
