import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Modal.css';

class Modal extends Component {
  static propTypes = {
    // NOTE: modalId is targeted by its caller via either data-attribute or javascript:
    // ie. <button...data-toggle="modal" data-target="#<modalId>">
    // ie. $('#<modalId>').modal(<option>)
    // Options: https://getbootstrap.com/docs/4.1/components/modal/#options

    modalId: PropTypes.string.isRequired,
    header: PropTypes.string,
    children: PropTypes.any,
    hideCloseButton: PropTypes.bool,
    type: PropTypes.string,
  }
  // type refers to orientation of modal (i.e., center vs. vertical)

  static defaultProps = {
    header: '',
    type: '',
    hideCloseButton: false,
  }

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    const { modalId, header, hideCloseButton, type } = this.props;
    const closeButton = !hideCloseButton && (
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    );
    return (
      <div className="modal fade" id={modalId} tabIndex="-1" role="dialog" aria-labelledby={`${modalId}Title`} aria-hidden="true">
        <div className={`modal-dialog modal-dialog-${type || 'centered'} modal-lg`} role="document">
          <div className="modal-content">
            {
              header && (
                  <div className="modal-header">
                    <h4 className="modal-title" id={`${modalId}Title`}>{header}</h4>
                    { closeButton }
                  </div>
                )
            }
            <div className="modal-body">
              {!header && closeButton}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
