import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import InputField from './InputField';
import SummaryItem from './SummaryItem';

import { deleteScenarioItem } from '../parse';
import { fourDecimalPlaces } from '../utils/regex';
import { NEW_QTY, QTY, TICKER_OBJECT, ERR_DELETING_SCENARIO_ITEM } from '../constants';

import './SmallTable.css';

const INPUT = 'INPUT';

class SmallTable extends Component {
  static propTypes = {
    editable: PropTypes.bool,
    tHeader: PropTypes.array,
    deletable: PropTypes.bool,
    updateQty: PropTypes.func,
    className: PropTypes.string,
    tableClass: PropTypes.string,
    showNotification: PropTypes.func,
    entries: PropTypes.array.isRequired,
    setFocusOnInputIndex: PropTypes.number,
    removeScenarioItem: PropTypes.func,
  };

  static defaultProps = {
    tHeader: [],
    className: '',
    tableClass: '',
    editable: false,
    deletable: false,
    updateQty: () => {},
    setFocusOnInputIndex: null,
    showNotification: () => {},
    removeScenarioItem: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      quantity: '',
      inputIndex: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { setFocusOnInputIndex } = this.props;
    if(prevProps.setFocusOnInputIndex !== setFocusOnInputIndex) {
      this.setState({ inputIndex: setFocusOnInputIndex });
    }
  }

  handleClick = (e) => {
    const { inputIndex } = this.state;
    if (e.target.tagName === INPUT && inputIndex !== null) {
      this.setState({ inputIndex: null, quantity: '' });
    }
  }

  updateQuantity(quantity) {
    if (!quantity || fourDecimalPlaces.test(Math.abs(quantity)) || quantity === '-') {
      this.setState({ quantity });
    }
  }

  renderHeader = arr => (
    <thead>
      <tr>
        {arr.map((name, i) => (
            <th scope="col" key={i}>{name}</th>
        ))}
      </tr>
    </thead>
  )

  submitUpdate = (e, symbol) => {
    e.preventDefault();
    const { updateQty } = this.props;
    const { quantity } = this.state; 
    if (quantity !== '' && !isNaN(quantity)) {
      const value = parseFloat(quantity);
      updateQty(value, symbol);
    }

    this.setState({ inputIndex: null, quantity: '' });
  }

  setInputIndex = (id, value) => {
    this.setState({ inputIndex: id, quantity: value });
  }

  async onDeleteScenarioItem(scenarioItemId) {
    const { removeScenarioItem, showNotification } = this.props;
    try {
      await deleteScenarioItem(scenarioItemId);
      removeScenarioItem(scenarioItemId);
    } catch (err) {
      console.log('Error deleting scenario item', err);
      showNotification('error', ERR_DELETING_SCENARIO_ITEM);
    }
  };

  displayEditableContent = (obj, key, i) => {
    const { inputIndex, quantity } = this.state;
    const { deletable } = this.props;
    const value = obj[key];
    const { id } = obj;
    if (key === TICKER_OBJECT) {
      const scenarioItemId = obj[TICKER_OBJECT].id;
      return (
        <td key={key+id} className="border-light align-items-center" >
          <span className="d-flex text-left align-items-center mr-3">
            <SummaryItem
              heading={value.company}
              tickerSymbol={value.symbol}
              price={value.price}
            />
            {deletable &&
              <button className="border-0 p-0 cursor-pointer ml-3 bg-trans" onClick={()=> this.onDeleteScenarioItem(scenarioItemId)}>
                <i className="fe fe-trash-2"></i>
              </button>
            }
          </span>

        </td>
      );
    } else if (inputIndex === i && (key === NEW_QTY || key === QTY)) {
      return (
        <td key={key+id} className="border-light align-middle py-0" style={{maxWidth: '80px'}}>
          <form onSubmit={e => this.submitUpdate(e, obj[TICKER_OBJECT].symbol)}>
            <InputField
              type="text"
              controlled
              value={quantity}
              onInputChange={(value)=>this.updateQuantity(value)}
              placeholder={(value).toString()} id={(value).toString()}
              stylingClasses="text-right"
              onBlur={this.handleClick}
              onEscapeKey={this.handleClick}
              autoFocus
            />
          </form>
        </td>
      );
    } else {
      return (
        <td key={key+id} className="border-light align-middle" onClick={() => this.setInputIndex(i, value)} onFocus={this.handleClick}>
          <div className={key === NEW_QTY ? 'input-display' : ''}>{value}</div>
        </td>
      );
    }
  }

  render() {
    const { tableClass, entries, tHeader, editable, className } = this.props;
    const objectKeys = !_.isEmpty(entries) && Object.keys(entries[0]).filter(name => name !== 'id');

    return (
      <table className={`table ${tableClass || 'table-sm'} ${className}`}>
        { tHeader ? this.renderHeader(tHeader) : '' }
        <tbody>
          {
            entries.map((entry, i) => (
              <tr key={i} className="text-center">
                { objectKeys.map((objectKey, j) => (
                  editable 
                    ? (this.displayEditableContent(entry, objectKey, i)) 
                    : (
                      <td className="border-light align-items-center" key={j}>
                        {entry[objectKey]}
                      </td>
                    )
                ))}
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}

export default SmallTable;
