import { SETTINGS } from "./setting";

// general error
export const ERR_INTERNAL_ERROR = 'Internal Server Error';

// infusionsoft error
export const SUBSCRIBE_PLAN_ERROR = `It looks like your account is already subscribed to this plan. If this doesn't seem right, please contact ${SETTINGS.SUPPORT_EMAIL} for help.`;

// signin page
export const ERR_INCORRECT_CREDENTIAL = 'The email or password you entered is incorrect';

// import transaction page
export const ERR_INCORRECT_FILE_TYPE = 'Incorrect file type.';
export const ERR_IMPORT_TRANSACTIONS = 'There is an error with uploading your file.';
export const DUPE_COLUMN_SELECTION = 'Detected a duplicate from your column selections.';
export const ERR_EMPTY_PORTFOLIO_NAME = 'Portfolio name cannot be left blank.';
export const ERR_PASSWORD_REQUIREMENTS = 'Password does not meet requirements';
export const MISSING_DATE_FORMAT = 'Please select date format';

// portfolio page
export const ERR_INSUFFICIENT_OFFSITE_DATA = 'The selected ticker doesn\'t have sufficient data available to complete this import process.';
export const ERR_CREATING_TRANSACTION = 'There was an error importing your transaction.';
export const ERR_EDITING_TRANSACTION = 'There was an error editing your transaction.';
export const ERR_UPDATING_QTY = 'There was an error updating the quantity.';
export const ERR_DELETING_SCENARIO_ITEM = 'There was an error deleting your scenario item';
export const ERR_BASIC_PLAN_PORTFOLIO_LIMIT_REACHED = 'Basic Plan does not allow for more than 1 portfolio.';
export const ERR_BASIC_PLAN_TICKER_LIMIT_REACHED = 'You have reached the limit of 10 tickers per portfolio under the basic plan.';
export const ERR_DELETE_TX = 'There was an error deleting your transaction(s).'
export const ERR_MAX_TX_LIMIT_REACHED = 'You have already reached the maximum number of transactions for this portfolio.'
export const ERR_MAX_TX_LIMIT_REACHED_FOR_CSV_IMPORT = 'This file import will exceed the maximum number of transactions per portfolio. Try deleting some transactions, or import them into a new portoflio.'

// investment institution page
export const ERROR_TOKEN_LINK = 'Import from brokerage account temporarily disabled: Unable to establish connection with Plaid. Please try again later.';
export const ERROR_GET_INSTITUTIONS = 'There was an error retrieving your investment institutions.';
export const ERROR_DELETE_INSTITUTION = 'There was an error deleting your investment institution.';
export const NO_VALID_TRANSACTIONS = 'There are no applicable investment transactions found from this investment institution.';
export const ERROR_CONNECTING_INSTITUTION = 'There was an error connecting to the selected brokerage account';

// plaid
export const PLAID_GENERAL_ERROR = 'There is error connecting with Plaid. Please unlink portfolio or try again later.';
export const PLAID_CONNECT_ACCOUNT_ERROR = 'There was an error connecting to your Plaid account. Please reconnect your account or try again later.';