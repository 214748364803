import React, { Component }from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';

import './DropZoneInput.css';

function typesText(types) {
  if (isNil(types) || isEmpty(types)) {
    return null;
  }

  const tail = [...types.map(type => type.toUpperCase())];
  const head = tail.splice(0, tail.length - 1);
  const joinedTypes = [head.join(', '), tail[0]].filter(s => s);

  return `${joinedTypes.join(' or ')} only`;
}

class DropZoneInput extends Component {
  static propTypes = {
    info: PropTypes.string,
    types: PropTypes.array,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    const { info, types } = this.props;
    return (
      <div className="drop-zone">
        <div>
          <p>
            <i className="fe fe-upload" />
          </p>
          <p>{info}</p>
          <p className="allowed-types">
            ({typesText(types)})
          </p>
        </div>
      </div>
    );
  }
}

export default DropZoneInput;
