import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { VALID_GREEN, INVALID_RED, INPUT_TYPE, INPUT_VALIDATION_TYPE } from '../constants'; 
import { checkPasswordFormat, checkEmailFormat } from '../utils/regex';
import './InputField.css';

export default class InputField extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onEscapeKey: PropTypes.func,
    autoFocus: PropTypes.bool,
    stylingClasses: PropTypes.string,
    clearRefOnModalClose: PropTypes.string,
    type: PropTypes.oneOf([
      INPUT_TYPE.EMAIL, INPUT_TYPE.PASSWORD, INPUT_TYPE.SEARCH, 
      INPUT_TYPE.TELEPHONE, INPUT_TYPE.TEXT, INPUT_TYPE.URL, INPUT_TYPE.TIME
    ]).isRequired,
    disabled: PropTypes.bool,
    // id of the form for the input field
    // this id will be used for checking form validation when user press 'Enter' key
    formId: PropTypes.string,

    // for uncontrolled input:
    inputValue: PropTypes.func,
    defaultValue: PropTypes.string,
    
    // for controlled input:
    controlled: PropTypes.bool,
    onInputChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    feedbackText: PropTypes.string,
    title: PropTypes.string,
    // parent component may toggle boolean to reset the validation state of this child component:
    clearValidation: PropTypes.bool,
  }

  static defaultProps = {
    label: '',
    required: false,
    pattern: null,
    placeholder: '',
    defaultValue: null,
    onBlur: null,
    onEscapeKey: null,
    autoFocus: null,
    stylingClasses: '',
    clearRefOnModalClose: null,
    disabled: false,
    // required for controlled input:
    controlled: false,
    onInputChange: null,
    value: '',
    formId: null,
    feedbackText: '',
    title: '',
    clearValidation: false,
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      validationType: INPUT_VALIDATION_TYPE.NONE,
    }
  }

  componentDidMount() {
    const { clearRefOnModalClose } = this.props;
    if (clearRefOnModalClose) {
      const modal = $(`#${clearRefOnModalClose}`);
      modal.on('hidden.bs.modal', () => {
        if (this.inputRef.current) {
          this.inputRef.current.value = '';
        }
      });
    }
  }
  
  componentDidUpdate(prevProps) {
    const { clearValidation } = this.props;
    if(prevProps.clearValidation !== clearValidation){
      this.setState({ validationType: INPUT_VALIDATION_TYPE.NONE });
    }
  }

  uncontrolledOnChange() {
    // for uncontrolled input: sends the value of the input back to its parent,
    // create a ref called inputValue in the parent component for this to work.
    const { inputValue } = this.props;
    inputValue(this.inputRef.current.value);
  }

  controlledOnChange(e) {
    // for controlled input: sends value to the state-changing function of its parent,
    // the input will always reflect the state via value props
    const { onInputChange } = this.props;
    onInputChange(e.target.value);
    this.validateInputField(e.target.value);
  }
  
  revealPassword = () => {
    const { id } = this.props;
    const password = document.getElementById(id);
    if (password) {
      if (password.type === INPUT_TYPE.PASSWORD) {
        password.setAttribute('type', INPUT_TYPE.TEXT);
      } else {
        password.setAttribute('type', INPUT_TYPE.PASSWORD);
      }
    }
  }

  handleKeyDown(e) {
    const { formId } = this.props;
    if (e.key ==='Escape'){
      const { onEscapeKey } = this.props; 
      const current = this.inputRef.current;
      if (current) current.value = '';
      if (onEscapeKey) onEscapeKey(e);
    }
    if (e.key === 'Enter' && formId) {
      // perform validate the form 
      // if it pass the validation let if flow with the normal step
      if(!document.querySelector(`#${formId}`).reportValidity()){
        e.preventDefault();
      } 
    }
  }

  validateInputField(value) {
    const { type } = this.props;

    let validationType = INPUT_VALIDATION_TYPE.NONE;
    if (!value) validationType = INPUT_VALIDATION_TYPE.ERROR;
    switch (type) {
      case INPUT_TYPE.EMAIL:
        validationType = (value.length > 0) && checkEmailFormat.test(String(value).toLowerCase()) ? INPUT_VALIDATION_TYPE.SUCCESS : INPUT_VALIDATION_TYPE.ERROR;
        break;
      case INPUT_TYPE.PASSWORD: 
        validationType = checkPasswordFormat.test(value) ? INPUT_VALIDATION_TYPE.SUCCESS : INPUT_VALIDATION_TYPE.ERROR;
        break;
      default:
        validationType = (value.length > 0) ? INPUT_VALIDATION_TYPE.SUCCESS : INPUT_VALIDATION_TYPE.ERROR;
    }
    this.setState({ validationType });
  }

  render() {
    const { id, name, label, required, placeholder, defaultValue, pattern, type, stylingClasses, 
            onBlur, autoFocus, controlled, value, feedbackText, title, disabled } = this.props;    
    const { validationType } = this.state;
    const hasLabel = label ? <label htmlFor={id}>{label}</label> : null;

    let validationColor = '';
    if (controlled) {
      if (validationType === INPUT_VALIDATION_TYPE.SUCCESS) {
        validationColor = VALID_GREEN;
      } else if (validationType === INPUT_VALIDATION_TYPE.ERROR) {
        validationColor = INVALID_RED;
      }
    }

    return (
      <div className="input-field-container form-group flex-fill pr-11 mb-0">
        {hasLabel}
        <div className={type === INPUT_TYPE.PASSWORD ? "input-group input-group-merge" : ''}>
          { controlled
          ? (
            <input
              id={id}
              name={name}
              type={type}
              pattern={pattern}
              required={required}
              aria-label={placeholder}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onBlur={onBlur}
              onKeyDown={e => this.handleKeyDown(e)}
              style={{ borderColor: validationColor }}
              className={`${stylingClasses} ${type === INPUT_TYPE.PASSWORD ? 'no-border-radius-right' : ''} form-control `}
              disabled={disabled}
              // controlled input option:
              value={value}
              onChange={(e) => this.controlledOnChange(e)}
              title={title}
            />
          )
          : (
            <input
              id={id}
              name={name}
              type={type}
              pattern={pattern}
              required={required}
              aria-label={placeholder}
              placeholder={placeholder}
              autoFocus={autoFocus}
              onBlur={onBlur}
              onKeyDown={e => this.handleKeyDown(e)}
              className={`${stylingClasses} ${type === INPUT_TYPE.PASSWORD ? 'no-border-radius-right' : ''} form-control `}
              disabled={disabled}
              // uncontrolled input option:
              ref={controlled ? null : this.inputRef}
              defaultValue={defaultValue}
              onChange={() => this.uncontrolledOnChange()}
              title={title}
            />
          )}
          
          {/* add visibility icon to password fields */}
          {
            type === 'password'
              ? (
                <div className="input-group-append">
                  <span className="input-group-text cursor-pointer" style={{ borderColor: validationColor}} onClick={() => this.revealPassword()}>
                    <i className="fe fe-eye" />
                  </span>
                </div>
              ) : null
          }
        </div>
        <div className={`${validationType === INPUT_VALIDATION_TYPE.SUCCESS || !validationType ? 'hide-invalid-feedback' : 'invalid-color'}`}>{feedbackText}</div>
      </div>
    );
  }
}
