import React, { Component } from 'react';
import Modal from '../containers/Modal';

export default class StatModal extends Component {
  render() {
    const { id, header } = this.props;

    return (
      <Modal modalId={id} header={header}>
        {this.props.children}
      </Modal>
    );
  }
}
