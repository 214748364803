import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Parse from 'parse';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Tabs from '../../containers/Tabs';
import Tab from '../../components/Tab';
import AccountSettings from './AccountSettings';
import AccountProfile from './AccountProfile';
import AccountBilling from './AccountBilling';
import AccountNotificationSettings from './AccountNotificationSettings';
import { eventUserAccountManaged } from '../../utils/googleAnalytics';

const tabIndices = { settings: 0, profile: 1, billing: 2, notifications: 3 };

class Account extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    tagIds: PropTypes.object,
    productIds: PropTypes.object,
    checkUserPlans: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tagIds: null,
    productIds: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { heading: 'Account', queryString: 'settings', TabComponent: AccountSettings },
        { heading: 'Profile', queryString: 'profile', TabComponent: AccountProfile },
        { heading: 'Billing', queryString: 'billing', TabComponent: AccountBilling },
        { heading: 'Notifications', queryString: 'notifications', TabComponent: AccountNotificationSettings },
      ],
      activeTab: 0,
      passedDownTab: null,
      isAdmin: false,
    };
  }

  async componentDidMount() {
    const { match, refreshNotifications } = this.props;
    this.setState({ passedDownTab: tabIndices[match.params.tabName] });
    eventUserAccountManaged();

    refreshNotifications();
  }

  async componentWillMount() {
    const { tabs } = this.state;
    const isAdmin = await Parse.Cloud.run('isAdmin');
    this.setState({ isAdmin });
    if (isAdmin) {
      tabs.splice(2, 1); // remove billing from tab
      this.setState({ tabs });
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { isAdmin } = this.state;
    if (prevProps.match.params !== match.params) {
      if (isAdmin && tabIndices[match.params.tabName] === tabIndices.notifications) {
        this.setState({ passedDownTab: tabIndices.billing });
      } else {
        this.setState({ passedDownTab: tabIndices[match.params.tabName] });
      }
    }
  }

  updateUserInformation = async (invalidateCache) => {
    const { email } = Parse.User.current().attributes;
    return await Parse.Cloud.run('getUserInformation', { email, invalidateCache });
  }

  render() {
    const { tabs, activeTab, passedDownTab } = this.state;
    const { tagIds, productIds, checkUserPlans, onPremiumPlan } = this.props;
    const localStorageTabIndex = Number(localStorage.getItem('activeTab'));
    return (
      <div className="container">
        <div className="mx-5 my-5">
          <Header content="Settings" icon={<i className="fe fe-settings" />} />
          <Tabs defaultActiveTabIndex={activeTab || localStorageTabIndex} passedDownTab={passedDownTab}>
            {
              tabs.map((obj, i) => {
                const { TabComponent } = obj;
                return (
                  <Tab linkClassName="" heading={obj.heading} queryString={obj.queryString} key={i}>
                    <TabComponent 
                      tagIds={tagIds} 
                      productIds={productIds} 
                      checkUserPlans={checkUserPlans} 
                      updateUserInformation={this.updateUserInformation} 
                      onPremiumPlan={onPremiumPlan}
                    />
                  </Tab>
                );
              })
            }
          </Tabs>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Account);
