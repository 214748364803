import _ from "lodash";

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

export const currencyWithWords = (number) => {
  if (_.isNil(number) || Number.isNaN(number)) {
    return ("N/A");
  }

  const divisorsByDigitPlace = [
    { divisor: 1e9, suffix: ' billion' },
    { divisor: 1e6, suffix: ' million' },
    { divisor: 1e3, suffix: ' thousand' },
    { divisor: 1, suffix: '' },
  ].find(({ divisor }) => Math.abs(number) >= divisor);

  if (!_.isEmpty(divisorsByDigitPlace)) {
    return (currencyFormatter.format(Math.abs(number) / divisorsByDigitPlace.divisor)) + divisorsByDigitPlace.suffix;
  }
  return number.toFixed(2);
}