import React, { Component } from 'react';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';

import Dropdown from '../../components/Dropdown';
import InputField from '../../components/InputField';
import Toast from '../../components/Toast';
import { SETTINGS, ACCOUNT_PROFILE_TITLE } from '../../constants';
import { withGA } from '../../utils/googleAnalytics';

const UserSettings = Parse.Object.extend('UserSettings');

class AccountProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSettings: {},
      profileItems: {},
      showInput: false,
      toast: null,
    };
    this.reasonForInvesting = '';
  }

  componentDidMount() {
    this.getUserSettings().then(() => {
      const { userSettings } = this.state;
      if (userSettings && Object.keys(userSettings).length > 0) {
        this.setState({ profileItems: userSettings.attributes.profileData || {} });
        this.reasonForInvesting = userSettings.attributes.profileData ? userSettings.attributes.profileData.inputVal : '';

        const input = userSettings.attributes.profileData ? userSettings.attributes.profileData.inputVal : '';
        if (input && input.length > 0) {
          this.setState({ showInput: true });
        }
      }
    });
    document.title = ACCOUNT_PROFILE_TITLE;
  }

  getUserSettings = async () => {
    const currentUser = Parse.User.current();
    const query = new Parse.Query(UserSettings);
    query.equalTo('user', currentUser);
    try {
      const result = await query.first();
      if (result) this.setState({ userSettings: result });
    } catch (err) {
      console.log('Error: ', err);
    }
  }

  handleClick = (e, questionKey, menu) => {
    const { profileItems } = this.state;
    Object.keys(menu).forEach((k) => {
      if (e.target.innerText === 'Other') {
        this.setState({ showInput: true });
      }
      if (e.target.innerText !== 'Other' && questionKey === 'OPT3') {
        this.setState({ showInput: false });
        profileItems.inputVal = '';
      }
      if (menu[k] === e.target.innerText && profileItems) {
        profileItems[questionKey] = k;
      }
    });
    this.setState({ profileItems });
    this.updateProfileSettings(profileItems);
  }

  updateProfileSettings = async (profileItemsObj) => {
    const currentUser = Parse.User.current();
    const query = new Parse.Query(UserSettings);
    query.equalTo('user', currentUser);
    try {
      const result = await query.first();
      if (!result) {
        this.createUserSettings(profileItemsObj);
      }
      result.save({ profileData: profileItemsObj });
      this.getUserSettings();
    } catch (err) {
      console.log('Error', err);
    }
    this.setState({ toast: { type: 'success', message: 'Saved' } });
    setTimeout(() => this.hideNotification(), 2500);
  }

  hideNotification = () => {
    this.setState({ toast: null });
  }

  createUserSettings = async (profileItemsObj) => {
    const userSettings = new UserSettings();
    const currentUser = Parse.User.current();
    userSettings.setACL(new Parse.ACL(currentUser));
    try {
      await userSettings.save({ user: currentUser, profileData: profileItemsObj });
      this.getUserSettings();
    } catch (err) {
      console.log('Error: ', err);
    }
  }

  saveInputVal = () => {
    const { profileItems } = this.state;
    profileItems.inputVal = this.reasonForInvesting;
    this.setState({ profileItems });
    this.updateProfileSettings(this.state.profileItems);
  }

  render() {
    const { profileItems, showInput, toast } = this.state;
    return (
      <div className="container mt-5 p-0">
        <form id="password-reset-form" className="needs-validation">
          <div className="row">

            <div className="col-8">
              {
                SETTINGS.PROFILE_DROPDOWN_OPTIONS.map((option) => {
                  const { label, key, menu, text } = option;
                  return (
                    <div className="row" key={key}>
                      <div className="col-12">
                        <div className="form-group">
                          <Dropdown
                            dashClass="btn-white"
                            text={profileItems[key] ? menu[profileItems[key]] : text }
                            label={label}
                            menu={Object.values(menu)}
                            id={key}
                            onChangeAction={(e) => { this.handleClick(e, key, menu); }}
                            />
                        </div>
                      </div>
                    </div>
                  );
                })
              }
              {
                showInput
                ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group" onBlur={this.saveInputVal}>
                        <InputField
                          type="text"
                          id="hiddenProfileInput"
                          inputValue={(input) => { this.reasonForInvesting = input; }}
                          placeholder={this.reasonForInvesting}
                          />
                      </div>
                    </div>
                  </div>
                ) : null
              }
            </div>

            <div className="col">
              <div className="alert alert-white py-4 border" style={{marginTop: '2em'}}>
                <h3><i className="fe fe-info mr-3 d-inline-block" style={{transform: 'translateY(1px)'}}></i>Why do you need this info?</h3>
                <small>
                  <p className="text-muted">We&apos;re working on new features all the time. This information is used to help personalize recommendations specifically for you. We&apos;ll never rent or sell your info.</p>
                  <p className="text-muted mb-0"> <a href={SETTINGS.PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">Read our Privacy Policy</a></p>
                </small>
              </div>
              <Toast notification={toast} onClose={() => this.hideNotification()} />
            </div>

          </div>
        </form>

      </div>
    );
  }
}

export default withGA(withRouter(AccountProfile));
