import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Parse from 'parse';

class PrivateRoute extends Component {
  render() {
    const currentUser = Parse.User.current();
    const { RouteComponent, path, ...rest } = this.props;
    return (
      <Route
        path={path}
        render={(props) => {
          if (currentUser) return <RouteComponent {...rest} />;
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }}
      />);
  }
}
export default PrivateRoute;
