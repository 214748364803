import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBox extends Component {
    static propTypes = {
      errorMsg: PropTypes.string,
    }

    static defaultProps = {
      errorMsg: null,
    }

    render() {
      const { errorMsg } = this.props;
      return errorMsg ? (
        <div className="alert alert-danger" role="alert">
          { errorMsg }
        </div>
      ) : null;
    }
}

export default ErrorBox;
