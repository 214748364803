import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ActionLink from '../components/ActionLink';

class IconDropdown extends Component {
  static propTypes = {
    options: PropTypes.object,
    iconClass: PropTypes.string,
  }

  static defaultProps = {
    options: null,
    iconClass: '',
  }

  render() {
    const { options } = this.props;
    const optionsArr = [];

    Object.keys(options).forEach((key) => {
      optionsArr.push(<ActionLink dropdownItem key={key} text={key} clickAction={options[key]} />);
    });

    return (
      <div id="dropdown-icon" className="avatar avatar-sm mx-2">
        <img id="dropdown-icon" src="/img/avatar-navy.svg" alt="My Account" className="avatar-img rounded-circle cursor-pointer" aria-haspopup="true" data-toggle="dropdown"/>
        <div className="dropdown-menu dropdown dropdown-menu-right" aria-labelledby="dropdownMenuOffset">
          {optionsArr}
        </div>
      </div>
    );
  }
}

export default IconDropdown;
