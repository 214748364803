import React, { Component } from 'react';
import Parse from 'parse';
import { withRouter } from 'react-router-dom';
import { withGA, eventUserAccountCreationCompleted } from '../../utils/googleAnalytics';

import PropTypes from 'prop-types';
import Toast from '../../components/Toast';
import ActionLink from '../../components/ActionLink';

class VerifyEmailPage extends Component {
  static propTypes = {
    userInfo: PropTypes.object.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      toast: null,
    };
  }

  componentDidMount() {
    eventUserAccountCreationCompleted();
  }

  hideNotification = () => {
    this.setState({ toast: null });
  }

  resendVerificationEmail = () => {
    const { userInfo, userEmail } = this.props;
    const email = userEmail || userInfo.email_addresses[0].email;

    Parse.Cloud.run('resendVerificationEmail', { email })
      .then((res) => {
        if (res === 'success') {
          this.setState({ toast: { type: 'success', message: 'Email Sent' } });
          setTimeout(() => this.hideNotification(), 2500);
        } else {
          this.setState({ toast: { type: 'error', message: 'Fail to send verification email' } });
          setTimeout(() => this.hideNotification(), 2500);
        }
      })
      .catch((err) => {
        console.log('Error', err);
        this.setState({ toast: { type: 'error', message: 'Fail to send verification email' } });
        setTimeout(() => this.hideNotification(), 2500);
      });
  }

  render() {
    const { userEmail } = this.props
    const { toast } = this.state;
    return (
      <div className="container-fluid center-signup">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 col-xl-4 my-5 text-center">
            <i className="fe fe-send mb-4" style={{fontSize: '48px'}}></i>
            <h1 className="display-1">Almost done!</h1>
            { userEmail ? (
              <p className="lead">Please verify your email address. <ActionLink text="Resend verification email" clickAction={() => { this.resendVerificationEmail(); }} /></p>
            ): (
              <div>
                <p className="lead">We just sent you a link to verify your email address. Click the link to verify your email, then log in with your existing Investors Alley password.
                If you do not have an existing password for Investors Alley, you will receive an email with a new password.</p>
                <p className="text-muted">Can&rsquo;t find the email? Check your spam folder or <ActionLink text="resend verification email" clickAction={() => { this.resendVerificationEmail(); }} />.</p>
              </div>
            )}
          </div>
        </div>
        <Toast notification={toast} onClose={() => this.hideNotification()} />
      </div>
    );
  }
}

export default withGA(withRouter(VerifyEmailPage));
