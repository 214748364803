import React, { Component } from 'react';
import HeadRoom from 'react-headroom';
import { withRouter } from 'react-router-dom';

import $ from 'jquery';
import Parse from 'parse';
import NavBar from './containers/NavBar';
import Main from './pages/Main';

import './App.css';

import { PARSE_CONFIGURATION } from '../src/constants';
import getConfig from './utils/getConfig';

class App extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      userIsAdmin: false,
      progressPercentage: 0,
      notificationsSeen: false,
      // state for handling event between NavBar & Main components:
      resetImportState: false,

      // todo: seen and unseen are temporary placeholders -- will be replaced by api call to change status of
      // each individual notification. I just needed a way to change them all at once (set state) for local - MS
      seen: true,
      unseen: false,
      userInfo: {},
      // parse configurations
      tagIds: null,
      productIds: null,
      // user plan information
      onBasicPlan: true, // default set to be true or else it will render incorrectly
      onPremiumPlan: false,
      subscribedToTDH: false,
      loadingPlanType: true,
    };
  }

  async componentWillMount () {
    // 6/14/19 update changes some state data and will cause error for users currently logged in
    // after push to production due to state persistence using hydrateStateWithSessionStorage.
    // this forces users to log out and clear their session storage
    if (!localStorage.getItem('acquired_update_06-15-19')) {
      const { history } = this.props;
      const { userIsAdmin } = this.state;
      await Parse.User.logOut();
      if (userIsAdmin) {
        history.push('/phinaz');
      } else {
        history.push('/login');
      }
      sessionStorage.clear();
    };

    try {
      const configurations = await getConfig();
      const tagIds = await configurations.get(PARSE_CONFIGURATION.TAGS_IDS);
      const productIds = await configurations.get(PARSE_CONFIGURATION.PRODUCT_IDS);
      await this.setState({ tagIds, productIds });
      await this.checkUserPlans();
    } catch (error) {
      console.log('Failed to get basic configuration information', error);
    }
  }

  componentDidMount = async () => {
    if (Parse.User.current()) {
      const admin = await Parse.Cloud.run('isAdmin');
      this.setState({ userIsAdmin: admin });
    }

    this.refreshNotifications();
  }

  checkUserPlans = async () => {
    this.setState({ loadingPlanType: true });
    const currentUser = Parse.User.current();

    try {
      if(!currentUser) return;
      
      currentUser.fetch();
 
      const configurations = await getConfig();
      const tagIds = await configurations.get(PARSE_CONFIGURATION.TAGS_IDS);

      let onBasicPlan = currentUser.attributes.plans.includes(tagIds.BASIC_PLAN);
      const onPremiumPlan = (currentUser.attributes.plans.includes(tagIds.PREMIUM_PLAN) || currentUser.attributes.plans.includes(tagIds.BUNDLE_PLAN));
      const subscribedToTDH = (currentUser.attributes.plans.includes(tagIds.TDH) || currentUser.attributes.plans.includes(tagIds.BUNDLE_PLAN));

      if (onBasicPlan && onPremiumPlan) {
        // in the faulty event when a user has both basic plans and premium plans
        onBasicPlan = false;
      }

      await this.setState({ onBasicPlan, onPremiumPlan, subscribedToTDH });
    } catch(e) {
      console.error('Error with checking user plans.', e);

      // after user logged-in, this could check against old session
      // even we do currentUser.fetch but session still refer to the old data from cache
      // need to force web browser to do hard reload
      if (e.code === Parse.Error.INVALID_SESSION_TOKEN) {
        window.location.reload();
      }
    } finally {
      this.setState({ loadingPlanType: false });
    }
  }

  setProgressPercentage = (progressPercentage) => {
    this.setState({ progressPercentage });
  }

  setNotificationStatus = (bool) => {
    this.setState({ notificationsSeen: bool, unseen: true });
  }

  updateUserRole = (isAdmin) => {
    // this received during loggin in adminUser, the current user is undefined right after sign-in (still not sure why) - Nan
    if (isAdmin) this.setState({ onPremiumPlan: true, subscribedToTDH: true, onBasicPlan: false, loadingPlanType: false });
    this.setState({ userIsAdmin: isAdmin });
  }

  updateUserInformation = (userInfo) => {
    this.setState({ userInfo });
  }

  toggleImportReset = () => {
    const { resetImportState } = this.state;
    this.setState({ resetImportState: !resetImportState })
  }

  refreshNotifications = async () => {
    const user = Parse.User.current();
    if (!user) {
      return;
    }

    const query = new Parse.Query('Notification');
    query.equalTo('user', user);
    query.descending('createdAt');

    const notifications = await query.find();

    this.setState({
      notifications,
    });
  }

  hideNotificationCard() {
    const menu = $("#notification-menu");
    menu.removeClass('show');
  }

  render() {
    const { progressPercentage, notifications, userIsAdmin, resetImportState, userInfo,
            tagIds, productIds, onBasicPlan, subscribedToTDH, onPremiumPlan, loadingPlanType } = this.state;
    const { history } = this.props;
    // obtains pathname and sets dynamic css attribute to NavBar accordingly
    const isImportTransactionPath = history.location.pathname === '/import-transactions';
    const isLogin = history.location.pathname === '/login' || history.location.pathname === '/phinaz';
    const navHeight = $("#nav-bar").outerHeight();

    let notificationsSeen = true;
    if (notifications) {
      notifications.forEach(notification => notificationsSeen = notificationsSeen && notification.get('viewed'));
    }

    return (
      <div className="App">

        { isLogin
          ? (
            <NavBar
              percentage={progressPercentage}
              notifications={notifications}
              notificationsSeen={notificationsSeen}
              setNotificationStatus={this.setNotificationStatus}
              userIsAdmin={userIsAdmin}
              toggleImportReset={this.toggleImportReset}
              tagIds={tagIds} 
              isPremium={onPremiumPlan}
              isTDH={subscribedToTDH}
            />
            )
          : (
            <HeadRoom 
              id="nav-bar"
              pinStart={-40}
              className={isImportTransactionPath ? "fixed-top" : null}
              onUnpin={() => this.hideNotificationCard()}
            >
              <NavBar
                percentage={progressPercentage}
                notifications={notifications}
                notificationsSeen={notificationsSeen}
                setNotificationStatus={this.setNotificationStatus}
                userIsAdmin={userIsAdmin}
                toggleImportReset={this.toggleImportReset}
                tagIds={tagIds}
                isPremium={onPremiumPlan}
                isTDH={subscribedToTDH}
              />
            </HeadRoom>
            )
        }

        <Main
          setProgressPercentage={this.setProgressPercentage}
          notificationsSeen={notificationsSeen}
          notifications={notifications}
          setNotificationStatus={this.setNotificationStatus}
          navPadding={isImportTransactionPath ? navHeight : 0 }
          updateUserRole={this.updateUserRole}
          updateUserInformation={this.updateUserInformation}
          userIsAdmin={userIsAdmin}
          resetImportState={resetImportState}
          userInfo={userInfo}
          refreshNotifications={this.refreshNotifications}
          tagIds={tagIds}
          productIds={productIds}
          onBasicPlan={onBasicPlan}
          onPremiumPlan={onPremiumPlan}
          subscribedToTDH={subscribedToTDH}
          checkUserPlans={this.checkUserPlans}
          loadingPlanType={loadingPlanType}
        />
      </div>
    );
  }
}

export default withRouter(App);
