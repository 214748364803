import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class SelectSubAccounts extends Component {
  static propTypes = {
    activeInstitution: PropTypes.object,
    confirmImport: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubAccountClick: PropTypes.func.isRequired,
    selectedSubAccounts: PropTypes.array,
    subAccounts: PropTypes.array,
  }

  render() {
    const { subAccounts, selectedSubAccounts, onSubAccountClick, activeInstitution, confirmImport, loading } = this.props;

    if (isEmpty(activeInstitution)) {
      // TODO: Implement display for empty state.
      return null;
    }

    return (
      <div className="m-2">
        <section className="modal-body">
          <p className="lead">Select the portfolio(s) you would like to import from your {activeInstitution.name} account:</p>

            {subAccounts.map(subAccount => {
              return (
                <div
                  className="form-check d-flex align-items-center mb-3"
                  key={subAccount.id}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={subAccount.id}
                    id={subAccount.id}
                    checked={selectedSubAccounts.includes(subAccount.id)}
                    onChange={() => onSubAccountClick(subAccount.id)}
                  />
                  <label className="form-check-label lead" htmlFor={subAccount.id} >
                    <p className="mb-0">{subAccount.name}</p>
                  </label>
                </div>
              )
            })}
        </section>
        <div className="modal-footer pb-0 pt-3">
          <button type="button" className="btn btn-outline-dark mr-auto" disabled={loading} data-dismiss="modal">Cancel</button>
          <button type="button" className="btn btn-primary" disabled={isEmpty(selectedSubAccounts) || loading} onClick={confirmImport}>Import Transactions</button>
        </div>
      </div>
    );
  }
}

export default SelectSubAccounts;
