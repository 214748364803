import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Button from '../components/Button';
import { EN, EN_GB, EN_FORMAT, EN_GB_FORMAT } from '../constants';

class ImportingError extends Component {
  static propTypes = {
    warningItem: PropTypes.object.isRequired,
    importTransaction: PropTypes.func.isRequired,
    updateDateFormat: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
  }

  static defaultProps = {
    dateFormat: null,
  }

  closeModal = (modalId) => {
    $(`${modalId}`).modal('hide');
  }

  importRecommended = async () => {
    const { dateFormat, updateDateFormat, importTransaction } = this.props;
    const newDateFormat = dateFormat === EN ? EN_GB : EN;
    await updateDateFormat(newDateFormat);
    importTransaction();
    this.closeModal('#import-transactions-warning');
  }

  importAsIs = () => {
    const { importTransaction } = this.props;
    importTransaction();
    this.closeModal('#import-transactions-warning');
  }

  render() {
    const { warningItem, dateFormat } = this.props;
    const objectKeys = Object.keys(warningItem);
    const objectValues = Object.values(warningItem);
    const selectedDate = dateFormat === EN ? EN_FORMAT : EN_GB_FORMAT;
    const recommendedFormat = dateFormat === EN ? EN_GB_FORMAT : EN_FORMAT;

    return (
      <div className="m-4">
        <div>You selected the {selectedDate} date format, but it looks like your file uses the {recommendedFormat} format.</div>
        <div className="d-flex">
          {objectKeys.map(objKey => <div className="row-content d-flex justify-content-center py-1" key={objKey}>{objKey}</div>)}
        </div>
        <div className="d-flex">
          {objectValues.map((objValue, vIndex) => <div className={`row-content border d-flex justify-content-center py-1 ${vIndex === 1 ? 'bg-light' : ''}`} key={objValue}>{objValue}</div>)}
        </div>
        <div className="d-flex mt-4" style={{justifyContent: 'space-between'}}>
          <Button name="Import with Recommended Format" clickAction={() => this.importRecommended()} />
          <Button name="Import As-Is" clickAction={() => this.importAsIs()} />
        </div>
      </div>
    );
  }
}

export default ImportingError;
