import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import $ from 'jquery';
import { isEmpty, get } from 'lodash';
import Flatpickr from 'react-flatpickr';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';

import ErrorBox from '../components/ErrorBox';
import Button from '../components/Button';
import InputField from '../components/InputField';

import 'flatpickr/dist/themes/airbnb.css'

import { deleteTransactionsByIds } from '../parse/transaction';
import { fourDecimalPlaces, twoDecimalPlaces } from '../utils/regex';
import { withGA, eventPortfolioUpdated } from '../utils/googleAnalytics';
import { ERR_CREATING_TRANSACTION, ERR_EDITING_TRANSACTION, ERR_INSUFFICIENT_OFFSITE_DATA, EN_FORMAT } from '../constants';

import './AddTicker.css';

const BUY = 'BUY', SELL = 'SELL';

class AddTicker extends Component {
  static propTypes = {
    getPortfolioData: PropTypes.func.isRequired,
    activePortfolio: PropTypes.object.isRequired,
    txToEdit: PropTypes.object,
    onSelectTxToEdit: PropTypes.func,
    showNotification: PropTypes.func,
  };

  static defaultProps = {
    txToEdit: {},
    onSelectTxToEdit: () => {},
  };

  _typeahead = '';
  state = {
    allowNew: false,
    isLoading: false,
    options: [],
    selectedTicker: null,
    quantity: null,
    price: null,
    date: this.startOfDate,
    transactionCost: 0,
    transactionType: BUY,
    autoClosingPriceChecked: false,
    errorMessage: null,
    loading: false,
    manualFilter: false,
    clearInputValidation: false,
  };

  startOfDate = moment().startOf('day').toDate();

  componentDidMount () {
    const modal = $('#addTicker');
    modal.on('hidden.bs.modal', () => {
      this._resetInputFields();
    });
  };

  componentDidUpdate(prevProps) {
    const { txToEdit, showOpenPositions } = this.props;
    if(!isEmpty(txToEdit) && prevProps.txToEdit !== txToEdit) {
      const { id, companyName, symbol, totalQuantity, price, date, cost } = txToEdit;
      this.setState({
        date,
        price,
        transactionCost: cost,
        quantity: Math.abs(totalQuantity),
        transactionType: showOpenPositions ? BUY : SELL,
        selectedTicker: { id, companyName, symbol },
      })
    }
  }

  // handling search with one key and w/ extra space
  _filterBy = (option, props) => {
    return (option.name.toLowerCase().indexOf(props.text.trim().toLowerCase()) !== -1 ||
    option.symbol.toLowerCase().indexOf(props.text.trim().toLowerCase()) !== -1)
  }

  _handleSearch = async (query) => {
    this.setState({ isLoading: true });
    const result = await Parse.Cloud.run('getSymbolLookup', { query });
    this.setState({isLoading: false, options: result});
  }

  _handleChange = async (ticker) => {
    this.setState({ errorMessage: '' }); // clear error message
    const { autoClosingPriceChecked } = this.state;
    if (autoClosingPriceChecked) {
      // use auto closing price
      try {
        const closingPrice = await Parse.Cloud.run('queryClosingPriceByTicker', { ticker: ticker[0].symbol });
        this.setState({ price: closingPrice });
      } catch (error) {
        console.error(error);
        this.setState({ errorMessage: ERR_INSUFFICIENT_OFFSITE_DATA });
      }
    }
    await this.setState({ selectedTicker: ticker[0] });
  }

  _updateTickerQty(quantity) {
    if (!quantity || fourDecimalPlaces.test(quantity)) {
      this.setState({ quantity })
    }
  }

  _updateTickerDate = (value) => {
    const date = value[0];
    this.setState({ date });
  }

  _updatePrice(price) {
    if (!price || twoDecimalPlaces.test(price)) {
      this.setState({ price });
    }
  }

  _updateTransactionCost(transactionCost) {
    if (!transactionCost || twoDecimalPlaces.test(transactionCost)) {
      this.setState({ transactionCost });
    }
  }

  _updateTransactionType (e) {
    const transactionType = e.target.value;
    this.setState({ transactionType })
  }

  _isIncomplete() {
    const { selectedTicker, quantity, price, autoClosingPriceChecked } = this.state;
    if ( !selectedTicker || !quantity || (!price && !autoClosingPriceChecked) ) {
      return true;
    }
  }

  _resetInputFields() {
    const { onSelectTxToEdit } = this.props;
    const { clearInputValidation } = this.state;

    if(this._typeahead) this._typeahead.getInstance().clear();
    this.setState({
      loading: false,
      selectedTicker: null,
      date: this.startOfDate,
      price: null,
      quantity: null,
      transactionType: BUY,
      autoClosingPriceChecked: false,
      transactionCost: 0,
      errorMessage: null,
      clearInputValidation: !clearInputValidation,
    });

    onSelectTxToEdit();
    $('#price').prop('disabled', false); // reset disable price input
  }

  async toggleAutoClosingPrice() {
    this.setState({ errorMessage: '' }); // clear error message
    let { autoClosingPriceChecked, selectedTicker } = this.state;
    autoClosingPriceChecked = !autoClosingPriceChecked; // revert the value here, so we can use it as condition check

    // disable Price field
    $('#price').prop('disabled', autoClosingPriceChecked);

    // if  checked and company is selected, get closing price
    // else just disable the input
    if (autoClosingPriceChecked && selectedTicker) {
      // use auto closing price
      try {
        const { symbol } = selectedTicker;
        const closingPrice = await Parse.Cloud.run('queryClosingPriceByTicker', { ticker: symbol });
        this.setState({ price: closingPrice });
      } catch (error) {
        console.error(error);
        this.setState({ errorMessage: ERR_INSUFFICIENT_OFFSITE_DATA });
      }
    }
    this.setState( { autoClosingPriceChecked });
  }

  enterFunction = (e) => {
    if(this._isIncomplete()) return;
    if (e.key === 'Enter') {
      this.submitTicker();
    }
  }

  submitTicker = async () => {
    this.setState({ loading: true, errorMessage: '' });

    const { activePortfolio, getPortfolioData, showNotification, txToEdit } = this.props;
    const { selectedTicker, quantity, date, transactionType, price, transactionCost } = this.state;

    const formattedPrice = parseFloat(price).toFixed(2);

    /*
      Note on transaction id:
      - BUY should refer to 'id'
      - SELL should refer to 'referenceId'.
      Due to a sing sell tx possibly being segmented to multiple rows,
      the id for sell tx is a randomized shortId while reference ID points to the actual tx in db.
    */

    const txId = get(txToEdit, 'referenceId') || get(txToEdit, 'id') || get(selectedTicker, 'id');
    if(!isEmpty(txToEdit)){ // UPDATE EXISTING TRANSACTION
      try {
        await Parse.Cloud.run('updateTransaction', {
          txId,
          date,
          quantity,
          transactionType,
          price: formattedPrice,
          transactionCost,
          portfolioId: activePortfolio.id,
        });

        await getPortfolioData();

        // GA tracking syntax for client -- want to track when user uses Add
        // Ticker feature
        eventPortfolioUpdated('Transaction Edited');
      } catch (err) {
        return this.setState({ errorMessage: ERR_EDITING_TRANSACTION, loading: false });
      }
    } else { // NEW TRANSACTION
      try {
        const newTx = await Parse.Cloud.run('createTransaction', {
          symbol: get(selectedTicker, 'symbol'),
          date,
          quantity,
          transactionType,
          price: formattedPrice,
          portfolioId: activePortfolio.id,
          transactionCost,
        });

        // refresh transactions & chart
        // if newly added ticker does not return any transaction data, it means iex does not have sufficient data to display.
        const newTxId = get(newTx, 'id');

        const portfolioData = await getPortfolioData();
        const { summary: { transaction } } = portfolioData;

        if (isEmpty(transaction) && !!newTxId) {
          await deleteTransactionsByIds([newTxId]);
          showNotification('error', ERR_INSUFFICIENT_OFFSITE_DATA);
          this.setState({ loading: false });
        }

        // GA tracking syntax for client -- want to track when user uses Add
        // Ticker feature
        eventPortfolioUpdated('Transaction Created by Adding Ticker');
      } catch (err) {
        return this.setState({ errorMessage: ERR_CREATING_TRANSACTION, loading: false });
      };
    }

    // reset input fields
    $('#addTicker').modal('hide');
  }

  render() {
    const { quantity, date, price, transactionCost, transactionType, autoClosingPriceChecked, errorMessage, loading, manualFilter, clearInputValidation } = this.state;
    const { txToEdit } = this.props;

    return (
      <form onKeyPress={(e) => this.enterFunction(e)}>
        <ErrorBox errorMsg={errorMessage} />

        <div className="form-group">
          <label htmlFor="tickerSymbol">Company</label>
          { !isEmpty(txToEdit)
            ? (
              <InputField
                id='edit-tx'
                type="text"
                controlled
                value={`${txToEdit.companyName} (${txToEdit.symbol})`}
                clearValidation={clearInputValidation}
                disabled
              />
            )
            : (
              <AsyncTypeahead
                {...this.state}
                minLength={1}
                useCache={false} // https://github.com/ericgio/react-bootstrap-typeahead/issues/417
                id="tickerSymbol"
                onSearch={this._handleSearch}
                onChange={this._handleChange}
                ref={(ref) => this._typeahead = ref}
                placeholder="Search by company name or symbol..."
                filterBy={ manualFilter ? this._filterBy : ["name", "symbol"] }
                labelKey={(option) => `${option.name} (${option.symbol})`} // displays result as "companyName (ticker)"
                renderMenuItemChildren={option => (
                  <span key={option.symbol}>{option.name}  ({option.symbol})</span>)
                }
              />
            )
          }
        </div>

        <div className="form-group">
          <label htmlFor="transactionType">Transaction Type</label>
          <div className="input-group">
            <select className="custom-select" id="transactionType" aria-label="Select Transaction Type" value={transactionType} onChange={(e) => this._updateTransactionType(e)}>
              <option value={BUY}>BUY</option>
              <option value={SELL}>SELL</option>
            </select>
          </div>
        </div>

        <div className="form-group">
          <InputField
            type="text"
            id="quantity"
            label="Quantity"
            stylingClasses="form-control"
            controlled
            value={quantity || ''}
            onInputChange={(value) => this._updateTickerQty(value)}
            placeholder='Enter the number of shares bought or sold'
            clearValidation={clearInputValidation}
            />
        </div>

        <div className="form-group">
          <label htmlFor="tickerPurchaseDate">{transactionType === BUY ? 'Purchase' : 'Sell'} Date</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fe fe-calendar"></i></span>
            </div>
            <Flatpickr
              id="tickerPurchaseDate"
              className="form-control"
              value={moment(date).format(EN_FORMAT)} // EN_FORMAT automatically converts date to start of date
              options={{
                maxDate: new Date(),
                dateFormat: 'm/d/Y',
              }}
              onChange={(d)=>this._updateTickerDate(d)}
              />
          </div>
        </div>

        <div className="form-row align-items-center">
          <div className="col">
            <div className="form-group mr-3">
              <label htmlFor="price">Price</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fe fe-dollar-sign"></i></span>
                </div>
                <InputField
                  type="text"
                  id="price"
                  stylingClasses="form-control no-border-radius rounded-right"
                  controlled
                  value={ price ? price : '' }
                  onInputChange={(value) => this._updatePrice(value)}
                  placeholder={transactionType === BUY ? 'Enter the price paid per share' : 'Enter the price sold per share'}
                  clearValidation={clearInputValidation}
                  />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="latest-closing-price"
                checked={autoClosingPriceChecked}
                onChange={() => this.toggleAutoClosingPrice()}
                />
              <label className="form-check-label" htmlFor="latest-closing-price">Use last closing price</label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="transactionCost">Commissions and Fees</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fe fe-dollar-sign"></i></span>
            </div>
            <InputField
              type="text"
              id="transactionCost"
              stylingClasses="form-control no-border-radius rounded-right"
              controlled
              value={transactionCost || ''}
              onInputChange={(value) => this._updateTransactionCost(value)}
              placeholder='Enter the total additional fees paid (optional)'
              clearValidation={clearInputValidation}
              />
          </div>
        </div>

        <Button
          dashClass="btn-block"
          type="button"
          clickAction={this.submitTicker}
          disabled={this._isIncomplete()}
          name={!isEmpty(txToEdit) ? "Save Edit to Existing Transaction" : "Add Transaction"}
          loading={loading} />

      </form>
    );
  }
}

export default withGA(AddTicker);
