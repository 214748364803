import React, { Component } from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import chrono from 'chrono-node';
import _ from 'lodash';

import ToolTip from '../components/ToolTip';
import Dropdown from '../components/Dropdown';
import './ColumnPicker.css';
import {
  SYMBOL_NAME, DATE_NAME, QUANTITY_NAME, PRICE_NAME, TRANSACTION_TYPE_NAME, TRANSACTION_COST_NAME,
  SYMBOL_KEY, DATE_KEY, QUANTITY_KEY, PRICE_KEY, TRANSACTION_TYPE_KEY, TRANSACTION_COST_KEY,
  GOOD, ERR, WARNING, EN, EN_GB, EN_FORMAT, EN_GB_FORMAT } from '../constants';


// error and warning messages
const TEXT_ERROR = 'This column should contain a text type.';
const DOLLAR_ERROR = 'This column should contain a number without any $ value.';
const PERCENTAGE_ERROR = 'This column should contain a number without any % value.';
const NUMBER_ERROR = 'This column should contain a number type. The value must be postive.';
const DATE_ERROR = 'This column should contain a date type';
const BUY_SELL_ERROR = 'This column should contain only the texts "Buy" or "Sell". Consider choosing to not import this column.';
const DATE_WARNING = ' Are you sure you selected the correct date format?';
// other configs
const NUM_OF_PREVIEW_ROWS = 5, BUY = 'BUY', SELL = 'SELL';
const DATE_LOCALE_FORMATS = [EN_FORMAT, EN_GB_FORMAT];

class ColumnPicker extends Component {
  static propTypes = {
    column: PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    rowCount: PropTypes.number.isRequired,
    previewRows: PropTypes.array.isRequired,
    submitData: PropTypes.func.isRequired,
    toggleDoNotImport: PropTypes.func.isRequired,
    updateDateFormat: PropTypes.func.isRequired,
    duplicateColumns: PropTypes.array.isRequired,
    typeCheckValue: PropTypes.shape({
      key: PropTypes.string.isRequired, // 'null', 'good', 'error'
      message: PropTypes.string,
    }),
    dateFormat: PropTypes.string,
    doNotImport: PropTypes.object,
    dateError: PropTypes.bool,
    clearDateError: PropTypes.func.isRequired,
  }

  static defaultProps = {
    typeCheckValue: null,
    dateFormat: null,
    dateError: false,
    doNotImport: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      selection: null,
    };
  }

  componentDidUpdate(prevProp) {
    const { selection } = this.state;
    const { dateFormat, submitData, column: { key } } = this.props;

    if (prevProp.dateFormat !== dateFormat) {
      const matchingData = this.findMatchingData();
      const typeCheckValue = matchingData && !_.isEmpty(matchingData.data) && this.checkType(matchingData.data);
      submitData(key, selection, typeCheckValue);
    }
  }

  selectColumn = async (selection) => {
    const { submitData, clearDateError, column: { key } } = this.props;
    await this.setState({ selection });
    if (key === DATE_KEY) clearDateError();
    const matchingData = this.findMatchingData();
    const typeCheckValue = matchingData && !_.isEmpty(matchingData.data) && this.checkType(matchingData.data);
    submitData(key, selection, typeCheckValue);
  }

  handleDoNotImport = (key) => {
    const { toggleDoNotImport } = this.props;
    toggleDoNotImport(key);
    this.setState({ selection: null });
  }

  findMatchingData = () => {
    const { selection } = this.state;
    const { previewRows } = this.props;
    return !_.isEmpty(previewRows) && previewRows.find(data => data.column === selection);
  }

  selectDateFormat = (selection) => {
    const { updateDateFormat, clearDateError } = this.props;
    const format = selection === EN_GB_FORMAT ? EN_GB : EN;
    clearDateError();
    updateDateFormat(format);
  }

  checkType = (previewData) => {
    const { column, dateFormat } = this.props;
    const warningIndex = [];
    let typeCheckResult = { key: GOOD }, parsedDate;

    // helper functions
    const _isNotText = data => ((data && !isNaN(data)) || (data.indexOf('%') > -1) || (data.indexOf('$') > -1));
    const _isNotBuyOrSell = data => !(data === BUY || data === SELL);
    const _isNotPositiveNumber = data => (parseFloat(data) < 0) || !_.isFinite(parseFloat(data));
    const _containsSymbol = (data, symbol) => data.indexOf(symbol) > -1;

    previewData.forEach((pItem, pIndex) => {
      let data = (pItem || '').trim();
      switch (column.key) {
        case SYMBOL_KEY:
          if (_isNotText(data)) {
            typeCheckResult = { key: ERR, name: SYMBOL_NAME, message: TEXT_ERROR };
          }
          break;
        case DATE_KEY:
          parsedDate = chrono.parse(data);
          if (data && _.isEmpty(parsedDate)) {
            typeCheckResult = { key: ERR, name: DATE_NAME, message: DATE_ERROR };
            return;
          }
          if (!_.isEmpty(parsedDate)) {
            const impliedDay = parsedDate[0].start.knownValues.day;
            const impliedMonth = parsedDate[0].start.knownValues.month;
            if ((dateFormat === EN && impliedMonth > 12)
              || (dateFormat === EN_GB && impliedDay > 12)) {
              warningIndex.push(pIndex);
              typeCheckResult = { key: WARNING, warningIndex, message: DATE_WARNING };
            }
          }
          break;
        case QUANTITY_KEY:
          if (_containsSymbol(data, '$')) {
            typeCheckResult = { key: ERR, name: QUANTITY_NAME, message: DOLLAR_ERROR };
            return;
          }
          if (_containsSymbol(data, '%')) {
            typeCheckResult = { key: ERR, name: QUANTITY_NAME, message: PERCENTAGE_ERROR };
            return;
          }
          if (data && _isNotPositiveNumber(data)) {
            typeCheckResult = { key: ERR, name: QUANTITY_NAME, message: NUMBER_ERROR };
          }
          break;
        case PRICE_KEY:
          if (_containsSymbol(data, '%')) {
            typeCheckResult = { key: ERR, name: PRICE_NAME, message: PERCENTAGE_ERROR };
            return;
          }
          if (_containsSymbol(data, '$')) data = currency(data);
          if (data && _isNotPositiveNumber(data)) {
            typeCheckResult = { key: ERR, name: PRICE_NAME, message: NUMBER_ERROR };
          }
          break;
        case TRANSACTION_TYPE_KEY:
          if (_isNotText(data)) {
            typeCheckResult = { key: ERR, name: TRANSACTION_TYPE_NAME, message: TEXT_ERROR };
          }
          if (_isNotBuyOrSell(data.toUpperCase())) {
            typeCheckResult = { key: ERR, name: TRANSACTION_TYPE_NAME, message: BUY_SELL_ERROR };
          }
          break;
        case TRANSACTION_COST_KEY:
          if (_containsSymbol(data, '%')) {
            typeCheckResult = { key: ERR, name: TRANSACTION_COST_NAME, message: PERCENTAGE_ERROR };
            return;
          }
          if (_containsSymbol(data, '$')) data = currency(data);
          if (data && _isNotPositiveNumber(data)) {
            typeCheckResult = { key: ERR, name: TRANSACTION_COST_NAME, message: NUMBER_ERROR };
          }
          break;
        default:
          break;
      }
    });
    return typeCheckResult;
  }

  render() {
    const { selection } = this.state;
    const { column, rowCount, previewRows, duplicateColumns, dateFormat, typeCheckValue, doNotImport, dateError } = this.props;
    const headers = !_.isEmpty(previewRows) && previewRows.map(data => data.column);
    const matchingData = this.findMatchingData();
    const showError = duplicateColumns.includes(selection);

    // tool tip section
    let toolTip;
    if (typeCheckValue && typeCheckValue.key) {
      const { key, message } = typeCheckValue;
      if (key === ERR) {
        toolTip = (
          <div className="p-0" style={{transform: 'translateY(-7px)'}}>
            <ToolTip tooltipId={column.name} popupText={message}>
              <span className="badge badge-danger"><i className="fe fe-x-circle" /> {ERR}</span>
            </ToolTip>
          </div>);
      } else if (key === WARNING) {
        toolTip = (
          <ToolTip tooltipId={column.name} popupText={message}>
            <span className="badge badge-warning"><i className="fe fe-alert-triangle" /> {WARNING}</span>
          </ToolTip>);
      } else if (column.key === DATE_KEY && !dateFormat) {
        toolTip = (
          <div className="p-0" style={{transform: 'translateY(-7px)'}}>
            <ToolTip tooltipId={column.name} popupText="Confirm the date format using the dropdown below">
              <span className="badge badge-warning"><i className="fe fe-alert-triangle" /> Confirm date format...</span>
            </ToolTip>
          </div>);
      } else if (key === GOOD) {
        toolTip = (
          <div className="badge badge-success" style={{transform: 'translateY(-7px)'}}>
            <span className=""><i className="fe fe-check-circle" /> {GOOD}</span>
          </div>);
      }
    }

    // footer section
    let footer;
    if (column.key === SYMBOL_KEY && rowCount) {
      footer = (
        <div className="card-footer bordered">
          <p className="m-0">{rowCount > NUM_OF_PREVIEW_ROWS ? `+ ${rowCount - NUM_OF_PREVIEW_ROWS} more transactions` : `Total of ${rowCount} transactions`}</p>
        </div>
      );
    } else if (column.key === DATE_KEY) {
      footer = (
        <div className="card-footer bordered py-2 px-3">
          <Dropdown text="Select date format" id={column.key}  clickAction={this.selectDateFormat} menu={DATE_LOCALE_FORMATS} dashColor={dateError ? 'danger' : 'white'} maxHeight={200} width={300} />
        </div>
      );
    } else if (column.key === TRANSACTION_TYPE_KEY || column.key === TRANSACTION_COST_KEY) {
      footer = (
        <div className="card-footer bordered">
          <div className="row align-items-center">
            <div className="col-8">
              Don't import column
            </div>
            <div className="col-auto">
              <div className="custom-control custom-checkbox-toggle">
                <input type="checkbox" className="custom-control-input" id={column.key} key={column.key} onChange={() => this.handleDoNotImport(column.key)} checked={doNotImport[column.key]}/>
                <label className="custom-control-label" htmlFor={column.key}></label>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="import-column mr-4">
        <div className="d-flex align-items-center justify-content-between">
          <h3>{column.name}</h3>
          {toolTip}
        </div>

        <div className="card column-picker-inner-container">

          <div className="card-header px-3">
            <Dropdown id="column-name" label="" text={selection || "Select a column"} clickAction={this.selectColumn} menu={headers} dashColor={showError ? 'danger' : 'outline-primary'} maxHeight={200} width={250} />
          </div>
          { matchingData && !_.isEmpty(matchingData.data)
            ? (
              <ul className="card-table">
                {matchingData.data.map((data, index) => <li className="card-table-item" key={index}>{data}</li>)}
              </ul>
            )
            : (
              <div className="card-body bg-light">
                <div className="column-list d-flex justify-content-center align-items-center">
                  <div className="card card-inactive text-center py-2 px-4 m-0">No column selected</div>
                </div>
              </div>
            )
          }

          {footer}


          {/* overlay when user selects do not import option */}
          <div className={ doNotImport[column.key] ? "overlay" : null } />


        </div>

      </div>
    );
  }
}

export default ColumnPicker;
