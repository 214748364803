import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { COMPANY, TOTAL_QUANTITY } from '../constants';

class ShowHideColumns extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    toggleAction: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  state = {}

  render() {
    const { columns, toggleAction } = this.props;
    return (
      <div>
        <ul className="list-group">
          {columns.map(({ key, name, hidden }) => {
            if (key === COMPANY || key === TOTAL_QUANTITY) return null;
            return (
              <li key={key} className="list-group-item d-flex justify-content-between">
                <span>{name}</span>
                <div className="custom-control custom-checkbox-toggle">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={key}
                    value={key}
                    checked={!hidden}
                    onChange={() => toggleAction(key)}
                  />
                  <label className="custom-control-label" htmlFor={key} />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ShowHideColumns;
