import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Parse from 'parse';

import Card from '../components/Card';
import ActionLink from '../components/ActionLink';
import Button from '../components/Button';
import ErrorBox from '../components/ErrorBox';
import ButtonGroup from '../components/ButtonGroup';
import { SETTINGS } from '../constants';

const ANNUAL = 0;
class BillingModalContent extends Component {
  static propTypes = {
    saveBillingInfoFromModal: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    setErrorMessage: PropTypes.func,
    addNewCard: PropTypes.func,
  }

  static defaultProps = {
    errorMessage: '',
    setErrorMessage: () => {},
    addNewCard: () => {},
    buttonText: 'Submit',
    planPrice: 0.0,
    nextBillingDate: '',
    planTitle: null,
    creditCardInfo: null,
    changePlanInformation: false,
    cancelTitle: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      buttons: [
        { name: SETTINGS.PLAN_OPTIONS.ANNUAL, active: true },
        { name: SETTINGS.PLAN_OPTIONS.MONTHLY, active: false },
      ],
      planType: {
        name: SETTINGS.PLAN_OPTIONS.ANNUAL,
      },
      tdhSubscriptionPrice: 99, //default value
      tdhPlanPrice: 49, //default value
    }
    this.password = '';
    this.mobileNumber = null;
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.billingState = null;
    this.zipCode = '';
    this.country = null;
    this.cardType = null;
    this.cardNo = null;
    this.expMonth = null;
    this.expYear = null;
    this.securityCode = null;
    this.nameOnCard = '';
  }

  async componentWillMount() {
    const planInfo = await Parse.Cloud.run('getProductInformation');
    const tdhSubscriptionPrice = _.get(planInfo, 'tdhSubscriptionData[0].plan_price', 99);
    const tdhPlanPrice = _.get(planInfo, 'tdhTrailPrice', 49);
    this.setState({ tdhPlanPrice, tdhSubscriptionPrice });
  }

  saveBillingInfo = () => {
    const { saveBillingInfoFromModal, selectedPlan, cancelTitle } = this.props;
    const { buttons } = this.state;
    const button = buttons[ANNUAL];

    const submittedBillingInfo = {
      renew: !cancelTitle,
    };
    // using current credit card and changing plan
    if(selectedPlan) submittedBillingInfo.selectedPlan = button.active ? selectedPlan.annual : selectedPlan.monthly;
    saveBillingInfoFromModal(submittedBillingInfo);
  }

  cancelPlan = () => {
    const { saveBillingInfoFromModal } = this.props;
    saveBillingInfoFromModal( { cancel: true } );
  }

  handleButtonGroupClick = (input) => {
    let { buttons } = this.state;
    buttons = [
      { name: SETTINGS.PLAN_OPTIONS.ANNUAL, active: (input.name === SETTINGS.PLAN_OPTIONS.ANNUAL) },
      { name: SETTINGS.PLAN_OPTIONS.MONTHLY, active: (input.name === SETTINGS.PLAN_OPTIONS.MONTHLY) },
    ];
    this.setState({ planType: input, buttons });
  }

  render() {
    const { errorMessage, buttonText, planTitle, selectedPlan, creditCardInfo, changePlanInformation, cancelTitle, addNewCard } = this.props;
    const { buttons, tdhSubscriptionPrice, tdhPlanPrice } = this.state;
    const creditCardValidation = (creditCardInfo && creditCardInfo.validation_status === 'Good');
    const button = buttons[ANNUAL];
    return (
      <div className="my-3 text-left mx-3 p-0">
        {planTitle && (
          <div className="container p-0">
              <div className="d-flex flex-column p-0 m-0">
                <div className="container d-flex justify-content-between p-0">
                  <div className="font-m font-weight-bold">{planTitle}</div>
                  { ( _.size(selectedPlan) >= 2 ) ?
                    (
                      <div className="d-flex flex-column">
                        {selectedPlan &&
                        (
                          <div className="font-weight-bold">
                            Price <span>${ button.active ? ((selectedPlan.annual.plan_price)/12).toFixed(2) : (selectedPlan.monthly.plan_price) }/mo</span>
                          </div>
                        )}
                        <div className={!button.active ? "hideContent" : ""}>Total: ${selectedPlan.annual.plan_price}</div>
                      </div>
                    ) : ( // tdh subscription
                      <div className="d-flex flex-column">
                        <div className="font-weight-bold">${tdhPlanPrice}*</div>
                      </div>
                    ) }
                </div>
                {
                (_.size(selectedPlan) >= 2) &&
                  (
                    <div className="mt-2">
                      <ButtonGroup dashClass="btn-outline-secondary" buttons={buttons} clickAction={this.handleButtonGroupClick} />
                    </div>
                  )
                }
              </div>
          </div>
          )}
        {
          changePlanInformation && (
            <div className="alert alert-light p-4 mt-4">
              <p className="mb-0">
              Your plan automatically renews at the end of your billing cycle. You can stop this renewal at any time by clicking the red ‘cancel’ button at the bottom of this page. If you cancel your renewal, you will not receive a refund for the remainder of the term for your specific plan.
              </p>
            </div>
          )
        }
        {(!creditCardInfo) ? (
          <div className="my-3">
            <div className="d-flex flex-column align-items-center py-4">
              <h3>Please Add Payment Method</h3>
              <div><Button name="Add New Card" clickAction={() => { addNewCard() }} /></div>
            </div>
          </div>
        ) : (
          <div>
            <ErrorBox errorMsg={errorMessage} />
            <p><strong>We'll bill your saved credit card:</strong></p>
            <Card>
              <div className="d-flex justify-content-between">
                <div>
                  <span>{creditCardInfo.card_type + ' ending ' + creditCardInfo.card_number.substring(creditCardInfo.card_number.length - 4)}</span>
                  <span className={`ml-2 badge ${creditCardValidation ? "badge-soft-success" : "badge-soft-danger"}`}>{ creditCardValidation ? 'Active' : 'Inactive'}</span>
                </div>
              </div>
            </Card>
            <div className="my-3 d-flex flex-column">
              <Button dashClass="btn-block" type="button" name={buttonText} clickAction={() => this.saveBillingInfo()}/>
              { (_.size(selectedPlan) <= 1 ) && (
                /* TDH - Option*/
                // TODO - Update price dynamically for TDH subscription price
                <React.Fragment>
                  <small>
                    *Automatically renews after the 1st year for ${tdhSubscriptionPrice}/year unless you choose to cancel before the renewal date.
                  </small>
                </React.Fragment>
              ) }
              {changePlanInformation && cancelTitle && (
                <div className="my-3 text-center">
                  <ActionLink textTheme="danger" text={cancelTitle} clickAction={() => this.cancelPlan()} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default BillingModalContent;
