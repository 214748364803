import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckboxItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onChangeAction: PropTypes.func,
    checked: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    title: null,
    body: null,
    id: null,
    onChangeAction: () => {},
    checked: false,
  }

  render() {
    const { title, body, id, disabled, onChangeAction, checked } = this.props;
    return (
      <div className="row">
        <div className="custom-control custom-checkbox row">
          <input id={id} className="custom-control-input" type="checkbox" name="destination" disabled={disabled} checked={checked} onChange={onChangeAction} />
          <label htmlFor={id} className="custom-control-label">&nbsp;</label>
        </div>
        { title && (
          <h3 className={`col-8 ${disabled ? 'text-muted' : ''}`}>
            {title}
          </h3>)
        }
        { body && (
          <div className={`col-8 ${disabled ? 'text-muted' : ''}`}>
            {body}
          </div>
        )}
      </div>
    );
  }
}

export default CheckboxItem;
