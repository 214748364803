import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';

import App from './App';


// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// dashkit
import './assets/css/theme.css';
import './assets/css/main.css';
import './assets/fonts/feather/feather.css';
import './assets/js/theme';

const Parse = require('parse');

Parse.initialize('app');
Parse.serverURL = '/api';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
