import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import './Tooltip.css';

class ToolTip extends Component {
  static propTypes = {
    tooltipId: PropTypes.string.isRequired,
    popupText: PropTypes.string,
    placement: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    popupText: null,
    placement: 'top',
    className: '',
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { tooltipId } = this.props;
    $(`[data-toggle="${tooltipId}"]`).tooltip();
  }

  render() {
    const { tooltipId, popupText, placement, children, className } = this.props;
    return (
      <div className={`tooltip-trigger ${className}`}>
        <div className="p-0" data-toggle={tooltipId} data-placement={placement} title={popupText}>
          { children }
        </div>
      </div>
    );
  }
}

export default ToolTip;
