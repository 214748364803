import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { NOTIFICATION_TYPES } from '../constants/notifications';
import './Notification.css';

const getFormattedNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const PortfolioList = ({portfolios, mainTitle}) => (
  <span className={mainTitle ? "portfolio-url" : "notification-pretitle"}>
    {portfolios[0] != null && portfolios.slice(0, 2).map((p, i) => <React.Fragment key={p.id+i}>{i > 0 ? ', ' : null}<Link to={{ pathname: '/portfolio', state: { redirectWithPortfolioId: p.id }} }>{p.name}</Link></React.Fragment>)}
    {portfolios.length > 2 ? <span> + {portfolios.length - 2} more</span> : null}
  </span>
);

const DividendAnnouncement = ({portfolioName, symbol, companyName, exDate, rateChange, rateChangePercent, time}) => (
  <React.Fragment>
    <div className="notification-text">
      {
        rateChange >= 0.001 ? <React.Fragment><strong title={companyName}>{symbol}</strong> just increased their dividend rate with an ex-date of <strong>{exDate}</strong> <span className="badge badge-success">+{rateChangePercent}%</span></React.Fragment>
        : rateChange <= -0.001 ? <React.Fragment><strong title={companyName}>{symbol}</strong> just cut their dividend rate with an ex-date of <strong>{exDate}</strong> <span className="badge badge-danger">-{rateChangePercent}%</span></React.Fragment>
        : <React.Fragment><strong title={companyName}>{symbol}</strong> just announced their next dividend with an ex-date of <strong>{exDate}</strong> <span className="badge badge-secondary">0.00%</span></React.Fragment>
      }
      <PortfolioList portfolios={[portfolioName]} />
    </div>
    <div className="timestamp">{time}</div>
  </React.Fragment>
);

const DividendPayment = ({portfolioName, symbol, companyName, paymentAmount, time}) => (
  <React.Fragment>
    <div className="notification-text">
      <strong title={companyName}>{symbol}</strong> just paid you a dividend of <strong>${paymentAmount}</strong>.
      <PortfolioList portfolios={[portfolioName]} />
    </div>
    <div className="timestamp">{time}</div>
  </React.Fragment>
);

const PortfolioValuation = ({portfolioId, portfolioName, valuationAmount, valuationChange, valuationChangePercent, time}) => (
  <React.Fragment>
    <div className="notification-text">
      {
        valuationChange >= 0.001 ? <React.Fragment>Your <PortfolioList portfolios={[{name: portfolioName, id: portfolioId}]} mainTitle={true} /> portfolio valuation <strong>increased</strong> to <strong>${getFormattedNumber(valuationAmount)}</strong> <span className="badge badge-success">+{getFormattedNumber(valuationChange)} ({valuationChangePercent}%)</span></React.Fragment>
        : valuationChange <= -0.001 ? <React.Fragment>Your <PortfolioList portfolios={[{name: portfolioName, id: portfolioId}]} mainTitle={true} /> portfolio valuation decreased to <strong>${getFormattedNumber(valuationAmount)}</strong> <span className="badge badge-danger">-{getFormattedNumber(valuationChange)} ({valuationChangePercent}%)</span></React.Fragment>
        : <React.Fragment>Your <PortfolioList portfolios={[{name: portfolioName, id: portfolioId}]} mainTitle={true}/> portfolio valuation has remained flat at <strong>${valuationAmount}</strong> <span className="badge badge-secondary">0.00 (0.00%)</span></React.Fragment>
      }
    </div>
    <span className="timestamp">{time}</span>
  </React.Fragment>
);

class Notification extends Component {
  COMPONENT_MAP = {
    [NOTIFICATION_TYPES.DIVIDEND_ANNOUNCEMENT]: DividendAnnouncement,
    [NOTIFICATION_TYPES.DIVIDEND_PAYMENT]: DividendPayment,
    [NOTIFICATION_TYPES.PORTFOLIO_VALUATION]: PortfolioValuation,
  }

  static propTypes = {
    tagName: PropTypes.oneOf(['li', 'div']),
    notification: PropTypes.object.isRequired,
  }

  static defaultProps = {
    tagName: 'li',
  };

  timeString(date) {
    const now = moment();
    const diffDays = now.diff(date, 'days');
    const diffHours = now.diff(date, 'hours');
    const diffMinutes = now.diff(date, 'minutes');

    // NOTE: Time since notification was created as short string
    let time;
    if (diffDays >= 7) {
      time = Math.trunc(diffDays / 7) + 'w';
    } else if (diffDays > 0) {
      time = diffDays + 'd';
    } else if (diffHours > 0) {
      time = diffHours + 'h';
    } else if (diffMinutes > 0) {
      time = diffMinutes + 'm';
    } else {
      time = 'now';
    }

    return time;
  }

  render() {
    const { COMPONENT_MAP, timeString } = this;
    const { notification } = this.props;
    const type = notification.get('type');
    const viewed = notification.get('viewed');
    const rateChange = notification.get('rateChange');
    const valuationChange = notification.get('valuationChange');

    // NOTE: Calculate rate change percentage from dollar value
    let rateChangePercent = 0, valuationChangePercent = 0;
    if (type === NOTIFICATION_TYPES.DIVIDEND_ANNOUNCEMENT) {
      rateChangePercent = Math.abs(rateChange) / (notification.get('dividendRatePerShare') - rateChange);
    } else if (type === NOTIFICATION_TYPES.PORTFOLIO_VALUATION) {
      valuationChangePercent = Math.abs(valuationChange) / (notification.get('valuationAmount') - valuationChange);
    }

    const Component = COMPONENT_MAP[type];
    const props = {
      portfolioId: notification.get('portfolioId'),
      portfolioName: notification.get('portfolioName'),
      symbol: notification.get('symbol'),
      companyName: notification.get('companyName'),
      exDate: moment.utc(notification.get('exDate')).format('MMM. D, YYYY'),
      paymentAmount: (notification.get('paymentAmount') || 0).toFixed(2),
      valuationAmount: (notification.get('valuationAmount') || 0).toFixed(2),
      valuationChange: (valuationChange && valuationChange.toFixed(2)) || 0,
      valuationChangePercent: Math.abs(valuationChangePercent).toFixed(2),
      rateChange: rateChange || 0,
      rateChangePercent: Math.abs(rateChangePercent).toFixed(2),
      rateChangeType: notification.get('rateChangeType'),
      time: timeString(notification.get('createdAt')),
    };

    return (
      <li className={`notification ${viewed ? '' : 'new'}`}>
        <Component {...props} />
      </li>
    );
  }
}

export default Notification;
