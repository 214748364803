import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import Parse from 'parse';

import PlansPage from './PlansPage';
import SignupPage from './SignupPage';
import AccountPage from './AccountPage';
import VerifyEmailPage from './VerifyEmailPage';
import Loader from '../../components/Loader';

import { getTrackingCode } from '../../utils/trackingCode';
import { getParseConfiguration } from '../../utils/configuration';
import { withGA } from '../../utils/googleAnalytics';
import { SETTINGS, ERR_INTERNAL_ERROR, PARSE_CONFIGURATION, PATHS } from '../../constants';

import './Onboarding.css';

const ProductInformation = Parse.Object.extend('InfusionsoftProductInformation');
class Onboarding extends Component {
  static propTypes = {
    setProgressPercentage: PropTypes.func.isRequired,
  }

  constructor(props) {

    // need to declare const variables before assigning to this.___ in order for redirect of infusionsoft > onboarding page to persist params, per Nan.
    const url = new URL(window.location.href);
    const infusionSoftEmail = url.searchParams.get("inf_field_Email"); // this parameter is from infusionsoft order form	
    const userEmail = url.searchParams.get("useremail"); // this parameter will be used for email verification	
    const iaEmail = url.searchParams.get("iaEmail"); // this parameter will be used for continue sign-up for IA (Investor Alley) user account	
    const orderId = url.searchParams.get('orderId');
    const mltEmail = url.searchParams.get('EMAIL') || url.searchParams.get('Email') || url.searchParams.get('email') || url.searchParams.get('inf_email'); // email from mlt ad that redirects to divcaster -- used to prefill the form in signup
    const planCode = url.searchParams.get('code'); // plan codes

    super(props);
    this.state = {
      activePage: null,
      userInfo: null,
      selectedPlan: null,
      plans: {
        id: null,
        BASIC_PLAN: {
          name: 'Basic',
          caption: 'Free!',
          subscriptions: {
            monthly: {
              plan_price: 0,
            },
            annual: {
              plan_price: 0,
            },
          },
        },
        PREMIUM_PLAN: {
          id: null,
          name: 'Premium',
          caption: 'Most Popular!',
          subscriptions: {
            monthly: {
              plan_price: 0,
            },
            annual: {
              plan_price: 0,
            },
          },
        },
        BUNDLE_PLAN: {
          id: null,
          name: 'Premium + TDH',
          caption: 'Best Deal!',
          subscriptions: {
            monthly: {
              plan_price: 0,
            },
            annual: {
              plan_price: 0,
            },
          },
        },
        TDH_SUBSCRIPTION: {
          tdhPlanPrice: null,
          tdhSubscriptionPrice: null
        }
      },
      userAbandonedSignUp: true,
      loadingPlan: true,
      loading: true,
      trackingCode: getTrackingCode(),
      errorMessage: null
    };

    this.processPlan = !!infusionSoftEmail;
    this.infusionsoftEmail = infusionSoftEmail;
    this.userEmail = userEmail;
    this.iaEmail = iaEmail;
    this.orderId = orderId;
    this.mltEmail = mltEmail;
    this.planCode = planCode;
  }

  async componentWillMount() {
    const { userInfo } = this.props;
    const { trackingCode } = this.state;
    const { SIGNUP, PLANS, COMPLETE } = PATHS;
    try {
      // if there are parameters sent from infusionsoft (after user enter finished signing up plan), go to continue verify email;
      // or user doesnot verify email yet, send to verify email
      if (this.infusionsoftEmail) {
        const url = new URL(window.location.href);
        const inf_field_Email = url.searchParams.get("inf_field_Email");
        const inf_field_FirstName = url.searchParams.get("inf_field_FirstName");
        const inf_field_LastName = url.searchParams.get("inf_field_LastName");
        const queries = `inf_field_Email=${inf_field_Email}&inf_field_FirstName=${inf_field_FirstName}&inf_field_LastName=${inf_field_LastName}`;
        // sign-up users
        await Parse.Cloud.run('signUpAccount', { email: inf_field_Email, invalidateCache: true, trackingCode, orderId: this.orderId });
        
        this.setPage(SETTINGS.ONBOARDING.COMPLETE, queries);
        this.setState({ activePage: COMPLETE });
      } else if(this.userEmail){
        this.setPage(SETTINGS.ONBOARDING.COMPLETE);
        this.setState({ activePage: COMPLETE });
      } else if(this.iaEmail) {
        await this.getProductInformation();
        this.setPage(SETTINGS.ONBOARDING.PLAN_PAGE);
        const userInfo = await Parse.Cloud.run('getUserInformation', { email: this.iaEmail });
        this.setState({ userInfo, activePage: PLANS });
      } else {
        await this.getProductInformation();
        this.setState({ userInfo, activePage: SIGNUP });
      }
    } catch (error) {
      this.setState({ errorMessage: ERR_INTERNAL_ERROR });
      console.log('Error during sign-up new users', error);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { setProgressPercentage } = this.props;
    const { ACCOUNT, COMPLETE, PLANS } = PATHS
    // when url path changes, this updates the state's page tracker
    // which rerenders the component with the correct view
    const { page } = this.props.match.params;
    if(prevProps.match.params.page !== page) {
      this.setState({ activePage: page});
      
      switch(page) {
        case PLANS:
         return setProgressPercentage(35);
        case ACCOUNT: //TODO: remove this later
          return setProgressPercentage(50);
        case COMPLETE:
          return setProgressPercentage(100);
        default:
          setProgressPercentage(0);
      }
    }
  }
    
  async getProductInformation() {
    const { plans } = this.state;
    try {
      const configurationQuery = new Parse.Query(ProductInformation);
      configurationQuery.limit(1);
      const results = await configurationQuery.find();
      const productConfiguration = results[0];

      const premiumPlans = await productConfiguration.get('premiumPlan');
      const bundlePlan = await productConfiguration.get('bundlePlan');
      const tdhSubscriptionInformation = await productConfiguration.get('infusionsoftTdhSubscription');
      const tdhTrailPrice = await productConfiguration.get('tdhTrialPrice');
      
      // note: planCode index start from 0, but plan price index 0 is for default plan price
      // so index need to be increased by 1 position
      const planCodes = await productConfiguration.get('planCodes');
      const planIndex = planCodes && planCodes.includes(this.planCode) ? (planCodes.indexOf(this.planCode) + 1) : 0;

      plans.PREMIUM_PLAN.subscriptions.monthly = premiumPlans.monthly[planIndex];
      plans.PREMIUM_PLAN.subscriptions.annual = premiumPlans.yearly[planIndex];
      plans.BUNDLE_PLAN.subscriptions.monthly = bundlePlan.monthly[planIndex];
      plans.BUNDLE_PLAN.subscriptions.annual = bundlePlan.yearly[planIndex];
      plans.TDH_SUBSCRIPTION.tdhSubscriptionPrice = tdhSubscriptionInformation[0].plan_price;
      plans.TDH_SUBSCRIPTION.tdhPlanPrice = tdhTrailPrice;

      const productIds = await getParseConfiguration(PARSE_CONFIGURATION.PRODUCT_IDS);
      plans.BASIC_PLAN.id = productIds.BASIC;
      plans.PREMIUM_PLAN.id = productIds.PREMIUM;
      plans.BUNDLE_PLAN.id = productIds.PREMIUM_TDH;

      this.setState({ 
        plans, 
        loadingPlan: false, 
        // if the code is valid, save to state and pass it to child component
        planCode: planCodes && planCodes.includes(this.planCode) ? this.planCode : null 
      });
    } catch (error) {
      console.log('Could not get product information', error);
      this.setState({ errorMessage: ERR_INTERNAL_ERROR })
    }
  }

  /**
   * note on percentage complete:
   * Sign-up page = 0%
   * Select plan = 25%
   * Add account information = 50%
   * Billing Information (handle by infusionsoft) = 75%
   * Verification Email = 100%
   */
  setPage = (page, queries) => {
    const { setProgressPercentage, history } = this.props;
    const { SIGNUP, PLANS, ACCOUNT, COMPLETE } = PATHS;
    switch (page) {
      // when setting page, it updates the router history with respective path
      // which then updates the view via componentDidUpdate
      case SETTINGS.ONBOARDING.PLAN_PAGE:
        setProgressPercentage(25);        
        history.push(`/${SIGNUP}/${PLANS}`);
        break;
      case SETTINGS.ONBOARDING.ACCOUNT_PAGE:
        setProgressPercentage(50);
        if(queries) {
          history.push(`/${SIGNUP}/${ACCOUNT}?${queries}`)
        } else {
          history.push(`/${SIGNUP}/${ACCOUNT}`);
        }
        break;
      case SETTINGS.ONBOARDING.COMPLETE:
        setProgressPercentage(100);
        history.push(`/${SIGNUP}/${COMPLETE}`);
        break;
      default:
        setProgressPercentage(0);
        history.push(`${this.mltEmail ? `/${SIGNUP}?EMAIL=${this.mltEmail}` : `/${SIGNUP}`}`);
      }
  }

  updateUserInfo = (userInfo) => {
    this.setState({ userInfo });
  }

  setSelectedPlan = (selectedPlan) => {
    this.setState({ selectedPlan });
  }

  signUpAbandoned = (bool) => {
    this.setState({ userAbandonedSignUp: bool });
  }

  render() {
    const { activePage, userInfo, selectedPlan, plans, loadingPlan, trackingCode, planCode, loading, errorMessage } = this.state;
    const email = this.mltEmail || get(userInfo, 'email_addresses[0].email');
    let page;

    if (!!loading) {
      return (
        <div className="loading-container">
          <Loader />
        </div>
      )
    }

    switch (activePage) {
      case PATHS.PLANS:
        page = <PlansPage 
                  setPage={this.setPage} 
                  setSelectedPlan={this.setSelectedPlan} 
                  trackingCode={trackingCode}
                  userInfo={userInfo} 
                  selectedPlan={selectedPlan} 
                  plans={plans} 
                  loadingPlan={loadingPlan}
                  planCode={planCode}
                  userEmail={email}
                />;
        break;
      // TODO: Remove this page
      case PATHS.ACCOUNT:
        page = <AccountPage 
                setPage={this.setPage} 
                userInfo={userInfo} 
                trackingCode={trackingCode}
                processPlan={this.processPlan} 
                selectedPlan={selectedPlan} 
                orderId={this.orderId}
                infusionsoftEmail={this.infusionsoftEmail} 
                updateUserInfo={this.updateUserInfo}
                iaUserSignup={!!this.iaEmail} 
                signUpAbandoned={this.signUpAbandoned}
              />;
        break;
      case PATHS.COMPLETE:
        page = <VerifyEmailPage 
                setPage={this.setPage} 
                userInfo={userInfo}
                userEmail={this.userEmail} 
              />;
        break;
      default:
        page = <SignupPage mainErrorMessage={errorMessage} setPage={this.setPage} updateUserInfo={this.updateUserInfo} email={email} />;
    }
    return (
      <div className="onboarding-container">
        {page}
      </div>
    );
  }
}

export default withGA(withRouter(Onboarding));
