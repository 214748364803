import $ from 'jquery';

const tableScroll = () => {
  const tableHeader = $('#portfolio-table-header');
  const tableBody = $('#portfolio-table-body');
  const tableFooter = $('#portfolio-table-footer');
  const draggable = $('#draggable');
  let height, width, maxLeft, maxTop, minLeft, minTop, ypos, xpos, itop, ileft;
  // Draggable Behavior for custom scroll bar:
  draggable.on('mousedown', (e) => {
    const dr = $('#draggable').addClass('drag').css('cursor', 'pointer');
    height = dr.outerHeight();
    width = dr.outerWidth();
    maxLeft = dr.parent().offset().left + dr.parent().width() - dr.width();
    maxTop = dr.parent().offset().top + dr.parent().height() - dr.height();
    minLeft = dr.parent().offset().left;
    minTop = dr.parent().offset().top;

    ypos = dr.offset().top + height - e.pageY;
    xpos = dr.offset().left + width - e.pageX;
    $('.container-fluid').on('mousemove', (e) => {
      itop = e.pageY + ypos - height;
      ileft = e.pageX + xpos - width;
      if (dr.hasClass('drag')) {
        if (itop <= minTop) { itop = minTop; }
        if (ileft <= minLeft) { ileft = minLeft; }
        if (itop >= maxTop) { itop = maxTop; }
        if (ileft >= maxLeft) { ileft = maxLeft; }
        dr.offset({ top: itop, left: ileft });

        // TODO: find calculation to synch ratio of scrollbar & table
        tableHeader.scrollLeft(ileft);
        tableBody.scrollLeft(ileft);
      }
    }).on('mouseup', () => {
      dr.removeClass('drag');
    });
  });

  // Synching Table Header & Table Body scroll positions:
  tableBody.on('scroll', () => {
    if (tableBody.is(':hover')) {
      tableHeader.scrollLeft(tableBody.scrollLeft());
      tableFooter.scrollLeft(tableBody.scrollLeft());

      // TODO: find calculation to synch ratio of scrollbar & table
      draggable.offset({ left: tableBody.scrollLeft() });
    }
  });

  tableHeader.on('scroll', () => {
    if (tableHeader.is(':hover')) {
      tableBody.scrollLeft(tableHeader.scrollLeft());
      tableFooter.scrollLeft(tableHeader.scrollLeft());
    }
  });

  tableFooter.on('scroll', () => {
    if (tableFooter.is(':hover')) {
      tableHeader.scrollLeft(tableFooter.scrollLeft());
      tableBody.scrollLeft(tableFooter.scrollLeft());
    }
  });
};

const portfolioHeaderScroll = () => {
  $(window).on('scroll', () => {
    const portfolioHeader = $('#portfolio-header');
    const togglePortfolioHeader = $('#toggle-portfolio-header');
    const elementTop = portfolioHeader.offset() && portfolioHeader.offset().top;
    const viewportTop = $(window).scrollTop();

    togglePortfolioHeader.toggleClass('fade-in',  elementTop === viewportTop);
  });
};

export { tableScroll, portfolioHeaderScroll };
