import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Parse from 'parse';

import ReportModal from '../../components/ReportModal';

import './GenerateReport.css';

export async function generateReport(data) {
  await Parse.Cloud.run('generateReport', data);
}

class GenerateReport extends Component {
  static propTypes = {
    refreshReports: PropTypes.func,
    displayNewReportAlert: PropTypes.func,
  }

  static defaultProps = {
    refreshReports: () => {},
    displayNewReportAlert: () => {},
  }

  refreshReports = () => {
    // NOTE: delaying this a bit so we can give the job a
    //       chance to save the report before we query for it
    setTimeout(_ => {
      this.props.displayNewReportAlert();
      this.props.refreshReports();
      this.props.history.push('/reports/');
    }, 200);
  }

  callGenerateReport = async (data) => {
    await generateReport(data);
    this.refreshReports();
  }

  render() {
    const { tagIds } = this.props;

    return (
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" data-toggle="modal" data-target="#user-list-report-modal">
              <div className="card-body">
                <div className="text-left">
                  <i className="fe fe-users" style={{fontSize: '32px'}}/>
                </div>
                <h2 className="card-title my-3">
                  User List
                </h2>
                <p className="card-text text-muted mb-2">Generate a list of users by account type.</p>
              </div>
            </button>
          </div>
          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" data-toggle="modal" data-target="#user-portfolio-report-modal">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <i className="fe fe-pie-chart" style={{fontSize: '32px'}}/>
                </div>
                <h2 className="card-title my-3">
                  User Portfolio
                </h2>
                <p className="card-text text-muted mb-2">Generate a list of all the companies in a user's portfolio.</p>
              </div>
            </button>
          </div>
          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" data-toggle="modal" data-target="#holdings-report-modal">
              <div className="card-body card-full-height">
                <div className="text-left color-dark">
                  <i className="fe fe-list" style={{fontSize: '32px'}}/>
                </div>
                <h2 className="card-title my-3">
                  Holdings
                </h2>
                <p className="card-text text-muted mb-2">Generate a list of companies owned by all users and the gross dividend collected.</p>
              </div>
            </button>
          </div>
          <div className="col-12 col-lg-4 pb-4">
            <button className="card card-full-height card-hoverable m-0" data-toggle="modal" data-target="#tdh-report-modal">
              <div className="card-body card-full-height">
                <div className="text-left">
                  <img src="/img/logo-tdh-target.png" alt="The Dividend Hunter" style={{height: '32px', width: '32px'}} />
                </div>
                <h2 className="card-title my-3">
                  The Dividend Hunter
                </h2>
                <p className="card-text text-muted mb-2">Generate a list of TDH companies and the gross dividends collected by users.</p>
              </div>
            </button>
          </div>
        </div>
          <ReportModal
            id="user-list-report-modal"
            header="New User List Report"
            type="USER_LIST"
            tagIds={tagIds}
            selectUser="multiple"
            onSubmit={this.callGenerateReport}
          />
          <ReportModal
            id="user-portfolio-report-modal"
            header="New User Portfolio Report"
            type="USER_PORTFOLIO"
            tagIds={tagIds}
            selectUser="single"
            onSubmit={this.callGenerateReport}
            />
          <ReportModal
            id="holdings-report-modal"
            header="New Holdings Report"
            type="HOLDINGS"
            tagIds={tagIds}
            selectCompany={true}
            onSubmit={this.callGenerateReport}
            />
          <ReportModal
            id="tdh-report-modal"
            header="New TDH Report"
            type="TDH"
            tagIds={tagIds}
            onSubmit={this.callGenerateReport}
          />
      </div>
    );
  }
}

export default withRouter(GenerateReport);
