import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import _ from 'lodash';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { PlaidLink } from 'react-plaid-link';
import { withRouter } from 'react-router-dom';

import Button from '../../components/Button';
import AddTicker from '../../containers/AddTicker';
import { PLAID_ERROR_CODE } from '../../constants/plaid';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import InvestmentInstitution from '../../modals/InvestmentInstitution';
import Loader from '../../components/Loader';
import Modal from '../../containers/Modal';
import SelectSubAccounts from '../../modals/SelectSubAccounts';
import UpdateInstitution from '../../modals/UpdateInstitution';
import Toast from '../../components/Toast';

import {
  getInvestmentInstitutions,
  saveSubAccountsToPortoflio
} from '../../parse';

import {
  ERROR_CONNECTING_INSTITUTION,
  ERROR_DELETE_INSTITUTION,
  ERROR_GET_INSTITUTIONS,
  ERROR_TOKEN_LINK,
  NO_VALID_TRANSACTIONS,
  PLAID_CONNECT_ACCOUNT_ERROR,
} from '../../constants/error';

import './NoTransaction.css';

const TOAST_DELAY = 5000;
const INVESTMENTS = "investments";

const NoTransaction = ({
  activePortfolio,
  closeModal,
  getPortfolioData,
  onBasicPlan,
  onUpdateActivePortfolio,
  onUpdateInvestmentInstitution,
  openModal,
}) => {
  const [linkToken, updateLinkToken] = useState();
  const [plaidUpdateModeToken, updatePlaidUpdateModeToken] = useState();
  const [institutions, updateInstitutions] = useState([]);
  const [activeInstitution, updateActiveInstitution] = useState();
  const [subAccounts, updateSubAccounts] = useState([]);
  const [selectedSubAccounts, updateSelectedSubAccounts] = useState([]);
  const [notification, updateNotification] = useState();
  const [loading, updateLoading] = useState(false);
  const [modalLoading, updateModalLoading] = useState(false);

  let toastTimeout;

  useEffect(() => {
    $('[role="tooltip"]').hide();
    createLinkToken();
    getAllInvestmentInstitutions();
  }, []);

  const showNotification = (type, message) => {
    updateNotification({ type, message });
    if (toastTimeout) {
      clearTimeout(toastTimeout);
    }
    toastTimeout = setTimeout(() => hideNotification(), TOAST_DELAY);
  }

  const hideNotification = () => {
   updateNotification();
  }

  async function createLinkToken() {
    try {
      const token = await callParseCloudCreateLinkToken();
      updateLinkToken(token);
    } catch (e) {
      console.error('Error loading plaid.', e);
      showNotification('error', ERROR_TOKEN_LINK);
    }
  };

  const getAllInvestmentInstitutions = async () => {
    try {
      const institutions = await getInvestmentInstitutions();
      updateInstitutions(institutions);
    } catch (e) {
      console.error('Error getting existing investment institutions.', e);
      showNotification('error', ERROR_GET_INSTITUTIONS);
    }
  }

  const onLoginSuccess = async (publicToken, { institution }) => {
    const institutionId = institution.institution_id;
    const institutionName = institution.name;

    try {
      const investmentInstitutionId = await callParseCloudExchangeTokenAndSaveAccess(publicToken, institutionId, institutionName);
      const institutionObj = {
        id: investmentInstitutionId,
        name: institutionName,
        institutionId: institutionId,
      };

      const investmentAccounts = await callParseCloudGetAllInvestmentAccounts(investmentInstitutionId);
      updateSelectedSubAccounts([]);
      updateActiveInstitution(institutionObj);
      updateSubAccounts(investmentAccounts);
      openModal('#select-sub-accounts');

    } catch (e) {
      console.error('Error obtaining access token: ', e);
      showNotification('error', ERROR_GET_INSTITUTIONS);
    }
  };

  const getInvestmentAccounts = async () => {
    const investmentAccounts = await callParseCloudGetAllInvestmentAccounts(activeInstitution.id);
    updateSelectedSubAccounts([]);
    updateSubAccounts(investmentAccounts);
  };

  const onUpdateSuccess = async () => {
    try {
      await getInvestmentAccounts();
      closeModal('#update-institution');
      openModal('#select-sub-accounts');

    } catch (e) {
      console.error('Error obtaining access token: ', e);
      showNotification('error', ERROR_GET_INSTITUTIONS);
    }
  }

  const onConfirmExistingInstitutionImport = async () => {
    try {
      await getInvestmentAccounts();
      closeModal('#investment-institution');
      openModal('#select-sub-accounts');
    } catch (e) {
      // If Plaid indicates ITEM_LOGIN_REQUIRED in its error message, it means user has to re-enter in credential.
      // Retrieve a new linkToken specific to the expired investment institution:
      if (e.message === PLAID_ERROR_CODE.ITEM_LOGIN_REQUIRED) { // e.code === 141
        try {
          const newToken = await Parse.Cloud.run('createUpdateLinkToken', { id: activeInstitution.id });
          updatePlaidUpdateModeToken(newToken);
          closeModal('#investment-institution');
          openModal('#update-institution');
        } catch (e) {
          updateActiveInstitution();
          showNotification('error', PLAID_CONNECT_ACCOUNT_ERROR);
        }
      } else {
        updateActiveInstitution();
        showNotification('error', PLAID_CONNECT_ACCOUNT_ERROR);
      }
    } finally {
      closeModal('#investment-institution');
    }
  }

  const onConnectInstitutionBySubAccounts = async () => {
    const investmentInstitutionId = activeInstitution.id;
    const portfolioId = activePortfolio.id;
    const subAccountIds = selectedSubAccounts;

    closeModal('#select-sub-accounts');

    try {
      updateLoading(true);
      const hasTransactions = await callParseCloudImportInvestmentDataFromPlaid(investmentInstitutionId, portfolioId, subAccountIds);
      if (!hasTransactions) {
        return showNotification('error', NO_VALID_TRANSACTIONS);
      }

      // Save sub account ids to DB
      await saveSubAccountsToPortoflio(portfolioId, subAccountIds);

      onUpdateActivePortfolio({...activePortfolio, investmentInstitutionId });
      onUpdateInvestmentInstitution(activeInstitution);

      await getPortfolioData();

    } catch (e) {
      console.error('Error getting investment transactions: ', e);
      showNotification('error', ERROR_CONNECTING_INSTITUTION);
    } finally {
      updateLoading(false);
    }
  };

  const onInstitutionClick = (institution) => {
    updateActiveInstitution(institution);
    openModal('#investment-institution');
  }

  // Modal: Delete
  const onDeleteInstitution = async () => {
    updateModalLoading(true);
    try {
      await callParseCloudDeleteInstitution(activeInstitution.id);
      // update state
      const filterdInstitution = institutions.filter(({ id }) => id !== activeInstitution.id);
      updateInstitutions(filterdInstitution);
      updateActiveInstitution();
    } catch (e) {
      console.error('Error deleting selected institution', e);
      showNotification('error', ERROR_DELETE_INSTITUTION);
      updateLoading(false);
    } finally {
      updateModalLoading(false);
      closeModal('.modal');
    }
  }

  const handleSubAccountClick = (id) => {
    if (!id) {
      return;
    }
    let newSelections = [];
    if(selectedSubAccounts.includes(id)) {
      newSelections = selectedSubAccounts.filter(accountId => accountId !== id);
    } else {
      newSelections = [...selectedSubAccounts, id];
    }
    updateSelectedSubAccounts(newSelections);
  }

  // Parse Cloud Functions
  const callParseCloudCreateLinkToken = async () => {
    return await Parse.Cloud.run('createLinkToken');
  };
  const callParseCloudDeleteInstitution = async (activeinstitutionId) => {
    return await Parse.Cloud.run('deleteInvestmentInstitutionById', { id: activeinstitutionId });
  };
  const callParseCloudExchangeTokenAndSaveAccess = async (publicToken, institutionId, institutionName) => {
    return await Parse.Cloud.run('exchangeTokenAndSaveAccess', { publicToken, institutionId, institutionName });
  };
  const callParseCloudGetAllInvestmentAccounts = async (investmentInstitutionId) => {
    return await Parse.Cloud.run('getAllInvestmentAccounts', { id: investmentInstitutionId });
  };
  const callParseCloudImportInvestmentDataFromPlaid = async (investmentInstitutionId, portfolioId, subAccountIds) => {
    return await Parse.Cloud.run('importInvestmentDataFromPlaid', {
      id: investmentInstitutionId,
      portfolioId,
      subAccountIds,
    });
  };

  if (loading) {
    return (
      <div style={{ display: "inline-block", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Loader />
      </div>
    )
  }

  return (
    <div className="empty-container">
      <div className="container">
        <div className="row justify-content-center my-5">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h1 className="m-0"><i className="fe fe-pie-chart mr-3" style={{transform: 'translateY(1.5px)', display: 'inline-block'}} />{activePortfolio.name}</h1>
              <div style={{textAlign: 'right'}}>
                <button className="btn btn-sm btn-white" onClick={() => openModal('#portfolioSideModal')}>Switch Portfolio</button>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h2>This portfolio is empty!</h2>

                { onBasicPlan ? (
                  <div>
                    <p className="lead m-0">Get started below by adding a transaction.</p>
                    <AddTicker
                      activePortfolio={activePortfolio}
                      getPortfolioData={getPortfolioData}
                      showNotification={showNotification}
                    />
                  </div>
                ) : (
                  <div>
                    { linkToken && (
                      <>
                        <p className="lead m-0">Connect Divcaster directly to your brokerage account! This feature is the simplest and easiest way to load your portfolio into Divcaster.</p>
                        <PlaidLink
                          token={linkToken}
                          onSuccess={onLoginSuccess}
                          product={[INVESTMENTS]}
                          style={plaidLinkStyle}
                        >
                          Connect New Account
                        </PlaidLink>
                      </>
                    )}

                    { !_.isEmpty(institutions) && (
                        <div className="card-footer bordered pl-0">
                          <p className="lead m-0 mb-3">Use an existing connected investment institution:</p>
                          {institutions.map((institution) => {
                            const { id, name } = institution;
                            return (
                              <Button
                                key={id}
                                name={name}
                                type="button"
                                dashColor="light"
                                dashClass="institution"
                                clickAction={() => onInstitutionClick(institution)}
                                disabled={loading}
                              />
                            )
                          })}
                        </div>
                      )
                    }

                    <div className={(linkToken || !_.isEmpty(institutions)) ? "card-footer bordered pl-0" : null}>
                      <p className="lead m-0">If you have already have a CSV file from your broker, you can import it by <a href="#!" onClick={() => openModal('#import-transactions')}>clicking here</a>.</p>
                    </div>

                    <div className="card-footer bordered pl-0">
                      <p className="lead">You may also manually add a transaction below:</p>
                      <AddTicker
                        activePortfolio={activePortfolio}
                        getPortfolioData={getPortfolioData}
                        showNotification={showNotification}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="position-absolute">
        <Toast notification={notification} onClose={() => hideNotification()} />
      </div>

      <Modal modalId="investment-institution">
        <InvestmentInstitution
          activeInstitution={activeInstitution}
          callConnectInstitution={onConfirmExistingInstitutionImport}
          callDeleteFunction={() => openModal('#delete-institution')}
          loading={loading}
        />
      </Modal>

      <Modal modalId="delete-institution">
        <DeleteConfirmationModal
          callDeleteFunction={onDeleteInstitution}
          copyInsertion={{ text: `This will unlink ALL of your portfolios that were connected and cannot be undone`, button: `Yes, Disconnect Account` }}
        />
      </Modal>
      <Modal modalId="select-sub-accounts">
        <SelectSubAccounts
          subAccounts={subAccounts}
          activeInstitution={activeInstitution}
          selectedSubAccounts={selectedSubAccounts}
          onSubAccountClick={handleSubAccountClick}
          confirmImport={onConnectInstitutionBySubAccounts}
          loading={loading}
        />
      </Modal>
      <Modal modalId="update-institution">
        <UpdateInstitution
          activeInstitution={activeInstitution}
          onDeleteInstitution={onDeleteInstitution}
          linkToken={plaidUpdateModeToken}
          onCloseModal={closeModal}
          onUpdateSuccess={onUpdateSuccess}
          loading={modalLoading}
        />
      </Modal>
    </div>
  )
}

NoTransaction.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getPortfolioData: PropTypes.func.isRequired,
  activePortfolio: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export const plaidLinkStyle = {
  marginTop: "16px",
  marginBottom: "16px",
  padding: "0.5rem 0.75rem",
  borderRadius: "0.375rem",
  backgroundColor: "#236cdc",
  borderColor: "#236cdc",
  color: "white",
}

export default withRouter(NoTransaction);
