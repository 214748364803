import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';

import ActionLink from '../components/ActionLink';
import Button from '../components/Button';
import Ellipses from '../components/Ellipses';
import InputField from '../components/InputField';
import ToolTip from '../components/ToolTip';

import { ERR_BASIC_PLAN_TICKER_LIMIT_REACHED, ERR_EMPTY_PORTFOLIO_NAME, PORTFOLIO_NAME_MAX_LENGTH, TX_LIMIT_PER_PORTFOLIO, ERR_MAX_TX_LIMIT_REACHED } from '../constants';
import { getPortfolios, duplicatePortfolio, renamePortfolio } from '../parse';
import { eventPortfolioDuplicated } from '../utils/googleAnalytics';
import './TableControl.css';

const BASIC_PLAN_TICKER_LIMIT = 10;
const LOCKED_POP_UP_TEXT = "This portfolio is linked to your brokerage account and can't be edited. To make changes, please unlink or duplicate this portfolio.";

class TableControl extends Component {
  static propTypes = {
    tickers: PropTypes.array,
    userIsAdmin: PropTypes.bool,
    txToDelete: PropTypes.array,
    onBasicPlan: PropTypes.bool.isRequired,
    showNotification: PropTypes.func.isRequired,
    activePortfolio: PropTypes.object.isRequired,
    portfolioSummary: PropTypes.object.isRequired,
    showOpenPositions: PropTypes.bool.isRequired,
    onToggleOpenClosePosition: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tickers: [],
    txToDelete: [],
  }

  constructor(props) {
    super(props);
    this._typeahead = '';
    this.state = {
      allowNew: false,
      isLoading: false,
      options: [],
      selectedTicker: null,
      tickerQuantity: null,
      portfolios: null,
      requestRename: false,
    };
  }

  duplicate = async(portfolioId) => {
    const { showNotification, updatePortfolios, setActivePortfolio } = this.props;
    const newCopy = await duplicatePortfolio(portfolioId);
    eventPortfolioDuplicated();
    updatePortfolios();
    setActivePortfolio(newCopy);
    showNotification('success', 'Complete Duplicate Portfolio');
  }

  rename = async(e) => {
    const { activePortfolio, showNotification, setActivePortfolio, updatePortfolios } = this.props;
    e.preventDefault();

    if(!this.portfolioName) showNotification('error', ERR_EMPTY_PORTFOLIO_NAME);

    let renamedPortfolio = await renamePortfolio(activePortfolio.id, this.portfolioName);
    await setActivePortfolio(renamedPortfolio);

    updatePortfolios();
    this.setState({ requestRename: false });
  }

  openModal = modalId => (
    $(`${modalId}`).modal()
  )

  createTransaction() {
    const { onBasicPlan, tickers, showNotification, portfolioSummary } = this.props;
    const basicPlanLimitReached = onBasicPlan && tickers.length >= BASIC_PLAN_TICKER_LIMIT;

    if (portfolioSummary.txCount >= TX_LIMIT_PER_PORTFOLIO) {
      return showNotification('error', ERR_MAX_TX_LIMIT_REACHED);
    }
    if (basicPlanLimitReached) {
      return showNotification('error', ERR_BASIC_PLAN_TICKER_LIMIT_REACHED);
    }

    this.openModal('#addTicker');
  }

  togglePosition = (e, open) => {
    e.preventDefault();
    this.props.onToggleOpenClosePosition && this.props.onToggleOpenClosePosition(open);
  }

  onRefreshClick = async () => {
    const { onRefreshPlaidPortfolio } = this.props;
    await onRefreshPlaidPortfolio();
  }

  render() {
    const { activePortfolio, isPlaidConnectionExpired, onBasicPlan, onExportTransactions, userIsAdmin, openDeleteModal,  showNotification, showOpenPositions, tdh, txToDelete } = this.props;
    const { requestRename, isLoading } = this.state;
    
    const controlDisabled = !!activePortfolio.investmentInstitutionId;
    const lastUpdatedDate = _.get(activePortfolio, 'plaidLinkUpdatedAt');
    
    const dropdownList = [
      { name: 'Export CSV', clickAction: () => onExportTransactions && onExportTransactions() },
      { name: 'Duplicate Portfolio', clickAction: () => { this.duplicate(activePortfolio.id); } },
      { name: 'Rename Portfolio', clickAction: () => { this.setState({ requestRename: true }); } },
      { name: 'Delete Portfolio', clickAction: async() => {
        const portfolios = await getPortfolios();
        if (portfolios.length > 1) openDeleteModal(activePortfolio);
        else showNotification('error', 'Must have at least one portfolio');
      } },
    ]

    return (
      <div id="portfolio-header" className="table-control-container">
        { ((userIsAdmin && activePortfolio.name === 'The Dividend Hunter') || (activePortfolio.name !== 'The Dividend Hunter'))
          ?
          (
            <div className="control-section">

              <ul className="nav navbar-expand nav-tabs mr-4 nav-tabs-table-control">
                <li className="nav-item text-nowrap">
                  <a href="/#" className={`nav-link no-highlight m-0 pb-4 ${showOpenPositions ? 'active' : ''}`} onClick={e => this.togglePosition(e, true)}>Open Positions</a>
                </li>
                <li className="nav-item text-nowrap">
                  <a href="/#" className={`nav-link no-highlight m-0 pb-4 ${showOpenPositions ? '' : 'active'}`} onClick={e => this.togglePosition(e, false)}>Closed Positions</a>
                </li>
              </ul>

              { onBasicPlan ? (
                !controlDisabled && ( 
                  <div>
                    <Button
                      name="Create Transaction"
                      dashColor="primary"
                      clickAction={this.createTransaction}
                      />
                    <ToolTip tooltipId='import-transactions' popupText='Premium Feature'>
                      <div className='btn btn-outline-primary disabled ml-3'>
                        Import CSV
                      </div>
                    </ToolTip>
                  </div>
                  )
                ) : (
                  <div className="d-flex flex-row align-items-center">
                    {/* TODO: Extract nested ternaries to a render function */}
                    { controlDisabled ? (
                      <>
                      <ToolTip tooltipId='import-transactions' popupText={LOCKED_POP_UP_TEXT}>
                        <div className="btn btn-primary disabled">Create Transaction</div>
                      </ToolTip>
                      <ToolTip tooltipId='import-transactions' popupText={LOCKED_POP_UP_TEXT}>
                        <div className="btn btn-outline-primary disabled ml-3">Import CSV</div>
                      </ToolTip>
                        <Button
                          name="Refresh Portfolio"
                          dashColor="info"
                          dashClass="ml-3"
                          disabled={isLoading || isPlaidConnectionExpired}
                          clickAction={this.onRefreshClick}
                        />
                        <Button
                          name="Unlink Portfolio "
                          dashColor="light"
                          dashClass="ml-3"
                          disabled={isLoading}
                          clickAction={() => this.openModal('#unlinkPortfolio')}
                        />
                      </>
                    ) : (
                      <div>
                        <Button
                          name="Create Transaction"
                          dashColor="primary"
                          clickAction={() => this.createTransaction()}
                        />
                        <Button
                          name="Import CSV"
                          dashColor="outline-primary"
                          dashClass="ml-3"
                          disabled={onBasicPlan}
                          clickAction={() => this.openModal('#import-transactions')}
                        />
                      </div>
                      )
                    }
                    <Ellipses
                      dashColor="dark"
                      dashClass="d-inline-block ml-2"
                      dropDownItems={tdh.id === activePortfolio.id ? dropdownList.slice(0, 2) : dropdownList}
                    />
                  </div>
                )}
              
                {/* selected transactions deletion */}
                { !_.isEmpty(txToDelete) && <ActionLink text="Delete Selected" clickAction={() => this.openModal('#deleteTransactions')}/> }

                {/* last refresh date of plaid-linked portfolio */}
                {(controlDisabled && lastUpdatedDate) && <div style={{ color: "#6c757d" }}>Last Refreshed at: {moment.utc(lastUpdatedDate).format('L')}</div>}

                { isPlaidConnectionExpired && <div className="badge badge-danger ml-3">Connection Expired</div>}

              </div>
            )
            : ( <div className="control-section" /> )
          }

          {/* Portfolio Header */}
          <div className="d-flex align-items-center fade-in-right" id="toggle-portfolio-header">
            { requestRename ? (
              <div className="btn btn-light mt-3 d-flex justify-content-between p-1">
                <form
                  onSubmit={(e) => {this.rename(e);}}>
                  <InputField
                    id="rename-portfolio-input"
                    placeholder="Enter Portfolio Name"
                    defaultValue={activePortfolio.name}
                    inputValue={(input) => { this.portfolioName = input; }}
                    type="text"
                    autoFocus
                  />
                </form>
                <div className="d-flex flex-row my-auto">
                  <div className="mx-1">
                    <Button
                      name=""
                      icon="fe fe-x"
                      clickAction={() => this.setState({ requestRename: false })}
                      dashColor="primary btn-sm btn-outline-primary px-1 circle-button"
                      iconStyling="d-flex flex-start"
                    />
                  </div>
                  <div>
                    <Button
                      name=""
                      icon="fe fe-check"
                      clickAction={(e) => this.rename(e)}
                      dashColor="primary btn-sm btn-outline-primary px-1 circle-button"
                      iconStyling="d-flex flex-start"
                    />
                  </div>
                </div>
              </div>
            ) : (
            <div className="m-0 p-0 text-center d-flex flex-column align-items-center">
              <h2 className="m-0 text-dark truncate" style={{ cursor: 'default' }} title={activePortfolio.name}>
                {activePortfolio.name.length < PORTFOLIO_NAME_MAX_LENGTH ? activePortfolio.name : activePortfolio.name.substring(0, PORTFOLIO_NAME_MAX_LENGTH) + '...'}
              </h2>
            </div>
            )}
            {
              activePortfolio.name !== 'The Dividend Hunter' && (
                <button href="/#" data-toggle="modal" data-target="#portfolioSideModal" className="btn btn-sm btn-white ml-3">
                  Switch Portfolio
                </button>
              )
            }
          </div>

          {/* hide columns setting */}
          <div className="control-section">
            {/* <button
              type="button"
              data-toggle="modal"
              data-target="#portfolio-tutorial-modal"
              className="btn btn-white mr-3">
              <i className="fe fe-help-circle mr-2" style={{transform: 'translateY(1px)'}} />
              Watch Tutorial
            </button> */}
            <Button name="" icon="fe fe-settings" dashColor="white" clickAction={() => { this.openModal('#show-hide-columns'); }} />
          </div>
        </div>
      );
    }
  }

  export default TableControl;
