import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Header from '../components/Header';
import UploadBox from '../containers/UploadBox';
import DropZoneInput from '../components/DropZoneInput';
import ErrorBox from '../components/ErrorBox';

const CSV_TYPES = ['.csv', 'text/csv'];

class ImportTransactions extends Component {
  static propTypes = {
    onFileChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
  }

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }

  setErrorMessage = (errorMessage) => {
    this.setState({ errorMessage });
  }

  switchModal = (modalId) => {
    $('#import-transactions').modal('hide');
    $(`${modalId}`).modal();
  };

  render() {
    const { errorMessage } = this.state;
    const { onFileChange } = this.props;
    return (
      <div className="m-4">
        <Header content="Import Transactions" />
        <UploadBox
          onChange={onFileChange}
          acceptableFiles={CSV_TYPES}
          setErrorMessage={this.setErrorMessage}
        >
          <div>
            <DropZoneInput
              info="Drag &amp; Drop File, or Choose File"
              types={['CSV']}
            />
          </div>
        </UploadBox>
        <div>
          <ErrorBox errorMsg={errorMessage} />
        </div>
      </div>
    );
  }
}

export default ImportTransactions;
